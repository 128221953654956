import React from 'react';
import { RangeValueType } from '@/types';
import './index.scss';

interface IProps {
  dateFirst: RangeValueType;
  dateSecond: RangeValueType;
}

export default function Legend (props: IProps) {
  const { dateFirst, dateSecond } = props;
  return (
    <div className="net-sales-summary-legend-wrap">
      <span className="blue-point"/>
      <span>{ `${ dateFirst?.[0].format('MM/DD') } - ${ dateFirst?.[1].format('MM/DD，YYYY') }` }</span>
      <span className="gray-point"/>
      <span>{ `${ dateSecond?.[0].format('MM/DD') } - ${ dateSecond?.[1].format('MM/DD，YYYY') }` }</span>
    </div>
  )
}
