import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import moment from 'moment-timezone';
import { echarts } from '@/utils/echarts';
import { globalState } from '@/stores';
import { RangeValueType } from '@/types';
import Legend from '@/Pages/NetSalesSummary/components/Legend';
import { TableEmptyContent } from '@/Components/EmptyContent';
import ChartTooltip from '@/Pages/NetSalesSummary/components/ChartTooltip';
import { INetSalesByDay } from '@/Pages/NetSalesSummary/interface';
import { formatFullPrice } from '@/utils';
import './index.scss'


interface IProps {
  dateFirst: RangeValueType;
  dateSecond: RangeValueType;
  dataList: Array<INetSalesByDay>
}

export default function NetSalesByDaysOfWeek (props: IProps) {
  const { i18n } = globalState;
  const { dateFirst, dateSecond, dataList } = props;
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    const _chartData = dataList.map(x => {
      return { ...x, dateDisplay: moment(x.date.format('YYYY/MM/DD')).format('ddd') + '##' + x.date.format('YYYY/MM/DD') }
    });
    setChartData(_chartData)
  }, [dataList]);

  const chartConfig = () => ({
    legend: { show: false },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: (params, ticket, callback) => {
        const _title = params[0].axisValue;
        const _data = params[0].data;
        return ReactDOMServer.renderToString(<ChartTooltip title={ _title } data={ _data }/>);
      }
    },
    grid: {
      left: 70,
      top: 10,
      right: 15,
      bottom: 30,
    },
    dataset: {
      dimensions: ['dateDisplay', 'currentNetSales', 'compareNetSales'],
      source: chartData
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        formatter: (datum) => `${ datum.split('##')[0] }`
      }
    },
    yAxis: {
      axisLabel: {
        formatter: (datum) => `${ formatFullPrice(datum) }`
      }
    },
    series: [
      {
        type: 'bar',
        barWidth: 35,
        barGap: 0,
        itemStyle: { color: '#2563EB', borderRadius: [5, 5, 0, 0] },
      },
      {
        type: 'bar',
        barWidth: 35,
        barGap: 0,
        itemStyle: { color: '#BEBEBE', borderRadius: [5, 5, 0, 0] }
      },
    ]
  })


  return (
    <div className="net-sales-summary-by-days-of-week">
      <div className="net-sales-summary-by-days-of-week-header-wrap">
        <div className="net-sales-summary-by-days-of-week-title-wrap">
          <span>{ i18n.t('pc_net_sales_summary_net_sales_by_days_of_week') }</span>
        </div>
      </div>
      <Legend dateFirst={ dateFirst } dateSecond={ dateSecond }/>
      <div className="charts-wrap">
        { chartData.length > 0 ?
          <ReactEChartsCore echarts={ echarts } option={ chartConfig() }/> :
          <div className="no-data">{ TableEmptyContent }</div>
        }
      </div>
    </div>
  )
}