import React, { useState } from 'react';
import './index.scss'
import DashedLine from '../component/DashedLine';
import TextLineNew from '../component/TextLineNew';

const DateSection = (props) => {
  return <div className='refund-date'>
    <DashedLine/>
    <div className='preview-ui-discount-item'>
      <div className='preview-ui-discount-item-info'>
        <TextLineNew
          text={'Refunded Card Amount'}
          style={{
            fontSize: 'md',
            fontWeight: 'bold'
          }}
        />
      </div>
      <div className='preview-ui-discount-item-price'>
        <TextLineNew
          text='-$0.00'
          style={{
            fontSize: 'md',
            fontWeight: 'bold'
          }}
        />
      </div>
    </div>
    <div className='refund-date-item'>
      <div className='refund-date-item-label'>
        <TextLineNew text={'Refund Date:'} />
      </div>
      <div className='refund-date-item-value'>
        <TextLineNew text={'Jun 18, 2024 7:30:00 PM'} />
      </div>
    </div>
  </div>
}
export default DateSection;