import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Form, Radio, Checkbox, TimePicker, Popover, Switch } from 'antd'
import moment from 'moment-timezone';
import { globalState } from '@/stores';
import './Availability.scss'
import { IAvailability, IDayOptions, IOptions, ITimeOptions } from '../interface'
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface IProps {
  editInfo: {
    availableTime: {
      dayOption: IDayOptions
      days: Array<IDayOptions>
      timeOption: ITimeOptions
      endTime: number
      startTime: number
    },
    // onlineOrdering: boolean
  }
}

const Availability = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState
  // const { editInfo: { availableTime, onlineOrdering } } = props
  const { editInfo: { availableTime } } = props
  const [form] = Form.useForm();

  const [dayOptionRadio, setDayOptionRadio] = useState<IAvailability>('specificDaysAndTime');
  const [timeOptionRadio, setTimeOptionRadio] = useState<IAvailability>('always');
  const [timeRange, setTimeRange] = useState<any>(['00:00:00', '00:00:00']);

  const dataSpecialOptions: Array<IOptions> = [
    { label: i18n.t('mon'), value: 'Monday' },
    { label: i18n.t('tue'), value: 'Tuesday' },
    { label: i18n.t('wed'), value: 'Wednesday' },
    { label: i18n.t('thu'), value: 'Thursday' },
    { label: i18n.t('fri'), value: 'Friday' },
    { label: i18n.t('sat'), value: 'Saturday' },
    { label: i18n.t('sun'), value: 'Sunday' },
  ];

  useImperativeHandle(ref, () => {
    return {
      form,
      timeRange
    };
  }, [form, timeRange]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((timeInSeconds % 3600) / 60).toString().padStart(2, '0');
    const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const parseTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const onChangeTimePicker = (time, timeString) => {
    const startTime = parseTime(timeString[0] || '00:00:00');
    const endTime = parseTime(timeString[1] || '00:00:00');
    setTimeRange([startTime, endTime])
  }

  useEffect(() => {
    if (dayOptionRadio === 'always') {
      form.setFieldsValue({
        'days': null
      });
    }
    if (timeOptionRadio === 'always') {
      form.setFieldsValue({
        'customTime': null
      });
      setTimeRange([])

    }
  }, [dayOptionRadio, timeOptionRadio])

  useEffect(() => {
    if (availableTime) {
      const { dayOption, days, timeOption, endTime, startTime } = availableTime

      // 老逻辑兼容，如果是always的话，将转换为specificDaysAndTime，并且days为[1-7]
      setDayOptionRadio('specificDaysAndTime')

      setTimeOptionRadio(timeOption.value)

      // ** 数值二次处理 **
      // * days *
      const newDays = dayOption.value === 'always' ? dataSpecialOptions : days
      const daysData = newDays ? newDays.map(item => item.value) : []

      // * 将字符串转换为秒对象 *
      const startTimeFormatted = formatTime(startTime);
      const endTimeFormatted = formatTime(endTime);
      setTimeRange([startTimeFormatted, endTimeFormatted])
      form.setFieldsValue({
        dayOption: dayOption.value,
        days: daysData,
        timeOption: timeOption.value,
        customTime: [dayjs(`${startTimeFormatted}`, 'HH:mm:ss'), dayjs(`${endTimeFormatted}`, 'HH:mm:ss')]
      });

      // 手动触发更新
      form.submit();
    }
  }, [availableTime])

  // useEffect(() => {
  //   form.setFieldValue('onlineOrdering', onlineOrdering);
  // },
  // [onlineOrdering])

  return (
    <div className='menus-availability'>
      <Card title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{i18n.t('availability')}</span>
          <Popover
            placement='top'
            arrow={false}
            content={<div
              className='popover-text'
              style={{ fontSize: 14 }}
            >{i18n.t('specific_days_and_time_tips')}</div>}
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      } >
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          initialValues={{
            'dayOption': dayOptionRadio,
            'timeOption': timeOptionRadio,
            days: dataSpecialOptions.map(option => option.value)
          }}
        >
          <div>{i18n.t('pc_availability_title')}</div>
          <Form.Item
            name="days"
            rules={[
              { validator: async (_, value) => {
                if (value.length === 0) {
                  return Promise.reject(new Error(i18n.t('pc_availability_days')));
                }
              } },
            ]}
          >
            <Checkbox.Group options={dataSpecialOptions} />
          </Form.Item>

          <div>{i18n.t('pc_menu_hours')}</div>
          <Form.Item name="timeOption">
            <Radio.Group
              onChange={(e) => { setTimeOptionRadio(e.target.value) }}
            >
              <Radio value="always">{i18n.t('pc_opening_hours')}</Radio>
              <Radio value="specificDaysAndTime">{i18n.t('pc_custom_hours')}</Radio>
            </Radio.Group>
          </Form.Item>
          {
            timeOptionRadio === 'specificDaysAndTime' &&
            <Form.Item
              name="customTime"
              rules={[
                { validator: async (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error(i18n.t('pc_availability_time')));
                  }
                } },
              ]}
            >
              <TimePicker.RangePicker
                defaultValue={timeRange[0] && timeRange[1] ? [dayjs(`${timeRange[0]}`, 'HH:mm:ss'), dayjs(`${timeRange[1]}`, 'HH:mm:ss')] : null}
                onChange={(time, timeString) => onChangeTimePicker(time, timeString)}
              />
            </Form.Item>
          }
          {/* <div className='visible-channel'>{ i18n?.t('visible_channel') }</div>
          <div>{ i18n?.t('menu_online_ordering') }</div>
          <Form.Item name="onlineOrdering">
            <Radio.Group>
              <Radio value={true}>{ i18n?.t('yes') }</Radio>
              <Radio value={false}>{ i18n?.t('no') }</Radio>
            </Radio.Group>
          </Form.Item> */}
        </Form>

      </Card>
    </div>
  )
})

export default Availability