import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Image, message, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { SyncOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import './index.scss';
import { ApiQueryOrder } from '@/request/api';
import { globalState } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { formatTime } from '@/utils'
import { observer } from 'mobx-react';
import store from '@/stores/userInfo'

interface IOrderly {
  code: number
  data: {
    discountAmount: string
    latestTime: string
    netSales: string
    orderCount: string
    refreshTime: string
    refundAmount: string
    totalAmount: string
  } | null
  message: string
  success: boolean
}
const Home = observer((props: any) => {
  const navigate = useNavigate();
  const { i18n } = globalState;
  // const fullName = store.getUserInfo.fullName || '';
  // const timeZoneId = store.getShopInfo.timeZone || '';
  const [fullName, setFullName] = useState<string>('');
  const [timeZoneId, setTimeZoneId] = useState<string>('');
  const [newDate, setDate] = useState<any>('');
  const [rotate, setRotate] = useState(false);
  const [homeList, setHomeList] = useState(null);
  const [employeeMenus, setEmployeeMenus] = useState(JSON.parse(localStorage.getItem('employeeMenusRes')));
  const quickAction = [
    {
      name: 'menu',
      url: '/menus/menuBuilder',
      id: 5,
      pc_: 'pc_menu'
    }, {
      name: 'reports',
      url: '/reports/sales',
      id: 20,
      pc_: 'pc_reports'
    }, {
      name: 'employees',
      url: '/employeeList',
      id: 14,
      pc_: 'pc_employees'
    }, {
      name: 'payments',
      url: '/payments/paymentMethods',
      id: 10,
      pc_: 'pc_payments'
    }
  ]

  const dataShow = [
    {
      name: 'pc_net_sales',
      placeholder: 'pc_net_placeholder',
      id: '18',
      errorText: 'pc_no_router',
      data: `$ ${homeList?.netSales ?? 0}`
    },
    // {
    //   name: "pc_gross_sales",
    //   placeholder: "pc_gross_placeholder",
    //   id: "18",
    //   errorText: "pc_no_router",
    //   data: `$ ${homeList?.totalAmount ?? 0}`
    // },
    {
      name: 'pc_orders',
      placeholder: 'pc_orders_placeholder',
      id: '18',
      errorText: 'pc_no_router',
      data: homeList?.orderCount ?? '0'
    }, {
      name: 'pc_discount',
      placeholder: 'pc_discount_placeholder',
      id: '18',
      errorText: 'pc_no_router',
      data: homeList?.discountAmount ?? '0'
    }
  ]
  const getQueryOrder = async () => {
    try {
      const res: any = await ApiQueryOrder({ params: '' })
      if (res.code === 0) {
        setHomeList(res.data);
        const _Date = res.data?.standardRefreshTime
        setDate(formatTime(_Date, 'MM/dd/yyyy HH:mm tt'))
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    // query interface
    getQueryOrder()
  }, [timeZoneId]);

  useEffect(() => {
    setFullName(store.getUserInfo?.fullName)
    setTimeZoneId(store.getShopInfo?.timeZone)
    setEmployeeMenus(JSON.parse(localStorage.getItem('employeeMenusRes')))
  }, [store.getUserInfo, store.getShopInfo]);

  const navigatePage = (name: any, page: string) => {
    if (employeeMenus?.findIndex(x => x.id === name) === -1) {
      message.error(i18n?.t('pc_router_error'));
      return
    }
    navigate(page)
  }

  const clickRefreshDate = async () => {
    setRotate(true)
    await getQueryOrder()
    setRotate(false)
  }



  return (<div className="home-page">
    <Row className='home-info'>
      <Col span={12} className="home-welcome-name">{i18n?.t('pc_welcome')} {fullName}!</Col>
      <Col span={12} className="home-welcome-date">
        <Tooltip title="Data is refreshed every half hour.">
          <QuestionCircleOutlined />
        </Tooltip>
        <span>{i18n?.t('pc_date')}</span>
        <span className="home-welcome-date-text">{newDate}</span>
        <Spin indicator={<SyncOutlined className="home-welcome-date-rotate" spin={rotate} onClick={() => {
          clickRefreshDate()
        }} />} />
      </Col>
    </Row>
    <Row className="home-actions">
      <p className="home-actions-title">{i18n?.t('pc_quick_actions')}</p>
      {
        quickAction.map((item: any) => {
          return (
            <Col key={item.id} span={6} onClick={() => {navigatePage(item.id, item.url)}} style={{ cursor: 'pointer' }}>
              <Image src={require(`../../assets/${item.name}.png`)} className="home-actions-icon" alt="" preview={false} />
              <span className="home-actions-text">{i18n?.t(item.pc_)}</span>
            </Col>
          )
        })
      }
    </Row>
    <Row className="home-data-list">
      {
        dataShow.map((item:any, index: number) => {
          return (<Col onClick={
            () => {
              index === 1 && navigatePage(20, '/reports/orders')
            }
          } className="home-dataShow" key={ item.id + index }>
            <div className="orderBox">
              <div>
                {i18n?.t(item.name)}
                <Tooltip title={i18n?.t(item.placeholder)}>
                  <QuestionCircleOutlined className="home-dataShow-icon" />
                </Tooltip>
              </div>
              <div className="goingtoRight" style={{ display: employeeMenus?.findIndex(x => x.id === 20) === -1 || index !== 1 ? 'none' : 'block' }}>
                <RightOutlined />
              </div>
            </div>
            <span style={{ display: employeeMenus?.findIndex(x => x.id === 20) === -1 ? 'block' : 'none' }} className="ruloData">{i18n?.t('pc_no_router')}</span>
            <span style={{ display: employeeMenus?.findIndex(x => x.id === 20) !== -1 ? 'block' : 'none' }}>{item.data}</span>
          </Col>)
        })
      }
    </Row>
    <Row>
      <Col span={24} className="home-footer-img">
        <Image src={require('../../assets/homeMaskimg.png')} className="home-footer-icon" alt="" preview={false} />
        <p>{i18n?.t('pc_features')}</p>
      </Col>
    </Row>
  </div>)
})
export default Home