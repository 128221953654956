import React from 'react'
import { Button, Result } from 'antd';

const App: React.FC = () => (
  <Result
    status="500"
    title="Sorry, there was an error with the page."
    subTitle=" Please contact the system administrator."
    extra={<Button type="primary" href="/">Back Home</Button>}
  />
);

export default App;