import { Form, Input, Button, Table, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { ApiPermissionCategoryReferData, ApiPermissionQueryPage } from '@/request/api';
import { toRes } from '@/utils';
import Loading from '@/utils/loading';
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import { globalState } from '@/stores';
import './index.scss'

export default function Permissions () {
  const { i18n } = globalState;

  const [form] = Form.useForm()
  const [isInit, setIsInit] = useState(false)
  const [tableData, setTableData] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  })
  const paramsRef = useRef({ name: '', category: null })

  const init = async ()=>{
    Loading.show()
    await fetchTableData();
    await fetchCategoryList();
    Loading.hide()
    return () => {
      Loading.hide()
    }
  }
  useEffect(() => {
    init()
  }, []);

  const fetchTableData = async ({ current, pageSize }: { current?: number, pageSize?: number } = {}) => {
    const params = {
      request:{
        name: paramsRef.current.name ?? '',
        categoryId: paramsRef.current.category ?? null,
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    const [_, data] = await toRes(ApiPermissionQueryPage(params));
    setTableData(data?.dataList ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: pageSize ?? prev.pageSize,
      total: data?.total ?? 0,
      current: current ?? data?.current
    }))
  }

  const fetchCategoryList = async () => {
    const [_, data] = await toRes(ApiPermissionCategoryReferData());
    setIsInit(true)
    setCategoryList(data?.map(x => ({ label: x.name, value: x.id })) ?? [])
  }

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const handleReset = () => {
    form.resetFields();
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const tableColumns = [
    { title: 'ID', dataIndex: 'id', key: 'id', width: 120 },
    { title: i18n.t('permission_name'), dataIndex: 'name', key: 'name', width: 250 },
    { title: i18n.t('permission_display_sort'), dataIndex: 'displayOrder', key: 'displayOrder', width: 150 },
    { title: i18n.t('permission_category'), dataIndex: 'categoryName', key: 'categoryName' }
  ]

  return (
    isInit && <div className='permissions'>
      <div className='title'>{ i18n.t('menu_permissions') }</div>
      <div className='filterWrap'>
        <Form form={ form } layout="inline">
          <Form.Item name={ 'name' }>
            <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: i18n.t('permission_name') }) }/>
          </Form.Item>
          <Form.Item name={ 'category' }>
            <Select
              style={ { minWidth: '150px' } }
              showSearch
              notFoundContent={ SelectEmptyContent }
              placeholder={ i18n.t('select_x', { msg: i18n.t('permission_category') }) }
              onChange={ (x) => form.setFieldValue('category', x) }
              filterOption={ (input, option) => ((option?.label ?? '') as string).includes(input) }
              options={ categoryList }
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={ <SearchOutlined/> } onClick={ handleSearch }>
              { i18n.t('search') }
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={ <RedoOutlined/> } onClick={ handleReset }>
              { i18n.t('reset') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className='tableWrap'>
        <Table
          columns={ tableColumns } dataSource={ tableData }
          pagination={ pager }
          onChange={ handleTableChange }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}