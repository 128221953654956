import React from 'react';
import { Popover } from 'antd';

const UserActions = ({ onMyAccount, onLogout, i18n }: { onMyAccount: () => void; onLogout: () => void; i18n: any }) => (
  <Popover content={(
    <div style={{ width: '100px' }}>
      <div style={{ padding: '8px 8px', cursor: 'pointer' }} className="menuListBox1" onClick={onMyAccount}>{i18n?.t('pc_my_account')}</div>
      <div style={{ padding: '8px 8px', cursor: 'pointer' }} className="menuListBox1" onClick={onLogout}>{i18n?.t('pc_log_out')}</div>
    </div>
  )}
  placement="bottomRight" trigger="click" arrow={false}>
    <span className='iconfont icon-user user-btn'></span>
  </Popover>
);
export default UserActions