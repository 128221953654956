import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Radio, Spin } from 'antd';
import { ApiBatchSavePayMethod, ApiQueryPaymentMethodByUuids } from '@/request/api';
import { removeNullUndefinedFields, to } from '@/utils';
import './index.scss'
import { PAY_TYPE, PAY_TYPE_MAP } from '@/Pages/PaymentMethods/enum';

export default function PaymentMethodsEdit (props) {
  const { editItem, close, fetchTableData } = props
  const { i18n } = globalState;
  const [form] = Form.useForm()
  const [isInit] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const initialValues = {
    display: true,
    name: '',
    payType: PAY_TYPE.OTHER_PAYMENT,
  }
  const init = async () => {
    const _isEdit = !!editItem;
    setIsEdit(_isEdit);
    if (_isEdit) {
      await fetchDetail();
    }
    setLoading(false);
  }

  useEffect(() => {
    init()
  }, []);

  const formRules = {
    name: [
      { required: true, message: i18n.t('please_enter_x', { msg: i18n.t('name') }) },
      () => ({
        validator (_, value) {
          const chineseRegex = /[\u4e00-\u9fa5]/g;
          const chineseCount = (value.match(chineseRegex) || []).length;
          const totalLength = value.length + chineseCount;
          return totalLength > 200 ? Promise.reject(new Error(i18n.t('frontOfHouse_pc_maximum', { length: 200 }))) : Promise.resolve();
        },
      }),
    ],
    payType:[
      { required: true, message: i18n.t('please_select_x', { msg: i18n.t('type') }) },
    ]
  }

  const fetchDetail = async () => {
    const resp: any = await ApiQueryPaymentMethodByUuids({ uuids: [editItem.uuid] })
    if (resp.code === 0) {
      const item = resp?.data?.[0] ?? initialValues;
      const payType = item?.payType?.value ?? item?.payType;
      form.setFieldsValue({
        display: item.display,
        payType: payType === PAY_TYPE.OTHER_PAYMENT ?  PAY_TYPE.OTHER_PAYMENT : undefined,
        name: item.name
      })
    }
    setLoading(false);
  }

  const handleCancel = () => {
    close();
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const resultObj = {
      display: formParams.display,
      payType: { value: PAY_TYPE['OTHER_PAYMENT'], displayName: i18n.t(PAY_TYPE_MAP[PAY_TYPE['OTHER_PAYMENT']]) },
      // payType: { value: formParams.payType, displayName: i18n.t(PAY_TYPE_MAP[formParams.payType]) },
      name: formParams.name,
      systemBuilt: false,
      uuid: editItem?.uuid
    }
    setLoading(true);
    const resp: any = await ApiBatchSavePayMethod({ payMethods: [removeNullUndefinedFields(resultObj)] });
    if (resp.code === 0) {
      message.success(i18n.t('message_success'));
      fetchTableData();
      close();
    }
    setLoading(false)
  }

  return (
    isInit && <Spin spinning={ loading }>
      <div className="payment-methods-edit">
        <div className="header-wrap">
          <div className="title">{ isEdit ? i18n.t('edit_payment_method') : i18n.t('add_payment_methods') }</div>
          <div className="actions">
            <Button onClick={ handleCancel }>{ i18n?.t('cancel') }</Button>
            <Button type="primary" onClick={ handleSave }>{ i18n?.t('save') }</Button>
          </div>
        </div>
        <div className="form-wrap">
          <Form form={ form } layout="vertical" initialValues={ initialValues } requiredMark={ false }>
            <Form.Item name={ 'name' } label={ <div>{ i18n.t('name') }<span className="required">*</span></div> } rules={ formRules.name }>
              <Input
                width={ 400 } placeholder={ i18n.t('enter_x', { msg: i18n.t('name') }) }
                onBlur={ (e) => form.setFieldValue('name', e?.target?.value?.trim?.() ?? '') }
              />
            </Form.Item>
            {/* <Form.Item name="payType" label={ <div>{ i18n.t('type') }<span className="required">*</span></div> } rules={ formRules.payType }>
              <Radio.Group options={ [PAY_TYPE.OTHER_PAYMENT].map(x => ({ value: x, label: i18n.t(PAY_TYPE_MAP[x]) })) }/>
            </Form.Item> */}
            <Form.Item name="display" label={ <div>{ i18n.t('amount_pc_display_on') }<span className="required">*</span></div> }>
              <Radio.Group options={ [{ value: true, label: i18n.t('yes') }, { value: false, label: i18n.t('no') }] }/>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  )
}