import { Form, Input, Button, Table, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { ApiRolesQuery } from '@/request/api';
import { toRes } from '@/utils';
import Loading from '@/utils/loading';
import { globalState } from '@/stores';
import { TableEmptyContent } from '@/Components/EmptyContent';
import './index.scss'

export default function Roles () {
  const i18n = globalState.i18n;

  const [form] = Form.useForm()
  const [isInit, setIsInit] = useState(false)
  const [tableData, setTableData] = useState([])
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  })
  const paramsRef = useRef({ name: '', category: null })

  const roleTypeOptions = [
    { displayName: 'Template', label: 'Template', value: '1' },
    { displayName: 'Normal', label: 'Normal', value: '2' }
  ]

  const init = async ()=>{
    Loading.show()
    await fetchTableData();
    Loading.hide()
    return () => {
      Loading.hide()
    }
  }
  useEffect(() => {
    init()
  }, []);

  const fetchTableData = async ({ current, pageSize }: { current?: number, pageSize?: number } = {}) => {
    const params = {
      request:{
        ...form.getFieldsValue(),
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    const [_, data] = await toRes(ApiRolesQuery(params));
    setIsInit(true);
    setTableData(data?.list ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: pageSize ?? prev.pageSize,
      total: data?.total ?? 0,
      current: current ?? data?.current
    }))
  }

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const handleReset = () => {
    form.resetFields();
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const tableColumns = [
    { title: 'ID', dataIndex: 'roleId', key: 'roleId', width: 120 },
    { title: 'name', dataIndex: 'roleName', key: 'roleName', width: 120 },
    { title: 'merchantId', dataIndex: 'orgId', key: 'orgId', width: 120 },
    { title: 'tenantId', dataIndex: 'tenantId', key: 'tenantId', width: 120 },
    { title: 'name', dataIndex: 'roleName', key: 'roleName', width: 120 },
    { title: 'type', dataIndex: 'roleTypeDesc', key: 'roleTypeDesc', width: 100, render: (val) => i18n.t(val.replace('{', '').replace('}', '')) ?? '' },
    { title: 'permissions', dataIndex: 'permissionNames', key: 'permissionNames', width: 400 },
    { title: 'archived', dataIndex: 'archivedDesc', key: 'archivedDesc', width: 100, render: (val) => i18n.t(val.replace('{', '').replace('}', '')) },
  ]

  return (
    isInit && <div className='rolesWrap'>
      <div className='title'>{ i18n.t('menu_Roles') }</div>
      <div className='filterWrap'>
        <Form form={ form } layout="inline">
          <Form.Item name={ 'roleName' }>
            <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: 'name' }) }/>
          </Form.Item>
          <Form.Item name={ 'tenantId' }>
            <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: 'tenantId' }) }/>
          </Form.Item>
          <Form.Item name={ 'roleType' }>
            <Select
              style={ { minWidth: '150px' } }
              placeholder={ i18n.t('select_x', { msg: 'type' }) }
              onChange={ (x) => form.setFieldValue('type', x) }
              options={ roleTypeOptions }
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={ <SearchOutlined/> } onClick={ handleSearch }>
              { i18n.t('search') }
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={ <RedoOutlined/> } onClick={ handleReset }>
              { i18n.t('reset') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className='tableWrap'>
        <Table
          columns={ tableColumns } dataSource={ tableData }
          pagination={ pager }
          onChange={ handleTableChange }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}