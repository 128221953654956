import React, { useEffect, useState } from 'react';
import './index.scss';
import DashedLine from '../component/DashedLine';
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { ConfigTypeValue } from '../../interface';

interface IValue {
  value: string,
  hide: boolean
}
interface ICheckAndGuestInfoType {
  [key: string]: IValue
}
interface IProps {
  children: ICheckAndGuestInfoType,
  hide: boolean,
  id: string,
  sort: Array<string[]>
}

export default function CheckAndGuestInfoSection (props: IProps) {
  const { children, sort } = props
  const [type, setType] = useState<ConfigTypeValue>('Check');
  const [sortList, setSortList] = useState<Array<string[]>>([[]]);
  const [textLineList, setTextLineList] = useState<Array<any>>([]);
  const [hideTopDivider, setHideTopDivider] = useState<boolean>(false)


  // 有底部虚线的集合
  const bottomDashedLineList: Array<ConfigTypeValue> = ['Kitchen']

  const middleLineArray: string[] = [ // 文字大小为md的四个属性
    'check-info-section-diningOption',
    'check-info-section-salesChannel',
    'check-info-section-tableNumber',
    'check-info-section-checkNumber'
  ];

  const setTextLineFontSize = (type: ConfigTypeValue, ele: string) => {
    if (type === 'Kitchen' && ele === 'check-info-section-salesChannel') {
      return 'sm';
    }
    return middleLineArray.includes(ele) ? 'md' : 'sm';
  }

  const setMarginTop = () => {
    switch (type) {
    case 'Kitchen':
      return '0';

    default:
      return '0'
    }
  }


  useEffect(() => {
    const textLineList = Object.keys(children).map((ele: string) => {
      const item = children[ele];
      return {
        argumentsName: ele,
        style: {
          fontSize: setTextLineFontSize(type, ele),
        },
        hidden: item.hide,
        text: item.value
      }
    })
    setTextLineList(textLineList);
    setType(toJS(printOutDecorationStore.getUrlParamsType));
    // 存 顺序 信息
    setSortList(sort)
  }, [props])


  useEffect(() => {
    // preview 当前值
    const previewData = toJS(printOutDecorationStore.getPreviewData);

    const shouldHideSection = (item) => {
      return item.hide || (!item.hide && item?.modifier?.showOnCustomerCopyOnly);
    };

    const hideKey: Record<string, boolean> = {};
    const sectionsToCheck = ['header-section', 'restaurant-info-section'];

    previewData.forEach(item => {
      if (sectionsToCheck.includes(item.id) && shouldHideSection(item)) {
        hideKey[item.id] = true;
      }
    });

    setHideTopDivider(Object.keys(hideKey).length === sectionsToCheck.length);

  }, [printOutDecorationStore.getPreviewData])

  return (
    <div
      className='check-and-guest-info'
      style={{
        marginTop: setMarginTop()
      }}>
      { !bottomDashedLineList.includes(type) && !hideTopDivider &&  <DashedLine /> }
      <div className='check-and-guest-info-wrap'>
        {
          sortList.map((sorts: Array<string>, index: number) => (
            <div className='check-and-guest-info-wrap-sort-box' key={index}>
              {
                sorts.map((item: string, _index: number) => {
                  const props = textLineList.find(info => info.argumentsName === item);
                  return (props && !props.hidden && <div
                    className={'check-and-guest-info-wrap-sort-item'}
                    key={_index}
                  >

                    {item && item !== 'check-info-section-salesChannel' && <TextLineNew {...props} />}
                  </div>)
                })
              }
            </div>
          ))
        }
      </div>
      { bottomDashedLineList.includes(type) && <DashedLine /> }
    </div>
  )
}