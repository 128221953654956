import React from 'react';
import './index.scss'
import { globalState } from '@/stores';
import { Table } from 'antd';
import { RangeValueType } from '@/types';
import Legend from '@/Pages/NetSalesSummary/components/Legend';
import { formatFullPrice } from '@/utils';
import { DATA_TYPE, INetSalesByDay } from '@/Pages/NetSalesSummary/interface';

interface IProps {
  dateFirst: RangeValueType;
  dateSecond: RangeValueType;
  dataList: Array<INetSalesByDay>
}

export default function ComparisonSummary (props: IProps) {
  const { i18n } = globalState;
  const { dateFirst, dateSecond, dataList } = props;

  const tableColumns = [
    { title: i18n.t('pc_net_sales_summary_net_sales'), dataIndex: 'netSales', render: (val, row) => priceColumnRender(row, val, 'netSales') },
    // { title: i18n.t('pc_net_sales_summary_gross_sales'), dataIndex: 'grossSales', render: (val, row) => priceColumnRender(row, val, 'grossSales') },
    { title: i18n.t('pc_net_sales_summary_table_discounts'), dataIndex: 'discounts', render: (val, row) => priceColumnRender(row, val, 'discounts') },
    { title: i18n.t('pc_net_sales_summary_tax_amount'), dataIndex: 'tax', render: (val, row) => priceColumnRender(row, val, 'tax') },
    { title: i18n.t('pc_net_sales_summary_gratuity'), dataIndex: 'gratuities', render: (val, row) => priceColumnRender(row, val, 'gratuities') },
    { title: i18n.t('pc_net_sales_summary_tip'), dataIndex: 'tips', render: (val, row) => priceColumnRender(row, val, 'tips') },
    { title: i18n.t('pc_net_sales_summary_void'), dataIndex: 'voidSales', render: (val, row) => priceColumnRender(row, val, 'voidSales') },
    { title: i18n.t('pc_net_sales_summary_refund'), dataIndex: 'refunds', render: (val, row) => priceColumnRender(row, val, 'refunds') },
  ]

  const priceColumnRender = (row, val, field) => {
    const incrementVal = Number(row?.incrementData?.[field]);
    return (
      <div className="col-price-wrap">
        { row.incrementData &&
          <div className={ `${ incrementVal > 0 ? 'col-price-green' : (incrementVal < 0 ? 'col-price-red' : '') }` }>{ `${ incrementVal > 0 ? '+' : '' }${ incrementVal }%` }</div>
        }
        <div>{ formatFullPrice(val || 0) }</div>
      </div>
    )
  }

  return (
    <div className="net-sales-summary-comparison-summary">
      <div className="comparison-summary-header-wrap">
        <div className="comparison-summary-title-wrap">
          <span>{ i18n.t('pc_net_sales_summary_comparison_summary') }</span>
        </div>
      </div>
      <Legend dateFirst={ dateFirst } dateSecond={ dateSecond }/>
      <div className="table-wrap">
        <Table
          columns={ tableColumns }
          dataSource={ dataList.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          scroll={ { x: 'max-content' } }
          pagination={ false }
          rowClassName={ (row, index) => {
            if (row.dataType === DATA_TYPE.CURRENT) return 'current-row row-wrap';
            if (row.dataType === DATA_TYPE.COMPARE) return 'comparison-row row-wrap';
          } }
        />
      </div>
    </div>
  )
}