import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import PrintersAndCashDrawers from '@/Pages/PrintersAndCashDrawers';
import PrepStations from '@/Pages/PrepStations';
import Printouts from '@/Pages/Printouts';
import PrintOutDecoration from '../PrintOutDecoration';
import { TabType } from './enum';
import { getQuerys } from '@/utils';
import CashDrawer from '../CashDrawer';
const { TabPane } = Tabs;
export default function Printer () {
  const i18n = globalState.i18n;
  const [activeKey, setActiveKey] = useState(TabType.PRINTERSANDCASHDRAWERS);
  const jumpType = 'printOutDecoration';
  const changeTab = (value) => {
    setActiveKey(value);
  }
  useEffect(() => {
    const { from } = getQuerys(window.location.href);
    if (from === jumpType) {
      setActiveKey(TabType.PRINTOUTS);
    }
  }, [])
  return <>
    <div className='printer'>
      <Tabs activeKey={activeKey} onChange={changeTab} style={{ 'height': '100%' }}>
        <TabPane tab={i18n?.t('print_gearOpening_bill_pc_printersAndCashDrawers')} key={TabType.PRINTERSANDCASHDRAWERS}>
          <PrintersAndCashDrawers activeKey={activeKey}/>
        </TabPane>
        <TabPane tab={i18n?.t('prep_stations')} key={TabType.PREPSTATIONS} style={{ 'height': '100%' }}>
          <PrepStations activeKey={activeKey}/>
        </TabPane>
        <TabPane tab={i18n?.t('devices_Printouts')} key={TabType.PRINTOUTS}>
          <PrintOutDecoration activeKey={activeKey}/>
        </TabPane>
        <TabPane tab={i18n.t('cash_drawer')} key={TabType.CASHDRAWER}>
          <CashDrawer activeKey={activeKey}/>
        </TabPane>
      </Tabs>
    </div>
  </>
}