import React, { useEffect, useState } from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss';
import DashedLine from '../component/DashedLine';
import { ConfigTypeValue } from '../../interface';
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store';
interface IProps {
  text: string;
}

const ShiftSection = () => {
  return (
    <div>
      <div className="credit-card-payment-details-check">
        <TextLineNew
          text="Check #001"
          style={{
            fontWeight: 'bold',
          }}
        />
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Card type1 9999" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Card type2 8888" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Check Total" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Tip" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Paid Total" />
          <TextLineNew text="$0.00" />
        </div>
      </div>
      <div className="credit-card-payment-details-check">
        <TextLineNew
          text="Check #002"
          style={{
            fontWeight: 'bold',
          }}
        />
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Card type1 7777" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Card type2 6666" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Check Total" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Tip" />
          <TextLineNew text="$0.00" />
        </div>
        <div className="credit-card-payment-details-check-info">
          <TextLineNew text="Paid Total" />
          <TextLineNew text="$0.00" />
        </div>
      </div>
    </div>
  );
};

const ZReportSection = () => {
  const data = [
    { check: '1', last4: '1234', tip: '$0.00', total: '$0.00' },
    { check: ' ', last4: '1234', tip: '$0.00', total: '$0.00' },
    { check: '2', last4: '0000', tip: '$0.00', total: '$0.00' },
    { check: '3', last4: '0000', tip: '$0.00', total: '$0.00' },
  ];
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextLineNew text="Check" style={{ textAlign: 'left', flex: 1, fontWeight: 'bold', }} />
        <TextLineNew text="Last4" style={{ textAlign: 'left', flex: 1, fontWeight: 'bold', paddingLeft: 50 }} />
        <TextLineNew text="Tip" style={{ textAlign: 'right', flex: 1, fontWeight: 'bold', paddingRight: 50 }} />
        <TextLineNew text="Total" style={{ textAlign: 'right', flex: 1, fontWeight: 'bold', }} />
      </div>
      <DashedLine gap={{ margin: '8px 0px' }} />
      <div>
        {data.map((item, index) => (
          <div
            key={index}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <TextLineNew
              text={item.check}
              style={{ textAlign: 'left', flex: 1 }}
            />
            <TextLineNew
              text={item.last4}
              style={{ textAlign: 'left', flex: 1, paddingLeft: 50 }}
            />
            <TextLineNew
              text={item.tip}
              style={{ textAlign: 'right', flex: 1, paddingRight: 50 }}
            />
            <TextLineNew
              text={item.total}
              style={{ textAlign: 'right', flex: 1 }}
            />
          </div>
        ))}
      </div>
      <DashedLine gap={{ margin: '8px 0px' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextLineNew text="Total" style={{ textAlign: 'left', flex: 1, fontWeight:'bold' }} />
        <TextLineNew text="" style={{ textAlign: 'left', flex: 1, paddingLeft: 50 }} />
        <TextLineNew text="$0.00" style={{ textAlign: 'right', flex: 1, paddingRight: 50 }} />
        <TextLineNew text="$0.00" style={{ textAlign: 'right', flex: 1 }} />
      </div>
    </div>
  );
};

const CreditCardPaymentDetailsSection = (props: IProps) => {
  const [configType, setConfigType] = useState<ConfigTypeValue>('Check');

  useEffect(() => {
    setConfigType(toJS(printOutDecorationStore.getUrlParamsType));
  }, [printOutDecorationStore.getUrlParamsType]);

  return (
    <div className="credit-card-payment-details">
      <div className="credit-card-payment-details-item">
        <TextLineNew
          text="CREDIT CARD PAYMENT DETAILS"
          style={{
            fontWeight: 'bold',
          }}
        />
      </div>
      <DashedLine gap={{ margin: '8px 0px' }} />
      {configType === 'ShiftReport' && <ShiftSection />}
      {configType === 'ZReport' && <ZReportSection />}
    </div>
  );
};
export default CreditCardPaymentDetailsSection;
