import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import Price from '../components/Price';
import classNames from 'classnames';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';

export default function ServiceChargeList ({ list: tableData = [] }) {
  const { i18n } = globalState;
  const [total, setTotal] = useState()
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)

  const tableColumns = [
    { title: i18n.t('orderDetail_service_charge_name'), dataIndex: 'serviceChargeName', width: 200 },
    { title: i18n.t('orderDetail_service_charge_count'), dataIndex: 'count', width: 90 },
    { title: i18n.t('orderDetail_service_charge_amount'), dataIndex: 'serviceChargeAmount', width: 120, render: val => <Price value={ val }/> },
    // { title: i18n.t('orderDetail_service_charge_tax'), dataIndex: 'serviceChargeTaxAmount', width: 120, render: val => Price },
    { title: i18n.t('orderDetail_service_charge_refund'), dataIndex: 'refundAmount', width: 120, render: val => <Price value={ val }/> },
    { title: i18n.t('pc_sales_summary_total_amount'), dataIndex: 'totalAmount', width: 130, render: val => <Price value={ val }/> },
  ]
  const [showColumnsList, setShowColumnsList] = useState(tableColumns.map(x => x.dataIndex))
  useEffect(() => {
    setTotal(tableData.find(x => x.serviceChargeName === 'Total')?.totalAmount ?? 0)
  }, [tableData]);

  const handleChangeColumns = (showColumnsList) => {
    setIsShowEmptyTable(showColumnsList.length === 0)
    setShowColumnsList(showColumnsList);
  }

  return (
    <div className="sales-summary-service-charge-list">
      <div className="header-wrap">
        <div className="sales-list-title-wrap">
          <span>{ i18n.t('pc_sales_summary_service_charge_total') }: </span>
          <Price value={ total }/>
        </div>
        <div className="actions-wrap">
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>

      </div>
      <div className={ classNames('net-sales-list-table-wrap', { 'no-data': tableData.length === 0 }) }>
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === tableData.length - 1 }) }
          scroll={ { y: 410, x: 'max-content' } }
          pagination={ false }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}