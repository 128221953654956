import React, { useState, useRef, useEffect, useCallback } from 'react'
import './index.scss'
import { Button } from 'antd';
import { useCountDown } from '@/hooks/useCountDown';
import { globalState } from '@/stores';
export interface IProps {
    duration?: number, // 倒计时长度
    length?: number // 验证码位数
    verifyCodeCallBack: (params: any)=>void
    resendCallBack: ()=>void
}
export default function VerifyCode (props: IProps) {
  // 使用倒计时hook
  const {
    count,
    handleStartCountDown
  } = useCountDown(props.duration || 60);
  const i18n = globalState.i18n;
  const [length] = useState<number>(props.length || 6);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [selectedLine, setSelectedLine] = useState<boolean>(false);
  const [cursor, setCursor] = useState<boolean>(false);
  const [startCountDown, setStartCountDown] = useState<boolean>(false);
  const [countDownText, setCountDownText] = useState<string>(i18n?.t('send'));
  const inputEl = useRef(null);
  const handleVerifyCodeSelected = useCallback(() => {
    inputEl.current.focus();
    const value = inputEl.current.value;
    if (value.length <= 0) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(value.length === length ? value.length - 1 : value.length);
    }
    setSelectedLine(!(value.length === length));
    setCursor(true);
  }, [])

  const handleInputChange = useCallback((e: any) => {
    const value = e.target.value.slice(0, 6);
    inputEl.current.value = value;
    setVerifyCode(value);
    props.verifyCodeCallBack(value);
    if (value.length <= length - 1) {
      setSelectedIndex(value.length);
    }
    setSelectedLine(!(value.length === length));
  }, [])

  const handleInputBlur = useCallback(() => {
    setSelectedLine(false);
    setCursor(false);
  }, [])

  const handleVerificationCodeClick = useCallback(() => {
    setStartCountDown(true);
    handleStartCountDown();
    console.log('countDownText === i18n?.t("resend")', countDownText === i18n?.t('resend'));

    if (countDownText === i18n?.t('resend')) {
      props.resendCallBack();
    }
  }, [countDownText])

  useEffect(() => {
    if (count <= 0) {
      setTimeout(() => {
        setStartCountDown(false);
        setCountDownText(i18n?.t('resend'));
      }, 1000)
    }
  }, [count])

  useEffect(() => {
    handleVerificationCodeClick();
  }, [])

  return (
    <div className="verify-code-wrap">
      <input ref={inputEl} onChange={ handleInputChange } onBlur={ handleInputBlur } className="verify-code-input"/>
      <div className="verify-code-container" onClick={ handleVerifyCodeSelected }>
        {
          Array.from({ length: length }).map((ele, index) => {
            return (
              <div className={`character character-default ${selectedIndex === index && selectedLine && 'character-selected-default'} ${selectedIndex === index && cursor && 'character-selected-line'}`} data-id={`${index}`} key={index}>
                { verifyCode[index] }
              </div>
            )
          })
        }
      </div>
      <Button className="verification-code-button" disabled={startCountDown} onClick={ handleVerificationCodeClick }>{ startCountDown ? `${count}s` : countDownText  }</Button>
    </div>
  )
}