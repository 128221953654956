import { IBaseDefaultMapData, ITag } from '../interface';

export const defaultRefundMapData: Array<IBaseDefaultMapData<ITag>> = [{
  'id': 'header-section',
  'title': 'print_decoration_header',
  'defaultValue': false,
  'disabled': false,
  'children': [
    {
      'tag': 'input',
      'defaultValue': [{
        'id': 'header-section-text',
        'value': 'This is header',
        'disabled': false
      }],
    },
    {
      'tag': 'checkboxGroup',
      'defaultValue': [{
        'id': 'showOnCustomerCopyOnly',
        'label': 'Dashboard_PrintConfig_CustomerCopyOnly',
        'value': 'showOnCustomerCopyOnly',
        'disabled': false,
        'modifier': {
          'isModifier': true
        }
      }],
      'modifier': {
        'dividerTop': true
      }
    }
  ]
},
{
  'id': 'restaurant-info-section',
  'title': 'print_decoration_restaurant_info',
  'defaultValue': false,
  'disabled': false,
  'children': [
    {
      'tag': 'checkboxGroup',
      'defaultSort': [['restaurant-info-section-restaurantName'], ['restaurant-info-section-address'], ['restaurant-info-section-phoneNumber']],
      'defaultValue': [{
        'id': 'restaurant-info-section-restaurantName',
        'label': 'Dashboard_PrintConfig_RestaurantName',
        'value': 'restaurant-info-section-restaurantName',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'restaurant-info-section-address',
        'label': 'Dashboard_PrintConfig_RestaurantAddress',
        'value': 'restaurant-info-section-address',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'restaurant-info-section-phoneNumber',
        'label': 'Dashboard_PrintConfig_PhoneNumber',
        'value': 'restaurant-info-section-phoneNumber',
        'checked': true,
        'disabled': false,
      }],
    }, {
      'tag': 'checkboxGroup',
      'defaultValue': [{
        'id': 'showOnCustomerCopyOnly',
        'label': 'Dashboard_PrintConfig_CustomerCopyOnly',
        'value': 'showOnCustomerCopyOnly',
        'disabled': false,
        'modifier': {
          'isModifier': true
        }
      }],
      'modifier': {
        'dividerTop': true
      }
    }
  ]
},
{
  'id': 'check-info-section',
  'title': 'print_decoration_check_info',
  'defaultValue': false,
  'disabled': true,
  'children': [{
    'tag': 'checkboxGroup',
    'defaultSort': [
      ['check-info-section-diningOption', 'check-info-section-tableNumber'],
      ['check-info-section-checkNumber', 'check-info-section-salesChannel'],
      ['check-info-section-serverName', 'check-info-section-guestNumber'],
      ['check-info-section-date'],
    ],
    'defaultValue': [
      {
        'id': 'check-info-section-salesChannel',
        'label': 'pc_product_mix_sales_channel',
        'value': 'check-info-section-salesChannel',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'check-info-section-serverName',
        'label': 'Dashboard_PrintConfig_ServerName',
        'value': 'check-info-section-serverName',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'check-info-section-guestNumber',
        'label': 'Dashboard_PrintConfig_GuestNumber',
        'value': 'check-info-section-guestNumber',
        'checked': true,
        'disabled': false,
      }
    ],
  }]
},
{
  'id': 'guest-info-section',
  'title': 'print_decoration_guest_info',
  'defaultValue': false,
  'disabled': false,
  'children': [{
    'tag': 'checkboxGroup',
    'defaultSort': [
      ['guest-info-section-guestName', 'guest-info-section-guestPhoneNumber'],
      ['guest-info-section-guestNotes']
    ],
    'defaultValue': [
      {
        'id': 'guest-info-section-guestName',
        'label': 'Dashboard_PrintConfig_GuestName',
        'value': 'guest-info-section-guestName',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'guest-info-section-guestPhoneNumber',
        'label': 'Dashboard_PrintConfig_GuestPhoneNumber',
        'value': 'guest-info-section-guestPhoneNumber',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'guest-info-section-guestNotes',
        'label': 'Dashboard_PrintConfig_GuestNotes',
        'value': 'guest-info-section-guestNotes',
        'checked': true,
        'disabled': false,
      },
    ]
  }]
},
{
  'id': 'item-info-section',
  'title': 'print_decoration_refund_info',
  'defaultValue': false,
  'disabled': true,
  'children': [
    {
      'tag': 'spaceTag',
      'defaultValue': [{
        'id': 'item-info-section-narrow',
        'label': 'Dashboard_PrintConfig_DishLineSpacing_Narrow',
        'value': '5',
        'checked': false,
        'disabled': false
      },
      {
        'id': 'item-info-section-mid',
        'label': 'Dashboard_PrintConfig_DishLineSpacing_Mid',
        'value': '15',
        'checked': true,
        'disabled': false,
      },
      {
        'id': 'item-info-section-wide',
        'label': 'Dashboard_PrintConfig_DishLineSpacing_Wide',
        'value': '30',
        'checked': false,
        'disabled': false,
      }],
    },
    {
      'tag': 'checkboxGroup',
      'defaultSort': [['item-info-section-itemNotes']],
      'defaultValue': [{
        'id': 'item-info-section-itemNotes',
        'label': 'Dashboard_PrintConfig_ItemNotes',
        'value': 'item-info-section-itemNotes',
        'disabled': false,
      }],
    }
  ]
},
{
  'id': 'footer-section',
  'title': 'print_decoration_footer',
  'defaultValue': false,
  'disabled': false,
  'children': [
    {
      'tag': 'input',
      'defaultValue': [{
        'id': 'footer-section-text',
        'value': 'This is footer',
        'disabled': false
      }],
    },
    {
      'tag': 'checkboxGroup',
      'defaultValue': [{
        'id': 'showOnCustomerCopyOnly',
        'label': 'Dashboard_PrintConfig_CustomerCopyOnly',
        'value': 'showOnCustomerCopyOnly',
        'disabled': false,
        'modifier': {
          'isModifier': true
        }
      }],
      'modifier': {
        'dividerTop': true
      }
    }]
}
]