import React, { useEffect, useState } from 'react';
import { Button, Collapse, message, Select, Table, Tabs, Tooltip } from 'antd';
import { globalState } from '@/stores';
import dayjs, { Dayjs } from 'dayjs';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { formatDollar, formatPrice, getToday, toRes } from '@/utils';
import { ApiIsOnlineOrderingDeviceList, ApiQueryEmployeeList, ApiQueryOperationLog } from '@/request/api';
import useGetState from '@/hooks/useGetState';
import PepprDatePicker, { DATE_RANGE_TYPE } from '@/Components/DatePicker';
import './index.scss'
import Columns from '@/Components/Columns/Columns';
import { SyncOutlined } from '@ant-design/icons';
import axios from 'axios';
import { IPage, IParamsState, OPERATE_TYPE_I18N, TAB_OPERATE_FILTERS_MAP, TAB_TYPE, TAB_TYPE_I18N } from './enum';
import { ColumnsType } from 'antd/es/table';

let axiosSource = axios.CancelToken.source();

export default function LossReport (props) {
  const { isActive } = props;
  const { i18n } = globalState;

  const [isInit, setIsInit] = useState<boolean>(false)
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tableData, setTableData] = useState([])
  const [curTabType, setCurTabType, getCurTabType] = useGetState<string>(TAB_TYPE.VOID)
  const [employeeAllList, setEmployeeAllList] = useState([])
  const [snAllList, setSnAllList] = useState([])
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState({ columnKey: 'operateTime', order: 'descend' });
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [rangePresets, setRangePresets] = useState([])
  const tableColumns = [
    {
      dataIndex: 'operateTime', key: 'operateTime', title: i18n.t('pc_employee_report_operate_time'), width: 180,
      sorter: true, sortOrder: sortedInfo.columnKey === 'operateTime' ? sortedInfo.order : null, sortDirections: ['ascend', 'descend', 'ascend']
    },
    { dataIndex: 'posDisplayNo', key: 'posDisplayNo', title: i18n.t('pc_employee_report_check#'), width: 100 },
    { dataIndex: 'operatorName', key: 'operatorName', title: i18n.t('pc_employee_report_employee'), width: 120 },
    { dataIndex: 'approverName', key: 'approverName', title: i18n.t('pc_employee_report_approver'), width: 120, render: (val) => val || '-' },
    { dataIndex: 'operateType', key: 'operateType', title: i18n.t('pc_employee_report_type'), width: 150, render: (val) => i18n.t(OPERATE_TYPE_I18N[val]) },
    {
      dataIndex: 'operateObject', key: 'operateObject', title: i18n.t('pc_employee_report_item'), width: 300,
      render: (val) => {
        let list = [];
        (val?.itemList || []).forEach(ele => {
          const existingItem = list.find(f => f.name === ele.name);
          if (existingItem) {
            const index = list.findIndex(f => f.name === ele.name);
            let count = isNaN(list[index].count) ? 0 : +list[index].count;
            count += isNaN(ele.count) ? 0 : +ele.count;
            list[index].count = count;
          } else {
            list.push({
              ...ele,
              count: +(ele.count) || 0
            });
          }
        })
        list = list.map(x => x.count !== 1 && x.count !== 0 ? `${ x.name } ${isNaN(x.count) ? '' : `【${ x.count % 1 === 0 ? x.count : parseFloat(x.count).toFixed(2) }】`}` : x.name)
        if (!list || list.length === 0) return ''
        if (list.length === 1) return <div style={{ overflowWrap: 'anywhere' }}>{list[0]}</div>
        const [_, ...children] = list;
        return (
          <Collapse
            className="colItemCollapse"
            expandIconPosition="end"
            collapsible="icon"
            ghost
            items={[{ key: Math.random().toString(36).substring(2, 5), label: list[0], children: <div>{children.map((x, index) => <div key={index}>{x}</div>)}</div> }]}
          />
        )
      }
    },
    { dataIndex: 'value', key: 'value', title: i18n.t('pc_employee_report_value'), width: 100, render: (val) => `${formatDollar(val)}${formatPrice(val)}` },
    {
      dataIndex: 'reason', key: 'reason', title: i18n.t('pc_employee_report_reason'), width: 200,
      render: (_, row) => {
        return [row.reason, row.reasonComment].filter(x => (x || '').trim()).join(', ') || 'N/A';
      }
    },
    { dataIndex: 'sn', key: 'sn', title: i18n.t('dashboard_loss_report_pos_sn'), width: 120 },
    { dataIndex: 'deviceType', key: 'deviceType', title: i18n.t('dashboard_loss_report_device_type'), width: 120 },
  ]
  const [shouldShowColumnKeys, setShouldShowColumnKeys] = useState(tableColumns.map(x => x.key))
  const [displayTimeRange, setDisplayTimeRange] = useState(['', ''])
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [params, setParams, getParams] = useGetState<IParamsState>({
    date: [dayjs(), dayjs()],
    employeeIdList: [],
    sortByDescTime: false,
    operateTypeList: [],
    dateRangeType: DATE_RANGE_TYPE.TODAY,
    sn: undefined,
  })

  const init = async () => {
    const _today = await getToday();
    setToday(_today);
    setParams({ ...params, date: [_today, _today] });
    await fetchEmployeeList();
    await fetchPosSnList();
    await fetchTableData();
    setIsInit(true);
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    if (!isActive || !isInit) return;
    fetchTableData();
  }, [isActive]);

  const tabItems = [
    { key: TAB_TYPE.VOID, label: i18n.t(TAB_TYPE_I18N[TAB_TYPE.VOID]) },
    { key: TAB_TYPE.REFUND, label: i18n.t(TAB_TYPE_I18N[TAB_TYPE.REFUND]) },
    { key: TAB_TYPE.DISCOUNT, label: i18n.t(TAB_TYPE_I18N[TAB_TYPE.DISCOUNT]) },
  ];

  const onDateRangeChange = (dates) => {
    const dateRangeType = rangePresets.find((v) => {
      return (v.value[0].isSame(dates[0])) && (v.value[1].isSame(dates[1]))
    })?.key ?? DATE_RANGE_TYPE.CUSTOM
    setParams(({ ...params, date: dates, dateRangeType }))
    fetchTableData();
  };

  const fetchEmployeeList = async () => {
    const _params = { needPage: false }
    const resp: any = await ApiQueryEmployeeList(_params)
    if (resp?.code !== 0) return;
    const { list = [] } = resp.data;
    setEmployeeAllList((list || []).map(x => ({ label: x.employee.fullName, value: x.employee.employeeId })))
  }

  const fetchPosSnList = async () => {
    const resp: any = await ApiIsOnlineOrderingDeviceList()
    if (resp?.code !== 0) return;
    const snList = resp.data.map(x => ({ label: x.posDevice.sn, value: x.posDevice.sn }))
    setSnAllList(snList)
  }

  const fetchTableData = async () => {
    const _pager = getPager()
    const _sorter = getSortedInfo();
    const { date, ..._curParams } = getParams();
    const _curTabType = getCurTabType();
    const reqParams = {
      request: {
        ..._curParams,
        operateTypeList: _curParams.operateTypeList.length === 0 ? TAB_OPERATE_FILTERS_MAP[_curTabType] : _curParams.operateTypeList,
        employeeIdList: _curParams.employeeIdList?.length === 0 ? null : _curParams.employeeIdList,
        beginTime: date[0].format('YYYY-MM-DD 00:00:00'),
        endTime: date[1].format('YYYY-MM-DD 23:59:59'),
      },
      orderBy: {
        field: 'operateTime',
        order: _sorter.order === 'descend' ? 'DESC' : 'ASC' // 原sortByDescTime字段，"ASC"(原false) OR "DESC"(原true)，默认倒序DESC
      },
      pageSize: _pager.pageSize,
      current:  _pager.current,
    }
    setIsLoading(true);
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    let resp;
    try {
      resp = await ApiQueryOperationLog(reqParams, { cancelToken: axiosSource.token });
    } catch (err) {
      if (axios.isCancel(err)) return;
      message.error(err.message);
    }
    setIsLoading(false);
    if (resp?.code !== 0) return;
    const { beginTime, endTime, list, current, total } = resp.data;
    setDisplayTimeRange([beginTime, endTime])
    setTableData(list)
    setPager({ ...pager, total, current })
  }

  const onTabsChange = (key) => {
    setSortedInfo({ columnKey: 'operateTime', order: 'descend' })
    setCurTabType(key)
    setIsShowEmptyTable(false)
    setShouldShowColumnKeys(tableColumns.filter(x => {
      if (key === TAB_TYPE.DISCOUNT && x.key === 'reason') {
        return false;
      }
      if ([TAB_TYPE.REFUND, TAB_TYPE.DISCOUNT].includes(key) && ['sn', 'deviceType'].includes(x.key)) {
        return false;
      }
      return true
    }).map(x => x.key))
    setParams({ ...params, operateTypeList: [] })
    setPager({ ...pager, current: 1 })
    fetchTableData();
  };

  const handleTableChange = (_pagination, _, sorter) => {
    setSortedInfo(sorter);
    setPager({ ...pager, ..._pagination });
    fetchTableData();
  }

  const handleEmployeeChange = (val) => {
    setParams({ ...params, employeeIdList: val ? [val] : undefined });
    setPager({ ...pager, current: 1 })
    fetchTableData();
  }

  const handlePosSnChange = (val) => {
    setParams({ ...params, sn: val || undefined });
    setPager({ ...pager, current: 1 })
    fetchTableData();
  }

  const handleOperateTypeListChange = (val) => {
    setParams({ ...params, operateTypeList: val ?? [] });
    setPager({ ...pager, current: 1 })
    fetchTableData();
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShouldShowColumnKeys(list);
  }

  return (
    <div className="lossReport">
      <Tabs
        className="tabsWrap" defaultActiveKey={TAB_TYPE.VOID} items={tabItems} onChange={onTabsChange}
        tabBarExtraContent={{ left: <div className="main-title">{i18n.t('pc_loss_report_loss_report')}</div> }}
      />
      <div className="filterWrap">
        <div className="leftWrap">
          <PepprDatePicker value={params.date} onChange={onDateRangeChange} today={today} setPresets={setRangePresets} />
          <Select
            className="employeeSelect"
            options={employeeAllList}
            showSearch
            optionFilterProp="label"
            value={params.employeeIdList}
            onChange={handleEmployeeChange}
            style={{ width: 200 }}
            allowClear
            placeholder={i18n.t('pc_employee_report_all_employee')}
          />
          <Select
            className="employeeSelect" allowClear mode="multiple" placeholder={i18n.t('pc_loss_report_all_operate_type')} maxTagCount={2} showSearch={false}
            value={params.operateTypeList}
            onChange={handleOperateTypeListChange}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                <span>+{omittedValues.length}...</span>
              </Tooltip>
            )}
            options={TAB_OPERATE_FILTERS_MAP[curTabType].map(x => ({ value: x, label: i18n.t(OPERATE_TYPE_I18N[x]) }))}
            style={{ minWidth: 250 }}
          />

          {
            curTabType === TAB_TYPE.VOID && <Select
              className="employeeSelect"
              options={snAllList}
              showSearch
              optionFilterProp="label"
              value={params.sn}
              onChange={handlePosSnChange}
              style={{ width: 200 }}
              allowClear
              placeholder={i18n.t('dashboard_loss_report_pos_sn')}
            />
          }
        </div>
        <div className="rightWrap">
          <Button type="link" onClick={fetchTableData}><SyncOutlined /></Button>
          <Columns
            value={shouldShowColumnKeys}
            options={
              tableColumns.filter(x => {
                if (curTabType === TAB_TYPE.DISCOUNT && x.key === 'reason') {
                  return false;
                }
                return true
              }).map(item => ({ label: item.title, value: item.dataIndex }))}
            onChange={handleChangeColumns}
          />
        </div>
      </div>
      <div className="timeInfo">{`${i18n.t('pc_employee_report_time_range')}: ${displayTimeRange[0]} - ${displayTimeRange[1]}`}</div>
      <div className="tableWrap">
        <Table
          className={isShowEmptyTable ? 'emptyTable' : ''}
          loading={isLoading}
          columns={tableColumns.filter(x => {
            if (curTabType === TAB_TYPE.DISCOUNT && x.key === 'reason') {
              return false;
            }
            return shouldShowColumnKeys.includes(x.key)
          }) as ColumnsType}
          dataSource={tableData.map((x, i) => ({ ...x, key: i }))}
          rowKey={'key'}
          pagination={pager}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: TableEmptyContent }}
          summary={() => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={999}>
                {TableEmptyContent}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
    </div>
  )
}