interface SourceItem {
  type: string;
  left: number;
  top: number;
  width: number;
  height: number;
  name: string;
  id: string;
  seat: number;
  transformX: number;
  transformY: number;
	isNew?: boolean;
}

// 定义目标数组项的接口
interface TargetItem {
  id: string;
  areaId: string;
  tableName: string;
  seatsNum: number;
	type: string;
  layout: {
    type: string;
    left: number;
    top: number;
    width: number;
    height: number;
  };
}

export const customRound = (dividend:number, divisor:number):number => {
  const quotient = dividend / divisor;
  const remainder = dividend % divisor;
  const halfDivisor = divisor / 2;
  return remainder < halfDivisor ? Math.floor(quotient) : Math.ceil(quotient);
}

export const transformData = (source:SourceItem[], areaId: string):TargetItem[] => {
  const shapeContent = ['circle', 'square']
  return source.map((item): TargetItem => {
    return {
      id: item.isNew ? '' : item.id, // 假设id直接映射
      areaId, // areaId不在源数据中，需要额外的逻辑或默认值
      tableName: item.name, // 假设name映射为tableName
      seatsNum: item.seat, // seat映射为seatsNum
      type: shapeContent.includes(item.type) ? 'table' : 'object',
      layout: {
        type: item.type, // 直接映射
        left: Math.max(item.left + item.transformX, 0), // 假设需要加上transformX
        top: Math.max(item.top + item.transformY, 0), // 假设需要加上transformY
        width: item.width, // 直接映射
        height: item.height, // 直接映射
      },
    };
  });
}

export const reverseTransformData = (targetArray: TargetItem[]): SourceItem[] => {
  // 先过滤掉没有layout属性的项
  const filteredArray = targetArray.filter((item) => item.layout);

  // 然后对过滤后的数组进行映射
  return filteredArray.map((item): SourceItem => {
    // 定义transformX和transformY
    const transformX = 0; // 示例假设值
    const transformY = 0; // 示例假设值

    // 映射剩余项
    return {
      type: item.layout!.type,
      left: item.layout!.left - transformX,
      top: item.layout!.top - transformY,
      width: item.layout!.width,
      height: item.layout!.height,
      name: item.tableName,
      id: item.id,
      seat: item.seatsNum,
      transformX: transformX,
      transformY: transformY,
    };
  });
};




