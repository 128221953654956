import { Tabs } from 'antd'
import Employees from '@/Pages/EmployeeList/Employees';
import Jobs from '@/Pages/EmployeeList/Jobs';
import React, { useState } from 'react';
import './index.scss'
import { globalState } from '@/stores';


export default function EmployeeList () {
  const { i18n } = globalState;
  const [curTab, setCurTab] = useState('Employees')
  const tabsItems = [
    {
      key: 'Employees',
      tab: i18n.t('pc_employees'),
      children: <Employees curTab={ curTab } tabKey={ 'Employees' }/>
    },
    {
      key: 'Roles',
      tab: i18n.t('menu_Roles'),
      children: <Jobs curTab={ curTab } tabKey={ 'Roles' }/>
    }
  ]

  const handleTabsChange = (key) => {
    setCurTab(key);
  }
  return (
    <div className='employee-list'>
      <Tabs defaultActiveKey='Employees' onChange={ handleTabsChange }>
        { tabsItems.map(item => (
          <Tabs.TabPane { ...item } key={item.key}>
            { item.children }
          </Tabs.TabPane>
        )
        ) }
      </Tabs>
    </div>
  )
}