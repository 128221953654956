import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps {
  text: string
}
const PaymentDetailsSection = (props: IProps) => {
  return <div className='payment-detail'>
    <div>
      <TextLineNew
        text='PAYMENT DETAILS'
        style={{
          fontWeight: 'bold'
        }}
      />
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }} />
    <div className='payment-detail-item'>
      <div className='payment-detail-item-title'>
        <TextLineNew text='Cash' />
      </div>
      <div className='payment-detail-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>

    <div className='payment-detail-list'>
      <div className='payment-detail-item'>
        <div className='payment-detail-item-title'>
          <TextLineNew text='Card' />
        </div>
        <div className='payment-detail-item-value'>
          <TextLineNew text='$0.00' />
        </div>
      </div>
      <div className='item-children'>
        <div className='item-children-detail'>
          <TextLineNew text='American Express' />
          <TextLineNew text='$0.00' />
        </div>
        <div className='item-children-detail'>
          <TextLineNew text='Chase' />
          <TextLineNew text='$0.00' />
        </div>
        <div className='item-children-detail'>
          <TextLineNew text='Capital One' />
          <TextLineNew text='$0.00' />
        </div>
      </div>
    </div>

    <div className='payment-detail-item'>
      <div className='payment-detail-item-title'>
        <TextLineNew
          text='Total'
          style={{
            fontWeight: 'bold'
          }}
        />
      </div>
      <div className='payment-detail-item-value'>
        <TextLineNew
          text='$0.00'
          style={{
            fontWeight: 'bold'
          }}
        />
      </div>
    </div>
  </div>
}
export default PaymentDetailsSection;