import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Form, Input, Radio  } from 'antd'
import UploadImage from '@/Components/UploadImage/UploadImage';
import ColorFilter from '../component/ColorFilter';
import { globalState } from '@/stores'
import { isStrictlyEmptyString } from '@/utils'
import './ItemInfo.scss'

interface IProps {
  editInfo: {
    name: string
    foreignName: string
    shortName: string
    itemImage: Array<{ url: string }>
    alcoholContains: boolean
    description: string
    color: string
  }
}

const ItemInfo = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState
  const { editInfo } = props
  const [form] = Form.useForm();

  const [itemImageUrl, setItemImageUrl] = useState<string>('');

  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);


  const handleImgUrlUpload = (imageUrl: Array<{url: string}>) => {
    setItemImageUrl(imageUrl ? imageUrl[0].url : '')
  };
  const removeImageUrl = () => {
    setItemImageUrl('')
  }

  useEffect(() => {
    form.setFieldsValue({ 'itemImage': [{ url: itemImageUrl }] });
  }, [itemImageUrl])

  useEffect(() => {
    const { name, foreignName, shortName, itemImage, alcoholContains = false, description, color } = editInfo
    form.setFieldsValue({
      name,
      foreignName,
      shortName,
      itemImage,
      alcoholContains,
      description,
      color
    });
    setItemImageUrl(itemImage ? itemImage[0].url : '')
  }, [editInfo])


  return (
    <div className='menus-general-info'>
      <Card title={i18n.t('general_info')}>
        <Form form={form} name="item_info_form" layout="vertical">
          <Form.Item
            name="name"
            style={{ marginBottom: 15 }}
            label={
              <div>
                <span>{i18n.t('amount_pc_name')}</span>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </div>
            }
            rules={[
              { validator: async (_, value) => {
                if (isStrictlyEmptyString(value)) {
                  return Promise.reject(i18n?.t('required_x', { msg: i18n?.t('createMenuGroup_pc_menu_group') }));
                }
                if (value?.length > 100) {
                  return Promise.reject(i18n?.t('frontOfHouse_pc_maximum', { length: 100 }))
                }
                return Promise.resolve()
              } },
            ]}>
            <Input onBlur={() => { form.validateFields(['name']) }} maxLength={100}/>
          </Form.Item>
          <Form.Item
            name="foreignName"
            label={i18n.t('foreign_name')}
            style={{ marginBottom: 15 }}
          >
            <Input  maxLength={100}/>
          </Form.Item>
          <Form.Item
            name="shortName"
            label={i18n.t('pos_monitor_pos_nickname')}
            style={{ marginBottom: 15 }}
          >
            <Input  maxLength={100}/>
          </Form.Item>
          <Form.Item
            name="description"
            label={i18n.t('createMenuItem_pc_item_description')}
            style={{ marginBottom: 15 }}
          >
            <Input  maxLength={300}/>
          </Form.Item>
          <Form.Item
            name="itemImage"
            label={i18n.t('createMenuItem_pc_item_image')}
            style={{ marginBottom: 15 }}
          >
            <div>
              <div style={{  marginBottom: 10 }}>{i18n.t('dashboard_upload_image_tip')}</div>
              <UploadImage
                onImageUpload={handleImgUrlUpload}
                onRemove={removeImageUrl}
                cropWidth={1280}
                cropHeight={800}
                maxCount={1}
                enableCrop
                fileImg={itemImageUrl}
              />
            </div>
          </Form.Item>
          <Form.Item name='color' label={i18n?.t('menus_pos_button_color')} style={ { 'marginTop': '10px' } }>
            <Input style={ { display: 'none' } }/>
            <ColorFilter colorText={editInfo.color} handleChangeColorItem={(colorText: string) => {
              form.setFieldValue('color', colorText)
            }}/>
          </Form.Item>
          <Form.Item
            name="alcoholContains"
            label={
              <div>
                <span>{i18n.t('alcohol_contains')}</span>
                <span style={{ 'color': '#ff4d4f' }}> * </span>
              </div>
            }
          >
            <Radio.Group>
              <Radio value={false}>{i18n.t('Dashboard_PrintConfig_GroupbyItem_No')}</Radio>
              <Radio value={true}>{i18n.t('Dashboard_PrintConfig_GroupbyItem_Yes')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
})

export default ItemInfo