import React from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
const TipCustomSection = () => {
  return <div className='tip-custom-area'>
    <div className='tip-custom-area-item'>
      <div className='tip-custom-area-item-custom-tips'>
        <TextLineNew text='Tip'/>
        <div className='tip-custom-area-item-custom-tips-line'></div>
      </div>
      <div className='tip-custom-area-item-custom-tips'>
        <TextLineNew text='Total'/>
        <div className='tip-custom-area-item-custom-tips-line'></div>
      </div>
    </div>
  </div>
}
export default TipCustomSection;