import React, { useState, useEffect, forwardRef } from 'react';
import dayjs from 'dayjs';
import { Input, Form, Select, Tooltip } from 'antd';
import './index.scss';
import { globalState } from '@/stores';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ApiLanguagesQuery } from '@/request/api';

interface IProps {
  editState: boolean;
  formData: IFormData;
  onSetRequestForm: (obj: IFormData) => void;
  onFormValidate: (obj: any) => void;
}

export default forwardRef((props: IProps, ref: any) => {
  // let form: FormInstance;
  const i18n = globalState.i18n;
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<IFormData>({});
  const [validateList, setValidateList] = useState({});
  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const [languageListConfig, setLanguageListConfig] = useState<Array<{ displayName: string, lang: string }>>([]);

  // 获取语言的配置
  const getInitLanguageList = async () => {
    const res = await ApiLanguagesQuery({});
    if (res.code === 0) {
      setLanguageListConfig(res?.data || []);
    }
  };

  const getInitial = () => {
    form.resetFields();
    return new Promise((resolve) => {
      const obj = props.formData;
      const time = obj.dailyClearTime
        ? new Date(obj.dailyClearTime * 1000)
        : new Date();
      const str = `${time.getHours()}:${time.getMinutes()}`;
      const dailyClearTime = dayjs(str, 'HH:mm');
      setFormData(obj);
      resolve({
        orgId: obj.orgId,
        nodeId: obj.nodeId,
        name: obj.name,
        contactPhone: obj.contactPhone,
        streetAddress: obj.streetAddress,
        city: obj.city,
        state: obj.state,
        zipcode: obj.zipcode,
        manualDailyClear: +obj.manualDailyClear,
        dailyClearTime: dailyClearTime,
        useLangs: obj.useLangs,
      });
    });
  };


  const handleDataChange = (obj: IFormData) => {
    setFormData(obj);
    props.onSetRequestForm(obj);
  };


  const handleFormChange = (changedValues, allValues) => {
    handleDataChange(allValues);
  };


  const showTips = (message: string) => {
    return (_, v) => {
      if (!v) {
        const list = {
          ...validateList,
          [message]: message,
        };
        setValidateList(list);
        props.onFormValidate(list);
        return Promise.reject(message);
      } else {
        const list = {
          ...validateList,
          [message]: '',
        };
        setValidateList(list);
        props.onFormValidate(list);
      }
      return Promise.resolve();
    };
  };

  const handleFormInputBlur = (form, key) => {
    form.validateFields([key]);
  };

  const starLabel = () => {
    return (
      <>
        {props.editState ? <span style={{ color: '#ff4d4f' }}>*</span> : <></>}
      </>
    );
  };

  const onSwitchLanguage = (checkedValues) => {
    handleDataChange({
      ...formData,
      useLangs: [...checkedValues],
    });
    handleFormInputBlur(form, 'useLangs');
  };

  useEffect(() => {
    getInitial().then((res) => {
      form.setFieldsValue(res);
    });

    getInitLanguageList();
  }, [props.formData]);

  return (
    <div className="resturant-info-form">
      <Form
        {...layout}
        form={form}
        onValuesChange={handleFormChange}
        ref={ref}
      >
        <Form.Item
          label={
            <div>
              <span style={{ color: '#999' }}>
                {i18n?.t('restaurantInfo_pc_merchant_id')}
              </span>
              {starLabel()}
            </div>
          }
          name="orgId"
        >
          {props.editState ? (
            <Input style={{ width: 350 }} disabled={props.editState} />
          ) : (
            formData.orgId
          )}
        </Form.Item>

        <Form.Item
          label={
            <div>
              <span style={{ color: '#999' }}>{i18n.t('name')}</span> {starLabel()}
            </div>
          }
          name="name"
          rules={[
            () => ({
              validator: showTips('Please enter Name'),
            }),
          ]}
        >
          {props.editState ? (
            <Input
              onBlur={() => {
                handleFormInputBlur(form, 'name');
              }}
              style={{ width: 350 }}
            />
          ) : (
            formData.name || '-'
          )}
        </Form.Item>

        <Form.Item
          label={
            <div>
              {' '}
              <span style={{ color: '#999' }}>{i18n?.t('phone')}</span>{' '}
            </div>
          }
          name="contactPhone"
        >
          {props.editState ? (
            <Input
              onBlur={() => {
                handleFormInputBlur(form, 'contactPhone');
              }}
              style={{ width: 350 }}
            />
          ) : (
            formData.contactPhone || '-'
          )}
        </Form.Item>

        <Form.Item
          label={
            <div>
              {' '}
              <span style={{ color: '#999' }}>
                {i18n?.t('street_address')}
              </span>{' '}
              {starLabel()}
            </div>
          }
          name="streetAddress"
          rules={[
            () => ({
              validator: showTips('Please enter Address'),
            }),
          ]}
        >
          {props.editState ? (
            <Input
              onBlur={() => {
                handleFormInputBlur(form, 'streetAddress');
              }}
              style={{ width: 350 }}
            />
          ) : (
            formData.streetAddress || '-'
          )}
        </Form.Item>

        <Form.Item
          label={
            <div>
              {' '}
              <span style={{ color: '#999' }}>{i18n?.t('city')}</span>{' '}
              {starLabel()}
            </div>
          }
          name="city"
          rules={[
            () => ({
              validator: showTips('Please enter City'),
            }),
          ]}
        >
          {props.editState ? (
            <Input
              onBlur={() => {
                handleFormInputBlur(form, 'city');
              }}
              style={{ width: 350 }}
            />
          ) : (
            formData.city || '-'
          )}
        </Form.Item>

        <Form.Item
          label={
            <div>
              {' '}
              <span style={{ color: '#999' }}>{i18n?.t('state')}</span>{' '}
              {starLabel()}
            </div>
          }
          name="state"
          rules={[
            () => ({
              validator: showTips('Please enter State'),
            }),
          ]}
        >
          {props.editState ? (
            <Input
              onBlur={() => {
                handleFormInputBlur(form, 'state');
              }}
              style={{ width: 350 }}
            />
          ) : (
            formData.state || '-'
          )}
        </Form.Item>

        <Form.Item
          label={
            <div>
              {' '}
              <span style={{ color: '#999' }}>{i18n?.t('zipCode')}</span>{' '}
              {starLabel()}
            </div>
          }
          name="zipcode"
          rules={[
            () => ({
              validator: showTips('Please enter Zip Code'),
            }),
          ]}
        >
          {props.editState ? (
            <Input
              onBlur={() => {
                handleFormInputBlur(form, 'zipcode');
              }}
              style={{ width: 350 }}
            />
          ) : (
            formData.zipcode || '-'
          )}
        </Form.Item>


        <div style={{ fontWeight: 500, fontSize: 16 }}> { i18n?.t('Dashboard_restaurant_languages') } </div>
        <Form.Item
          label={
            <div>
              <span style={{ color: '#999' }}>{i18n?.t('Dashboard_restaurant_language_title')}</span>
              {starLabel()}
            </div>
          }
          name="useLangs"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.length === 0) {
                  return Promise.reject(new Error(i18n?.t('Dashboard_restaurant_please_select_language')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <div >
            <Select
              mode="multiple"
              fieldNames={{ label: 'displayName', value: 'lang' }}
              disabled={!props.editState}
              style={{ width: 350 }}
              options={languageListConfig.map(option => ({
                ...option,
                displayName: i18n?.t(option.displayName)
              }))}
              value={formData.useLangs}
              onChange={(value) => {
                form.setFieldsValue({ useLangs: value });
                onSwitchLanguage(value);
              }}
            />
            <Tooltip
              placement="top"
              color="#ffffff"
              overlayInnerStyle={{ color: '#000000' }}
              title={ i18n?.t('Dashboard_restaurant_language_note')}>
              <InfoCircleOutlined style={{ marginLeft: '10px' }} />
            </Tooltip>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
});
