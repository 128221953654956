import { service } from '@/request/index'

export const ApiQueryMenuOptionGroups: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/queryMenuOptionGroups', params);

export const ApiModifierList: any = params => service.post('rms/merchant/menu/modifier/list', params);

export const ApiQuickSaveModifierGroup: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/quickSaveModifierGroup', params);

export const ApiFuzzyQueryMenuOption: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/fuzzyQueryMenuOption', params);

export const ApiViewArchiveUsage: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/viewArchiveUsage', params);