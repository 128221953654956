import React, { useState } from 'react'
import { Table } from 'antd'
import { globalState } from '@/stores';
import classNames from 'classnames';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import './index.scss'
import { ITopStatsTable } from '../interface';
import { formatFullPrice } from '@/utils';

interface IProps {
  tableData: Array<ITopStatsTable>,
  title: string
}

export default function TopSubMenusList (props: IProps) {
  const { i18n } = globalState;

  const [sortedInfo, setSortedInfo] = useState<SorterResult<ITopStatsTable>>({ columnKey: 'sales', order: 'descend' });

  const { tableData = [], title } = props;

  const tableColumns = [
    { title: i18n.t('pc_product_mix_name'), dataIndex: 'name', width: 250 },
    {
      title: i18n.t('pc_product_mix_quantity'), dataIndex: 'actualQuantity', key: 'actualQuantity', width: 100,
      sorter: true, sortOrder: sortedInfo.columnKey === 'actualQuantity' ? sortedInfo.order : null, sortDirections: ['descend', 'descend', 'descend']
    },
    {
      title: i18n.t('pc_product_mix_sales'), dataIndex: 'sales', key: 'sales', width: 100, render: val => formatFullPrice(val),
      sorter: true, sortOrder: sortedInfo.columnKey === 'sales' ? sortedInfo.order : null, sortDirections: ['descend', 'descend', 'descend']
    },
  ]

  const handleTableChange = (_, __, sorter) => {
    setSortedInfo(sorter);
  }

  const getSortFn = (a, b) => {
    const { columnKey, order } = sortedInfo
    if (!order) return a.name.localeCompare(b.name);
    if (columnKey === 'quantity' && a.quantity === b.quantity) return a.name.localeCompare(b.name);
    if (columnKey === 'sales' && a.sales === b.sales) return a.name.localeCompare(b.name);
    if (order === 'ascend') return a[columnKey as keyof typeof a] - b[columnKey as keyof typeof b]
    if (order === 'descend') return b[columnKey as keyof typeof b] - a[columnKey as keyof typeof a]
  }

  return (
    <div className="top-sub-menus-list">
      <div className="top-sub-menus-list-header-wrap">
        <div className="top-sub-menus-list-title-wrap">
          <span>{title}</span>
        </div>
      </div>
      <div className={ classNames('top-sub-menus-list-table-wrap', { 'no-data': tableData.length === 0 }) }>
        <Table
          columns={ tableColumns as ColumnsType }
          onChange={ handleTableChange }
          dataSource={ tableData.sort(getSortFn).map((x, i) => ({ ...x, key: `${ x.name }${ i }` })) }
          rowKey={ 'key' }
          pagination={ false }
        />
      </div>
    </div>
  )
}