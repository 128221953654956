import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { TableEmptyContent } from '@/Components/EmptyContent';
import useGetState from '@/hooks/useGetState';
import { errorOutput, getQuerys, toRes } from '@/utils';
import { ApiQueryPayoutDetail } from '@/request/api';
import { useNavigate } from 'react-router-dom';
import './index.scss'

interface ISummaryData {
  txn: number;
  paymentAmount: string;
  refund: string;
  chargeback: string;
  fees: string;
  netPayouts: string;
  salesPeriod: string;
  payoutDate: string;
}

interface IPage {
  current: number;
  pageSize: number;
  pageNum: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

const defaultSummaryData = { txn: 0, paymentAmount: '$0.00', refund: '$0.00', chargeback: '$0.00', fees: '$0.00', netPayouts: '$0.00' }

export default function PayoutReportDetail () {
  const { i18n } = globalState;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [summaryData, setSummaryData] = useState<ISummaryData>(defaultSummaryData as ISummaryData)
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageNum: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })

  useEffect(() => {
    fetchTableData();
  }, []);

  const summaryList = [
    { title: i18n.t('dashboard_payout_report_txn'), value: summaryData?.txn },
    { title: i18n.t('pc_payout_report_payment_amount'), value: summaryData?.paymentAmount },
    { title: i18n.t('pc_payout_report_refund'), value: summaryData?.refund },
    { title: i18n.t('pc_payout_report_chargeback'), value: summaryData?.chargeback },
    { title: i18n.t('pc_payout_report_fees'), value: summaryData?.fees },
    { title: i18n.t('pc_payout_report_net_payouts'), value: summaryData?.netPayouts, style: { minWidth: '150px' } },
  ]

  const tableColumns = [
    { dataIndex: 'orderId', key: 'orderId', title: i18n.t('pc_payout_report_order_id'), width: 120 },
    { dataIndex: 'orderDate', key: 'orderDate', title: i18n.t('pc_payout_report_order_date'), width: 180 },
    { dataIndex: 'paymentId', key: 'paymentId', title: i18n.t('pc_payout_report_payment_id'), width: 120 },
    { dataIndex: 'paymentDate', key: 'paymentDate', title: i18n.t('pc_payout_report_payment_date'), width: 180 },
    { dataIndex: 'timezone', key: 'timezone', title: i18n.t('pc_payout_report_time_zone'), width: 120 },
    { dataIndex: 'paymentAmount', key: 'paymentAmount', title: i18n.t('pc_payout_report_payment_amount'), width: 160 },
    { dataIndex: 'refundAmount', key: 'refundAmount', title: i18n.t('pc_payout_report_refund'), width: 120 },
    { dataIndex: 'chargebackAmount', key: 'chargebackAmount', title: i18n.t('pc_payout_report_chargeback'), width: 120 },
    { dataIndex: 'fees', key: 'fees', title: i18n.t('pc_payout_report_fee'), width: 80 },
    { dataIndex: 'netPayouts', key: 'netPayouts', title: i18n.t('pc_payout_report_net_payouts'), width: 120 },
  ]

  const handleTableChange = (_pagination) => {
    setPager({ ...pager, ..._pagination, pageNum: _pagination.current });
    fetchTableData();
  }

  const fetchTableData = async () => {
    const { id } = getQuerys(location.href);
    const _pager = getPager()
    const params = {
      payoutBatch: id,
      page: {
        pageNum: _pager.pageNum,
        pageSize: _pager.pageSize
      },
    }
    setLoading(true)
    const resp: any = await ApiQueryPayoutDetail(params);
    setLoading(false)
    const data = resp?.data
    if (resp?.code !== 0) {
      errorOutput(resp?.message, i18n.t('pc_payout_report_query_failed'), i18n)
    }
    setSummaryData(data?.summary ? data.summary : defaultSummaryData)
    setPager({
      ...pager,
      pageSize: data?.page?.pageSize ?? 10,
      pageNum: data?.page?.pageNum ?? 1,
      total: data?.page?.total ?? 0,
      current: data?.page?.pageNum ?? 1
    })
    setTableData(data?.data ?? [])
  }

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/payments/payout', { replace: true })
    }
  }

  return (
    <div className='payoutReportDetail'>
      <div className='titleWrap'>
        <span className='mainTitle' onClick={ handleGoBack }>{ i18n.t('pc_payout_report_title') }</span>
        <span className='split'>/</span>
        <span className='subTitle'>{ i18n.t('pc_payout_report_report_details') }</span>
        <span>{ summaryData?.payoutDate }</span>
      </div>
      <div className='reportDate'>
        { i18n.t('pc_payout_report_sales_period') }: { summaryData?.salesPeriod }
      </div>
      <div className='summaryWrap'>
        { summaryList.map((item, index) => (
          <div className='summaryItem' style={ item?.style || {} } key={ index }>
            <div className='name'>{ item.title }</div>
            <div className='price'>{ item.value }</div>
          </div>)
        ) }
      </div>
      <div className='tableWrap'>
        <Table
          columns={ tableColumns }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          pagination={ pager }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          loading={ loading }
        />
      </div>
    </div>
  )
}