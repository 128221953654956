import './index.scss'
import { globalState } from '@/stores';
import { Button, Form, Input, Select, Radio, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { SelectEmptyContent } from '@/Components/EmptyContent';
import { verifyEmail } from '@/utils/index'
import { to, toRes } from '@/utils';
import { ApiRestaurantSettleIn, ApiRestaurantSettleInMetadata } from '@/request/api';
import Loading from '@/utils/loading';


export default function SettleIn () {
  const i18n = globalState.i18n;

  const [form] = Form.useForm()
  const [isInit, setIsInit] = useState(false)
  const [businessLineOptions, setBusinessLineOptions] = useState([])
  const [timeZoneOptions, setTimeZoneOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const init = async ()=>{
    Loading.show()
    await initOptions();
    Loading.hide()
    return () => {
      Loading.hide();
    }
  }
  useEffect(() => {
    init()
  }, []);

  const initOptions = async () => {
    const [_, data] = await toRes(ApiRestaurantSettleInMetadata());
    setIsInit(true)
    setBusinessLineOptions(data?.businessLines?.map(x => ({ value: x.value, label: x.displayName })) ?? [])
    setTimeZoneOptions(data?.timeZones?.map(x => ({ value: x.value, label: x.displayName })) ?? [])
  }

  const handleSubmit = async () => {
    const [_, isPass] = await to(form.validateFields());
    if (!isPass) return;
    const params = form.getFieldsValue();
    setLoading(true);
    const [err] = await toRes(ApiRestaurantSettleIn(params));
    if (!err) message.success(i18n.t('message_success'));
    setLoading(false);
  }

  return (
    isInit && <div className='settleIn'>
      <div className='title'>{ i18n.t('menu_SettleIn') }</div>
      <div className='formWrap'>
        <Form form={ form } labelCol={ { flex: '150px' } } wrapperCol={ { flex: 1 } }>
          <Form.Item name={ 'corp' } label={ 'corp' } required rules={ [{ required: true, message: i18n.t('please_enter_x', { msg: 'corp' }) }] }>
            <Input/>
          </Form.Item>
          <Form.Item name={ 'businessLine' } label={ 'businessLine' } required rules={ [{ required: true, message: i18n.t('please_select_x', { msg: 'businessLine' }) }] }>
            <Select
              onChange={ (x) => form.setFieldValue('businessLine', x) }
              notFoundContent={ SelectEmptyContent }
              options={ businessLineOptions }
            />
          </Form.Item>
          <Form.Item name={ 'firstName' } label={ 'firstName' } required rules={ [{ required: true, message: i18n.t('please_enter_x', { msg: 'firstName' }) }] }>
            <Input/>
          </Form.Item>
          <Form.Item name={ 'lastName' } label={ 'lastName' } required rules={ [{ required: true, message: i18n.t('please_enter_x', { msg: 'lastName' }) }] }>
            <Input/>
          </Form.Item>
          <Form.Item
            name={ 'email' }
            label={ 'Email address' }
            required
            rules={ [
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(i18n.t('please_enter_x', { msg: 'email' }));
                  }
                  if (!verifyEmail(value)) {
                    return Promise.reject('Please enter a valid email address');
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input onBlur={ () => { form.validateFields(['email']); } }/>
          </Form.Item>
          <Form.Item name={ 'phoneNumber' } label={ 'phoneNumber' }>
            <Input/>
          </Form.Item>
          <Form.Item name={ 'timeZone' } label={ 'timeZone' } required rules={ [{ required: true, message: i18n.t('please_select_x', { msg: 'timeZone' }) }] }>
            <Select
              onChange={ (x) => form.setFieldValue('timeZone', x) }
              notFoundContent={ SelectEmptyContent }
              options={ timeZoneOptions }
            />
          </Form.Item>
          <Form.Item name={ 'isTest' } label={ 'Restaurant Type' } required rules={ [{ required: true, message: i18n.t('please_select_x', { msg: 'isTest' }) }] }>
            <Radio.Group onChange={ x => form.setFieldValue('isTest', x.target.value) }>
              <Radio value={ false }>Formal</Radio>
              <Radio value={ true }>Test</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <div className='btnWrap'>
          <Button type="primary" onClick={ handleSubmit } style={ { width: 300 } } loading={ loading }>
            { i18n.t('settle_pc_submit') }
          </Button>
        </div>
      </div>
    </div>
  )
}