export enum OperationType {
    EDIT = 0,
    ADD = 1,
}


export const startBalanceConfigOpts = [
  {
    label: 'equal to end balance of last session',
    value: 1
  },
  {
    label: 'customized amount',
    value: 2
  }
]

export enum StartBalanceConfigTypeEnum {
  LAST_END_BALANCE = 1,
  CUSTOMER_SET_BALANCE = 2
}