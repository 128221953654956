import React, { useEffect, useState } from 'react';
import { Checkbox, Empty, Popover, Button } from 'antd';
import type { CheckboxProps } from 'antd';
import './index.scss';
const CheckboxGroup = Checkbox.Group;
import { globalState } from '@/stores';

interface props{
    value: Array<any>;
    options: Array<{label: string, value: string, disabled?: boolean}>;
    onChange: any;
    icon?: string;
    text?: string;
    placement?: any; // 下拉出现的位置 默认bottomRight 参考antd的Popover用法
}
export default function Columns ({
  value = [],
  options = [],
  onChange,
  icon = 'icon-table',
  text = '',
  placement = 'bottomRight'
}:props) {
  const { i18n } = globalState;
  const checkAll = options.length === value.length;
  const indeterminate = value.length > 0 && value.length < options.length;

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    onChange(e.target.checked ? options.map(item => item.value) : []);
  };
  const content = (
    <div className="slider">
      {options.length > 0 ? <div>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          {i18n.t('pc_employee_report_all')}
        </Checkbox>
        <br />
        <CheckboxGroup className="selectCheck" options={options} value={value} onChange={onChange} />
      </div> : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  )
  return (
    <div className="columns">
      <Popover content={content} title="" trigger="click" placement={placement} arrow={false}>
        <Button type='link' className='btn' icon={<i className={`iconfont ${icon}`}></i>}>
          {text ? <span className="txt">{text}</span> : null}
        </Button>
      </Popover>
    </div>
  )
}