import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';
import PepprDatePicker from '@/Components/DatePicker';
import { Button, Form, Input, message, Select, Table } from 'antd';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import { downloadFile, errorOutput, formatFullPrice, removeNullUndefinedFields, formatBackI18n } from '@/utils';
import useGetState from '@/hooks/useGetState';
import { globalState } from '@/stores';
import { debounce } from '@/utils/throttleDebounce';
import { ApiApaasQueryExportResult, ApiExportPaymentTransactionReport, ApiQueryPaymentTransactionReport } from '@/request/api';
import { PAYMENT_STATUS_MAP, PAYMENT_STATUS_TYPE, REFUND_STATUS_MAP, REFUND_STATUS_TYPE } from '@/Pages/Transaction/enum';
import './index.scss'
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { formatTime } from '@/utils/index';
import { useMobile } from '@/stores/MobileContext';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

let axiosSource = axios.CancelToken.source();

export default function Card (props) {
  const { i18n } = globalState;
  const { today, isActive } = props;
  const { isMobile } = useMobile()

  const [inited, setInited] = useState<boolean>(false)
  const [isShowEmptyTable, setIsShowEmptyTable] = useState(false)
  const [showColumnsList, setShowColumnsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const [form] = Form.useForm()

  let exportTaskId;

  useEffect(() => {
    setShowColumnsList(tableColumns.filter(x => !x.hide).map(x => x.dataIndex))
  }, []);

  useEffect(() => {
    if (!today) return;
    form.setFieldValue('date', [today.subtract(6, 'day').startOf('day'), today])
    fetchTableData();
  }, [today]);

  useEffect(() => {
    if (inited && isActive) {
      fetchTableData();
    }
  }, [isActive]);

  const tableColumns = [
    { dataIndex: 'paymentId', key: 'paymentId', title: i18n.t('pc_transaction_payment_id') },
    { dataIndex: 'orderId', key: 'orderId', title: i18n.t('pc_transaction_order_id'), render: val => val || '-' },
    { dataIndex: 'orderNo', key: 'orderNo', title: i18n.t('pc_transaction_order_no'), render: val => val || '-' },
    { dataIndex: 'paymentDate', key: 'paymentDate', title: i18n.t('pc_transaction_payment_date'), render: (val, row) => <>{ formatTime(val, 'MM/dd/yyyy hh:mm:ss tt') }</> },
    { dataIndex: 'orderDate', key: 'orderDate', title: i18n.t('pc_transaction_order_date'), render: val => {
      if (!!val) {
        return formatTime(val, 'MM/dd/yyyy hh:mm:ss tt');
      } else {
        return '-';
      }
    } },
    { dataIndex: 'checkId', key: 'checkId', title: i18n.t('pc_transaction_check_id'), render: val => val || '-' },
    { dataIndex: 'checkNo', key: 'checkNo', title: i18n.t('pc_transaction_check_no'), render: val => val || '-' },
    { dataIndex: 'amount', key: 'amount', title: i18n.t('pc_transaction_amount'), render: val => formatFullPrice(val) },
    { dataIndex: 'tip', key: 'tip', title: i18n.t('pc_transaction_tip'), render: val => formatFullPrice(val) },
    { dataIndex: 'total', key: 'total', title: i18n.t('pc_transaction_total'), render: val => formatFullPrice(val) },
    { dataIndex: 'paymentStatus', key: 'paymentStatus', title: formatBackI18n('pc_transaction_payment_status', i18n) },
    { dataIndex: 'refundStatus', key: 'refundStatus', title: formatBackI18n('pc_transaction_refund_status', i18n), render: val => val || '-' },
    { dataIndex: 'cardLast4', key: 'cardLast4', title: i18n.t('trans_table_last4'), hide: true, render: val => val || '-' },
    { dataIndex: 'cardType', key: 'cardType', title: i18n.t('pc_transaction_card_type'), hide: true, render: val => val || '-' },
    { dataIndex: 'refundDate', key: 'refundDate', title: i18n.t('pc_transaction_refund_date'), hide: true, render: val => val && formatTime(val, 'MM/dd/yyyy hh:mm:ss tt') || '-' },
    { dataIndex: 'refundAmount', key: 'refundAmount', title: i18n.t('pc_transaction_refund_amount'), hide: true, render: val => val ? formatFullPrice(val) : '-' },
  ]

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const handleTableChange = (_pagination) => {
    setPager({ ...pager, ..._pagination });
    fetchTableData();
  }

  const handleFormValuesChange = (changedValues) => {
    setPager({ ...pager, current: 1 });
    if ('checkNo' in changedValues) {
      debounceFetchTableData();
    } else {
      fetchTableData();
    }
  }

  const fetchTableData = async () => {
    const pagerParams = getPager();
    const params = {
      request: {
        ...getRequestParams(),
      },
      orderBy: {
        field: 'pay_time',
        order: 'DESC'
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize,
    }
    setLoading(true);
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    let resp;
    try {
      resp = await ApiQueryPaymentTransactionReport(removeNullUndefinedFields(params), { cancelToken: axiosSource.token });
    } catch (err) {
      if (axios.isCancel(err)) return;
      message.error(err.message);
    }
    setLoading(false);
    setInited(true);
    if (resp?.code !== 0) {
      return;
    }
    const data = resp?.data
    setTableData(data?.list ?? [])
    setPager({
      ...pagerParams,
      current: data.current,
      total: data.total,
    })
  }

  const getRequestParams = () => {
    const formParams = form.getFieldsValue();
    return {
      checkNo: formParams.checkNo || null,
      beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
      paymentStatus: formParams.paymentStatus,
      refundStatus: formParams.refundStatus,
    }
  }

  const debounceFetchTableData = useCallback(debounce(500, fetchTableData), []);

  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const params = {
      ...getRequestParams(),
    }
    try {
      const resp: any = await ApiExportPaymentTransactionReport(removeNullUndefinedFields(params));
      exportTaskId = resp?.data?.exportTaskId ?? null;
      if (resp?.code !== 0 || !exportTaskId) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        return;
      }
      getExportResult();
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
    }
  }

  const getExportResult = async () => {
    try {
      const resp: any = await ApiApaasQueryExportResult({ params: { req: { exportTaskId } } });
      if (resp.code !== 0) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        stopExport();
        return;
      }
      const data = resp.data;
      if (data?.result && data?.url) {
        // 开启下载
        downloadFile(data.url);
        message.success(i18n.t('pc_payout_report_successfully_downloaded'))
        setIsExporting(false);
        exportTaskId = null;
      } else {
        const id = setTimeout(async () => {
          getExportResult();
        }, 2000);
        setTimeoutId(id);
      }
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
      stopExport();
    }
  }

  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }
  // endregion

  return (
    <div className="transaction-card">
      <div className="filter-wrap">
        <div className="left-wrap">
          <Form form={ form } layout="inline" onValuesChange={ handleFormValuesChange }>
            <Form.Item name={ 'checkNo' } normalize={ (val) => val.trim() }>
              <Input allowClear placeholder={ i18n.t('pc_transaction_checkno_input_placeholder') } prefix={ <SearchOutlined/> }/>
            </Form.Item>
            <Form.Item name={ 'date' }>
              <PepprDatePicker today={ today }/>
            </Form.Item>
            <Form.Item name={ 'paymentStatus' }>
              <Select
                allowClear
                notFoundContent={ SelectEmptyContent }
                placeholder={ i18n.t('Transactions_pc_payment_status') }
                options={
                  [
                    PAYMENT_STATUS_TYPE.VOIDED, PAYMENT_STATUS_TYPE.DECLINED, PAYMENT_STATUS_TYPE.PROCESSING,
                    PAYMENT_STATUS_TYPE.AUTHORIZED, PAYMENT_STATUS_TYPE.CAPTURED
                  ].map(x => ({ value: x, label: formatBackI18n(PAYMENT_STATUS_MAP[x], i18n) }))
                }
              />
            </Form.Item>
            <Form.Item name={ 'refundStatus' }>
              <Select
                allowClear
                notFoundContent={ SelectEmptyContent }
                placeholder={ i18n.t('Transactions_pc_refund_status') }
                options={
                  [
                    REFUND_STATUS_TYPE.NA, REFUND_STATUS_TYPE.PARTILA_REFUNDED, REFUND_STATUS_TYPE.REFUNDED
                  ].map(x => ({ value: x, label: formatBackI18n(REFUND_STATUS_MAP[x], i18n) }))
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="right-wrap">
          <Button type="link" onClick={ fetchTableData }><SyncOutlined/></Button>
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          loading={ loading }
          pagination={ { ...pager, position:[isMobile ? 'bottomCenter' : 'bottomRight'], simple: isMobile } }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
      <div className="action-wrap">
        <Button type="primary" loading={ isExporting } onClick={ () => handleExport() }>{ i18n.t('export_pc') }</Button>
      </div>
    </div>
  )
}