import React from 'react';
import './DashedLine.scss';
interface IProps {
  gap?: React.CSSProperties
}
// 虚线组件
const DashedLine = (props: IProps) => {
  return (
    <div className='dashed-line' style={ props.gap && props.gap }></div> // 应用样式
  );
}
export default DashedLine;