export interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

export enum CASH_OPERATE_TYPE {
  ORDER_CASH_PAYMENT = 3,
  CASH_TIP = 4,
  REFUND_CASH_OUT = 5,
  CASH_COLLECTED = 6,
  PAY_IN = 7,
  TIP_OUT = 8,
  PAY_OUT = 9,
  NO_SALE = 10,
}

export const CASH_OPERATE_TYPE_I18N = {
  [CASH_OPERATE_TYPE.ORDER_CASH_PAYMENT]: 'pc_cash_management_order_cash_payment',
  [CASH_OPERATE_TYPE.CASH_TIP]: 'pc_cash_management_cash_tip',
  [CASH_OPERATE_TYPE.REFUND_CASH_OUT]: 'pc_cash_management_refund_cash_out',
  [CASH_OPERATE_TYPE.CASH_COLLECTED]: 'pc_cash_management_cash_collected',
  [CASH_OPERATE_TYPE.PAY_IN]: 'pc_cash_management_pay_in',
  [CASH_OPERATE_TYPE.TIP_OUT]: 'pc_cash_management_tip_out',
  [CASH_OPERATE_TYPE.PAY_OUT]: 'pc_cash_management_pay_out',
  [CASH_OPERATE_TYPE.NO_SALE]: 'rms_overseas_operation_configuration_kitchen_CashTransactionType_NO_SALE'
}

export interface ICashDrawerTransDetail {
  transTime: string;
  employeeName: string;
  type: string;
  amount: string;
  reason: string;
}

export interface ICashManagementData {
  page: {
    total: number;
    pageNo: number;
    pageSize: number;
  };
  beginTime: string;
  endTime: string;
  cashDrawerTransDetailList: Array<ICashDrawerTransDetail>;
}