import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import DashedLine from '../component/DashedLine'
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { ConfigTypeValue } from '../../interface';
interface IProps{
  text: string
}
const FooterSection = (props: any) => {
  const defaultValue = 'This is footer';

  const [text, setText] = useState(defaultValue)
  const [configType, setConfigType] = useState<ConfigTypeValue>('Check');
  const [marginBottom, setMarginBottom] = useState(0)

  useEffect(() => {
    setConfigType(toJS(printOutDecorationStore.getUrlParamsType));

    if (props && props.children) {
      const footerText = props.children['footer-section-text']?.value;
      const defaultFooterText = (toJS(printOutDecorationStore.getUrlParamsType) === 'Kitchen' ? '' : defaultValue)
      setText(footerText || defaultFooterText)
      setMarginBottom(props?.modifier?.spacing || 0)
    }
  }, [props])

  return (
    <div
      className='preview-ui-footer'
      key={props?.id}
      style={
        { marginBottom: configType === 'Kitchen' ? (8 + marginBottom * 5.6) : 0 }
      }
    >
      { text &&
      <>
        <DashedLine/>
        <TextLineNew
          text={text}
          style={{
            color: text === 'This is footer' ? 'gray' : 'black',
            fontStyle: text === 'This is footer' ? 'italic' : 'normal'
          }}
        />
      </>
      }
    </div>)
}
export default FooterSection;