export const PAGE_NOT_FOUND = '*';
export const HOME = '/';
export const PERMISSIONS = '/permissions';
export const EMPLOYEE_LIST = '/employeeList';
export const ENTRANCES = '/entrances';
export const MY_ACCOUNT = '/myAccount';
export const RESTAURANT_INFO = '/restaurantInfo';
export const ROLES = '/roles';
export const SETTLE_IN = '/settleIn';
export const CATEGORIES = '/categories';
export const EMPLOYEE_CREATE = '/employeeCreate';
export const JOB_CREATE = '/jobCreate';
export const PAYMENTS_PAYOUT = '/payments/payout';
export const PAYMENTS_PAYOUT_DETAIL = '/payments/payoutDetail';
export const REPORTS_EMPLOYEE_TIME_SHEETS = '/reports/employeeTimeSheets';
export const REPORTS_EMPLOYEE_LABOR_SUMMARY = '/reports/laborSummary';
export const REPORTS_ORDERS = '/reports/orders';
export const REPORTS_SALES = '/reports/sales';
export const REPORTS_TRANSACTION = '/reports/transaction';
export const REPORTS_PRODUCT_MIX = '/reports/productMix';
export const REPORTS_CASH_MANAGEMENT = '/reports/cashManagement';
export const REPORTS_CASH_DRAWER_OVERVIEW = '/reports/cashDrawerOverview';
export const REPORTS_LOSS_REPORT = '/reports/lossReport';
export const FEE = '/fee';
export const MENUS_MENU_BUILDER = '/menus/menuBuilder';
export const MENUS_CREATE_MENU = '/menus/createMenu';
export const MENUS_CREATE_SUB_MENU = '/menus/createSubMenu';
export const MENUS_CREATE_MODIFIER = '/menus/createModifier';
export const MENUS_CREATE_ITEM = '/menus/createItem';
export const MENUS_MENU_DATABASE = '/menus/MenuDatabase';
export const MENUS_SALES_CATEGORIES = '/menus/salesCategories';
export const FRONT_OF_HOUSE_NOTES = '/frontOfHouse/notes';
export const FRONT_OF_HOUSE_GUEST_DISPLAY = '/frontOfHouse/guestDisplay';
export const FRONT_OF_HOUSE_TIPS = '/frontOfHouse/tips';
export const FRONT_OF_HOUSE_DINING_OPTION = '/frontOfHouse/diningOption';
export const FRONT_OF_HOUSE_TABLES = '/frontOfHouse/tables';
export const DEVICE_PRINTER = '/device/printer';
export const DECORATION_PRINT_OUT = '/decorationPrintOut';
export const DEVICE_POS_MONITOR = '/device/posMonitor';
export const FRONT_OF_HOUSE_SERVICE_CHARGE = '/frontOfHouse/serviceCharge';
export const PAYMENTS_PAYMENT_METHODS = '/payments/paymentMethods';
export const MENUS_TAX_RATES = '/menus/taxRates';
export const MENUS_PRE_MODIFIER = '/menus/preModifier';
export const MENUS_PRE_MODIFIER_EDIT = '/preModifier/edit';
export const PROMOTION = '/promotion';
export const LOGIN = '/login';
export const FORGET_PASSWORD = '/forgetPassword';
export const REGISTER = '/register';
export const ONLINE_ORDERS = '/onlineOrdering';
export const NET_SALES_SUMMARY = '/netSalesSummary';
export const REPORTS_CASH_DISCOUNTS_PROGRAM = '/reports/cashDiscountsProgram';
export const MENUS_EVENTS = '/menus/menuEvents';