import React, { useEffect, useState } from 'react';
import { Divider } from 'antd'
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store'
import './RenderSpaceTags.scss';
import { IRenderConfigProps } from '../../interface';
import { globalState } from '@/stores';


export default observer(function RenderSpaceTags (props: IRenderConfigProps<'spaceTag'>) {
  const { i18n } = globalState

  const { sectionId, content } = props
  const { defaultValue } = content;

  const [defaultValueList, setDefaultValueList] = useState([])
  const [currentCheckedValue, setCurrentCheckedValue] = useState<string>('')



  const changeCurrentValue = (value: string) => {
    const moduleId = content.defaultValue[0]?.id || ''

    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)

    // ***** modifier previewData *****
    previewData = previewData.map(item => {
      if (item.id === sectionId && moduleId) {
        item.children['item-info-section-dishLineSpacing'].value = value
        return { ...item };
      }
      return item;
    });

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  }

  useEffect(() => {
    // 默认值
    if (defaultValue) {
      setDefaultValueList(defaultValue)
    }

  }, [defaultValue])


  useEffect(() => {
    // 当前值
    const previewData = toJS(printOutDecorationStore.getPreviewData);
    previewData.forEach(item => {
      if (item.id === sectionId) {
        setCurrentCheckedValue(item.children['item-info-section-dishLineSpacing'].value || '14')
      }
    })
  }, [printOutDecorationStore.getPreviewData, sectionId]);

  return (
    <div style={{ paddingLeft: 20 }} className='render-space-tags'>
      <div style={{ paddingBottom: 20 }}>
        <span>{i18n?.t('Dashboard_PrintConfig_DishLineSpacing')}</span>
        <span style={{ 'color': '#ff4d4f' }}> * </span>
      </div>
      <div className="tag-box">
        {/* Marrow = 0px */}
        {/* Mid = 14px */}
        {/* Wide = current width */}
        {
          (defaultValueList || []).map(item => (
            <div
              className='tag-item'
              key={item.id}
              style={{
                color: item.value === currentCheckedValue ? '#2563EB' : '',
                backgroundColor: item.value === currentCheckedValue ? '#EEF0FF' : '',
                border: item.value === currentCheckedValue ? '1px solid transparent' : '',
                cursor: 'pointer'
              }}
              onClick={() => changeCurrentValue(item.value)}
            >{i18n?.t(item.label)}</div>
          ))
        }
      </div>
      <Divider />
    </div>
  )})