import React, { useEffect, useState } from 'react';
import { Button, message, Popover } from 'antd';
import { globalState } from '@/stores';
import { arrayMove } from '@dnd-kit/sortable';
import { ColumnsType } from 'antd/es/table';
import { ApiArchiveServiceCharge, ApiQueryServiceChargeList, ApiSortServiceCharge } from '@/request/api';
import { formatDollar, formatPrice, showWrapMask, toRes } from '@/utils';
import DragTable, { DragHandle } from '@/Components/DragTable';
import Drawer from '@/utils/GlobalDrawer';
import ServiceChargeEdit from './ServiceChargeEdit';
import './index.scss'

export default function ServiceCharge () {
  const { i18n } = globalState;
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({ archived: [false] });

  const CHARGE_TYPE = {
    PERCENT: { label: i18n.t('pc_service_charge_percent'), value: 1 },
    FIXED: { label: i18n.t('pc_service_charge_amount'), value: 2 },
    OPEN: { label: i18n.t('pc_service_charge_open'), value: 3 }
  }

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    { key: 'sort', align: 'center', width: 50, render: () => <DragHandle/> },
    { dataIndex: 'name', key: 'name', title: i18n.t('pc_service_charge_name') },
    { dataIndex: 'type', key: 'type', title: i18n.t('pc_service_charge_type'), render: (val) => val ? getLabelByValue(val, CHARGE_TYPE) : '-' },
    {
      dataIndex: 'value', key: 'value', title: i18n.t('pc_service_charge_charge'),
      render: (val, row) => {
        return {
          [CHARGE_TYPE.PERCENT.value]: `${ val }%`,
          [CHARGE_TYPE.FIXED.value]: formatDollar(val) + formatPrice(val),
          [CHARGE_TYPE.OPEN.value]: ''
        }[row.type];
      }
    },
    { dataIndex: 'autoApply', key: 'autoApply', title: i18n.t('dashboard_auto_apply'), render: (val) => val ? i18n.t('Dashboard_PrintConfig_GroupbyItem_Yes') : i18n.t('Dashboard_PrintConfig_GroupbyItem_No') },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('status'), width: 80,
      filters: [{ text: i18n.t('active'), value: false }, { text: i18n.t('archived'), value: true }],
      filteredValue: filteredInfo.archived || null,
      defaultFilteredValue: [false],
      filterResetToDefaultFilteredValue: true,
      render: (_, row) => {
        const _isActive = !row?.archived
        return (<div className='colStatus'>
          <div className={ _isActive ? 'greenPoint' : 'greyPoint' }></div>
          { _isActive ? i18n.t('active') : i18n.t('archived') }
        </div>)
      }
    },
    {
      key: 'actions', title: i18n.t('table_actions'), align: 'center',
      render: (_, row) => {
        return (
          <Popover content={
            <div className='tableActionsWrap'>
              <Button type="link" onClick={ () => handleEdit(row) }>{i18n.t('edit')}</Button>
              <Button type="link" onClick={ () => handleArchived(row) }>{ row?.archived ? i18n.t('activate') : i18n.t('archived') }</Button>
            </div>
          }>
            <Button type="link">...</Button>
          </Popover>
        )
      }
    }
  ];

  const fetchTableData = async () => {
    setLoading(true)
    const [_, data] = await toRes(ApiQueryServiceChargeList())
    setLoading(false)
    setTableData((data ?? []).sort((a, b) => a.displayOrder - b.displayOrder))
  }

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      const sortItem = tableData.find(x => x.id === active.id);
      const targetSort = tableData.find(x => x.id === over.id).displayOrder;
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      setLoading(true)
      const [err] = await toRes(ApiSortServiceCharge({ id: sortItem.id, targetSort }));
      if (!err) {
        message.success(i18n.t('message_success'));
      }
      fetchTableData();
    }
  };

  const handleTableChange = (_pagination, _filters) => {
    setFilteredInfo(_filters)
  }

  const handleEdit = (row?: any) => {
    showWrapMask();
    Drawer.open({ component: <ServiceChargeEdit editItem={ row } cbSave={ fetchTableData }/> });
  }

  const handleArchived = async (row) => {
    showWrapMask();
    const _params = {
      archived: !row.archived,
      ids: [row.id]
    }
    setLoading(true)
    const [err] = await toRes(ApiArchiveServiceCharge(_params))
    if (err) {
      setLoading(false)
      return;
    }
    await fetchTableData();
    message.success(i18n.t('message_success'));
  }

  const getLabelByValue = (value: any, mapObj: any, defaultVal?: any) => {
    for (const key in mapObj) {
      if (mapObj[key].value === value) {
        return mapObj[key].label;
      }
    }
    return defaultVal ?? value;
  }

  return (
    <div className='serviceChargeWrap'>
      <div className='titleWrap'>
        <div className='title'>{ i18n.t('pc_service_charge_service_charge') }</div>
        <div className='actionsWrap'>
          <Button type="primary" onClick={ () => handleEdit() }>{ i18n.t('pc_service_charge_add_new') }</Button>
        </div>
      </div>
      <div className='tableWrap'>
        <DragTable
          loading={ loading }
          onDragEnd={ onDragEnd }
          rowKey={ 'id' }
          columns={ tableColumns as ColumnsType }
          dataSource={ tableData.filter(x => (filteredInfo.archived || []).includes(x.archived)) }
          onChange={ handleTableChange }/>
      </div>
    </div>
  )
}
