import { pepprService } from '../index';

export interface Response<T = unknown> {
  code: number
  message?: string
  msg?: string
  success?: boolean
  data: T
}

export interface ICreateEmployee {
  employee: {
    firstName: string; //名
    lastName: string; //姓
    email: string; //邮箱
    phoneNumber?: string; //手机号
    accessCode: string; //通行码
    employeeId?: string;
  }
  roles: {
    roleId: string //职位id
    hourlyRate: number
  };
}

export interface IEmployee {
  tenantId?: number
  employeeId?: number
  accountId?: number
  dashboardAccess?: number
  hourlyRate?: any
  tag?: number
  lastName: string
  firstName: string
  fullName?: string
  phoneNumber: string
  email: string
  accessCode: string
  archived?: 0 | 1 // 0未归档 1已归档
  archivist?: number
  archivedTime?: number
}

export interface IRole {
  roleId: number
  roleName: number
  roleType: 1 | 2 // 1 模版 2正常创建
  roleTypeName: number
  archived: string // 0未归档 1已归档
  archiveName: string
}

export interface IEmployeeDetail {
  employee?: IEmployee
  roles?: IRole[];
  employeeCard?: EmployeeCard
}

interface EmployeeCard {
  employeeId: number;
  bindStatus: number;
  keyCardId: string;
}

const prefix = '/api/peppr-employee'

/**
 * 创建员工
 * @param params
 */
export const createEmployee = async (params: ICreateEmployee): Promise<boolean | undefined> => {
  const res: Response<boolean> = await pepprService.post(prefix + '/employee/create', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 修改员工
 * @param params
 * @returns
 */
export const editEmployee = async (params: ICreateEmployee): Promise<boolean | undefined> => {
  const res: Response<boolean> = await pepprService.post(prefix + '/employee/update', params);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 获取通行码
 */
export const getAccessCode = async (): Promise<string | undefined> => {
  const res: Response<string> = await pepprService.post(prefix + '/employee/generateAccessCode');
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};

/**
 * 查询员工详情
 */
export const getEmployeeDetail = async (employeeId: string): Promise<IEmployeeDetail | undefined> => {
  const res: Response<IEmployeeDetail> = await pepprService.post(prefix + `/employee/${employeeId}/queryDetail`);
  if (res.code === 200 || res.success) {
    return res.data;
  }
  return undefined;
};