import { IBaseDefaultMapData, ITag } from '../interface';

export const defaultKitchenMapData: Array<IBaseDefaultMapData<ITag>> = [
  {
    'id': 'prep-station-name',
    'title': '',
    'defaultValue': false,
    'disabled': false,
    'modifier': {
      'isHideConfig': true
    },
    'children': [],
  },
  {
    'id': 'service-detail-section',
    'title': '',
    'defaultValue': false,
    'disabled': false,
    'modifier': {
      'isHideConfig': true
    },
    'children': [],
  },
  {
    'id': 'header-section',
    'title': 'print_decoration_header',
    'defaultValue': false,
    'disabled': true,
    'children': [{
      'tag': 'slider',
      'defaultValue': [{
        'id': 'header-section',
        'label': 'Dashboard_PrintConfig_HeaderSpacing',
        'value': '5',
        'range': [0, 20]
      }],
      'modifier': {
        'sliderMapKey': 'spacing'
      }
    }]
  },
  {
    'id': 'check-info-section',
    'title': 'print_decoration_check_info',
    'defaultValue': false,
    'disabled': true,
    'children': [{
      'tag': 'checkboxGroup',
      'defaultSort': [
        ['check-info-section-checkNumber', 'check-info-section-tableNumber'],
        ['check-info-section-serverName', 'check-info-section-guestNumber'],
        ['check-info-section-date', 'check-info-section-salesChannel'],
      ],
      'defaultValue': [
        {
          'id': 'check-info-section-salesChannel',
          'label': 'pc_product_mix_sales_channel',
          'value': 'check-info-section-salesChannel',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'check-info-section-serverName',
          'label': 'Dashboard_PrintConfig_ServerName',
          'value': 'check-info-section-serverName',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'check-info-section-guestNumber',
          'label': 'Dashboard_PrintConfig_GuestNumber',
          'value': 'check-info-section-guestNumber',
          'checked': true,
          'disabled': false,
        }
      ],
    }]
  },
  {
    'id': 'guest-info-section',
    'title': 'print_decoration_guest_info',
    'defaultValue': false,
    'disabled': false,
    'children': [{
      'tag': 'checkboxGroup',
      'defaultSort': [
        ['guest-info-section-guestName', 'guest-info-section-guestPhoneNumber'],
        ['guest-info-section-guestNotes']
      ],
      'defaultValue': [
        {
          'id': 'guest-info-section-guestName',
          'label': 'Dashboard_PrintConfig_GuestName',
          'value': 'guest-info-section-guestName',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'guest-info-section-guestPhoneNumber',
          'label': 'Dashboard_PrintConfig_GuestPhoneNumber',
          'value': 'guest-info-section-guestPhoneNumber',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'guest-info-section-guestNotes',
          'label': 'Dashboard_PrintConfig_GuestNotes',
          'value': 'guest-info-section-guestNotes',
          'checked': true,
          'disabled': false,
        },
      ]
    }]
  },
  {
    'id': 'item-info-section',
    'title': 'print_decoration_item_info',
    'defaultValue': false,
    'disabled': true,
    'children': [

      {
        'tag': 'radioGroup',
        'id': 'item-info-section-groupByItem',
        'hide': false,
        'modifier': {
          'label': 'Dashboard_PrintConfig_GroupbyItem',
          'direction': 'vertical',
          'dividerBottom': true,
        },
        'defaultValue': [{
          'id': 'item-info-section-groupByItem-yes',
          'label': 'Dashboard_PrintConfig_GroupbyItem_Yes',
          'value': 'Yes',
          'checked': false,
          'disabled': false,
        },
        {
          'id': 'item-info-section-groupByItem-no',
          'label': 'Dashboard_PrintConfig_GroupbyItem_No',
          'value': 'No',
          'checked': true,
          'disabled': false,
        }]
      },
      {
        'tag': 'spaceTag',
        'defaultValue': [{
          'id': 'item-info-section-narrow',
          'label': 'Dashboard_PrintConfig_DishLineSpacing_Narrow',
          'value': '5',
          'checked': false,
          'disabled': false
        },
        {
          'id': 'item-info-section-mid',
          'label': 'Dashboard_PrintConfig_DishLineSpacing_Mid',
          'value': '15',
          'checked': true,
          'disabled': false,
        },
        {
          'id': 'item-info-section-wide',
          'label': 'Dashboard_PrintConfig_DishLineSpacing_Wide',
          'value': '30',
          'checked': false,
          'disabled': false,
        }],
      },
      {
        'tag': 'slider',
        'defaultValue': [{
          'id': 'item-info-section-itemDish',
          'label': 'Dashboard_print_configuration_kitchen_item_font_size',
          'value': '5',
          'range': [1, 10]
        }],
        'modifier': {
          'sliderMapKey': 'fontSize'
        }
      },
      {
        'tag': 'slider',
        'defaultValue': [{
          'id': 'item-info-section-itemDishModifier',
          'label': 'Dashboard_print_configuration_kitchen_modifier_font_size',
          'value': '5',
          'range': [1, 10]
        }],
        'modifier': {
          'sliderMapKey': 'fontSize'
        }
      },
      // {
      //   'tag': 'checkboxGroup',
      //   'defaultSort': [['item-info-section-itemNotes']],
      //   'defaultValue': [{
      //     'id': 'item-info-section-itemNotes',
      //     'label': 'Dashboard_PrintConfig_ItemNotes',
      //     'value': 'item-info-section-itemNotes',
      //     'disabled': false,
      //   },
      //   ],
      // },
      {
        'tag': 'slider',
        'defaultValue': [{
          'id': 'item-info-section-itemNotes',
          'label': 'Dashboard_print_configuration_item_note_modifier_font_size',
          'value': '5',
          'range': [1, 10]
        }],
        'modifier': {
          'sliderMapKey': 'fontSize'
        }
      },
      // {
      //   'tag': 'checkboxGroup',
      //   'defaultSort': [],
      //   'defaultValue': [
      //     {
      //       'id': 'item-info-section-checkNotes',
      //       'label': 'print_decoration_check_notes',
      //       'value': 'item-info-section-checkNotes',
      //       'disabled': false,
      //     }],
      // },
      {
        'tag': 'slider',
        'defaultValue': [{
          'id': 'item-info-section-checkNotes',
          'label': 'Dashboard_print_configuration_order_note_modifier_font_size',
          'value': '5',
          'range': [1, 10]
        }],
        'modifier': {
          'sliderMapKey': 'fontSize'
        }
      },
    ]
  },
  {
    'id': 'other-prep-station-section',
    'title': 'Dashboard_PrintConfig_OtherPrepStations',
    'defaultValue': true,
    'disabled': false,
    'children': [],
  },
  {
    'id': 'footer-section',
    'title': 'print_decoration_footer',
    'defaultValue': false,
    'disabled': true,
    'children': [{
      'tag': 'slider',
      'defaultValue': [{
        'id': 'footer-section',
        'label': 'Dashboard_PrintConfig_FooterSpacing',
        'value': '0',
        'range': [0, 20]
      }],
      'modifier': {
        'sliderMapKey': 'spacing'
      }
    }]
  },
]