import { Button } from 'antd'
import { globalState } from '@/stores';
import React, { FC, useEffect, useState } from 'react';
import { decodeParams, tryParse } from '@/utils/index'
import { checkAccount, bindAccount, ICheckAccountParams } from '@/request/Login';
import './index.scss'
import { useNavigate } from 'react-router-dom';
export interface BindingComponentProps{
  accountId?: number
  employeeId?: number
}
export const Binding: FC<BindingComponentProps> = () => {
  const i18n = globalState.i18n;
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useState<any>({});
  const [bindLoading, setBindLoading] = useState<boolean>(false);
  const [params, setParams] = useState<any>({});
  useEffect(() => {
    const fetchData = async () => {
      const params = tryParse<ICheckAccountParams>(decodeParams());
      const response = await checkAccount({ ...params });
      setParams(params);
      setAccountInfo(response);
    }
    fetchData();
  }, [])
  const handleBunding = async () => {
    setBindLoading(true);
    const res = await bindAccount({
      accountId: accountInfo?.accountId,
      employeeId: params?.employeeId
    })
    if (res) {
      window.location.replace('/login');
    }
    setBindLoading(false);
  }
  return <>
    <div className='binding-page'>
      <div className='binding-page-title'>Binding</div>
      <div>
        {
          ` Link existing account. Hi ${accountInfo?.firstName || ''}, you currently have an existing account with ${accountInfo?.restaurantName || ''}. Click the button below to activaed your account for ${accountInfo?.restaurantName || ''}`
        }
      </div>
      <Button type="primary" style={{ 'borderRadius': '4px', 'marginTop': '12px', 'width': '350px', 'height': '48px' }} onClick={ handleBunding } loading={bindLoading}>
        { i18n.t('confirm') }
      </Button>
    </div>
  </>
}