export enum OperationType {
    EDIT = 0,
    ADD = 1,
}

export enum PaperWidthListEnum{
    NARROW = '1',
    WIDE = '2'
}

export const PaperWidthList = [
  {
    label: 'Narrow (57.5-58mm)',
    value: '1'
  },
  {
    label: 'Wide (76-80mm)',
    value: '2'
  }
]

export const LinkWithFOH = [
  {
    label: 'yes',
    value: true
  },
  {
    label: 'rms_overseas_transaction_order_TaxCalType_NONE',
    value: false
  }
]
export enum CashDrawerTypeEnum{
    NO_CASH_DRAWER = '1',
    ONE_CASH_DRAWER = '2'
}
export const CashDrawerType = [
  {
    label: 'rms_overseas_operation_configuration_kitchen_CashDrawer_NO_CASH_DRAWER',
    value: CashDrawerTypeEnum.NO_CASH_DRAWER
  },
  {
    label: 'one_cashdrawer',
    value: CashDrawerTypeEnum.ONE_CASH_DRAWER
  }
]