import React, { useEffect, useState } from 'react'
import { Breadcrumb, Modal } from 'antd'
import './index.scss'
// import { title } from 'process'
import { globalState } from '@/stores';
import { useNavigate } from 'react-router-dom';


interface Props {
  slotAction?: React.ReactNode
  customTitle?:  Array<ICustomTitleItem>
  needConfirm?: boolean
}
export interface ICustomTitleItem {
  title: string
  href?: string
  router?: string
  onClick?: (event) => void
}

export const BreadCrumb: React.FC<Props> = (props) => {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const { slotAction, customTitle, needConfirm } = props
  const [modal, contextHolder] = Modal.useModal();
  const [breadcrumbItems, setBreadcrumbItems] = useState<Array<ICustomTitleItem>>([{ title: '' }]);
  const [path] = useState<string>(window.location.pathname);
  // 匹配对应路由的面包屑配置
  const matchBreadCrumb = () => {
    const breadcrumbItemsArr = [];
    if (customTitle) {
      customTitle.forEach((cItem) => {
        if (needConfirm) {
          breadcrumbItemsArr.push({
            title: cItem.title,
            onClick: async () => {
              console.log(cItem, 'cItem.router');
              if (cItem.router) {
                const confirmed = await modal.confirm({
                  content: i18n.t('unsaved_changes'),
                  cancelText: i18n.t('cancel'),
                  okText: i18n.t('discard'),
                })
                if (confirmed) {
                  navigate(cItem.router, { replace: true })
                }
              }
            }
          })
        } else {
          breadcrumbItemsArr.push({
            title: cItem.title,
            href: cItem.href,
            ...cItem
          })
        }
      })
      setBreadcrumbItems(breadcrumbItemsArr);
    }
  }

  useEffect(() => {
    matchBreadCrumb()
  }, [path])

  // const breadcrumb = () => {
  //   return <>
  //     {breadcrumbItems.map(item => {
  //       <span onClick={() => item.onClick}>{item.title}</span>
  //     })}
  //   </>
  // }
  return (
    <div className='breadCrumb'>
      {contextHolder}
      {breadcrumbItems.length > 1 ? <Breadcrumb className='bread-crumb' items={breadcrumbItems} /> : <div className='ant-breadcrumb'>{breadcrumbItems[0].title}</div>}
      <div className="slot">
        <div className="slot-action">{slotAction}</div>
      </div>
    </div>

  )
}

export default BreadCrumb