import { IBaseDefaultMapData, ITag } from '../interface';

export const defaultShiftReportMapData: Array<IBaseDefaultMapData<ITag>> = [
  {
    'id': 'tips-section',
    'title': 'tips_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'cash-reconciliation-section',
    'title': 'cash_reconciliation_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'payment-details-section',
    'title': 'payment_details_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'sales-categories-section',
    'title': 'sales_categories_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
  {
    'id': 'credit-card-payment-section',
    'title': 'credit_card_payment_section',
    'defaultValue': false,
    'disabled': false,
    'children': []
  },
]