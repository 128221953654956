import { service } from '@/request/index'

export const queryAllSalesCategory: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/reports/queryAllSalesCategory', params);

export const sortSalesCategory: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/reports/sortSalesCategory', params);

export const batchArchiveSalesCategory: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/reports/batchArchiveSalesCategory', params);

export const batchSaveSalesCategory: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/reports/batchSaveSalesCategory', params);

export const querySalesCategoryDetail: any = (params = []) => service.post('rms/overseas/MerchantManagementConsole/reports/querySalesCategoryDetail', params);