import { useRef, useState, useEffect } from 'react'
import { createWithLSLoader } from '@lcap/apaas-designer-official-i18n-lite'
import { globalState } from '@/stores';
import { _i18n } from '@/utils/language';
export function useI18n (): any  {
  const [i18n, setI18n] = useState<any>();
  const intervalRef = useRef<number>();
  useEffect(() => {
    // if (!!localStorage.getItem('i18n/supportLangs')) {
    // const _i18n = createWithLSLoader();
    globalState.i18n = _i18n
    setI18n(_i18n);
    // } else {
    //   intervalRef.current = setInterval(() => {
    //     const settings = localStorage.getItem('i18n/supportLangs');
    //     if (!!settings) {
    //       clearInterval(intervalRef.current);
    //       // const _i18n = createWithLSLoader();
    //       globalState.i18n = _i18n
    //       setI18n(_i18n);
    //     }
    //   }, 1000) as unknown as number
    // }
    // return () => {
    //   clearInterval(intervalRef.current);
    // }
  }, [])
  const handleClearIntervalRef = () => {
    clearInterval(intervalRef.current);
  }
  return {
    i18n,
    handleClearIntervalRef
  };
}