import './index.scss'
import VerifyCode from '@/Pages/MyAccount/component/VerifyCode';
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { ReactComponent as EyeTwoTone } from '@/assets/svg/EyeTwoTone.svg'
import React, { useState } from 'react';
import { resetPassword } from '@/request/Login'
import { message, Button, Input, Form, FormInstance } from 'antd';
import SuccessFul from '../SuccessFul';
import { checkPassword } from '@/utils/index'
import { globalState } from '@/stores';

export interface IResetPassword{
    email: string
    validateEmail: (email: string) => void
}
export default function ResetPassword (props: IResetPassword) {
  const [code, setCode] = useState('');
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const i18n = globalState.i18n;
  const layout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 14
    }
  }
  let form: FormInstance;
  const resendEmail = () => {
    props.validateEmail(props.email);
  }
  const jumpToLogin = () => {
    window.location.replace('/login');
  }
  const handleReset = async () => {
    const checkArr = await form.validateFields(['password', 'confirmPassword']);
    setResetLoading(true);
    if (!!checkArr['confirmPassword'] && (checkArr['password'] === checkArr['confirmPassword'])) {
      const res = await resetPassword({
        password: checkArr['password'],
        email: props.email,
        verificationCode: code
      });
      if (res) {
        setStep(step + 1);
        message.success(i18n.t('message_success'));
      }
    } else {
      message.error(i18n?.t('login_pc_password_inconsistent_tips'))
    }
    setResetLoading(false);
  }
  return !step ? <div className='reset-password'>
    <VerifyCode verifyCodeCallBack={(val) => {
      setCode(val);
    }} resendCallBack={ resendEmail }/>
    <div className="mterial-form">
      <Form
        {...layout}
        form={form}
        ref={(ref: FormInstance) => {
          form = ref;
        }}
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="password"
          required
          rules={[
            () => ({
              validator: (_, value) => checkPassword(value, i18n),
            }),
          ]}
        >
          <Input.Password onBlur={() => form.validateFields(['password'])} required placeholder={i18n?.t('password')} iconRender={ (visible: any) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/> }/>
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            () => ({
              validator: (_, value) => checkPassword(value, i18n),
            }),
          ]}
          required
        >
          <Input.Password onBlur={() => form.validateFields(['confirmPassword'])} placeholder={i18n?.t('login_pc_confirm_password')} iconRender={ (visible: any) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/> }/>
        </Form.Item>
        <Form.Item>
          <Button onClick={jumpToLogin}>{ i18n?.t('login_pc_back_to') }</Button>
          <Button type='primary' onClick={ handleReset } loading={resetLoading}>{ i18n?.t('login_pc_reset') }</Button>
        </Form.Item>
      </Form>
    </div>
  </div> : <SuccessFul/>
}