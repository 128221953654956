import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const CashReconciliationSection = (props: IProps) => {
  return <div className='cash-reconciliation'>
    <div>
      <TextLineNew text='CASH RECONCILIATION' style={{
        fontWeight: 'bold'
      }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='cash-reconciliation-item'>
      <div className='cash-reconciliation-item-title'>
        <TextLineNew text='Cash in Hand'/>
      </div>
      <div className='cash-reconciliation-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
    <div className='cash-reconciliation-item'>
      <div className='cash-reconciliation-item-title'>
        <TextLineNew text='Cash Due'/>
      </div>
      <div className='cash-reconciliation-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
  </div>
}
export default CashReconciliationSection;