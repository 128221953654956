import React, { useEffect, useState } from 'react'
import { globalState } from '@/stores';
import { Button } from 'antd';
import './index.scss'
import { isEmpty, toRes } from '@/utils';
import { ActiveIcon } from '../../../DiningOptions/component/IconComponent';
import { ReloadOutlined } from '@ant-design/icons';
import { archivePrepStation, unarchivePrepStation } from '@/request/PrepStations';
interface IProps {
  prepStations: any,
  changeItem: (item: any) => void,
  setIsEdit: (item: any) => void,
  getListData: () => any,
  selectedId: string,
  setSelectedId: (id: string) => any,
  setIsFOH: (isFoh: boolean) => any,
  setLastSelectedId: (id: string) => any
}
export default function NestedList (props: IProps) {
  const [fohList, setFohList] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const i18n = globalState.i18n;

  const checkItem = (item) => {
    props.changeItem(item);
    props.setIsEdit(true);
    const { id } = item;
    props.setSelectedId(id);
  }
  const addNew = () => {
    props.setIsEdit(false);
    props.setSelectedId(null);
    props.setIsFOH(false);
    props.setLastSelectedId(props.selectedId);
  }
  const handleArchive = async (item) => {
    const req = { id: item.id, status: true }
    const [, res] = await toRes(archivePrepStation(req))
    if (res) {
      props.getListData();
    }
  }
  const handleUnArchive = async (item) => {
    const req = { id: item.id, status: false }
    const [, res] = await toRes(unarchivePrepStation(req))
    if (res) {
      props.getListData();
    }
  }
  useEffect(() => {
    if (props.prepStations && !isEmpty(props.prepStations)) {
      const { frontOfHouse, kitchens } = props.prepStations;
      if (!isEmpty(frontOfHouse) && !props.selectedId) {
        setFohList([frontOfHouse]);
        props.setSelectedId(frontOfHouse?.id);
      }
      setKitchens(kitchens);
    }
  }, [
    props.prepStations
  ])

  return <>
    {
      !isEmpty(props.prepStations) && <div className="nestedlist-list">
        <ul className="nestedlist-list-items">
          <>
            {
              (fohList || []).map(m => (
                <li className={`nestedlist-list-item ${props.selectedId === m?.id && 'nestedlist-list-item-active'}`} key={m.id} onClick={() => {
                  props.setIsFOH(true);
                  checkItem(m)
                }}>
                  <span className="nestedlist-list-item-title">{m?.name}</span>

                </li>
              ))
            }
          </>
        </ul>
        <ul className="nestedlist-list-items">
          <li className="nestedlist-list-item">
            <span className="nestedlist-list-item-title">{i18n.t('print_gearOpening_bill_pc_kitchen')}</span>
          </li>
        </ul>
        <ul className="nestedlist-list-items nestedlist-list-ml nestedlist-list-min-height">
          <>
            {
              (kitchens && kitchens.length) ? kitchens.map(m => (
                <li className={`nestedlist-list-item nestedlist-list-subtitle ${props.selectedId === m?.id && 'nestedlist-list-item-active'}`} key={m.id} onClick={() => {
                  props.setIsFOH(false);
                  checkItem(m)
                }}>
                  <span className="nestedlist-list-item-title">{m?.name}</span>
                  {
                    props.selectedId === m?.id ?
                      m?.archived
                        ?
                        <div className="nestedlist-list-item-title-icon" onClick={() => { handleUnArchive(m) }}> <ReloadOutlined style={{ 'color': '#3266e3' }} /> </div>
                        :
                        <div className="nestedlist-list-item-title-icon" onClick={() => { handleArchive(m) }}> <ActiveIcon /> </div>
                      : <></>
                  }
                </li>
              )) : <>
                <div className="nestedlist-list-item-empty-text">no data</div>
              </>
            }
            <Button type="link" onClick={addNew} className='nestedlist-list-items-btn'>{i18n.t('add_new')}</Button>
          </>
        </ul>
      </div>
    }
  </>
}