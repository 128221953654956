export enum PAY_TYPE {
  CASH_PAYMENT = '1',
  CREDIT_CARD_PAYMENT = '2',
  OTHER_PAYMENT = '99'
}

export const PAY_TYPE_MAP = {
  [PAY_TYPE.CASH_PAYMENT]: 'rms_overseas_transaction_order_PayType_CASH_PAYMENT',
  [PAY_TYPE.CREDIT_CARD_PAYMENT]: 'rms_overseas_transaction_order_PayType_CREDIT_CARD_PAYMENT',
  [PAY_TYPE.OTHER_PAYMENT]: 'rms_overseas_transaction_order_PayType_OTHER_PAYMENT'
}
