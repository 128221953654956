import React from 'react';
import { globalState } from '@/stores';
import './index.scss';

export default function (props: any) {
  const i18n = globalState.i18n;
  return <>
    <div className="account-container-form">
      <span className="form-title">
        {i18n?.t('myAccount_pc_personal_infomation')}
      </span>
      <div className="material-form">
        <div className="material-form-item">
          <div className="material-form-container">
            <span className="form-container-label">
              {i18n?.t('myAccount_pc_frist_name')}
            </span>
            <span className="form-container-content">
              { props.infomation.firstName }
            </span>
          </div>
        </div>
        <div className="material-form-item">
          <div className="material-form-container">
            <span className="form-container-label">
              {i18n?.t('myAccount_pc_last_name')}
            </span>
            <span className="form-container-content">
              { props.infomation.lastName }
            </span>
          </div>
        </div>
        <div className="material-form-item">
          <div className="material-form-container">
            <span className="form-container-label">
              {i18n?.t('phone')}
            </span>
            <span className="form-container-content">
              { props.infomation.phoneNumber || '-' }
            </span>
          </div>
        </div>
        <div className="material-form-item">
          <div className="material-form-container">
            <span className="form-container-label">Id</span>
            <span className="form-container-content">
              { props.infomation.accountId }
            </span>
          </div>
        </div>
      </div>
    </div>
  </>
}