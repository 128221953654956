import React from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
interface IProps{
  text: string
}
export default function PaymentStatusSection (props: IProps)  {
  return <div className='preview-ui-payment-status'>
    <div className='preview-ui-payment-status-dashed-line'></div>
    <div className='preview-ui-payment-status-absolute'>
      <TextLineNew text={'Unpaid'} />
    </div>
  </div>
}