import React, { useState, useEffect } from 'react';
import { Spin, Button, message, Flex, Modal, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import BreadCrumb from '@/Pages/Menus/component/BreadCrumb'
import MenuNullBgi from '@/assets/menu-null-bgi.png'
import SubMenuNullBgi from '@/assets/sub-menu-bgi.png'
import ModifierNullBgi from '@/assets/modifier-null-bgi.png'
import { globalState } from '@/stores';
import SubMenu from './MenuHome/SubMenu';
import MenuItem from './MenuHome/MenuItem';
import DragSelect from '@/Components/DragSelect';
import { queryAllMenusCorrespondingStore, reSortUniversal, batchArchiveOrRestoreMenuNodes } from '@/request/Menus';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import menuStore from './store';
import WhiteList from '@/stores/whiteList';
import { WHITE_KEY } from '@/types';
import './MenuHome/index.scss'
export default observer(function Menus (props) {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState([])
  const [activeMenu, setActiveMenu] = useState(null)
  const [menuLoading, setMenuLoading] = useState<boolean>(false)
  const [dragLoading, setDragLoading] = useState<boolean>(false)
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    getMenuList()
  }, [])
  const getMenuList = async () => {
    setMenuLoading(true)
    const res = await queryAllMenusCorrespondingStore()
    setMenuLoading(false)
    const { data = [] } = res
    if (res.code === 0) {
      if (data.length > 0) {
        const options = data.map((item: any) => {
          return {
            id: item.uuid,
            name: item.name,
            displayOrder: item.displayOrder
          }
        })
        setMenuList(options)
        if (menuStore.getActiveMenu.uuid) {
          setActiveMenu(menuStore.getActiveMenu.uuid)
        } else {
          menuStore.setActiveMenu({ uuid: options[0].id, name: options[0].name })
          setActiveMenu(options[0].id || null)
        }
      } else {
        setMenuList([])
        menuStore.clearActive('')
      }
    }
  }
  const createMenu = () => {
    navigate('/menus/createMenu?pageType=menu')
  }
  const editMenu = (val) => {
    navigate(`/menus/createMenu?menuId=${val.id}&menuName=${encodeURIComponent(val.name)}&pageType=menu`)
  }
  const deleteMenu = async (val) => {
    const confirmed = await modal.confirm({
      content: `${i18n.t('menuTabList_pc_archive_tips', { menu_name: val.name })}`,
      okText: i18n.t('confirm'),
      cancelText: i18n.t('cancel')
    });
    if (confirmed) {
      const params = {
        nodes: [
          {
            archived: true,
            sourceId: val.id,
            sourceType: {
              displayName: 'Menu',
              value: 'Menu'
            }
          }
        ]
      }
      const res = await batchArchiveOrRestoreMenuNodes({ params })
      if (res.code === 0) {
        message.success(i18n.t('menuTabList_pc_success'));
        menuStore.clearActive('')
        getMenuList()
      }
    }
  }
  const selectMenu = (val) => {
    setActiveMenu(val.id)
    menuStore.setActiveMenu({ uuid: val.id, name: val.name })
  }
  const onDragEndMenu = async (active, over) => {
    const params = {
      sourceMenuNode: {
        sourceId: active.id,
        sourceType: 'Menu'
      },
      targetMenuNode: {
        sourceType: 'Menu',
        sourceId: active.id,
        displayOrder: over.displayOrder
      }
    };
    setDragLoading(true)
    const res = await reSortUniversal({ params });
    setDragLoading(false)
    if (res.code === 0) {
      message.success('success');
      getMenuList()
    }
  }
  const addSubMenu = () => {
    navigate(`/menus/createSubMenu?menuId=${encodeURIComponent(activeMenu)}&menuName=${encodeURIComponent(menuStore.getActiveMenu.name)}&pageType=subMenu`)
  }
  const createModifier = () => {
    navigate('/menus/createModifier')
  }
  return (
    <div className="menus-builder">
      {contextHolder}
      <Spin size="large" spinning={menuLoading}>
        <BreadCrumb
          slotAction={
            <Flex gap="middle">
              {WhiteList.getWhiteList.includes(WHITE_KEY.MENU_EVENT) && <Button onClick={() => navigate('/menus/menuEvents')}>Events</Button>}
              <Button onClick={createModifier}>{i18n.t('create_modifier_add_modifier_group')}</Button>
              {menuList.length > 0 && <div className="btn"><Button type="primary" onClick={createMenu}>{i18n.t('create_menu')}</Button></div>}
            </Flex>
          }
          customTitle={[{
            title: i18n.t('overseas_peppr_entrance_menu_builder'),
          }]}
        />
        {menuList.length > 0 && <div className="menu-select">
          <DragSelect
            style={{ minWidth: '150px' }}
            value={activeMenu}
            list={menuList}
            edit
            archive
            loading={dragLoading}
            handleEdit={editMenu}
            handleRemove={deleteMenu}
            onDragEnd={onDragEndMenu}
            onChange={selectMenu} />
        </div>}
        <div className={`${menuLoading == true && menuStore.getActiveSubMenu.uuid === null ? 'op0' : ''}`}>
          {menuList.length === 0 || menuStore.getActiveSubMenu.uuid === null ? <div className="null-box">
            {menuList.length === 0 && <div className="menus-null">
              <div className="bgi"><img src={MenuNullBgi} alt="" /></div>
              <div className="text">{i18n.t('pc_createMenu_tips')}</div>
              <Button type="primary" onClick={createMenu}>{i18n.t('create_menu')}</Button>
            </div>}
            {menuStore.getActiveSubMenu.uuid === null && menuList.length !== 0 && (<>
              <div className="sub-menu-null">
                <div className="bgi"><img src={SubMenuNullBgi} alt="" /></div>
                <div className="text s2">{i18n.t('menu_pc_submenu_tips')}</div>
                <Button onClick={addSubMenu}>{i18n.t('menubuilder_pc_create_submenu')}</Button>
              </div>
              <div className="or">Or</div>
              <div className="modifier-group-null">
                <div className="bgi s2">
                  <img src={ModifierNullBgi} alt="" />
                </div>
                <div className="tips">
                  <Popover
                    placement='topRight'
                    arrow={false}
                    content={<div className='popover-text'>{i18n.t('menu_pc_modifier_tips')}</div>}
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </div>
                <div className="text s3">{i18n.t('menu_pc_modifier_text')}</div>
                <Button type="primary" onClick={createModifier}>{i18n.t('create_modifier_add_modifier_group')}</Button>
              </div>
            </>)}
          </div> : null}
          <Flex className={`content-box ${menuStore.getActiveSubMenu.uuid === null || menuList.length === 0 ? 'dn' : ''}`}>
            <Flex className="sub-menu-list" flex={0}>
              <SubMenu
                activeMenuId={activeMenu}
                activeMenuName={menuStore.getActiveMenu.name}
                onload={subMenuLoading => setMenuLoading(subMenuLoading)} />
            </Flex>
            {menuStore.getActiveSubMenu.uuid !== null && <div className="item-list" style={{ width: '100%' }}>
              <MenuItem />
            </div>}
          </Flex>
        </div>
      </Spin>
    </div>
  )
})