import { makeAutoObservable } from 'mobx';
import { ApiQueryWhiteList } from '@/request/api';
class WhiteList {
  whiteList: string[] = [];

  constructor () {
    makeAutoObservable(this);
  }

  setWhiteList (list: string[]) {
    this.whiteList = [...list];
  }
  clearWhiteList () {
    this.whiteList = [];
  }
  get getWhiteList () {
    return this.whiteList;
  }
  // 获取whiteList
  async fetchWhiteList () {
    const res = await ApiQueryWhiteList();
    try {
      if (res?.code === 0) {
        const list = res?.data || [];
        this.setWhiteList(list);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

const whiteList = new WhiteList();
export default whiteList;
