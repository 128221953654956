import { pepprService, service, toResService } from '../index';
const prefix = '/api/peppr-org'

// 获取所有数据
export const getPrintSetting: any = (params = {}) => pepprService.post(prefix + '/device/queryPosDevices', params);

// 查询单条数据
export const queryPosDeviceInfo: any = (params = {}) => toResService.post(prefix + '/device/queryPosDeviceInfo', params);

// 保存编辑
export const updatePosDeviceInfo: any = (params = {}) => toResService.post(prefix + '/device/updatePosDeviceInfo', params)

// 删除
export const deletePosDevice: any = (params = {}) => pepprService.post(prefix + '/device/delete', params);

// 获取被允许的CardReaderModel
export const ApiQueryPosDeviceConfig: any = () => service.post('/rms/overseas/MerchantManagementConsole/devices/queryPosDeviceConfig');
