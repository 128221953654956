import React, { FC, useState } from 'react'
import './index.scss';
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { ReactComponent as EyeTwoTone } from '@/assets/svg/EyeTwoTone.svg'
import { tryParse } from '@/utils/index'
import { ICheckAccountParams, create } from '@/request/Login';
import { message, Input, Form, FormInstance, Button } from 'antd';
import SuccessFul from '../SuccessFul';
import { decodeParams, checkEmail, checkPassword } from '@/utils/index'
import { globalState } from '@/stores';
export interface SignUpComponentProps {
  info: string;
}
export const SignUp: FC<SignUpComponentProps> = () => {
  const [params] = useState(tryParse<ICheckAccountParams>(decodeParams()));
  const [signUpLoading, setSignUpLoading] = useState<boolean>(false);
  const { i18n } = globalState;
  const [step, setStep] = useState<number>(0);
  const layout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 14
    }
  }
  let [form] = Form.useForm();
  const getInitial = () => {
    return {
      email: params.email,
      password: '',
      confirmPassword: ''
    }
  }
  const handleSignUp = async () => {
    const checkArr = await form.validateFields(['email', 'password', 'confirmPassword']);
    setSignUpLoading(true);
    if (!!checkArr['confirmPassword'] && (checkArr['password'] === checkArr['confirmPassword'])) {
      const { tenantId, orgId, employeeId } = params || {};
      const { email, password } = checkArr || {};
      const res = await create({
        tenantId: tenantId,
        orgId: orgId,
        employeeId: employeeId,
        email: email,
        password: password
      });
      if (res) {
        setStep(step + 1);
      }
      setSignUpLoading(false);
    } else {
      message.error(i18n?.t('login_pc_password_inconsistent_tips'))
      setSignUpLoading(false);
    }
  }
  return !step ? <div className="sign-up">
    <div className="sign-up-logo">
      <div style={{ 'width': '166px', 'height': '48px' }}>
        <img src="https://overseasbucket.s3.us-west-1.amazonaws.com/image/PC/peppr_red_logo.png" />
      </div>
    </div>
    <div className="mterial-form">
      <Form
        {...layout}
        form={form}
        ref={(ref: FormInstance) => {
          form = ref;
        }}
        // setAsyncInitialValues={async () => await getInitial()}
        initialValues={getInitial()}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="email"
          required
          rules={[
            () => ({
              validator: (_, value) => checkEmail(value, i18n),
            }),
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="password"
          required
          rules={[
            () => ({
              validator: (_, value) => checkPassword(value, i18n),
            }),
          ]}
        >
          <Input.Password required placeholder={i18n?.t('password')} iconRender={(visible: any) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />} />
        </Form.Item>
        <div style={{ 'textAlign': 'left', 'fontSize': '12px', 'color': '#0006', 'marginBottom': '16px' }}>
          {i18n?.t('login_pc_must_be')}
        </div>
        <Form.Item
          name="confirmPassword"
          required
        >
          <Input.Password placeholder={i18n?.t('login_pc_confirm_password')} iconRender={(visible: any) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />} />
        </Form.Item>
      </Form>
      <Button type="primary" style={{ 'borderRadius': '4px', 'marginTop': '12px', 'width': '350px', 'height': '48px' }} onClick={handleSignUp} loading={signUpLoading}>{i18n.t('login_pc_change_password')}</Button>
    </div>
  </div> : <SuccessFul />
}