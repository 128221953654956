import React from 'react';
import { ConfigProvider, Drawer } from 'antd';
import ReactDOM from 'react-dom';
import theme from '@/styles/antdTheme';

class GlobalDrawer {
  static drawerContainer = null;
  static navigate = null;
  static open = ({ component, props, config, navigate }: { component?, props?, config?, navigate? } = {}) => {
    if (!GlobalDrawer.drawerContainer) {
      GlobalDrawer.drawerContainer = document.createElement('div');
      document.body.appendChild(GlobalDrawer.drawerContainer);
    }
    GlobalDrawer.navigate = navigate

    const handleClose = () => {
      this.close()
    };

    const contentComponent = React.cloneElement(component, { ...props, close: handleClose });
    ReactDOM.render(
      <ConfigProvider theme={ theme } componentSize="large">
        <Drawer
          placement="right"
          onClose={ handleClose }
          open={ true }
          destroyOnClose={ true }
          width={ 800 }
          styles={ {
            header: { display: 'none' },
            body: { padding: 0 }
          } }
          keyboard={ false }
          maskClosable={ false }
          { ...config }
        >
          { contentComponent }
        </Drawer>
      </ConfigProvider>,
      GlobalDrawer.drawerContainer
    );
  };

  static close = () => {
    if (GlobalDrawer.drawerContainer) {
      ReactDOM.unmountComponentAtNode(GlobalDrawer.drawerContainer);
      GlobalDrawer.drawerContainer.parentNode.removeChild(GlobalDrawer.drawerContainer);
      GlobalDrawer.drawerContainer = null;
      if (GlobalDrawer.navigate) {
        const { origin, pathname, search } = location
        GlobalDrawer.navigate({ origin, pathname, search })
        GlobalDrawer.navigate = null;
      }
    }
  };
}

export default GlobalDrawer;