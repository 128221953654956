import './index.scss'
import React, { useCallback, useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { Row, Col, Checkbox, Form, Spin, Input, Button } from 'antd'
import { getPermissionTree, createRole, getRoleDetail, editRoleDetail, IRoleDetail } from '@/request/Role'
import { message } from 'antd';
import { EDIT_JOBS } from '@/utils/BUS_TYPE';

export default function JobCreate (props) {
  const i18n = globalState.i18n;
  const [jobForm] = Form.useForm();
  const [permissionMatrix, setPermissionMatrix] = useState<any[]>([]);
  const [checkAllList, setCheckAllList] = useState([]);
  const [roleDetail, setRoleDetail] = useState<IRoleDetail>({});
  const [permissionTreeOptions, setPermissionTreeOptions] = useState([]);
  const [mandatoryClockIn, setMandatoryClockIn] = useState<boolean>(false);
  // 需要过滤掉不展示的checkbox
  const filteredArr = [1507];

  const handleCancel = () => {
    props.close();
  }

  const formatRequestParams = async () => {
    try {
      const params = await jobForm.validateFields(['jobTitle']);
      return params;
    } catch (err) {
      return false;
    }
  }

  const handleSave = async () => {
    const params = await formatRequestParams();
    if (!params) {
      return;
    }
    const permissionIdList = permissionMatrix.flat();
    // if (!permissionIdList.length) {
    //   message.error('Please check the form');
    //   return;
    // }
    let res = {};
    if (props.id) {
      const obj = {
        roleId: roleDetail.role.roleId,
        name: params.jobTitle,
        mandatoryClockIn: +mandatoryClockIn,
        permissionIdList
      }
      res = await editRoleDetail(obj);
    } else {
      res = await createRole({
        name: params.jobTitle as string,
        permissionIdList
      });
    }
    if (res) {
      message.success(i18n?.t('menuTabList_pc_success'));
      props.close();
      props.bus.emit(EDIT_JOBS);
    }
  }
  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 4 },
  };

  const getEmployeeFormInitial = async () => {
    if (props.id) {
      const { role, rolePermissions } = props.id && await getRoleDetail({
        roleId: props.id
      });
      const { roleName, mandatoryClockIn } = role || {};
      setRoleDetail({
        role,
        rolePermissions
      })
      jobForm.setFieldValue('jobTitle', roleName);
      setMandatoryClockIn(!!mandatoryClockIn);
    }
  }

  const setCheckedList = useCallback((index: number, e: any) => {
    const list = [...checkAllList];
    list[index] = e.target.checked;
    setCheckAllList(list)
  }, [checkAllList])

  const setMatrix = useCallback((index: number, values: number[]) => {
    const matrix = [...permissionMatrix];
    matrix[index] = [...values]
    setPermissionMatrix(matrix)
  }, [permissionMatrix])

  const onCheckAllChange = (index: number, e: any) => {
    setCheckedList(index, e);
    setMatrix(index, e.target.checked ? permissionTreeOptions[index].list.map(m => m.value) : []);
  }

  const handleCheckBoxChange = (index: number, values: number[]) => {
    setMatrix(index, values);
    setCheckedList(index, { target: { checked: values.length === permissionTreeOptions[index].list.length } });
  }

  useEffect(() => {
    if (props?.id && (!!roleDetail.rolePermissions && !!permissionTreeOptions.length)) {
      const arr = roleDetail.rolePermissions.reduce((accumulator, m) => {
        const name = i18n?.t(m.groupViewName.replace(/\{|\}/g, ''));
        const values = m.permissionList ? m.permissionList.map(m2 => m2.id) : []
        const index = permissionTreeOptions.findIndex(f => f.name === name);
        accumulator[index] = values;
        return accumulator;
      }, new Array(permissionTreeOptions.length).fill([]));
      setPermissionMatrix(arr);
      setCheckAllList(arr.map((m, index) => m.length === permissionTreeOptions[index].list.length));
    }
  }, [
    roleDetail,
    permissionTreeOptions,
  ])

  useEffect(() => {
    if (props.id) {
      getEmployeeFormInitial();
    }
    getPermissionTree().then(res => {
      if (!res) {
        return;
      }
      const list = (res || []).map(m => (
        {
          name: i18n?.t(m.groupViewName.replace(/\{|\}/g, '')),
          list: m.permissionList.map(m2 => ({
            label: m2.name,
            value: m2.id
          }))
        }
      ))
      const length = list.length || 0;
      setPermissionMatrix(new Array(length).fill([]));
      setCheckAllList(new Array(length).fill(false))
      setPermissionTreeOptions(list);
    })
  }, [])

  return (
    <Spin spinning={!!props.id && !(roleDetail?.role?.roleName)}>
      <div className='job-create-content'>
        <div className='job-create-content-box'>
          <span style={{ 'fontSize': '24px', 'fontWeight': '500', 'color': '#3d3d3d' }}>
            {i18n?.t(!!props.id ? 'jobAdd_pc_edit_job' : 'employeeList_pc_add_newjob')}
          </span>
          <div>
            <Button onClick={handleCancel}>{i18n?.t('cancel')}</Button>
            <Button type="primary" disabled={roleDetail?.role?.roleTag === 1} style={{ 'marginLeft': '12px' }} onClick={handleSave}>{i18n?.t('save')}</Button>
          </div>
        </div>
        <div className='job-create-content-wrap'>
          <div className='job-create-content-title'>{i18n?.t('general_info')}</div>
          <div className='job-create-content-form'>
            <Form
              {...layout}
              form={jobForm}
              validateTrigger="onSubmit"
            >
              <Form.Item
                label={
                  <div>
                    <span>{i18n?.t('role_title')}</span>
                    <span style={{ 'color': '#ff4d4f' }}> * </span>
                  </div>
                }
                name="jobTitle"
                rules={[
                  () => ({
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(i18n.t('role_please_enter_role'));
                      }
                      return Promise.resolve()
                    }
                  }),
                ]}
              >
                {<Input required onBlur={() => { jobForm.validateFields(['jobTitle']) }} />}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className='job-create-content-wrap'>
          <div className='job-create-content-title'>{i18n?.t('role_create_time_clock')}</div>
          <div className='job-create-content-card'>
            <Checkbox checked={mandatoryClockIn} onChange={val => { setMandatoryClockIn(val.target.checked) }}>
              <span style={{ 'padding': '0px 8px' }}>{i18n?.t('role_mandatory_clock_in')}</span>
            </Checkbox>
          </div>
        </div>
        <div className='job-create-content-wrap'>
          <div className='job-create-content-title'>{i18n?.t('jobAdd_pc_access_permissions')}</div>
          <div className='job-create-content-card'>
            {
              permissionTreeOptions.map((m, i) => (
                <div className='job-create-checkbox-wrap' key={i} style={i > 0 ? { 'marginTop': '10px' } : {}}>
                  <div>
                    <Checkbox onChange={(e: any) => { onCheckAllChange(i, e) }} checked={!!checkAllList[i]} />
                    <span style={{ 'padding': '0px 8px' }}>{m.name}</span>
                  </div>
                  <div className='job-create-checkbox-group'>
                    <Checkbox.Group value={permissionMatrix[i]} style={{ width: '100%' }} onChange={(values: number[]) => { handleCheckBoxChange(i, values) }}>
                      <Row>
                        {
                          m.list.map((m2, i2) => (
                            !filteredArr.includes(m2.value) && <Col span={8} key={i2} style={{ 'lineHeight': '30px' }}>
                              <Checkbox value={m2.value} checked>{m2.label}</Checkbox>
                            </Col>
                          ))
                        }
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Spin>
  )
}