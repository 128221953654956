import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const RefundsSection = (props: IProps) => {
  return <div className='refunds-section'>
    <div>
      <TextLineNew text='REFUNDS' style={{
        fontWeight: 'bold',
      }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='refunds-section-item'>
      <div className='refunds-section-item-title'>
        <TextLineNew text='Same day refunds'/>
      </div>
      <div className='refunds-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
  </div>
}
export default RefundsSection;