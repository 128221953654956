import React from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
const SignatureSection = () => {
  return <>
    <div className='signature-section'>
      <div className='signature-section-symbol'>X</div>
    </div>
    <TextLineNew text='Signature' style={{
      fontSize: 'sm'
    }}/>
  </>
}
export default SignatureSection;