import React, { useEffect, useState } from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'

interface IChildren{
  [key: string]: any
}
interface IProps{
  children: IChildren
}
const EndOfReportSection = (props: IProps) => {
  const { children } = props;
  const [text, setText] = useState<string>('');
  useEffect(() => {
    setText((children['restaurant-info-section-text'] || {}).value)
  }, []);
  return <div className='report-footer'>
    <div className='report-footer-dashed-line'></div>
    <div className='report-footer-absolute'>
      { !!text && <TextLineNew text={text}/>}
    </div>
  </div>
}
export default EndOfReportSection;