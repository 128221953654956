import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Table } from 'antd';
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import useGetState from '@/hooks/useGetState';
import { formatBackI18n, formatFullPrice, formatTimestampWithoutTimeZone } from '@/utils';
import { ApiQueryTransactionList } from '@/request/api';
import { debounce } from '@/utils/throttleDebounce';
import PepprDatePicker from '@/Components/DatePicker';
import './index.scss'
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

function Cash (props) {
  const { i18n } = globalState;
  const { isActive, today } = props

  const [form] = Form.useForm()

  const [isInit, setIsInit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [tableData, setTableData] = useState([])
  const [showColumnsList, setShowColumnsList] = useState<Array<string>>([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)

  const REFUND_STATUS_TYPE = {
    NOT_REFUNDED: { value: '0', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_NOT_REFUNDED') },
    PARTIALLY_REFUNDED: { value: '1', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_PARTIALLY_REFUNDED') },
    FULLY_REFUNDED: { value: '2', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_FULLY_REFUNDED') }
  }

  const tableColumns = [
    { dataIndex: 'posDisplayCheckNo', key: 'posDisplayCheckNo', title: i18n.t('trans_check_no') },
    { dataIndex: 'standardCreatedTime', key: 'standardCreatedTime', title: i18n.t('trans_table_orderingTime'), render: (val) => formatTimestampWithoutTimeZone(val) },
    { dataIndex: 'orderIdLast6', key: 'orderIdLast6', title: i18n.t('trans_table_order#') },
    { dataIndex: 'tipAmount', key: 'tipAmount', title: i18n.t('trans_table_tips'), render: (val) => formatFullPrice(val) },
    { dataIndex: 'payed', key: 'payed', title: i18n.t('trans_table_paidTotal'), render: (val) => formatFullPrice(val) },
    { dataIndex: 'refundStatus', key: 'refundStatus', title: i18n.t('trans_table_refundStatus'), render: (val) => formatBackI18n(val?.displayName, i18n) || '-' },
    { dataIndex: 'refundTotal', key: 'refundTotal', title: i18n.t('trans_table_refundTotal'), render: (val) => val || Number(val) > 0 ? formatFullPrice(val) : '-' }
  ]

  const formInitialValues = {
    date: [today.subtract(6, 'day').startOf('day'), today],
    keyword: '',
    refundStatus: undefined
  }

  useEffect(() => {
    if (isActive) {
      setShowColumnsList(tableColumns.map(x => x.dataIndex))
      fetchTableData();
    }
  }, [isActive]);

  const handleFormValuesChange = (changedValues, allValues) => {
    setPager({ ...pager, current: 1 });
    if ('keyword' in changedValues) {
      debounceFetchTableData();
    } else {
      fetchTableData();
    }
  }

  const fetchTableData = async () => {
    const formParams = isInit ? form.getFieldsValue() : formInitialValues;
    const pagerParams = getPager();
    const result = {
      request: {
        payType: { value: '1', label: 'Cash' },
        keyword: formParams.keyword,
        startTime: formatTimestampWithoutTimeZone(formParams.date[0].startOf('day').valueOf(), { format: 'YYYY-MM-DD HH:mm:ss' }),
        endTime: formatTimestampWithoutTimeZone(formParams.date[1].endOf('day').valueOf(), { format: 'YYYY-MM-DD HH:mm:ss' }),
        refundStatus: Object.values(REFUND_STATUS_TYPE).find(x => x.value === formParams.refundStatus),
      },
      orderBy: {
        field: 'created_time',
        order: 'DESC'
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize
    }
    setLoading(true)
    const resp: any = await ApiQueryTransactionList(result);
    setIsInit(true)
    setLoading(false)
    if (resp.code !== 0) return;
    const data = resp?.data;
    setTableData(data.list ?? [])
    setPager({
      ...pager,
      pageSize: data.pageSize,
      total: data.total,
      current: data.current ?? 1
    })
  }

  const handleTableChange = (_pagination) => {
    setPager({ ...pager, ..._pagination, pageNo: _pagination.current });
    fetchTableData();
  }

  const debounceFetchTableData = debounce(500, fetchTableData);

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  return (
    <div className="transaction-cash">
      <div className="filter-wrap">
        <Form form={ form } layout="inline" initialValues={ formInitialValues } onValuesChange={ handleFormValuesChange }>
          <Form.Item name={ 'keyword' } normalize={ (val) => val.trim() }>
            <Input allowClear placeholder={ i18n.t('Transactions_pc_order#') } style={ { width: '250px' } } prefix={ <SearchOutlined/> }/>
          </Form.Item>
          <Form.Item name={ 'date' }>
            <PepprDatePicker today={ today }/>
          </Form.Item>
          <Form.Item name={ 'refundStatus' }>
            <Select
              allowClear
              style={ { minWidth: '150px' } }
              notFoundContent={ SelectEmptyContent }
              placeholder={ i18n.t('Transactions_pc_refund_status') }
              options={ [REFUND_STATUS_TYPE.NOT_REFUNDED, REFUND_STATUS_TYPE.PARTIALLY_REFUNDED, REFUND_STATUS_TYPE.FULLY_REFUNDED] }
            />
          </Form.Item>
        </Form>
        <div className="right-wrap">
          <Button type="link" onClick={ fetchTableData }><SyncOutlined/></Button>
          <Columns value={ showColumnsList } options={ tableColumns.map(item => ({ label: item.title, value: item.dataIndex })) } onChange={ handleChangeColumns }/>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          onChange={ handleTableChange }
          loading={ loading }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          pagination={ pager }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}

export default React.memo(Cash);