import React from 'react';
import * as ReactDOM from 'react-dom';
import { Spin } from 'antd';

class GlobalLoading {
  static loadingContainer = null;

  static show = ({ container: _container = null }: { container?: HTMLElement | null } = {}) => {
    const container = _container || document.querySelector('.ant-layout-content');
    if (!GlobalLoading.loadingContainer) {
      GlobalLoading.loadingContainer = document.createElement('div');
      GlobalLoading.loadingContainer.setAttribute('style', 'position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center; z-index: 9999;background-color: rgba(255, 255, 255, .7);');
      container.appendChild(GlobalLoading.loadingContainer);
      ReactDOM.render(<Spin size="large" />, GlobalLoading.loadingContainer);
    }
  };

  static hide = () => {
    if (GlobalLoading.loadingContainer) {
      ReactDOM.unmountComponentAtNode(GlobalLoading.loadingContainer);
      GlobalLoading.loadingContainer.parentNode.removeChild(GlobalLoading.loadingContainer);
      GlobalLoading.loadingContainer = null;
    }
  };
}

export default GlobalLoading;
