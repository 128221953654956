import React, { useEffect, useState } from 'react';
import './index.scss'
import DashedLine from '../component/DashedLine';
import TextLineNew from '../component/TextLineNew';
import { ConfigTypeValue } from '../../interface';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
interface IGuestInfoSection {
  value: string
  hide: boolean
}
interface IGuestInfo {
  [key: string]: IGuestInfoSection
}
const GuestInfoSection = (props: any) => {
  const [guestInfo, setGuestInfo] = useState<IGuestInfo>(null);
  const [sortList, setSortList] = useState<Array<string[]>>([[]]);
  const [dashedLineHide, setDashedLineHide] = useState<boolean>(true);
  const [type, setType] = useState<ConfigTypeValue>('Kitchen');
  // 有底部虚线的集合
  const bottomDashedLineList: Array<ConfigTypeValue> = ['Kitchen']

  useEffect(() => {
    const children = props.children;
    setSortList(props.sort);
    let index = 0;
    for (const key in children) {
      index += (children[key].hide ? 1 : 0);
    }
    setGuestInfo({
      ...children,
      ['guest-info-section-guestNotes']: {
        ...children['guest-info-section-guestNotes'],
        value: '[Note] This is guest note.'
      }
    });
    setDashedLineHide(index === Object.keys(children).length)
    setType(toJS(printOutDecorationStore.getUrlParamsType));
  }, [props])
  return (
    <div
      className='guest-info-section'
      key={props?.id}
    >
      {
        guestInfo && <>
          { !dashedLineHide && !bottomDashedLineList.includes(type) &&  <DashedLine /> }
          {
            sortList.map((item: string[], index) => {
              return (
                <div className='guest-info-section-content' key={index}>
                  {
                    item.map((value: string, index2: number) => {
                      const info = guestInfo[value];
                      return (
                        info && !info.hide && <TextLineNew
                          key={index2}
                          text={info.value}
                          style={{ fontSize: 'md' }}/>
                      )
                    })
                  }
                </div>
              )
            })
          }
          { !dashedLineHide && bottomDashedLineList.includes(type) && <DashedLine /> }
        </>
      }
    </div>
  )
}
export default GuestInfoSection;