import {
  Button,
  Form,
  Input,
  Space,
  Drawer,
  Radio,
  Spin,
  message,
  InputNumber,
} from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { isEmpty } from '@/utils';
import { saveCashDrawer } from '@/request/PrintersAndCashDrawers';
import './index.scss';
import { startBalanceConfigOpts, StartBalanceConfigTypeEnum } from '../enum';
import { RowData } from '../interface';
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<any>>;
  reloadTableData: () => void;
  // type: OperationType, // 0代表编辑 1代表新增
  rowData: RowData;
}

export default function CashConfigDrawer (props: Props) {
  const i18n = globalState.i18n;
  const layout = {
    labelCol: { span: 8 },
  };

  const [cashDrawerInfoForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [startBalanceConfigType, setStartBalanceConfigType] =
    useState<StartBalanceConfigTypeEnum>(StartBalanceConfigTypeEnum.LAST_END_BALANCE);

  // 初始化数据
  const initData = async () => {
    setLoading(true);
    if (props.rowData) {
      console.log('props.rowData', props.rowData);
      const {
        name,
        startBalanceConfigType,
        startBalanceConfigAmount,
        printName,
      } = props.rowData;
      cashDrawerInfoForm.setFieldsValue({
        name,
        startBalanceConfigType,
        startBalanceConfigAmount,
        printName,
      });
      setStartBalanceConfigType(startBalanceConfigType);
    }
    setLoading(false);
  };

  const onClose = () => {
    cashDrawerInfoForm.resetFields();
    props.setOpen((prevState) => !prevState);
  };

  const onSave = async () => {
    const params = await cashDrawerInfoForm.validateFields();

    const data = await saveCashDrawer({ ...params, id: props?.rowData?.id });
    if (data && data.code === 0) {
      cashDrawerInfoForm.resetFields();
      props.reloadTableData();
      message.success(i18n?.t('message_success'));
    }
  };

  const closeDrawer = () => {
    props.setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (props.open) {
      initData();
    }
  }, [props.open]);

  return (
    <Drawer
      title={i18n.t('dashboard_devices_printer_cashdrawer')}
      placement={'right'}
      width={650}
      onClose={closeDrawer}
      open={props.open}
      closeIcon={false}
      style={{ backgroundColor: '#F5F5F5' }}
      size="large"
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </Space>
      }
    >
      <div className="pos-monitor-content-card">
        <Spin spinning={loading}>
          <Form
            {...layout}
            form={cashDrawerInfoForm}
            validateTrigger="onSubmit"
            labelAlign="left"
          >
            <Form.Item
              label={
                <div>
                  <span className="printers-and-cash-drawers-required-symobol">
                    *
                  </span>
                  {i18n?.t('dashboard_devices_printer_cashdrawer_edit_name')}
                </div>
              }
              name="name"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    if (isEmpty(value)) {
                      return Promise.reject(
                        i18n?.t('please_enter_x', {
                          msg: i18n?.t(
                            'dashboard_devices_printer_cashdrawer_edit_name'
                          ),
                        })
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                required
                maxLength={30}
                placeholder={i18n?.t(
                  'dashboard_devices_printer_cashdrawer_edit_name'
                )}
                onBlur={() => {
                  cashDrawerInfoForm.validateFields(['name']);
                }}
              />
            </Form.Item>

            <Form.Item
              label={
                <div>
                  {i18n?.t(
                    'dashboard_devices_printer_cashdrawer_edit_start_balance'
                  )}
                </div>
              }
              name="startBalanceConfigType"
            >
              <Radio.Group
                onBlur={() => {
                  cashDrawerInfoForm.validateFields(['startBalanceConfigType']);
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setStartBalanceConfigType(value);
                  if (value === 1) {
                    cashDrawerInfoForm.setFieldsValue({
                      startBalanceConfigAmount: null,
                    });
                  }
                }}
              >
                {(startBalanceConfigOpts || []).map((ele) => (
                  <Radio value={ele.value} key={ele.label}>
                    {' '}
                    {ele.label}{' '}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {startBalanceConfigType === 2 && (
              <Form.Item
                name="startBalanceConfigAmount"
                rules={[
                  {
                    required: true,
                    message: i18n?.t('please_enter_x', {
                      msg: 'Starting balance amount',
                    }),
                  }
                ]}
                style={{ marginLeft: '190px' }}
              >
                <InputNumber
                  required
                  onBlur={() => {
                    cashDrawerInfoForm.validateFields([
                      'startBalanceConfigAmount',
                    ]);
                  }}
                  prefix="$"
                  min={0}
                  max={9999999}
                  precision={2}
                  style={{ width: 380,  }}
                />
              </Form.Item>
            )}

            <Form.Item
              label={
                <div>
                  {i18n.t('dashboard_devices_printer_cashdrawer_edit_printer')}
                </div>
              }
              name="printName"
            >
              <div>{props?.rowData?.printName || '-'}</div>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Drawer>
  );
}
