/**
 * 将给定数字修正为指定的有效数字位数。
 *
 * @param num 数字
 * @param precision 一个整数，指定有效数字的位数
 *
 * @example strip(0.09999999999999998) === 0.1 // true
 */
function strip (num, precision = 15) {
  return +parseFloat(Number(num).toPrecision(precision));
}

// 返回数字的位数长度
function digitLength (num) {
  // Get digit length of e
  const eSplit = num.toString().split(/[eE]/);
  const len = (eSplit[0].split('.')[1] || '').length - +(eSplit[1] || 0);
  return len > 0 ? len : 0;
}

// 将给定数字转换为整数，支持科学计数法。 如果是小数，将会放大这个数字。
function float2Fixed (num) {
  if (num.toString().indexOf('e') === -1) {
    return Number(num.toString().replace('.', ''));
  }
  const dLen = digitLength(num);
  return dLen > 0 ? strip(Number(num) * Math.pow(10, dLen)) : Number(num);
}

// 如果给定数字超出范围，则记录警告日志。
function checkBoundary (num) {
  if (_boundaryCheckingState) {
    if (num > Number.MAX_SAFE_INTEGER || num < Number.MIN_SAFE_INTEGER) {
      console.warn(`${ num } is beyond boundary when transfer to integer, the results may not be accurate`);
    }
  }
}

/**
 * 创建一个支持剩余参数的操作。
 *
 * @param operation The original operation
 */
function createOperation (operation) {
  return (...nums) => {
    const [first, ...others] = nums;
    return others.reduce((prev, next) => operation(prev, next), first);
  };
}

// 乘法
const times = createOperation((num1, num2) => {
  const num1Changed = float2Fixed(num1);
  const num2Changed = float2Fixed(num2);
  const baseNum = digitLength(num1) + digitLength(num2);
  const leftValue = num1Changed * num2Changed;

  checkBoundary(leftValue);

  return leftValue / Math.pow(10, baseNum);
});

// 加法
const plus = createOperation((num1, num2) => {
  const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)));
  return (times(num1, baseNum) + times(num2, baseNum)) / baseNum;
});

// 减法
const minus = createOperation((num1, num2) => {
  const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)));
  return (times(num1, baseNum) - times(num2, baseNum)) / baseNum;
});

// 除法
const divide = createOperation((num1, num2) => {
  const num1Changed = float2Fixed(num1);
  const num2Changed = float2Fixed(num2);

  checkBoundary(num1Changed);
  checkBoundary(num2Changed);

  // fix: 类似 10 ** -4 为 0.00009999999999999999，strip 修正
  return times(num1Changed / num2Changed, strip(Math.pow(10, digitLength(num2) - digitLength(num1))));
});

/**
 * 舍入
 *
 * @param num 要舍入的数字
 * @param decimal 一个整数，指定小数位数
 */
function round (num, decimal) {
  const base = Math.pow(10, decimal);
  let result = divide(Math.round(Math.abs(times(num, base))), base);

  if (num < 0 && result !== 0) {
    result = times(result, -1);
  }

  return result;
}

let _boundaryCheckingState = true;

/**
 * 是否检查数字的边界，默认启用。
 *
 * @param flag 指示是否启用
 */
function enableBoundaryChecking (flag = true) {
  _boundaryCheckingState = flag;
}

export default { strip, plus, minus, times, divide, round, digitLength, float2Fixed, enableBoundaryChecking };
