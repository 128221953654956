import React, { useState, useEffect } from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
interface IProps {
  text: string
}
const ZReportComponent = () => (
  <>
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew text='Cash tips' />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew text='Card tips' />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew text='Declared cash tips' />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>
  </>
)
const ShiftReport = () => (
  <>
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew text='Card tips' />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew text='Gratuity' />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew text='Cash tips (declared)' />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew text='$0.00' />
      </div>
    </div>
  </>
)
const TipsSection = (props: IProps) => {
  const [type, setType] = useState<string>('');
  useEffect(() => {
    setType(toJS(printOutDecorationStore.getUrlParamsType));
  }, [props])
  return <div className='report-tips'>
    <div>
      <TextLineNew
        text='TIPS'
        style={{
          fontWeight: 'bold',
        }} />
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }} />
    {
      type === 'ZReport' ? <ZReportComponent /> : <ShiftReport />
    }
    <div className='report-tips-item'>
      <div className='report-tips-item-title'>
        <TextLineNew
          text='Total'
          style={{
            fontWeight: 'bold',
          }} />
      </div>
      <div className='report-tips-item-value'>
        <TextLineNew
          text='$0.00'
          style={{
            fontWeight: 'bold',
          }} />
      </div>
    </div>
  </div>
}
export default TipsSection;