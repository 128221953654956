import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Form, Select, Switch } from 'antd'
import { globalState } from '@/stores';
import { IDefaultAndInheritsRes, IOptions } from '../interface'
import { getQuerys } from '@/utils'
import './Report.scss'


interface IProps {
  editInfo: {
    salesCategories: Array<any>
    inheritSalesCategory: boolean
  }
  pageType: 'menu' | 'subMenu' | 'item'
  isShowInheritRadio?: boolean
  defaultAndInherits: IDefaultAndInheritsRes
  inheritName: string
}

const Report = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState
  const { subMenuId, itemId } = getQuerys(location.href);

  const [form] = Form.useForm();
  const {
    editInfo,
    isShowInheritRadio = false,
    defaultAndInherits,
    pageType,
    inheritName = i18n.t('restaurant')
  } = props

  const [isInheritReport, setIsInheritReport] = useState<boolean>(true);
  const [allReportOptions, setAllReportOptions] = useState<Array<IOptions>>([]);
  const [inheritReportOptions, setInheritReportOptions] = useState<string>('');

  useImperativeHandle(ref, () => {
    return {
      form,
      isInheritReport
    }
  })

  const changSwitch = (value) => {
    setIsInheritReport(value)
    form.setFieldsValue({ inheritPrepStation: value })
    if (value) {
      form.setFieldsValue({ 'salesCategory': inheritReportOptions })
    }
  }

  const onChange = (value) => {
    setIsInheritReport(false)
    form.setFieldsValue({ inheritPrepStation: false })
    form.setFieldsValue({ salesCategories: value })
  }

  useEffect(() => {
    const { salesCategories, inheritSalesCategory } = editInfo

    if (inheritSalesCategory !== undefined) {
      console.log(salesCategories, inheritSalesCategory);
      form.setFieldsValue({ inheritPrepStation: inheritSalesCategory })
      setIsInheritReport(inheritSalesCategory)
    }
    if (salesCategories?.length > 0) {
      form.setFieldsValue({ salesCategory: salesCategories[0]?.id || '' })
    }
  }, [editInfo])

  useEffect(() => {
    if (defaultAndInherits) {
      const { defaultSalesCategoryConfigs, salesCategoryConfigs } =  defaultAndInherits
      const allOptions = (salesCategoryConfigs || []).map(item => ({
        value: item.id,
        label: item.name
      }));
      const inheritOptions = (defaultSalesCategoryConfigs || []).map(item => ({
        value: item.id,
        label: item.name
      }));
      if ((pageType === 'subMenu' && !subMenuId) || (pageType === 'item' && !itemId)) {
        form.setFieldsValue({ 'salesCategory': inheritOptions[0]?.value })
      }
      setAllReportOptions(allOptions)
      setInheritReportOptions(inheritOptions[0]?.value);
    }
  }, [defaultAndInherits])


  return (
    <div className='menus-report'>
      <Card title={i18n.t('pc_reports')}>
        <Form form={form} layout="vertical">
          { isShowInheritRadio && <Form.Item name="inheritPrepStation">
            <div>
              <Switch
                value={isInheritReport}
                onChange={changSwitch}
                style={{ marginRight: 5 }}
              />
              <span>{`${i18n.t('pc_inherit')} ${inheritName}`}</span>
            </div>
          </Form.Item>}
          <Form.Item name="salesCategory" label={i18n.t('pc_sales_summary_sales_category')}>
            <Select
              style={{ width: 250 }}
              options={allReportOptions}
              placeholder={i18n.t('pc_select_sales_category')}
              allowClear
              onChange={onChange}
            />
          </Form.Item>
        </Form>
      </Card>
    </div>
  )

})

export default Report