import { service } from '@/request/index';
import { IPreviewSectionAttributes, ConfigTypeValue } from '@/Pages/PrintOutDecoration/interface';
import { IRequestResponse } from '../interface';


// ***** API TYPE *****
export interface IUpdatePrintConfigParams {
  configType: ConfigTypeValue
  configDetails: Array<IPreviewSectionAttributes>
  version: number
}

export interface IQueryPrintConfigDetailParams {
  configType: ConfigTypeValue
}

export interface IQueryPrintConfigListRes {
  configType: ConfigTypeValue
  configDetails: Array<IPreviewSectionAttributes>
  modifiedTime: number
  version: number
  defaultConfigTemplate: Array<IPreviewSectionAttributes>
}

// ***** API FETCH *****
// 获取printout list 接口
export const ApiQueryPrintConfigList = (): Promise<IRequestResponse<IQueryPrintConfigListRes[]>> => service.post('rms/overseas/printConfig/queryPrintConfigList', {});

// 获取装修preview数据接口
export const ApiQueryPrintConfigDetail = (params: IQueryPrintConfigDetailParams): Promise<IRequestResponse<IQueryPrintConfigListRes>> => service.post('rms/overseas/printConfig/getPrintConfig', params)

// 更新装修preview数据的接口
export const ApiUpdatePrintConfig =
  (params: IUpdatePrintConfigParams): Promise<IRequestResponse<Pick<IQueryPrintConfigListRes, 'version'>>> =>
    service.post('rms/overseas/printConfig/updatePrintConfig', params)
