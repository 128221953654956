import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import { getCalendarToday } from '@/utils';
import { Dayjs } from 'dayjs';
import Card from './Card'
import Cash from './Cash'

enum TAB_TYPE {
  CARD = 'CARD',
  CASH = 'CASH'
}

export default function Transaction () {
  const { i18n } = globalState;
  const [tabValue, setTabValue] = useState(TAB_TYPE.CARD)
  const [today, setToday] = useState<Dayjs | null>(null)
  const tabsItems = [
    {
      key: TAB_TYPE.CARD,
      label: i18n.t('trans_card'),
      children: <Card isActive={ tabValue === TAB_TYPE.CARD } today={ today }/>
    },
    {
      key: TAB_TYPE.CASH,
      label: i18n.t('trans_cash'),
      children: <Cash isActive={ tabValue === TAB_TYPE.CASH } today={ today } />
    },
  ]

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setToday(await getCalendarToday())
  }

  const changeTab = (value) => {
    setTabValue(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-title">{ i18n.t('Transactions_pc_transactions') }</div>,
  }

  return (
    <div className="transaction">
      <Tabs tabBarExtraContent={ tabBarExtraContent } onChange={ changeTab } items={ tabsItems }></Tabs>
    </div>
  )
}