import React, { useEffect, useState } from 'react';
import './index.scss';
import { ArgumentsEnum } from './enum';
import { Flex } from 'antd';
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
interface IRestaurantInfoType {
  restaurantName?: string,
  address?: string,
  phoneNumber?: string
}

export default function RestaurantInfoSection (props: any) {
  const { children, sort } = props
  const [restaurantInfo, setRestaurantInfo] = useState<IRestaurantInfoType>({});
  const [sortList, setSortList] = useState <Array<string[]>>([[]]);
  const [addressInfo, setAddressInfo] = useState <any>({
    streetAddress:'Street Address',
    city: 'City',
    state: 'ST',
    zipcode:  'Zip Code',
  });
  const [type, setType] = useState<string>('');

  useEffect(() => {
    // 存 restaurantInfo 信息
    setRestaurantInfo({
      address: children[ArgumentsEnum['RESTAURANT-INFO-SECTION-ADDRESS']]?.value,
      restaurantName: children[ArgumentsEnum['RESTAURANT-INFO-SECTION-RESTAURANTNAME']]?.value,
      phoneNumber: children[ArgumentsEnum['RESTAURANT-INFO-SECTION-PHONENUMBER']]?.value
    })
    // 存 顺序 信息
    setSortList(sort)
    setType(toJS(printOutDecorationStore.getUrlParamsType));
  }, [props])


  useEffect(()=>{
    const { address } = restaurantInfo
    if (address) {
      setAddressInfo(JSON.parse(restaurantInfo.address));
    }
  }, [restaurantInfo])


  return (
    <div className='restaurant-info' key={props.id}>
      {(sortList || []).map(([id]) => {
        // 检查id是否在list中，如果在，则渲染对应的TextLine
        if (ArgumentsEnum['RESTAURANT-INFO-SECTION-RESTAURANTNAME'] === id) {
          return (
            <TextLineNew
              key={id}
              text={restaurantInfo.restaurantName}
              style={{
                fontSize: 'md',
                fontWeight: type === 'ZReport' || type === 'ShiftReport' ? 'default' : 'bold',
                marginBottom: 'wide'
              }}
            />
          );
        }
        if (ArgumentsEnum['RESTAURANT-INFO-SECTION-ADDRESS'] === id) {
          return (<div key={id}>
            <TextLineNew
              text={addressInfo.streetAddress}
              style={{
                fontSize: 'sm',
                fontStyle: addressInfo.streetAddress === 'Street Address' ? 'italic' : 'normal',
                color: addressInfo.streetAddress === 'Street Address' ? 'gray' : 'black'
              }}
            />

            <Flex justify='center'>
              <TextLineNew
                text={`${addressInfo.city},`}
                style={{
                  fontSize: 'sm',
                  color: addressInfo.city === 'City' ? 'gray' : 'black',
                  fontStyle: addressInfo.city === 'City' ? 'italic' : 'normal'
                }}
              />

              <TextLineNew
                text={`${addressInfo.state},`}
                style={{
                  fontSize: 'sm',
                  color: addressInfo.state === 'ST' ? 'gray' : 'black',
                  fontStyle: addressInfo.state === 'ST' ? 'italic' : 'normal'
                }}
              />

              <TextLineNew
                text={`${addressInfo.zipcode}`}
                style={{
                  fontSize: 'sm',
                  color: addressInfo.zipcode === 'Zip Code' ? 'gray' : 'black',
                  fontStyle: addressInfo.zipcode === 'Zip Code' ? 'italic' : 'normal'
                }}
              />
            </Flex>
          </div>)
        }
        if (ArgumentsEnum['RESTAURANT-INFO-SECTION-PHONENUMBER'] === id) {
          return (
            <TextLineNew
              key={id}
              text={restaurantInfo.phoneNumber}
              style={{
                fontStyle: restaurantInfo.phoneNumber === 'Phone Number' ? 'italic' : 'normal',
                color: restaurantInfo.phoneNumber === 'Phone Number' ? 'gray' : 'black'
              }}
            />
          )
        }
        return null;
      })}
    </div>
  )
}