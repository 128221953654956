import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ArchivedChange from '@/Pages/Menus/component/ArchivedChange';
import './index.scss';
import BreadCrumb from '../Menus/component/BreadCrumb';
import { ApiActivePreModifierGroup, ApiArchivePreModifierGroup, ApiGetPreModifierGroupList } from '@/request/PreModifier';
import { useNavigate } from 'react-router-dom';
import { IPageType, IPreModifierGroupItem } from './interface';

export default function PreModifier () {
  const { i18n } = globalState;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IPreModifierGroupItem[]>([]);
  const [filteredInfo, setFilteredInfo] = useState({ archived: [true, false] });

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: i18n.t('dashboard_menu_pre_modifier_group_name'),
      width: 250,
    },
    {
      dataIndex: 'preModifiers',
      key: 'preModifiers',
      title: i18n.t('dashboard_menu_pre_modifier_group_members'),
      render: (_, row) =>
        row?.preModifiers && Array.isArray(row.preModifiers)
          ? row.preModifiers.map(modifier => modifier.name).join(', ')
          : '',
    },
    {
      dataIndex: 'archived',
      key: 'archived',
      title: i18n.t('dashboard_menu_pre_modifier_group_status'),
      width: 100,
      filters: [
        { text: i18n.t('active'), value: false },
        { text: i18n.t('archived'), value: true },
      ],
      filteredValue: filteredInfo.archived || null,
      defaultFilteredValue: [true, false],
      filterResetToDefaultFilteredValue: true,
      render: (data, row) => (
        <ArchivedChange
          data={data}
          onChange={(val) => handleEditStatus(val, row)}
        />
      ),
    },
    {
      key: 'actions',
      title: i18n.t('dashboard_menu_pre_modifier_group_action'),
      align: 'center',
      width: 200,
      render: (_, row) => {
        return (
          <Button type="link" onClick={() => handlePreModifierGroup('edit', row)}>
            {i18n.t('edit')}
          </Button>
        );
      },
    },
  ];

  const handlePreModifierGroup = async (pageType: IPageType, row?: IPreModifierGroupItem) => {
    switch (pageType) {
    case 'add':
      navigate('/preModifier/edit', { state: { pageType } });
      break;
    case 'edit':
      navigate('/preModifier/edit', { state: { rowPreModifierInfo: row, pageType } });
      break;
    default:
      break;
    }
  };

  const handleEditStatus = async (val, row) => {
    if (val === row.archived) return;
    setLoading(true);
    const apiAction = val ? ApiArchivePreModifierGroup : ApiActivePreModifierGroup;
    const resp = await apiAction({ groupId: row.uuid });
    if (resp.code === 0) {
      await fetchTableData();
      message.success(i18n.t('message_success'));
    }
    setLoading(false);
  };

  const handleTableChange = (_pagination, _filters) => {
    setFilteredInfo(_filters);
  };

  const fetchTableData = async () => {
    setLoading(true);
    const res = await ApiGetPreModifierGroupList();
    setLoading(false);

    if (res.code === 0) {
      setTableData(res.data);
    }
  };

  return (
    <div className="pre-modifier">
      <div className="m-title">
        <BreadCrumb
          customTitle={[{
            title: i18n.t('dashboard_menu_pre_modifier'),
          }]}
        />
      </div>

      <div className="pre-modifier-tips">
        {i18n.t('dashboard_menu_pre_modifier_reminder')}
      </div>
      <div className="table-wrap">
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button onClick={() => handlePreModifierGroup('add')}>
            { i18n.t('dashboard_menu_pre_modifier_create_group') }
          </Button>
        </div>
        <Table
          loading={loading}
          rowKey={'uuid'}
          columns={tableColumns as ColumnsType}
          dataSource={tableData.filter((x) =>
            (filteredInfo.archived || []).includes(x.archived)
          )}
          onChange={handleTableChange}
          pagination={false}
          locale={{ emptyText: i18n.t('dashboard_menu_pre_modifier_group_empty') }}
        />
      </div>
    </div>
  );
}
