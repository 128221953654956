import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const TotalDiscountsSection = (props: IProps) => {
  return <div className='total-discounts'>
    <div>
      <TextLineNew
        text='TOTAL DISCOUNTS'
        style={{
          fontWeight: 'bold',
        }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='total-discounts-item'>
      <div className='total-discounts-item-title'>
        <TextLineNew text='Discount'/>
      </div>
      <div className='total-discounts-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>
  </div>
}
export default TotalDiscountsSection;