export enum RATE_TYPE {
  PERCENT = '1',
  FIXED = '2',
  DISABLED = '4'
}

export const RATE_TYPE_MAP = {
  [RATE_TYPE.PERCENT]: 'rms_overseas_operation_configuration_menu_RateType_Percent',
  [RATE_TYPE.FIXED]: 'rms_overseas_operation_configuration_menu_RateType_Fixed',
  [RATE_TYPE.DISABLED]: 'rms_overseas_operation_configuration_menu_RateType_Disabled'
}

export enum ROUNDING_TYPE {
  ROUND_UP = '1',
  ROUND_DOWN = '2',
  HALF_EVEN = '3',
  HALF_UP = '4'
}

export const ROUNDING_TYPE_MAP = {
  [ROUNDING_TYPE.ROUND_UP]: 'round_up',
  [ROUNDING_TYPE.ROUND_DOWN]: 'round_down',
  [ROUNDING_TYPE.HALF_EVEN]: 'half_even',
  [ROUNDING_TYPE.HALF_UP]: 'half_up'
}