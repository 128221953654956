import React, {  useState } from 'react';
import { Button, Popover } from 'antd'
import { globalState } from '@/stores';
import './Edit.scss'

export default function Edit ({ onChange, disable = true }) {
  const { i18n } = globalState;
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    !disable && setOpen(newOpen);
  };
  const content = (
    <div onClick={hide} className="ul-edit">
      <div className="edit-item" onClick={() => onChange(true)}>{i18n.t('archive')}</div>
      <div className="edit-item" onClick={() => onChange(false)}>{i18n.t('restore')}</div>
    </div>
  )
  return (
    <>
      <Popover
        content={content}
        trigger="click"
        title=""
        placement="bottomRight"
        open={open}
        onOpenChange={handleOpenChange}
        arrow={false}>
        <Button type='link' disabled={disable} className="edit-btn" icon={<i className='iconfont icon-edit'></i>}></Button>
      </Popover>
    </>
  )
}