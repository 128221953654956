import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';

const ServiceChargeSection = () => {
  return <div className='service-charge-section'>
    <div className='service-charge-section-item'>
      <TextLineNew text='SERVICE CHARGE' style={{
        fontWeight: 'bold',
      }}/>
      <TextLineNew text='Qty'/>
      <TextLineNew text='Sales'/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='service-charge-section-item'>
      <TextLineNew text='Category 1'/>
      <TextLineNew text='1'/>
      <TextLineNew text='$0.00'/>
    </div>
  </div>
}
export default ServiceChargeSection;