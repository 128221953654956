import React from 'react'
import './index.scss'
import DashedLine from '../component/DashedLine'
import TextLineNew from '../component/TextLineNew'
const PaymentInfoSection = () => {
  return (
    <div className='preview-ui-payment-info'>
      <DashedLine/>
      <TextLineNew text='06/18/2024 7:30:00 PM'/>
      <TextLineNew text='Card Info ****9999'/>
      <TextLineNew text='Method:Contactless'/>
      <TextLineNew text='Authorization:Approved'/>
      <TextLineNew text='Approval Number:123456'/>
      <TextLineNew text='Payment ID:12341234123456'/>
    </div>
  )
}
export default PaymentInfoSection;