import React from 'react';
import './index.scss';
import dayjs from 'dayjs';
import { IToolTipsDataItem } from '@/Pages/NetSalesSummary/interface';
import moment from 'moment-timezone';
import { formatFullPrice } from '@/utils';
import classNames from 'classnames';
import { globalState } from '@/stores';

interface IProps {
  title: string,
  data: Partial<IToolTipsDataItem>
}

export default function ChartTooltip (props: IProps) {
  const { i18n } = globalState;
  const { title, data } = props;
  const getItemInfo = () => {
    const currentDate = moment(title.split('##')[1]).format('MMM D,YYYY');
    const currentNetSales = data.currentNetSales
    const comparedDate = moment(dayjs(title.split('##')[1]).subtract(1, 'week').format('YYYY/MM/DD')).format('MMM D,YYYY');
    const compareNetSales = data.compareNetSales
    return { currentDate, currentNetSales, comparedDate, compareNetSales };
  }
  const itemInfo = getItemInfo();
  return (
    <div className="net-sales-summary-chart-tooltip">
      { itemInfo.currentNetSales !== undefined && <>
        <div className="date-wrap"><span className="blue-point"/><span>{ itemInfo.currentDate }</span></div>
        <div className="price-wrap"><span>{ i18n.t('pc_net_sales_summary_net_sales') }</span><span>{ formatFullPrice(itemInfo.currentNetSales) }</span></div>
      </> }
      { itemInfo.compareNetSales !== undefined && <>
        <div className={ classNames('date-wrap', { 'mt12': itemInfo.currentNetSales !== undefined }) }><span className="gray-point"/><span>{ itemInfo.comparedDate }</span></div>
        <div className="price-wrap"><span>{ i18n.t('pc_net_sales_summary_net_sales') }</span><span>{ formatFullPrice(itemInfo.compareNetSales) }</span></div>
      </> }
    </div>
  )
}