import type { Dispatch, SetStateAction } from 'react';
import { useState, useRef, useCallback } from 'react';

type GetStateAction<S> = () => S;

function useGetState<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>, GetStateAction<S>];

function useGetState<S = undefined>(): [
    S | undefined,
  Dispatch<SetStateAction<S | undefined>>,
  GetStateAction<S | undefined>
];
function useGetState<S> (initialState?: S) {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  stateRef.current = state;
  const setCurrentState = (pre: SetStateAction<S | undefined | any>) => {
    setState(pre);
    if (typeof pre === 'function') {
      stateRef.current = pre(state);
    } else {
      stateRef.current = pre;
    }
  };
  const getState = useCallback(() => stateRef.current, []);
  return [state, setCurrentState, getState];
}

export default useGetState;