import { toJS } from 'mobx';
import { IPreviewSectionAttributes } from '../interface';
import { configDataOptions } from '../mapData/typeMapConfigDataOptions';
import printOutDecorationStore from '../store';

/**
 * [function 1: ]
 * 如果block的checkbox是false的时候，其中section下面的children都会默认改为false
 *
 * */
export const handleToggleChildrenVisibility = (previewItem: IPreviewSectionAttributes, defaultSort: Array<string[]>, newCheckStatus: boolean) => {
  Object.keys(previewItem.children).forEach(key => {
    // 将每个child的hide属性设置为true/false
    previewItem.children[key].hide = !newCheckStatus;
  });

  if (newCheckStatus) {
    previewItem.sort = defaultSort
  } else {
    previewItem.sort = [[]]
  }

  return previewItem
}

/**
 * [function 2: ]
 * 如果section下面的children的hide都为true，
 * -> 则将判断父级的disabled是否为false（false为可以移除），则对应父级的hide设置为true
 * -> 则将判断父级的disabled是否为true（true为可以移除），则对应父级的hide不动
 *
 * */
export const handleParentVisibilityBasedOnChildren = (previewItem: IPreviewSectionAttributes) => {

  // 获取所有的children的hide是不是true
  const allChildrenHideStatus = Object.values(previewItem.children).every(child => child.hide);

  // 如果都为 true，则去匹配config的默认值，如果整个section可以隐藏（disable: false）的时候，把对应 preview的hide设置为true
  if (allChildrenHideStatus) {

    const typePramsInUrl = toJS(printOutDecorationStore.getUrlParamsType)

    const sectionId = previewItem.id;

    const defaultConfigItem = configDataOptions[typePramsInUrl].find(item => item.id === sectionId);

    if (defaultConfigItem && !defaultConfigItem.disabled) {
      previewItem.hide = true;
    }
  } else {
    previewItem.hide = false;
  }
  return previewItem;
}