import React, { useRef, useState } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import './index.scss'
import { TAB_TYPE } from './enum'
import Pickup from './Pickup'

export default function OnlineOrders () {
  const { i18n } = globalState;
  const [tabValue, setTabValue] = useState(TAB_TYPE.PICKUP)

  const tabsItems = [
    {
      key: TAB_TYPE.PICKUP,
      label: i18n.t('pc_online_orders_pickup'),
      children: <Pickup isActive={ tabValue === TAB_TYPE.PICKUP }/>,
    },
  ]

  const changeTab = (value) => {
    setTabValue(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-title">{ i18n.t('pc_online_orders_title') }</div>
  }

  return (
    <div className="online-orders-wrap">
      <Tabs tabBarExtraContent={ tabBarExtraContent } onChange={ changeTab } items={ tabsItems }></Tabs>
    </div>
  )
}