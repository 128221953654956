import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal } from 'antd';
import { globalState } from '@/stores';
import { toRes } from '@/utils';
import { ApiArchiveEmployee, ApiUnArchiveEmployee } from '@/request/api';
import Loading from '@/utils/loading';

const ModalEmployeeArchive = forwardRef((_, ref) => {
  const { i18n } = globalState;
  const [visible, setVisible] = useState(false);
  const [props, setProps] = useState({
    fullName: undefined,
    archived: undefined,
    employeeId: undefined
  })
  const successCb = useRef(()=>'')

  const open = ({ success, props: _props }) => {
    setProps(_props)
    successCb.current = success;
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    Loading.show()
    if (props?.archived) {
      const [err] = await toRes(ApiUnArchiveEmployee({ employeeId: props.employeeId }))
      if (err) return;
    } else {
      const [err] = await toRes(ApiArchiveEmployee({ employeeId: props.employeeId }))
      if (err) return;
    }
    Loading.hide()
    successCb.current?.()
    close();
  }

  const getTitle = () => {
    if (props?.archived) {
      return `Activate employee ${ props.fullName }`
    } else {
      return `Archive employee ${ props.fullName }`
    }
  }

  const getContent = () => {
    if (props?.archived) {
      return <div>Would you like to activate the user employee { props.fullName }</div>
    } else {
      return <div>Would you like to archive employee { props.fullName }</div>
    }
  }

  useImperativeHandle(ref, () => ({ open, close }));

  return (
    visible && <Modal
      title={ getTitle() }
      okText={ i18n?.t('pc_modal_confirm') }
      cancelText={ i18n?.t('cancel') }
      visible={ visible }
      onCancel={ close }
      onOk={ handleSubmit }
      destroyOnClose
    >
      { getContent() }
    </Modal>
  );
});

export default ModalEmployeeArchive;