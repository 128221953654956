function throttle (delay: number, callback: (...args: any[]) => void, options?: {
  noTrailing?: boolean,
  noLeading?: boolean,
  debounceMode?: boolean
}): (...args: any[]) => void {
  const { noTrailing = false, noLeading = false, debounceMode = undefined } = options || {};
  let timeoutID: NodeJS.Timeout;
  let cancelled = false;
  let lastExec = 0;

  function clearExistingTimeout () {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  }

  function cancel (options?: { upcomingOnly?: boolean }) {
    const { upcomingOnly = false } = options || {};
    clearExistingTimeout();
    cancelled = !upcomingOnly;
  }

  function wrapper (...arguments_: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const elapsed = Date.now() - lastExec;

    if (cancelled) {
      return;
    }

    function exec () {
      lastExec = Date.now();
      callback.apply(self, arguments_);
    }

    function clear () {
      timeoutID = undefined;
    }

    if (!noLeading && debounceMode && !timeoutID) {
      exec();
    }

    clearExistingTimeout();

    if (debounceMode === undefined && elapsed > delay) {
      if (noLeading) {
        lastExec = Date.now();
        if (!noTrailing) {
          timeoutID = setTimeout(debounceMode ? clear : exec, delay);
        }
      } else {
        exec();
      }
    } else if (noTrailing !== true) {
      timeoutID = setTimeout(debounceMode ? clear : exec, debounceMode === undefined ? delay - elapsed : delay);
    }
  }

  wrapper.cancel = cancel;
  return wrapper;
}

// function debounce(delay: number, callback: Function, options?: { atBegin?: boolean }): Function {
//   const { atBegin = false } = options || {};
//   return throttle(delay, callback, {
//     debounceMode: atBegin !== false
//   });
// }

function debounce<T extends any[]> (
  delay: number,
  callback: (...args: T) => void,
  options?: { atBegin?: boolean }
): (...args: T) => void {
  const { atBegin = false } = options || {};
  return throttle(delay, callback, {
    debounceMode: atBegin !== false
  });
}

export { debounce, throttle };