import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Provider as BusProvider } from 'react-bus';
import { RouterProvider } from 'react-router-dom';
import router from '@/router/router';
import { fetchLatestData, getSupportLangs } from '@/utils/language';

// import routers from './router';
import { getCookie } from '@/utils';
import './App.css';
import './styles/antd-custom.scss'
import theme from '@/styles/antdTheme';
import themeMobile from '@/styles/antdThemeMobile';
import store from '@/stores/userInfo'
import { AccountWarning } from '@/Components/AccountWarning';
import { MobileContext } from '@/stores/MobileContext';

function App () {
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      const checkUserInfo = () => {
        const { accountId } = store.getUserInfo || {};
        const { nodeId } = store.getShopInfo || {};
        console.log('accountId', accountId, 'nodeId', nodeId);
        if (accountId && nodeId) {
          const cookieUserInfo = `${getCookie('accId')}${getCookie('nodeId')}`;
          const storeUserInfo = `${accountId}${nodeId}`;
          return cookieUserInfo === storeUserInfo;
        }
        return true;
      };
      if (!checkUserInfo()) {
        AccountWarning();
      }
    }
  };
  const [isMobile, setIsMobile] = useState(true)
  useEffect(() => {
    console.log('isMobile', isMobile)
  }, [isMobile])
  useEffect(() => {
    fetchLatestData()
    getSupportLangs()
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    function handleResize () {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <MobileContext.Provider value={{ isMobile }}>
      <ConfigProvider theme={ isMobile ? themeMobile : theme } componentSize={isMobile ? 'middle' : 'large'}>
        <BusProvider>
          <div className="App">
            <RouterProvider router={router} />
          </div>
        </BusProvider>
      </ConfigProvider>
    </MobileContext.Provider>
  );
}

export default App;
