import React, { FC } from 'react';
import { globalState } from '@/stores';
import { Card, Typography } from 'antd'
import './UsageCard.scss'
const { Paragraph } = Typography;

interface IProps {
  list: any[],
  tip?: string
}
const usedItem = (list) => {
  return list.map((item, index) => {
    return <div className='usage-item' key={index}>
      <div className='usage-item-content'>
        {item.usedArchiveName}
        {list.length == index + 1 ? '' : ' / '}
      </div>
    </div>
  })
}
const UsageCard: FC<IProps> = (props) => {
  const { i18n } = globalState
  return <div className='menus-usage'>
    <Card title={i18n.t('usage')}>
      <div>{props.tip}</div>
      <div className='menus-usage-list'>
        {props.list.map((item, index) => <div key={index} className='usage-wrap'>
          {usedItem(item.archivePath)}
        </div>)}
      </div>
    </Card>
  </div>
}

export default UsageCard;