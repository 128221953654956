import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Button, Switch, Form, Flex, message } from 'antd'
import { CloseCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import AddModifierGroup from './AddModifierGroup';
import { getQuerys } from '@/utils';

import './ModifierGroups.scss'
import ModalAddModifierGroup from './ModalAddModifierGroup';
import DragTable, { DragHandle } from '@/Components/DragTable';
import { DragEndEvent } from '@dnd-kit/core';
import { ColumnsType } from 'antd/es/table';
import { globalState } from '@/stores';
import { arrayMove } from '@dnd-kit/sortable';
import { ApiFuzzyQueryMenuOptionGroup } from '@/request/Menus/CreateSubMenu';
import { IDefaultAndInheritsRes } from '../interface';
import { debounce } from '@/utils/index'

interface IProps {
  editInfo: {
    inheritModifierGroup: boolean
    modifierGroups: Array<any>
  }
  isShowInheritButton?: boolean
  defaultAndInherits?: IDefaultAndInheritsRes
  inheritName: string
}

const ModifierGroups  = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState;
  const {
    editInfo,
    isShowInheritButton = true,
    defaultAndInherits,
    inheritName = i18n.t('restaurant')
  } = props;
  const { subMenuId, type, itemId, pageType } = getQuerys(location.href);
  const [form] = Form.useForm();
  const [isInheritModifierGroups, setIsInheritModifierGroups] = useState<boolean>(true);
  const [isOpenModalAddModifierGroup, setIsOpenModalAddModifierGroup] = useState(false);
  const [modifierGroupId, setModifierGroupId] = useState<string>('')
  const [searchItemList, setSearchItemList] = useState([])

  // ** Modifier Groups List **
  // [tableData]: 全量
  // [defaultTableData]: 自定义选择的默认
  // [inheritTableData]: 继承的默认
  const [tableData, setTableData] = useState<Array<any>>([])
  const [inheritTableData, setInheritTableData] = useState<Array<any>>([])

  const tableColumns: ColumnsType = [
    {
      key: 'sort',
      align: 'center',
      width: 50,
      render: (data, row) => { return (!row.isInherit && <DragHandle />) }
    },
    {
      dataIndex: 'name',
      key: 'name',
      width: 250,
      title: <>{i18n.t('createMenuGroup_pc_menu_group')} <span style={{ 'color': 'red' }}>*</span></>,
      render: (data, row) => {
        return (<div>{data} { row.isInherit ? <i className='inherit-icon iconfont icon-link'></i> : null }</div>)
      }
    },
    {
      dataIndex: 'options',
      key: 'options',
      title: i18n.t('modifiers'),
      render: (text) => {
        return (text || []).map(item => `${item.name}${item.modifierPrice && item.modifierPrice !== '0.00' ? ' $' + item.modifierPrice : ''}`).join(', ')
      },
    },
    {
      dataIndex: 'delete',
      align: 'center',
      render: (_, record) => <Flex>
        <Button type="link" icon={<EditOutlined />} onClick={() => editItem(record)} ></Button>
        { !record.isInherit && <Button type="link" icon={<CloseCircleOutlined />} onClick={() => removeItem(record)}></Button>}
      </Flex>
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      tableData,
      isInheritModifierGroups
    };
  }, [tableData, isInheritModifierGroups]);

  const handleMergeTableData = (prevTableData) => {
    const mergedData = [];
    const inheritIds = new Map(inheritTableData.map(item => [item.uuid, item]));
    prevTableData.forEach(item => {
      if (!inheritIds.has(item.uuid)) {
        mergedData.push(item);
      }
    });
    mergedData.push(...inheritTableData);
    return mergedData
  }

  const changSwitch = (value) => {
    if (value) {
      // 如果value为true，将继承的数据加到tableData数组最后
      const mergeData = handleMergeTableData(tableData)
      setTableData(mergeData);
    } else {
      // 如果value为false，移除继承的数据
      const filteredData = tableData.filter(item => !item.isInherit);
      setTableData(filteredData);
    }

    setIsInheritModifierGroups(value)
    form.setFieldsValue({ 'isInheritModifierGroups': value });
  }

  const openNewModifierGroups = () => {
    setModifierGroupId('')
    setIsOpenModalAddModifierGroup(true)
  }

  const handleSaveModifierGroupList = (list) => {
    setModifierGroupId('')

    // 判断list的id是否在tableData中（如果在是编辑动作）
    const exists = tableData.some(item => item.uuid === list.uuid);
    if (exists) {
    // 如果在，则替换这个相同id的对象
      const isInherit = inheritTableData.some(item => item.uuid === list.uuid);
      if (isInherit) { list.isInherit = true; }
      setTableData((prevTableData) => prevTableData.map(item => item.uuid === list.uuid ? list : item));
    } else {
      // 如果不在，则push到tableData数组最后
      // 首先找到继承数据的第一个元素的索引
      const firstInheritIndex = tableData.findIndex(item => item.isInherit);
      if (firstInheritIndex !== -1) {
      // 如果存在继承数据，将新数据插入到继承数据前面
        setTableData((prevTableData) => [
          ...prevTableData.slice(0, firstInheritIndex),
          list,
          ...prevTableData.slice(firstInheritIndex)
        ]);
      } else {
      // 如果不存在继承数据，直接push到tableData数组最后
        setTableData((prevTableData) => [...prevTableData, list]);
      }
    }

    setIsOpenModalAddModifierGroup(false)
  }

  const handleCancelModifierGroupList = () => {
    setModifierGroupId('');
    setTimeout(() => {
      setIsOpenModalAddModifierGroup(false)
    })
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over.id) {
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.uuid === active?.id);
        const overIndex = prevState.findIndex((record) => record.uuid === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const removeItem = async (item) => {
    const index = tableData.findIndex(ele => ele.uuid === item.uuid);
    const newData = [...tableData];
    newData.splice(index, 1)
    setTableData(newData);
  }

  const editItem = (item) => {
    setModifierGroupId(item.uuid)
    setIsOpenModalAddModifierGroup(true)
  }

  const searchItem = async (value: string) => {
    const params = {
      req: {
        combo: false,
        name: value
      }
    }
    const res = await ApiFuzzyQueryMenuOptionGroup({ params })
    if (res.code === 0) {
      setSearchItemList(res.data)
    }
  }

  const inputItem = (e: any) => {
    debounceInput(e.searchQuery)
  }
  const debounceInput = debounce(searchItem, 500)

  const onSelectedInfo = (row) => {
    // 首先，分离出继承的数据和非继承的数据
    const inheritData = tableData?.filter(item => item.isInherit);

    // 过滤掉row中继承的数据
    const filteredRow = row?.filter(r => !inheritData.some(i => i.uuid === r.uuid));

    // 更新tableData，保留继承的数据，非继承的数据更新为过滤后的row
    setTableData([...filteredRow, ...inheritData]);
  }


  useEffect(() => {
    const  { inheritModifierGroup, modifierGroups = [] } = editInfo
    form.setFieldsValue({ 'inheritModifierGroup': inheritModifierGroup ?? true });
    setIsInheritModifierGroups(inheritModifierGroup ?? true)

    if ((pageType === 'item' && itemId) ||
    (pageType === 'subMenu' && subMenuId)) {
      // itemId 有值是编辑态
      if (inheritModifierGroup) {
        // 如果value为true，将继承的数据加到tableData数组最后
        const mergeData = handleMergeTableData([...modifierGroups, ...inheritTableData])
        setTableData(mergeData);
      } else {
        // 如果value为false，则使用detail的值
        setTableData([...modifierGroups])
      }
    } else {
      // 如果value为true，将继承的数据加到tableData数组最后
      const mergeData = handleMergeTableData([...inheritTableData])
      setTableData([...mergeData])
    }
  }, [editInfo, inheritTableData])


  useEffect(() => {
    if (defaultAndInherits) {
      const { defaultModifierGroup } = defaultAndInherits
      if (defaultModifierGroup?.length > 0) {
        const inheritData = defaultModifierGroup.map(item => {
          return {
            ...item,
            isInherit: true
          }
        })

        // 存submenu的modifierGroup的数据
        setInheritTableData(inheritData)
      }
    }
  }, [defaultAndInherits])


  return (
    <div className='menus-modifier-groups'>
      <Card title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{i18n.t('menuDatabaseList_pc_modifierGroups')}</span>
        </div>
      } >
        <Form form={form} layout="vertical">
          {
            isShowInheritButton &&
            <Form.Item
              name="inheritModifierGroup"
              style={{ marginBottom: 15 }}
            >
              <div>
                <Switch
                  checked={isInheritModifierGroups}
                  onChange={changSwitch}
                  style={{ marginRight: 5 }}
                />
                <span>{`${i18n.t('pc_inherit')} ${inheritName}`}</span>
              </div>
            </Form.Item>
          }

          {
            tableData.length > 0 ?
              <div>
                <DragTable
                  onDragEnd={onDragEnd}
                  rowKey={'uuid'}
                  columns={tableColumns}
                  dataSource={tableData}
                />
                <Flex justify='end'>
                  <Button type='link' onClick={openNewModifierGroups}>{i18n.t('+_add')}</Button>
                </Flex>
              </div>
              :
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 15, marginTop: 10 }}
                onClick={openNewModifierGroups}
              >{i18n.t('pc_new_modifier_groups')}</Button>
          }

          <Form.Item name="prepStations">
            <AddModifierGroup
              type="modifierGroup"
              dataList={searchItemList}
              defaultSelectionRow={tableData}
              onGetSearchInfo={inputItem}
              isShowSearchType={false}
              onSelectedInfo={onSelectedInfo}
              rowKey='uuid'
            />
          </Form.Item>
        </Form>

      </Card>

      <ModalAddModifierGroup
        title={`${modifierGroupId ? i18n.t('create_modifier_edit_modifier_group') : i18n.t('pc_new_modifier_groups')}`}
        sourceType={type}
        isModalOpen={isOpenModalAddModifierGroup}
        subMenuId={subMenuId}
        modifierGroupId={modifierGroupId}
        handleOk={(res) => handleSaveModifierGroupList(res)}
        handleCancel={() => handleCancelModifierGroupList()}
      />
    </div>
  )
})

export default ModifierGroups