import React, { FC, useState } from 'react'
import './index.scss';
import { switchOrg, ILogin } from '@/request/Login/index'
import { ArrowRightOutlined } from '@ant-design/icons';
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom';
import { globalState } from '@/stores';
import './index.scss'

export interface IShopListProps{
    orgList: ILogin[]
}
export const ShopList: FC<IShopListProps> = (props: IShopListProps) => {
  const { history } = globalState;
  const navigate = useNavigate();
  const [spinning] = useState(false);
  const handleShopItemClick = async (info: ILogin) => {
    sessionStorage.setItem('tenantId', String(info.tenantId));
    sessionStorage.setItem('nodeId', String(info.orgId));
    await switchOrg({
      tenantId: info.tenantId,
      orgId: info.orgId
    })
    navigate('/', { replace: true })
  }
  return <div className="shop-list">
    <div className="shop-list-logo">
      <div style={{ 'width': '166px', 'height': '48px' }}>
        <img src="https://overseasbucket.s3.us-west-1.amazonaws.com/image/PC/peppr_red_logo.png" />
      </div>
    </div>
    <div className="shop-list-tips">Please select the store to log in to</div>
    <Spin spinning={spinning} style={{ 'marginTop': '5px' }}>
      <ul className="shop-list-items">
        {
          props.orgList.map(m => (
            <li className="shop-list-item" key={m.tenantId} data-id={ m.orgId } onClick={() => {
              handleShopItemClick(m);
            }}>
              <div className="shop-list-item-wrap">
                <div className="shop-list-item-info">
                  <div className="shop-item-title">{ m.orgName }</div>
                  <div className="shop-item-id">Merchant ID : { m.orgId }</div>
                </div>
                <div className="shop-list-item-arrow">
                  <ArrowRightOutlined/>
                </div>
              </div>
            </li>

          ))
        }
      </ul>
    </Spin>
  </div>
}