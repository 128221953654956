export const defaultAndInheritsData = {
  defaultModifierGroup: [],
  defaultPrepStations: [],
  prepStations: [],
  defaultTaxRateConfigs: [],
  taxRateConfigs: [],
  defaultSalesCategoryConfigs: [],
  salesCategoryConfigs: []
}

export const colorFilter = {
  defaultList: [
    {
      color: '#ffffff',
      id: '#ffffff'
    }
  ],
  normalList: [
    '#F4E1EB',
    '#F0C7DD',
    '#D9BACC',
    '#D0CBE0',
    '#DCC1E9',
    '#BBADBD',
    '#D8E4F1',
    '#B9D6ED',
    '#C4CDE7',
    '#FAECBA',
    '#FAD88D',
    '#F4D2B3',
    '#DEE2C0',
    '#CFE5B1',
    '#BEDFD4'].map(ele => ({ color: ele, id: ele }))
}