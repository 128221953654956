import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import { toRes } from '@/utils';
import { ApiRoleArchive } from '@/request/api';
import { Modal } from 'antd'
import { message } from 'antd';
import Loading from '@/utils/loading';
import createModal from '@/utils/createModal';
import ModalRoleActivateList from '@/Pages/EmployeeList/Jobs/ModalRoleActivateList';

export default function ModalRoleArchiveConfirm (props) {
  const { i18n } = globalState;
  const { item, fetchData, close } = props

  const [visible] = useState(true);
  const [isArchived, setIsArchived] = useState()

  useEffect(() => {
    setIsArchived(item?.archived)
  }, []);

  const openRoleActivateListModal = (item) => {
    createModal(ModalRoleActivateList, { item, fetchData }).open()
  }

  const handleOk = async () => {
    if (isArchived) {
      // 取消归档
      openRoleActivateListModal(item);
      close();
    } else {
      // 归档
      Loading.show();
      const [err] = await toRes(ApiRoleArchive({ roleId: item.roleId }))
      Loading.hide();
      if (err) return;
      message.success(i18n?.t('menuTabList_pc_success'));
      fetchData();
      close();
    }
  }

  const handleCancel = () => {
    close();
  }

  return (
    visible && <Modal
      width={ 600 }
      title={ `${ i18n.t('employees_pc_are_you_want', { status: isArchived ? i18n.t('employeeList_pc_unarchived') : i18n.t('employeeList_pc_archive'), name: item.roleName }) }` }
      open={ visible }
      onOk={ handleOk }
      onCancel={ handleCancel }
    >
      <div>{ isArchived ? '' : i18n.t('employeeList_pc_will_lose_all') }</div>
    </Modal>
  )
}