// 处理数据融合
import { IPreviewSectionAttributes } from '../interface';
import { IRestaurantRealInfoData } from '../store';

export const handleRestaurantInfoFusionPreview = (restaurantInfo: IRestaurantRealInfoData, previewData: Array<IPreviewSectionAttributes>): Array<IPreviewSectionAttributes> => {
  const { name, contactPhone, streetAddress, city, state, zipcode } = restaurantInfo;
  previewData.map((section) => {
    if (section.id === 'restaurant-info-section') {
      section.children['restaurant-info-section-restaurantName'].value = name || 'Restaurant Name'
      section.children['restaurant-info-section-address'].value = JSON.stringify({
        streetAddress: streetAddress || 'Street Address',
        city: city || 'City',
        state: state || 'ST',
        zipcode: zipcode || 'Zip Code',
      })
      section.children['restaurant-info-section-phoneNumber'].value = contactPhone || 'Phone Number'
    }
  })

  return previewData
}