export enum REFUND_STATUS_TYPE {
  NA = 0,
  PARTILA_REFUNDED = 1,
  REFUNDED = 2
}

export const REFUND_STATUS_MAP = {
  [REFUND_STATUS_TYPE.NA]: 'N/A',
  [REFUND_STATUS_TYPE.PARTILA_REFUNDED]: '{dashboard_partial_refunded}',
  [REFUND_STATUS_TYPE.REFUNDED]: '{dashboard_refunded}'
}

export enum PAYMENT_STATUS_TYPE {
  VOIDED = 1,
  DECLINED = 2,
  PROCESSING = 3,
  AUTHORIZED = 4,
  CAPTURED = 5,
}

export const PAYMENT_STATUS_MAP = {
  [PAYMENT_STATUS_TYPE.VOIDED]: '{orderDetail_pc_voided}',
  [PAYMENT_STATUS_TYPE.DECLINED]: '{dashboard_declined}',
  [PAYMENT_STATUS_TYPE.PROCESSING]: '{rms_overseas_transaction_pay_PayStatus_PROCESSING}',
  [PAYMENT_STATUS_TYPE.AUTHORIZED]: '{dashboard_authorized}',
  [PAYMENT_STATUS_TYPE.CAPTURED]: '{dashboard_captured}',
};