import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { globalState } from '@/stores';
import { Card, Form, Checkbox, Switch, Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { getQuerys } from '@/utils'
import './TaxRates.scss'
import { IOptions, IDefaultAndInheritsRes } from '../interface';
import { useNavigate } from 'react-router-dom';

interface IProps {
  editInfo: {
    overrideTaxRate: boolean
    taxRates: Array<any>
  },
  defaultAndInherits: IDefaultAndInheritsRes
  pageType: 'menu' | 'subMenu' | 'item'
  inheritName: string
}

const TaxRates = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState;
  const navigate = useNavigate();

  const { subMenuId, itemId, menuId } = getQuerys(location.href);
  const  {
    editInfo: { overrideTaxRate, taxRates },
    defaultAndInherits,
    pageType,
    inheritName = i18n.t('restaurant')
  } = props

  // ** taxRates **
  // [taxRatesOptions]: 全量
  // [defaultTaxRatesOptions]: 自定义选择的默认
  // [inheritTaxRatesOptions]: 继承的默认
  const [taxRatesOptions, setTaxRatesOptions] = useState<Array<IOptions>>([]);
  const [defaultTaxRatesOptions, setDefaultTaxRatesOptions] = useState<Array<string>>([]);
  const [inheritTaxRatesOptions, setInheritTaxRatesOptions] = useState<Array<string>>([]);

  const [form] = Form.useForm();
  const [isInheritTax, setIsInheritTax] = useState<boolean>(true);

  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);

  const changeCheckBox = () => {
    setIsInheritTax(false)
    form.setFieldsValue({ 'overrideTaxRate': true })
  }

  const changSwitch = () => {
    setIsInheritTax(prevIsInheritTax => {
      if (!prevIsInheritTax) {
        form.setFieldsValue({
          'taxRates': inheritTaxRatesOptions,
          'overrideTaxRate': false
        });
      } else {
        form.setFieldsValue({
          'overrideTaxRate': true
        });
      }
      return !prevIsInheritTax;
    });
  }
  const gotoPage = (link: string) => {
    navigate(link)
  }
  useEffect(() => {
    form.setFieldsValue({ 'taxRates': defaultTaxRatesOptions });
  }, [defaultTaxRatesOptions])

  useEffect(() => {
    form.setFieldsValue({ 'overrideTaxRate': overrideTaxRate !== undefined ? overrideTaxRate : false });
    setIsInheritTax(overrideTaxRate !== undefined ? !overrideTaxRate : true)
  }, [overrideTaxRate])


  useEffect(() => {
    if (taxRates) {
      const defaultTaxOptions = taxRates.map(item => item.uuid);
      setDefaultTaxRatesOptions(defaultTaxOptions);
    }
  }, [taxRates])


  useEffect(() => {
    const { taxRateConfigs, defaultTaxRateConfigs } = defaultAndInherits

    let inheritTaxOptions = []
    let defaultTaxOptions = []
    const taxOptions = taxRateConfigs.map(item => ({ label: item.name, value: item.uuid }));
    setTaxRatesOptions(taxOptions);

    switch (pageType) {
    case 'menu':
      defaultTaxOptions = defaultTaxRateConfigs.map(item => item.uuid);
      inheritTaxOptions = (taxRateConfigs.filter(item => item.choose === true) || []).map(item => item.uuid);
      if ((pageType === 'menu' && !menuId)) {
        setDefaultTaxRatesOptions(defaultTaxOptions);
      }
      setInheritTaxRatesOptions(inheritTaxOptions);
      break;

    case 'subMenu':
    case 'item':
      defaultTaxOptions = defaultTaxRateConfigs.map(item => item.uuid);
      inheritTaxOptions = [...defaultTaxOptions]
      if ((pageType === 'subMenu' && !subMenuId) || (pageType === 'item' && !itemId)) {
        setDefaultTaxRatesOptions(defaultTaxOptions);
      }
      setInheritTaxRatesOptions(inheritTaxOptions);
      break;

    default:
      break
    }
  }, [defaultAndInherits])

  return (
    <div className='menus-tax-rates'>
      <Card title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{i18n.t('tax_rates')}</span>
          <Popover
            placement='top'
            arrow={false}
            content={<div
              className='popover-text'
              onClick={() => gotoPage('/menus/taxRates')}
              style={{ fontSize: 14 }}
            >{i18n.t('pc_taxRates_tips')}</div>}
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      } >
        <Form
          name="tax_rates_form"
          form={form}
          layout="vertical"
        >
          <Form.Item name="overrideTaxRate">
            <div>
              <Switch
                checked={isInheritTax}
                onChange={changSwitch}
                style={{ marginRight: 5 }}
              />
              <span>{`${i18n.t('pc_inherit')} ${inheritName}`}</span>
            </div>
          </Form.Item>
          {
            taxRatesOptions.length > 0 ?
              <Form.Item name="taxRates" label={i18n.t('inherited_tax')}>
                <Checkbox.Group
                  options={taxRatesOptions}
                  onChange={changeCheckBox}
                />
              </Form.Item> :
              <span className='tips-text' onClick={() => gotoPage('/menus/taxRates')}>{i18n.t('pc_taxRates_tips')}</span>
          }
        </Form>
      </Card>
    </div>
  )
})

export default TaxRates