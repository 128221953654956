import React, { useState } from 'react';
import './index.scss'
import DashedLine from '../component/DashedLine';
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store';
import TextLineNew from '../component/TextLineNew';
interface IDiscount {
  [key: string]: string
}
interface IProps {
  data?: IDiscount
}
const DiscountSection = (props: IProps) => {
  const typeParamsInUrl = toJS(printOutDecorationStore.getUrlParamsType)
  const [discountInfo, setDiscountInfo] = useState<IDiscount>({
    subtotal: '$0.00',
    serviceCharge: '$0.00',
    discount: '-$0.00',
    tax: typeParamsInUrl === 'Refund' ? '-$0.00' : '$0.00',
    tips: '$0.00',
    total: '$0.00',
    authorizedAmount: '$0.00',
    refundTotal: '-$0.00',
    // refundCardAmount: '$0.00',
    refundDate: '06/18/2024 8:00:00 PM',
  })
  return (
    <div className='preview-ui-discount'>
      {/* {( typeParamsInUrl === 'Check' || typeParamsInUrl === 'Receipt' ) && <TextLineNew text={'>> SERVICE CHARGE IS NOT GRATUITY.'} /> } */}
      {typeParamsInUrl !== 'Refund' && <DashedLine/>}
      {discountInfo.subtotal && typeParamsInUrl !== 'Refund' && <div className='preview-ui-discount-item'>
        <div className='preview-ui-discount-item-info'>
          <TextLineNew text={'Subtotal'} />
        </div>
        <div className='preview-ui-discount-item-price'>
          <TextLineNew text={discountInfo.subtotal} />
        </div>
      </div>}
      {discountInfo.discount && typeParamsInUrl !== 'Refund' && <div className='preview-ui-discount-item'>
        <div className='preview-ui-discount-item-info'>
          <TextLineNew text={'Discount'} />
        </div>
        <div className='preview-ui-discount-item-price'>
          <TextLineNew text={discountInfo.discount} />
        </div>
      </div>}
      {discountInfo.serviceCharge && typeParamsInUrl !== 'Refund' && <div className='preview-ui-discount-item'>
        <div className='preview-ui-discount-item-info'>
          <TextLineNew text={'Service Charge'} />
        </div>
        <div className='preview-ui-discount-item-price'>
          <TextLineNew text={discountInfo.serviceCharge} />
        </div>
      </div>}
      {discountInfo.tax && <div className='preview-ui-discount-item'>
        <div className='preview-ui-discount-item-info'>
          <TextLineNew text={'Tax'} />
        </div>
        <div className='preview-ui-discount-item-price'>
          <TextLineNew text={discountInfo.tax} />
        </div>
      </div>}
      {discountInfo.refundTotal && typeParamsInUrl === 'Refund' && <div className='preview-ui-discount-item'>
        <div className='preview-ui-discount-item-info'>
          <TextLineNew text={'Refund Total'} />
        </div>
        <div className='preview-ui-discount-item-price'>
          <TextLineNew text={discountInfo.refundTotal} />
        </div>
      </div>}
      {discountInfo.total && typeParamsInUrl === 'Check' &&
      <div className='preview-ui-discount-item' style={{ marginTop: 15 }}>
        <div className='preview-ui-discount-item-info'>
          <TextLineNew
            text={'Total'}
            style={{
              fontSize: 'md',
              fontWeight: 'bold',
            }}
          />
        </div>
        <div className='preview-ui-discount-item-price'>
          <TextLineNew
            text={discountInfo.total}
            style={{
              fontSize: 'md',
              fontWeight: 'bold',
            }}
          />
        </div>
      </div>}
      {discountInfo.total && typeParamsInUrl === 'Receipt' &&
       <div className='preview-ui-discount-item'>
         <div className='preview-ui-discount-item-info'>
           <TextLineNew text={'Total'} />
         </div>
         <div className='preview-ui-discount-item-price'>
           <TextLineNew text={discountInfo.total} />
         </div>
       </div>}
      {discountInfo.authorizedAmount && typeParamsInUrl === 'Receipt' &&
      <div className='preview-ui-discount-item' style={{ marginTop: 15 }}>
        {/* 后续是否显示由传入的参数控制，这期只做展示 */}
        <div className='preview-ui-discount-item-info'>
          <TextLineNew
            text={'Authorized Amount'}
            style={{
              fontSize: 'md',
              fontWeight: 'bold',
            }}
          />
        </div>
        <div className='preview-ui-discount-item-price' >
          <TextLineNew
            text={discountInfo.authorizedAmount}
            style={{
              fontSize: 'md',
              fontWeight: 'bold',
            }}
          />
        </div>
      </div>}

      {/* {
        typeParamsInUrl === 'Check' && <DashedLine/>
      } */}

    </div>
  )
}
export default DiscountSection;