export enum StatusType{
  ACTIVE = '1',
  ARCHIVED = '2'
}
export const Status = [
  {
    label: 'active',
    value: StatusType.ACTIVE
  },
  {
    label: 'archived',
    value: StatusType.ARCHIVED
  }
]