import * as Sentry from '@sentry/react';
/**
 * @description 复制文本到剪贴板
 * @param {string} text - 要复制的文本内容
 * @returns {Promise<boolean>} - Promise 对象，在成功时返回 resolve(true)，在失败时返回 reject(false)
 */
const copyText = (text: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      if (navigator?.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            Sentry.captureEvent({
              message: 'CopyText Failed',
              level: 'info',
              extra: {
                additionalInfo: error,
                feature: 'CopyText',
                api: 'navigator.clipboard'
              }
            });
            reject(false);
          });
      } else {
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        resolve(true);
      }
    } catch (error) {
      Sentry.captureEvent({
        message: 'CopyText Failed',
        level: 'info',
        extra: {
          additionalInfo: error,
          feature: 'CopyText',
        }
      });
      reject(false);
    }
  });
};
export default copyText;