import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Radio, Select, Spin } from 'antd';
import { SelectEmptyContent } from '@/Components/EmptyContent';
import { ApiQueryServiceChargeDetail, ApiQueryTaxes, ApiSaveServiceCharge } from '@/request/api';
import { removeNullUndefinedFields, to } from '@/utils';
import './index.scss'

export default function ServiceChargeEdit (props) {
  const { editItem, close, cbSave } = props
  const { i18n } = globalState;
  const [form] = Form.useForm()
  const [isInit] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [taxRateOptions, setTaxRateOptions] = useState<Array<{ value: number, label: number }>>([])
  const [overThanOptions] = useState<Array<{ value: number, label: number }>>(Array.from({ length: 101 }, (_, i) => ({ value: i, label: i })))
  const [lessThanOptions, setLessThanOptions] = useState<Array<{ value: number, label: number | string }>>([])
  const paramType = Form.useWatch('type', form);
  const paramTaxed = Form.useWatch('taxed', form);
  const paramAutoApply = Form.useWatch('autoApply', form);

  const CHARGE_TYPE = {
    PERCENT: { label: i18n.t('pc_service_charge_percent'), value: 1 },
    FIXED: { label: i18n.t('pc_service_charge_fixed_amount'), value: 2 },
    OPEN: { label: i18n.t('pc_service_charge_open'), value: 3 }
  }

  const initialValues = {
    name: '',
    type: CHARGE_TYPE.PERCENT.value,
    value: 0,
    gratuity: false,
    taxed: true,
    taxRateUuids: undefined,
    autoApply: false,
    greaterThanGuestNum: 5,
    lessThanGuestNum: -1
  }
  const init = async () => {
    const _isEdit = !!editItem;
    if (_isEdit) {
      await Promise.all([fetchOptions(), fetchDetail()])
    } else {
      await fetchOptions();
      calcLessThanOptions(5);
    }
    setLoading(false)
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    if (paramType === CHARGE_TYPE.OPEN.value) {
      form.setFieldValue('autoApply', false)
    }
  }, [paramType]);

  const formRules = {
    name: [
      { required: true, message: i18n.t('please_enter_x', { msg: i18n.t('pc_service_charge_name') }) },
      () => ({
        validator (_, value) {
          const chineseRegex = /[\u4e00-\u9fa5]/g;
          const chineseCount = (value.match(chineseRegex) || []).length;
          const totalLength = value.length + chineseCount;
          return totalLength > 200 ? Promise.reject(new Error(i18n.t('frontOfHouse_pc_maximum', { length: 200 }))) : Promise.resolve();
        },
      }),
    ],
    type: [{ required: true, message: i18n.t('please_select_x', { msg: i18n.t('pc_service_charge_charge_type') }) }],
    valuePercent: [{ required: true, message: i18n.t('please_enter_x', { msg: i18n.t('pc_service_charge_percent') }) }],
    valueFixed: [{ required: true, message: i18n.t('please_enter_x', { msg: i18n.t('pc_service_charge_fixed_amount') }) }],
    taxRateUuids: [{ required: true, message: i18n.t('please_select_x', { msg: i18n.t('pc_service_charge_tax_rate') }) }],
    autoApply: [{ required: true, message: i18n.t('please_select_x', { msg: i18n.t('pc_service_charge_auto_apply') }) }],
    greaterThanGuestNum: [{ required: true, message: i18n.t('please_select_x', { msg: i18n.t('pc_service_charge_over_than') }) }],
    lessThanGuestNum: [{ required: true, message: i18n.t('please_select_x', { msg: i18n.t('pc_service_charge_less_than') }) }],
  }

  const fetchDetail = async () => {
    const res = await ApiQueryServiceChargeDetail({ ids: [editItem.id] })
    const item = res.code === 0 ? res.data[0] : initialValues;
    const { name, type, value, gratuity, taxed, taxRateUuids, autoApply, autoApplyConfig } = item
    const greaterThanGuestNum = autoApplyConfig?.greaterThanGuestNum ?? initialValues.greaterThanGuestNum;
    const lessThanGuestNum = autoApplyConfig?.lessThanGuestNum ?? initialValues.lessThanGuestNum;
    calcLessThanOptions(greaterThanGuestNum);
    form.setFieldsValue({
      name,
      type,
      value,
      gratuity,
      taxed,
      taxRateUuids: taxRateUuids?.[0],
      autoApply,
      greaterThanGuestNum,
      lessThanGuestNum,
    })
    setLoading(false);
  }

  const fetchOptions = async () => {
    const res = await ApiQueryTaxes();
    setTaxRateOptions((res?.data || []).filter(x => !x.archived).map(x => ({ label: x.name, value: x.uuid })))
  }

  const calcLessThanOptions = (baseNumber) => {
    setLessThanOptions([
      { value: -1, label: i18n.t('no_maximum') },
      ...(Array.from({ length: 19 }, (_, i) => ({ value: i + baseNumber + 2, label: i + baseNumber + 2 })))
    ]);
  }

  const handleCancel = () => {
    close();
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const { name, type, value, gratuity, taxed, taxRateUuids, autoApply, greaterThanGuestNum, lessThanGuestNum } = formParams;
    const params = {
      name,
      type,
      value: type === CHARGE_TYPE.OPEN.value ? 0 : value,
      gratuity,
      taxed,
      taxRateUuids: taxed ? [taxRateUuids] : [],
      id: editItem?.id,
      autoApply,
      autoApplyConfig: undefined
    }
    if (autoApply) {
      params.autoApplyConfig = {
        greaterThanGuestNum,
        lessThanGuestNum,
      }
    }
    setLoading(true);
    const res = await ApiSaveServiceCharge({ serviceChargeDTOList: [removeNullUndefinedFields(params)] });
    setLoading(false)
    if (res.code !== 0) return;
    message.success(i18n.t('message_success'));
    cbSave();
    close();
  }

  const handleTypeChange = () => {
    form.setFieldValue('value', 0)
  }

  const handleOverThanChange = (value) => {
    form.setFieldValue('lessThanGuestNum', -1);
    calcLessThanOptions(value)
  }

  return (
    isInit && <Spin spinning={ loading }>
      <div className="serviceChargeEdit">
        <div className="headerWrap">
          <div className="title">{ i18n.t('pc_service_charge_edit_service_charge') }</div>
          <div className="actions">
            <Button onClick={ handleCancel }>{ i18n?.t('cancel') }</Button>
            <Button type="primary" onClick={ handleSave }>{ i18n?.t('save') }</Button>
          </div>
        </div>
        <div className="formWrap">
          <Form form={ form } layout="vertical" initialValues={ initialValues } requiredMark={ false }>
            <div className="cardBg">
              <Form.Item name={ 'name' } label={ <div>{ i18n.t('pc_service_charge_name') }<span className="required">*</span></div> } rules={ formRules.name }>
                <Input width={ 400 } placeholder={ i18n.t('enter_x', { msg: i18n.t('pc_service_charge_name') }) } onBlur={ (e) => form.setFieldValue('name', e?.target?.value?.trim?.() ?? '') }/>
              </Form.Item>
              <Form.Item name={ 'type' } label={ <div>{ i18n.t('pc_service_charge_charge_type') }<span className="required">*</span></div> } rules={ formRules.type }>
                <Select
                  notFoundContent={ SelectEmptyContent }
                  placeholder={ i18n.t('select_x', { msg: i18n.t('pc_service_charge_charge_type') }) }
                  options={ [CHARGE_TYPE.PERCENT, CHARGE_TYPE.FIXED, CHARGE_TYPE.OPEN] }
                  onChange={ handleTypeChange }
                />
              </Form.Item>
              { paramType !== CHARGE_TYPE.OPEN.value &&
                <>
                  { paramType === CHARGE_TYPE.FIXED.value &&
                    <Form.Item name={ 'value' } label={ <div>{ CHARGE_TYPE.FIXED.label }<span className="required">*</span></div> } rules={ formRules.valueFixed }>
                      <InputNumber<number>
                        style={ { width: '100%' } }
                        max={ 99999 }
                        min={ 0 }
                        formatter={ (value) => `$${ value }` }
                        precision={ 2 }
                        parser={ (value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number }
                      />

                    </Form.Item> }
                  { paramType === CHARGE_TYPE.PERCENT.value &&
                    <Form.Item name={ 'value' } label={ <div>{ CHARGE_TYPE.PERCENT.label }<span className="required">*</span></div> } rules={ formRules.valuePercent }>
                      <InputNumber<number>
                        style={ { width: '100%' } }
                        min={ 0 }
                        max={ 100 }
                        formatter={ (value) => `${ value }%` }
                        precision={ 2 }
                        parser={ (value) => value?.replace('%', '') as unknown as number }
                      />
                    </Form.Item> }
                </>
              }
            </div>
            <div className="cardBg mt16">
              <Form.Item name="gratuity" label={ <div>{ i18n.t('pc_service_charge_gratuity') }<span className="required">*</span></div> }>
                <Radio.Group options={ [
                  { value: true, label: i18n.t('pc_service_charge_gratuity_yes') },
                  { value: false, label: i18n.t('pc_service_charge_gratuity_no') }
                ] }/>
              </Form.Item>
              <Form.Item name="taxed" label={ <div>{ i18n.t('pc_service_charge_taxed') }<span className="required">*</span></div> }>
                <Radio.Group options={ [{ value: true, label: i18n.t('yes') }, { value: false, label: i18n.t('no') }] }/>
              </Form.Item>
              {
                paramTaxed &&
                <Form.Item name="taxRateUuids" label={ <div>{ i18n.t('pc_service_charge_tax_rate') }<span className="required">*</span></div> } rules={ formRules.taxRateUuids }>
                  <Radio.Group className="taxRateRadioGroup">
                    { taxRateOptions.map(x => <Radio value={ x.value } key={ x.value }>{ x.label }</Radio>) }
                  </Radio.Group>
                </Form.Item>
              }
              <Form.Item name="autoApply" label={ <div>{ i18n.t('pc_service_charge_auto_apply') }<span className="required">*</span></div> } rules={ formRules.autoApply }>
                <Radio.Group options={ [{ value: true, label: i18n.t('yes') }, { value: false, label: i18n.t('no') }] } disabled={ paramType === CHARGE_TYPE.OPEN.value }/>
              </Form.Item>
              {
                paramAutoApply && <>
                  <Form.Item label={ <div>{ i18n.t('pc_service_charge_over_than') }<span className="required">*</span></div> }>
                    <Form.Item name="greaterThanGuestNum" noStyle rules={ formRules.greaterThanGuestNum }>
                      <Select options={ overThanOptions } style={ { width: 180 } } onChange={ handleOverThanChange }/>
                    </Form.Item>
                    <span className="ml10">{ i18n.t('pc_service_charge_people') }</span>
                  </Form.Item>
                  <Form.Item label={ <div>{ i18n.t('pc_service_charge_less_than') }<span className="required">*</span></div> }>
                    <Form.Item name="lessThanGuestNum" noStyle rules={ formRules.lessThanGuestNum }><Select options={ lessThanOptions } style={ { width: 180 } }/></Form.Item>
                    <span className="ml10">{ i18n.t('pc_service_charge_people') }</span>
                  </Form.Item>
                </>
              }
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  )
}