export enum DISCOUNT_TYPE {
  PERCENT = 1,
  FIXE,
  OPEN_PERCENT,
  OPEN_FIXED,
  COMP_OPEN_PERCENT,
  COMP_OPEN_FIXED
}

export enum APPLY_TYPE {
  CHECK = 1,
  ITEM = 2
}

export enum ORDER_STATUS_TYPE {
  CANCELED = 400
}