import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { ConfigTypeValue } from '../../interface';
interface IProps{
  sectionData: any
}
const HeaderSection = (props: any) => {
  const defaultValue = 'This is header';

  const [text, setText] = useState(defaultValue);
  const [configType, setConfigType] = useState<ConfigTypeValue>('Check');
  const [marginTop, setMarginTop] = useState(0)

  useEffect(() => {
    setConfigType(toJS(printOutDecorationStore.getUrlParamsType));

    if (props && props.children) {
      const headerText = props.children['header-section-text']?.value;
      const defaultHeaderText = (toJS(printOutDecorationStore.getUrlParamsType) === 'Kitchen' ? '' : defaultValue)
      setText(headerText || defaultHeaderText)
      setMarginTop(props?.modifier?.spacing || 0)
    }
  }, [props])

  return (
    <div
      className='header-section'
      key={props?.id}
      style={
        { marginTop: configType === 'Kitchen' ? (80 + marginTop * 4) : 0 }
      }
    >
      { text &&
      <TextLineNew
        text={text}
        style={{
          color: text === 'This is header' ? 'gray' : 'black',
          fontStyle: text === 'This is header' ? 'italic' : 'normal',
          fontSize: 'md',
          fontWeight: configType === 'ZReport' || configType === 'ShiftReport' ? 'bold' : 'default'
        }}
      />
      }
    </div>)
}
export default HeaderSection;