import React, { useEffect, useState } from 'react'
import { Card, Row, Col  } from 'antd'
import { globalState } from '@/stores';
import './Usage.scss'
import { ApiViewArchiveUsage } from '@/request/Menus/CreateItem'
import { getQuerys } from '@/utils'


const Usage = (() => {
  const { itemId } = getQuerys(location.href);
  const [usageData, setUsageData] = useState([]);
  const { i18n } = globalState

  const usedItem = (list) => {
    return list.map((item, index) => {
      return <span className="pointer" key={index}>
        <i className={`point ${item.usedArchiveStatus ? 'archived' : 'active'}`}></i>
        {item.usedArchiveName}
        {list.length == index + 1 ? '' : ' / '}
      </span>
    })
  }

  const getUsageData = async () => {
    const archive = {
      archiveType: 'MenuItem',
      archiveUUID: itemId
    }
    const res = await ApiViewArchiveUsage({ params: { archive } })
    if (res.code === 0) {
      setUsageData(res.data)
    }
  }

  useEffect(() => {
    if (itemId) {
      getUsageData()
    }
  }, [])

  return (
    <div className='menus-usage'>
      <Card title={i18n.t('usage')} >
        <div className="name">
          {i18n.t('used_in_menuGroup', { x: usageData.length })}:
        </div>
        <div className="info">
          {
            usageData.map((item, index) => {
              return <span className="item" key={index + 'menuGroupList'}>
                {usedItem(item.archivePath)}
                {usageData.length == index + 1 ? '' : '、'}
              </span>
            })
          }
        </div>
      </Card>
    </div>
  )
})

export default Usage
