import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
interface IProps{
  text: string
}
const ReprintSection = (props: IProps) => {
  return <div className='reprint-section'>
    <div className='reprint-section-dashed-line'></div>
    <div className='reprint-section-absolute'>
      <TextLineNew text='REPRINT: Dec 12, 2021 5:00:00 PM' style={{
        fontWeight: 'bold',
        fontSize: 'md'
      }}/>
    </div>
  </div>
}
export default ReprintSection;