import React, { useState, useEffect } from 'react';
import DashedLine from '../component/DashedLine';
import { ArgumentsEnum, fontSizeMapValue } from './enum';
import './index.scss';
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { ConfigTypeValue, IPreviewSectionChildren } from '../../interface';
import CheckNotesSection from '../checkNotesSection';
import { IItemInfoType } from './interface';

const ItemInfoSection = (props: any) => {
  const { children, sort } = props;

  const initData = {
    hide: true,
    value: '',
    modifier: {
      fontSize: '22',
    },
  };

  const [type, setType] = useState<ConfigTypeValue>('Check');
  const [itemInfo, setItemInfo] = useState<IItemInfoType>({
    itemNotes: { ...initData },
    checkNotes: { ...initData },
    itemDish: { ...initData },
    itemDishModifier: { ...initData },
  });

  const topDashedLineList: Array<ConfigTypeValue> = ['Kitchen'];

  useEffect(() => {
    setItemInfo({
      dishLineSpacing:
        children[ArgumentsEnum['ITEM-INFO-SECTION-DISHLINESPACING']]?.value,
      itemNotes: children[ArgumentsEnum['ITEM-INFO-SECTION-ITEMNOTES']] || {
        ...initData,
      },
      checkNotes: children[ArgumentsEnum['ITEM-INFO-SECTION-CHECKNOTES']] || {
        ...initData,
      },
      groupByItem:
        children[ArgumentsEnum['ITEM-INFO-SECTION-GROUPBYITEM']]?.value,
      itemDish: children[ArgumentsEnum['ITEM-INFO-SECTION-ITEMDISH']],
      itemDishModifier:
        children[ArgumentsEnum['ITEM-INFO-SECTION-ITEMDISHMODIFIER']],
    });

    setType(toJS(printOutDecorationStore.getUrlParamsType));
  }, [props]);

  const ItemNode: React.FC<{ itemInfo: IItemInfoType }> = ({ itemInfo }) => {
    const { itemNotes } = itemInfo;
    const { value, modifier } = itemNotes;
    const itemNodeFontSize = modifier?.fontSize || '5';

    return (
      <div className="item-info-comp">
        <div className="item-info-comp-column">
          <TextLineNew
            text={value}
            style={{
              fontSize: type === 'Kitchen' ? fontSizeMapValue[itemNodeFontSize] : 'sm',
            }}
          />
        </div>
      </div>
    );
  };

  const ItemPrice = () => {
    return (
      <TextLineNew
        text={type === 'Kitchen' ? '' : type === 'Refund' ? '-$0.00' : '$0.00'}
        style={{
          fontWeight: type === 'Kitchen' ? 'bold' : 'default',
          fontSize: type === 'Kitchen' ? 'md' : 'sm',
        }}
      />
    );
  };

  const ItemInfoWrap = ({
    id,
    name,
    isMarginBottom = true,
    itemDish,
  }: {
    id: string;
    name: string;
    isMarginBottom?: boolean;
    itemDish: IPreviewSectionChildren;
  }) => (
    <div
      className="item-info-wrap"
      style={{
        marginBottom: `${isMarginBottom ? itemInfo.dishLineSpacing : 0}px`,
      }}
    >
      <div className="item-info-wrap-item">
        <div className="item-info-wrap-item-id">
          <TextLineNew
            text={id}
            style={{
              fontWeight: type === 'Kitchen' ? 'bold' : 'default',
              fontSize:
                type === 'Kitchen'
                  ? fontSizeMapValue[itemDish?.modifier?.fontSize]
                  : 'sm',
            }}
          />
        </div>
        <div className="item-info-wrap-item-info">
          <TextLineNew
            text={name}
            style={{
              fontWeight: type === 'Kitchen' ? 'bold' : 'default',
              fontSize:
                type === 'Kitchen'
                  ? fontSizeMapValue[itemDish?.modifier?.fontSize]
                  : 'sm',
            }}
          />
        </div>
        <div className="item-info-wrap-item-price">
          <ItemPrice />
        </div>
      </div>

      <ItemModifierInfoWrap name="Modifier name" itemDishModifier={itemInfo?.itemDishModifier} />
    </div>
  );


  const ItemModifierInfoWrap = ({
    name,
    itemDishModifier,
  }: {
    name: string;
    itemDishModifier: IPreviewSectionChildren;
  }) => (
    <div
      className="item-info-wrap"
      style={{
        marginLeft: 50,
      }}
    >
      <div className="item-info-wrap-item">
        <div className="item-info-wrap-item-info">
          <TextLineNew
            text={name}
            style={{
              fontWeight: type === 'Kitchen' ? 'bold' : 'default',
              fontSize:
                type === 'Kitchen'
                  ? fontSizeMapValue[itemDishModifier?.modifier?.fontSize]
                  : 'sm',
            }}
          />
        </div>

      </div>
    </div>
  );

  return (
    <div
      className="item-info"
      style={topDashedLineList.includes(type) ? { marginTop: '16px' } : {}}
    >
      {!topDashedLineList.includes(type) && <DashedLine />}

      {!itemInfo?.checkNotes?.hide && type === 'Kitchen' && (
        <CheckNotesSection  itemInfo={itemInfo} />
      )}

      {type === 'Kitchen' && itemInfo.groupByItem === 'Yes' && (
        <>
          <ItemInfoWrap id="2" name="Item name 1" itemDish={itemInfo?.itemDish} />
        </>
      )}

      {type === 'Kitchen' && itemInfo.groupByItem === 'No' && (
        <>
          <ItemInfoWrap
            id="1"
            name="Item name 1"
            itemDish={itemInfo?.itemDish}
          />
          <ItemInfoWrap
            id="1"
            name="Item name 1"
            itemDish={itemInfo?.itemDish}
          />
        </>
      )}

      {type !== 'Kitchen' && (
        <>
          <ItemInfoWrap id="1" name="Item name 1" itemDish={itemInfo?.itemDish} />
        </>
      )}

      <ItemInfoWrap id="1" name="Item name 2" itemDish={itemInfo?.itemDish} />
      <ItemInfoWrap
        id="1"
        name="Item name 3"
        isMarginBottom={false}
        itemDish={itemInfo?.itemDish}
      />

      {!itemInfo?.itemNotes?.hide && <ItemNode itemInfo={itemInfo} />}

      {itemInfo?.checkNotes?.hide === false && type !== 'Kitchen' && (
        <CheckNotesSection  itemInfo={itemInfo}/>
      )}
    </div>
  );
};
export default ItemInfoSection;
