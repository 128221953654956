import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card, Form, InputNumber, Radio, Popover  } from 'antd'
import './Prices.scss'
import { IDisplayOption } from '../interface'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { globalState } from '@/stores';


interface IProps {
  editInfo: {
    pricingType: IDisplayOption
    basePrice: number
  },
  changePricePolicy?: (pricePolicy: 'default' | 'openPrice') => void
}
const Prices = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState
  const {
    editInfo
  } = props
  const [form] = Form.useForm();
  const [pricingTypeRadio, setPricingTypeRadio] = useState('default');

  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);



  useEffect(() => {
    const { pricingType, basePrice } = editInfo
    form.setFieldsValue({
      'pricingType': pricingType?.value ?? 'default',
      basePrice
    });
    setPricingTypeRadio( pricingType?.value ?? 'default')
  }, [editInfo])

  return (
    <div className='menus-prices'>
      <Card title="Prices" >
        <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
          <div>
            <span>{i18n.t('price_strategy')}</span>
            <span style={{ 'color': '#ff4d4f' }}> * </span>
          </div>
          <Form.Item
            name="pricingType"

            rules={[
              { validator: async (_, value) => {
                if (!value) {
                  return Promise.reject(new Error(i18n.t('pc_enter_price')));
                }
              } },
            ]}>
            <Radio.Group onChange={(e) => {
              setPricingTypeRadio(e.target.value)
              props.changePricePolicy && props.changePricePolicy(e.target.value)
            }}>
              <Radio value='default'>{i18n.t('base_price')}</Radio>
              <Radio value='openPrice'>
                {i18n.t('open_price')}
                <Popover style={{ 'width': '50px' }} content={
                  <div className='lorem-line-break'>
                    {i18n.t('createMenuItem_pc_open_price_tips')}
                  </div>
                } trigger="hover">
                  <ExclamationCircleOutlined style={{ 'marginLeft': '10px' }} />
                </Popover>
              </Radio>
            </Radio.Group>
          </Form.Item>
          {
            pricingTypeRadio === 'default' && <Form.Item
              name="basePrice"
              label={
                <div>
                  <span>{i18n.t('price')}</span>
                  <span style={{ 'color': '#ff4d4f' }}> * </span>
                </div>
              }
              rules={[
                { validator: async (_, value) => {
                  if (value == null || value === '') {
                    return Promise.reject(new Error(i18n.t('pc_enter_price')));
                  }
                } },
              ]}>
              <InputNumber prefix='$' min={0} max={9999999} precision={2} style={{ width: 200 }} />
            </Form.Item>
          }

        </Form>
      </Card>
    </div>
  )
})

export default Prices
