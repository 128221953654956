import React, { useEffect, useRef, useState } from 'react'
import { Table,  Input, Select, Space } from 'antd'
import type { TableColumnsType } from 'antd';
import { globalState } from '@/stores';
import { formatFullPrice } from '@/utils';
import './AddModifierGroup.scss'


interface IModifierDataType {
  key: React.Key;
  name: string;
  price: number;
  modifierGroups: string;
  isInherit: boolean
}

interface IModifierGroupDataType {
  key: React.Key;
  name: string;
  modifiers: string;
  itemName: string;
  isInherit: boolean
}

interface IItemDataType {
  key: React.Key;
  name: string;
  price: number;
  isInherit: boolean
}

type ITableRow =  Array<IModifierDataType | IModifierGroupDataType | IItemDataType>

interface IProps {
  type: 'modifier' | 'modifierGroup' | 'itemGroup'
  isShowSearchType?: boolean
  dataList: Array<any>
  rowKey: string;
  defaultSelectionRow?:  ITableRow
  onGetSearchInfo: (data: {
    searchType: 'Name' | 'ModifyGroup' | '',
    searchQuery: string
  }) => void
  onSelectedInfo: (data: {
    selectionRow?:  ITableRow
  }) => void
}

type ITypeOptions = {
  [key in IProps['type']]: {
    title: string;
    placeholder: string;
    columns: TableColumnsType<IModifierDataType | IModifierGroupDataType | IItemDataType>
  };
}

type IIpuntType = 'open' | 'close' | 'search'

interface ICallBackData {
  searchType: 'Name' | 'ModifyGroup' | ''
  searchQuery: string
  // selectionRow: ITableRow
}


/**
 * [AddModifierGroup] 使用说明
 *
 * props 入参如下 --->
 * @param onGetSearchInfo: (data: {
 *   searchType: 'Name' | 'ModifyGroup' | '', // 搜索类型，可选值为 'Name' 或 'ModifyGroup'
 *   searchQuery: string, // 搜索参数
 *   selectionRow: [{}], // 选中的所有row
 * }) => void
 *
 * @param isShowSearchType: 是否使用搜索类型，默认值：true
 *
 * @param dataList: table的渲染事件
 *
 * @param defaultSelectionRow: 初始化table被选中的值
 *
 * @param type: 使用改组件的type类型，对于一些placeholder有区分
 *
 * @param rowKey: 表单选择的唯一字段
 *
 * */

export default function AddModifierGroup (props: IProps) {
  const { i18n } = globalState
  const {
    type,
    isShowSearchType,
    dataList,
    rowKey,
    defaultSelectionRow,
    onGetSearchInfo,
    onSelectedInfo
  } = props;
  const [isShowSearchSelect, setIsShowSearchSelect] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
  const [dataSource, setDataSource] = useState<ITableRow>([]);
  const [isOpenList, setOpenList] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [callBackData, setCallBackData] = useState<ICallBackData>({
    searchType: 'Name',
    searchQuery: '',
    // selectionRow: defaultSelectionRow
  });
  const wrapperRef = useRef(null);

  const modifierColumns = [
    {
      title: i18n.t('name'),
      dataIndex: 'name',
    },
    {
      title: i18n.t('price'),
      dataIndex: 'modifierPrice',
      render: (text) => `${formatFullPrice(text)}`,
    },
    {
      title: i18n.t('modifier_groups'),
      dataIndex: 'modifierGroups',
      render: (ele) => {
        if (ele) {
          const list = (ele || []).map((item, index: number) => `${item.name}${index < ele.length - 1 ? ', ' : ''}`);
          return list;
        }
        return '';
      },
    },
  ];
  const modifierGroupColumns = [
    {
      title: i18n.t('menuDatabaseList_pc_modifierGroups'),
      dataIndex: 'name',
    },
    {
      title: i18n.t('modifiers'),
      dataIndex: 'options',
      render: (text) => {
        const arr = (text || []).map(item => {
          if (item.modifierPrice && item.modifierPrice !== '0.00') {
            return (`${item.name} $${item.modifierPrice}`)
          } else {
            return (item.name)
          }
        })
        return arr.length > 0 ? arr.join(', ') : '-'
      },
    },
  ];
  const itemColumns = [
    {
      title: i18n.t('name'),
      dataIndex: 'name',
    },
    {
      title: i18n.t('price'),
      dataIndex: 'basePrice',
      render: (data) => {
        return data != null && data > 0 ? '$' + data : '-'
      }
    },
  ];

  const typeOptions: ITypeOptions = {
    'modifier': {
      title: i18n.t('pc_add_from_existing_modifier'),
      placeholder: i18n.t('pc_search_existing_modifier'),
      columns: modifierColumns,
    },
    'modifierGroup': {
      title: i18n.t('pc_add_from_existing_modifier_group'),
      placeholder: i18n.t('pc_search_existing_modifier_group'),
      columns: modifierGroupColumns,
    },
    'itemGroup': {
      title: '',
      placeholder: i18n.t('pc_search_existing_item'),
      columns: itemColumns,
    }
  }

  const selectOptions: Array<{value: string, label: string}> = [
    {
      value: 'Name',
      label: i18n.t('modifier'),
    },
    {
      value: 'ModifyGroup',
      label: i18n.t('modifier_groups'),
    },
  ];

  const onSelect = (record, selected) => {
    let newSelectedRow: any = [];
    let newSelectedRowKeys = [];
    if (selected) {
      newSelectedRow = [record, ...defaultSelectionRow]
    } else {
      newSelectedRow = defaultSelectionRow.filter(item => item[rowKey] !== record[rowKey]);
    }
    newSelectedRowKeys = newSelectedRow?.map(item => item[rowKey]) || [];
    setSelectedRowKeys(newSelectedRowKeys)
    onSelectedInfo(newSelectedRow)
  }

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 70,
    onSelect: onSelect,
    getCheckboxProps: record => ({
      disabled: defaultSelectionRow.some(item => item[rowKey] === record[rowKey] && item.isInherit),
    })
  };

  const handleInput = (inputType: IIpuntType, e: any) => {
    // 初次点击的时候请求onClick
    switch (inputType) {
    case 'open':
      setOpenList(true)
      setCallBackData({ ...callBackData, searchQuery: '' })
      break;
    case 'search':
      const searchQuery = e.target.value
      setSearchValue(searchQuery)
      setCallBackData({ ...callBackData, searchQuery })
      break;
    default:
      break
    }
  }

  const onChangeSearchType = (searchType) => {
    setCallBackData({ ...callBackData, searchType })
  }

  useEffect(() => {
    // 获取props默认值
    setDataSource(dataList);
    setIsShowSearchSelect(isShowSearchType ?? true);
  }, [dataList]);


  useEffect(() => {
    // 获取select默认值
    const initialSelectedRowKeys = defaultSelectionRow?.map(item => item[rowKey]) || [];
    setSelectedRowKeys(initialSelectedRowKeys);
  }, [defaultSelectionRow])

  useEffect(() => {
    if (isShowSearchType === false) {
      setCallBackData({ ...callBackData, searchType: '' })
    }
  }, [isShowSearchType])

  useEffect(() => {
    onGetSearchInfo(callBackData)
  }, [callBackData])

  // 点击外部关闭Table的逻辑
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenList(false);
        setSearchValue('')
      }
    }
    // 组件挂载时添加监听器
    document.addEventListener('mousedown', handleClickOutside);
    // 组件卸载时移除监听器
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className='menus-add-modifier-group' ref={wrapperRef}>
      <div style={{ marginBottom: 10 }}>{typeOptions[type].title}</div>
      <Space.Compact size="large" style={{ width: '100%' }}>
        {
          isShowSearchSelect &&
          <Select
            style={{ width: 200 }}
            defaultValue="Name"
            options={selectOptions}
            onChange={onChangeSearchType}
          />
        }
        <Input
          placeholder={typeOptions[type].placeholder}
          value={searchValue}
          onChange={e =>handleInput('search', e)}
          onClick={e => handleInput('open', e)}
          maxLength={100}
          allowClear
        />
      </Space.Compact>
      <div className={`select-table ${isOpenList ? 'show' : ''}`}>
        <Table
          rowSelection={rowSelection}
          pagination={false}
          columns={typeOptions[type].columns}
          dataSource={dataSource}
          rowKey={rowKey}
          scroll={{ y: 450 }}
          onRow={(record) => ({
            onClick: () => {
              const isSelected = selectedRowKeys.includes(record[rowKey]);
              onSelect(record, !isSelected)
            },
          })}
        />
      </div>
    </div>
  )
}