import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { globalState } from '@/stores';
import classNames from 'classnames';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { TableEmptyContent } from '@/Components/EmptyContent';
import './index.scss'
import { IProductMixData, ISubMenuProductMix } from '../interface';
import { formatFullPrice, isEmpty } from '@/utils';
import NP from '@/utils/NP';

interface IProps {
  reportData: Partial<IProductMixData>
}

export default function BreakdownByMenuList (props: IProps) {
  const { i18n } = globalState;
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)
  const [displayTableData, setDisplayTableData] = useState<Array<ISubMenuProductMix>>([])
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ISubMenuProductMix>>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const { reportData: { subMenuStats = [] } } = props;

  const tableColumns = [
    { title: i18n.t('pc_product_mix_name'), dataIndex: 'name', width: 200 },
    {
      title: i18n.t('pc_product_mix_avg_price'), dataIndex: 'averagePrice', key: 'averagePrice', width: 120, render: val => formatFullPrice(val),
      sorter: true, sortOrder: sortedInfo.columnKey === 'averagePrice' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend']
    },
    {
      title: i18n.t('pc_product_mix_quantity'), dataIndex: 'actualQuantity', key: 'actualQuantity', width: 100,
      sorter: true, sortOrder: sortedInfo.columnKey === 'actualQuantity' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend']
    },
    {
      title: i18n.t('pc_product_mix_total_sales'), dataIndex: 'totalSales', key: 'totalSales', width: 130, render: val => formatFullPrice(val),
      sorter: true, sortOrder: sortedInfo.columnKey === 'totalSales' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend']
    },
    {
      title: i18n.t('pc_product_mix_net_sales'), dataIndex: 'netSales', key: 'netSales', width: 120, render: val => formatFullPrice(val),
      sorter: true, sortOrder: sortedInfo.columnKey === 'netSales' ? sortedInfo.order : null, sortDirections: ['descend', 'ascend']
    },
    { title: i18n.t('pc_product_mix_quantity_all'), dataIndex: 'quantityToAllPct', width: 150, render: val => `${NP.times(val, 100).toFixed(2)}%` },
    { title: i18n.t('pc_product_mix_quantity_sub_menu'), dataIndex: 'quantityToSubMenuPct', width: 190, render: val => `${NP.times(val, 100).toFixed(2)}%` },
    { title: i18n.t('pc_product_mix_net_sales_all'), dataIndex: 'netSalesToAllPct', width: 150, render: val => `${NP.times(val, 100).toFixed(2)}%` },
    { title: i18n.t('pc_product_mix_net_sales_sub_menu'), dataIndex: 'netSalesToSubMenuPct', width: 200, render: val => `${NP.times(val, 100).toFixed(2)}%` },
  ]
  const [showColumnsList, setShowColumnsList] = useState(tableColumns.map(x => x.dataIndex))

  useEffect(() => {
    if (isEmpty(props.reportData)) return;
    const sourceList = props.reportData.subMenuStats || []
    const result = sourceList
      .map((item, itemIndex) => ({
        ...item,
        menuItemStats: item.menuItemStats
          .map((subItem, subItemIndex) => ({ ...subItem, key: `${itemIndex}-${subItemIndex}` })),
        key: itemIndex,
      }))
    setExpandedRowKeys([]);
    setDisplayTableData(result)
  }, [props.reportData])

  const handleChangeColumns = (showColumnsList) => {
    setIsShowEmptyTable(showColumnsList.length === 0)
    setShowColumnsList(showColumnsList);
  }

  const handleTableChange = (_, __, sorter) => {
    setSortedInfo(sorter);
  }

  const getSortFn = (a, b) => {
    const { columnKey, order } = sortedInfo
    if (!order) return a.name.localeCompare(b.name);
    if (order === 'ascend') return a[columnKey as keyof typeof a] - b[columnKey as keyof typeof b]
    if (order === 'descend') return b[columnKey as keyof typeof b] - a[columnKey as keyof typeof a]
  }

  const handleExpandedRowsChange = (expandedRows) => {
    setExpandedRowKeys(expandedRows)
  }

  return (
    <div className="product-mix-break-down-by-menu">
      <div className="break-down-by-menu-header-wrap">
        <div className="break-down-by-menu-title-wrap">
          <span>{i18n.t('pc_product_mix_product_mix_breakdown_by_menu')}</span>
        </div>
        <div className="break-down-by-menu-actions-wrap">
          <Columns value={showColumnsList} options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex }))} onChange={handleChangeColumns} />
        </div>

      </div>
      <div className={classNames('break-down-by-menu-table-wrap', { 'no-data': subMenuStats.length === 0 })}>
        <Table
          className={isShowEmptyTable && 'empty-table'}
          columns={tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType}
          onChange={handleTableChange}
          dataSource={
            displayTableData
              .sort(getSortFn)
              .map(x => ({ ...x, menuItemStats: x.menuItemStats.sort(getSortFn) }))
          }
          rowKey={'key'}
          scroll={{ x: 'max-content' }}
          expandable={
            {
              childrenColumnName: 'menuItemStats',
              indentSize: 0,
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: handleExpandedRowsChange,
              expandIcon: ({ expanded, onExpand, record }) => {
                if ((record?.menuItemStats || []).length === 0) return;
                return expanded ? (
                  <span className="iconfont icon-DownOutlined expand-icon" onClick={e => onExpand(record, e)} />
                ) : (
                  <span className="iconfont  icon-UpOutlined expand-icon" onClick={e => onExpand(record, e)} />
                )
              }
            }
          }
          pagination={false}
          summary={() => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={999}>
                {TableEmptyContent}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
    </div>
  )
}