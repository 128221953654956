import React, { useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
interface IProps {
  text: string
}
const CustomerCopySection = (props: IProps) => {
  const [text, setText] = useState<string>('// MERCHANT COPY //');
  return <>{text && <div className='preview-ui-customer-copy-section'>
    <TextLineNew
      text={text}
      style={{
        fontSize: 'sm',
        fontWeight: 'bold'
      }}
    />
  </div>}</>
}
export default CustomerCopySection;