import './index.scss'
import { globalState } from '@/stores';
import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd'
import DiningOptionsTable from './DiningOptionsTable';
// import PosModuleDisplay from './PosModuleDisplay'; S22隐藏此页面
// import TabPane from 'antd/es/tabs/TabPane';
import { TabType } from './enum'

export default function DiningOptions () {
  const { i18n } = globalState;
  const [activeKey, setActiveKey] = useState(TabType.DININGOPTION);
  const changeTab = (value) => {
    setActiveKey(value);
  }

  const tabBarExtraContent = {
    left: <div className="main-tab-title">{ i18n.t('pc_product_mix_dining_option') }</div>,
  }

  return (
    <div className="dining-options-content">
      <div className="m-title">
        <div className="title">{i18n.t('pc_product_mix_dining_option')}</div>
      </div>
      <DiningOptionsTable activeKey={activeKey}/>
    </div>
  )
}