import { service } from '../index'

export const queryAllMenusCorrespondingStore: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/queryAllMenusCorrespondingStore', params);
export const queryMenuGroup: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/queryMenuGroup', params);
export const batchArchiveOrRestoreMenuNodes: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/batchArchiveOrRestoreMenuNodes', params);
export const getMenuItemList: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/getMenuItemList', params);
export const searchMenuItem: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/searchMenuItem', params);
export const addRelations: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/addRelations', params);
export const deleteRelations: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/deleteRelations', params);
export const fuzzyQueryMenuItem: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/fuzzyQueryMenuItem', params);
export const reSortUniversal: any = params => service.post('rms/overseas/MerchantManagementConsole/Menu/reSortUniversal', params);