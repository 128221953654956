import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const CashDrawerSection = (props: IProps) => {
  return <div className='cash-drawer-section'>
    <div>
      <TextLineNew text='CASH DRAWER' style={{
        fontWeight: 'bold'
      }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='cash-drawer-section-item'>
      <div className='cash-drawer-section-item-title'>
        <TextLineNew text='Cash payment'/>
      </div>
      <div className='cash-drawer-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='cash-drawer-section-item'>
      <div className='cash-drawer-section-item-title'>
        <TextLineNew text='Cash refund'/>
      </div>
      <div className='cash-drawer-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='cash-drawer-section-item'>
      <div className='cash-drawer-section-item-title'>
        <TextLineNew text='Cash in'/>
      </div>
      <div className='cash-drawer-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='cash-drawer-section-item'>
      <div className='cash-drawer-section-item-title'>
        <TextLineNew text='Cash out'/>
      </div>
      <div className='cash-drawer-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='cash-drawer-section-item'>
      <div className='cash-drawer-section-item-title'>
        {/* <TextLineNew text='Balance' type='bold'/> */}
        <TextLineNew text='Balance' style={{
          fontWeight: 'bold'
        }}/>
      </div>
      <div className='cash-drawer-section-item-value'>
        {/* <TextLineNew text='$0.00' type='bold'/> */}
        <TextLineNew text='$0.00' style={{
          fontWeight: 'bold'
        }}/>
      </div>
    </div>
  </div>
}
export default CashDrawerSection;