import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, message, Popconfirm, Checkbox, Spin } from 'antd'
import { globalState } from '@/stores';
import { isEmpty, isStrictlyEmptyString, toRes } from '@/utils'
import { listPrepStations, getPrepStation } from '@/request/PrintersAndCashDrawers';
import NestedList from './components/NestedList';
import InfoDetail from './components/InfoDetail';
import './index.scss'
import { FilterOutlined } from '@ant-design/icons';
import { Status, StatusType } from './enum'
import { TabType } from '../Printer/enum';
interface IProps{
    activeKey: TabType
}

export default function PrepStations (props: IProps) {
  const i18n = globalState.i18n;
  const [prepStations, setPrepStations] = useState<any>({});
  const [params, setParams] = useState({
    status: null
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [isFOH, setIsFOH] = useState<boolean>(true);
  const [lastSelectId, setLastSelectedId] = useState<string>();
  const confirm = () => {
    getListData();
  }

  const getListData = async () => {
    setLoading(true);
    const [, prepStations] = await toRes(listPrepStations(params));
    setPrepStations(prepStations);
    setLoading(false);
  }
  const fetchData = async () => {
    setLoading(true);
    const [, prepStations] = await toRes(listPrepStations(params));
    setPrepStations(prepStations);
    setIsEdit(true);
    if (prepStations?.frontOfHouse) {
      setSelectedId(prepStations?.frontOfHouse.id);
    }
    setLoading(false);
  }

  const changeItem = (item) => {
    setSelectedId(item.id);
  }

  const handleChangeSelectedId = (id: string) => {
    getListData();
    setSelectedId(id);
    setIsEdit(true);
  }
  const handleCancel = () => {
    setSelectedId(!lastSelectId ? selectedId : lastSelectId);
    setIsEdit(true);
    setIsFOH(false);
  }
  useEffect(() => {
    if (props.activeKey === TabType.PREPSTATIONS) {
      fetchData();
    }
  }, [props.activeKey])
  return (
    <Spin spinning={loading}>
      <div className="prep-stations">
        <div className='prep-stations-wrap'>
          <div className="prep-stations-head">
            <div className="prep-stations-head-title">{i18n?.t('prep_stations')}</div>
            <div className="prep-stations-head-filter">
              <Popconfirm
                title
                onConfirm={confirm}
                okText={i18n?.t('save')}
                cancelText={i18n?.t('cancel')}
                description={
                  <div className="prep-stations-head-filte-pop">
                    <Checkbox.Group options={Status} onChange={(val => {
                      if (val.length === Status.length || !val.length) {
                        setParams({ status: null });
                      } else {
                        setParams({ status: val.includes(StatusType.ACTIVE) ? false : true });
                      }
                    })} />
                  </div>
                }
                icon={<></>}
              >
                <FilterOutlined/>
              </Popconfirm>
            </div>
          </div>
          <div className="prep-stations-body">
            <div className="prep-stations-body-leftpanel">
              <NestedList
                prepStations={prepStations}
                changeItem={changeItem}
                setIsEdit={setIsEdit}
                getListData={getListData}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                setIsFOH={setIsFOH}
                setLastSelectedId={setLastSelectedId}/>
            </div>
            <div className="prep-stations-body-rightpanel">
              <InfoDetail
                selectedId={selectedId}
                handleCancel={handleCancel}
                isEdit={isEdit}
                handleChangeSelectedId={handleChangeSelectedId}
                setLastSelectedId={setLastSelectedId}
                isFOH={isFOH}/>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}