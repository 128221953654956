export enum OperationType {
    EDIT = 0,
    ADD = 1,
}

export enum ModelType {
    ADYEN = 1,
    INGENICO = 3
}

export const ModelTypeList = [
  {
    label: 'pc_adyen',
    value: ModelType.ADYEN
  },
  {
    label: 'pc_ingenic',
    value: ModelType.INGENICO
  }
]

export enum PosType {
  STATION = 1,
  TABLET = 4
}
export enum ModelName {
  S1F2 = 'S1F2',
  AMS1 = 'AMS1',
  TAP = 'TAP',
  NYC1 = 'NYC1'
}

export const PosTypeList = [
  {
    label: 'Station',
    value: PosType.STATION
  },
  {
    label: 'Tablet',
    value: PosType.TABLET
  }
]
export const StationModelList = [
  {
    label: 'S1F2',
    value: ModelName.S1F2
  },
  {
    label: 'AMS1',
    value: ModelName.AMS1
  },
  {
    label: 'NYC1',
    value: ModelName.NYC1
  }
];
export const TableModelList = [
  ...StationModelList,
  // {
  //   label: 'TAP',
  //   value: ModelName.TAP
  // }
];