import React from 'react'
import classNames from 'classnames';
import { formatFullPrice } from '@/utils';
import './Price.scss'

interface IProps {
  value: string | number,
  split?: string
}

export default function Price (props: IProps) {
  const { value, split = '' } = props;
  return (
    <span className={ classNames({ 'price-red': Number(value < 0) }) }>{ formatFullPrice(value, split) }</span>
  )
}