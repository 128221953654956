import RenderInput from '../configUI/renderTemplate/RenderInput';
import RenderCheckBoxGroup from '../configUI/renderTemplate/RenderCheckBoxGroup';
import RenderSpaceTags from '../configUI/renderTemplate/RenderSpaceTags';
import RenderRadioGroup from '../configUI/renderTemplate/RenderRadioGroup';
import { IRenderConfigProps, ITag } from '../interface';
import RenderSliders from '../configUI/renderTemplate/RenderSliders';


// 匹配config中的tag的渲染类型
export const renderChildComponent: Record<ITag, (props: IRenderConfigProps<ITag>) => JSX.Element> = {
  input: RenderInput,
  checkboxGroup: RenderCheckBoxGroup,
  spaceTag: RenderSpaceTags,
  radioGroup: RenderRadioGroup,
  slider: RenderSliders,
};
