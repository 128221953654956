import React, { useState, useEffect } from 'react'
import { Flex, Popover, Spin } from 'antd';
import DragList from '../DragList'
import './index.scss'
interface SelectOptionType {
  id: string | number;
  name: string;
  displayOrder: number;
}
interface DragSelectProps {
  list: SelectOptionType[];
  value: string | number;
  edit?: boolean;
  remove?: boolean;
  archive?: boolean;
  loading?: boolean;
  handleEdit?: (event) => void;
  handleRemove?: (event) => void;
  onDragEnd: (active, over) => void;
  onChange: (event) => void;
  style?: React.CSSProperties;
}
const DragSelect: React.FC<DragSelectProps> = ({
  list,
  value,
  edit = false,
  remove = false,
  archive = false,
  loading = false,
  style,
  onDragEnd,
  onChange,
  handleEdit = () => {
    console.log('//');
  },
  handleRemove = () => {
    console.log('//');
  }
}) => {
  const [selectItem, setSelectItem] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const onChangeItem = (item) => {
    hide()
    onChange(item)
  }
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const hide = () => {
    setOpen(false);
  };
  const content = (
    <Spin size="large" spinning={loading}>
      <div className="drag-select-slide">
        <DragList
          list={list}
          name="name"
          onDragEnd={onDragEnd}
          handleClick={onChangeItem}
          activeItem={value}
          edit={edit}
          archive={archive}
          remove={remove}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
        />
      </div>
    </Spin>
  )
  useEffect(() => {
    let actName = ''
    list.forEach(item => {
      if (item.id === value) {
        actName = item.name
      }
    });
    setSelectItem(actName)
  }, [value])
  return (
    <Popover
      className="drag-select"
      trigger="click"
      content={content}
      title=""
      open={open}
      placement="bottomLeft"
      onOpenChange={handleOpenChange}
      arrow={false}
    >
      <Flex style={style} className="drag-select-box" justify="space-between">
        <span className="drag-name">{selectItem || '请选择'}</span>
        <i className="iconfont icon-DownOutlined"></i>
      </Flex>
    </Popover>
  )
}
export default DragSelect