import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, InputNumber, message, Radio, Spin } from 'antd';
import { globalState } from '@/stores';
import TimeRangePicker from '@/Components/TimeRangePicker';
import { IFormData, AvailabilityType, WEEK_DAY_I18N } from './enum'
import { ApiSaveMenuEventConfig, ApiQueryMenuEventConfig } from '@/request/api'
import './index.scss';
import { formatPrice } from '@/utils';
import { LeftOutlined } from '@ant-design/icons';
const MenuEvents: React.FC = () => {
  const { i18n } = globalState;
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    mode: 2,
    liftAmount: '0.00',
    availableTime: []
  });

  const getEventConfig = async () => {
    setLoading(true);
    await ApiQueryMenuEventConfig().then((res) => {
      if (res.code === 0) {
        setFormData(res.data);
      }
    })
    setLoading(false);
  }
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleCancel = () => {
    setIsEdit(false);
    getEventConfig();
  };
  const handleSave = async () => {
    setLoading(true);
    await ApiSaveMenuEventConfig(formData).then((res) => {
      if (res.code === 0) {
        message.success(res.message);
        getEventConfig();
      } else {
        message.error(res.message);
      }
    })
    setLoading(false);
    setIsEdit(false);
  };
  const changeLiftAmount = (value) => {
    setFormData({ ...formData, liftAmount: formatPrice(value) })
  }
  const changeMode = (event) => {
    setFormData({ ...formData, mode: event.target.value })
  }
  const handleChangePickupHours = (event, item, field, weekIndex) => {
    const availableTime = [...formData.availableTime]
    if (field === 'openSwitch') {
      availableTime.find(x => x.day === item.day).openSwitch = event.target.value;
    } else if (field.includes('intervalTimes')) {
      const _hoursRangeIndex = field.split('_')[1];
      availableTime.find(x => x.day === item.day).intervalTimes[_hoursRangeIndex] = { startTime: event[0], endTime: event[1] };
    }
    setFormData({ ...formData, availableTime })
  }

  useEffect(() => {
    getEventConfig()
  }, []);
  return (
    <Spin size='large' spinning={loading}>
      <div className="menu-events">
        <div className="m-title">
          <div className="title">
            <Button onClick={()=> history.go(-1)} type='text' icon={<LeftOutlined />}>Events</Button>
          </div>
          <div className="action-wrap">
            { isEdit ?
              <>
                <Button onClick={ handleCancel } style={{ marginRight: '16px' }}>{ i18n.t('cancel') }</Button>
                <Button type="primary" onClick={ handleSave } loading={ loading }>{ i18n.t('save') }</Button>
              </>
              : <Button type="primary" onClick={ handleEdit } key={ 'edit' }>{ i18n.t('edit') }</Button>
            }
          </div>
        </div>
        <Form layout="vertical" disabled={ !isEdit }>
          <Form.Item label={ 'Enable Events' }>
            <Radio.Group value={formData.mode} onChange={changeMode}>
              <Radio value={1}>{i18n.t('pc_online_orders_on')}</Radio>
              <Radio value={2}>{i18n.t('pc_online_orders_off')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={ i18n.t('price') }>
            <Flex align='center'>
              <span style={{ marginRight: '5px' }}>Add </span>
              <InputNumber
                disabled={!isEdit || formData.mode === 2}
                value={formData.liftAmount}
                min={ 0 }
                max={ 1000 }
                addonBefore="$"
                stringMode
                precision={ 2 }
                onChange={changeLiftAmount} />
              <span style={{ marginLeft: '5px' }}> for each alcohol</span>
            </Flex>
          </Form.Item>
          <Form.Item label={i18n.t('availability')}>
            {
              formData.availableTime.map((x: AvailabilityType, weekIndex: number) => (
                <Form.Item name={ `pickupHours_${ weekIndex }` } key={ x.day } rootClassName={ 'pickup-hours-form-item' }>
                  <div className="pickup-hours-item">
                    <span className="week-day">{ i18n.t(WEEK_DAY_I18N[x.day]) }</span>
                    <Radio.Group
                      disabled={!isEdit || formData.mode === 2}
                      value={ x.openSwitch }
                      onChange={ (event) => handleChangePickupHours(event, x, 'openSwitch', weekIndex) }
                      options={ [{ value: true, label: i18n.t('pc_online_orders_on') },
                        { value: false, label: i18n.t('pc_online_orders_off') }] }/>
                    <TimeRangePicker
                      disabled={!isEdit || formData.mode === 2 || !x.openSwitch}
                      value={[x.intervalTimes[0].startTime, x.intervalTimes[0].endTime]}
                      onChange={ (timeString) => handleChangePickupHours(timeString, x, 'intervalTimes_0', weekIndex) }
                      endTimeSet />
                  </div>
                </Form.Item>
              ))
            }
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
export default MenuEvents;
