import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import Moveable from 'react-moveable';
import { Select, Form, Input, Modal, Button, message, Spin, InputNumber } from 'antd';
import { CloseCircleOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { globalState } from '@/stores';
import { Shape, RectangleType, CircleType, WallType } from './components/shape/shape'
import { ApiServiceArea, ApiQueryTableList, ApiAddTableInfo } from '../../request/api'
import {	customRound, transformData, reverseTransformData } from './utils'
import './index.scss'

let tempNewItem = { type: '', left: 0, top: 0, width: 0, height: 0, name: '', id: '', seat: 0, transformX:0, transformY:0 }

interface IProps{
	selectedAreaId?: string,
	goBack: () => void,
	activeKey: string,
	enterType: 0 | 1 // 进入方式，0为tab，1为表格点击edit table进入
}
interface ShapeDimensions {
  height: number;
  width: number;
}
const shapeDimensions: Record<string, ShapeDimensions> = {
  square: { height: 4, width: 4 },
  circle: { height: 5, width: 5 },
  wall: { height: 1, width: 7 },
};
const App = (props: IProps) => {
  //获取画布相对视口的距离
  // const elementRef = useRef(null);
  const { i18n } = globalState;
  const moveableRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [baseLeft, setBaseLeft] = useState(0);
  const [baseTop, setBaseTop] = useState(0);
  const [offSet, setOffSet] = useState({ x:0, y:0 });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

  const [canEdit, setCanEdit] = useState(true);
  //当前选中项
  const [selectedId, setSelectedId] = useState(null);
  //画布上所有元素
  const [content, setContents] = useState([]);
  const [originContent, setOriginContents] = useState([]);
  const [target, setTarget] = useState<HTMLElement | null>(null);


  const [tempSelectedAreaId, setTempSelectedAreaId] = useState('');
  const [selectedAreaId, setSelectedAreaId] = useState('');
  const [serviceAreas, setServiceAreas] = useState();

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const shapeContent = ['circle', 'square']

  useEffect(() => {
    selectedAreaId && queryQueryTableList()
  }, [selectedAreaId]);

  useEffect(() => {
    console.log(props, '我是props')
    if (props.enterType === 0) {
      setSelectedAreaId('');
    }
    if (props.activeKey === '2') {
      setSelectedId(null);
      console.log('props.selectedAreaId', props.selectedAreaId);
      queryShopInfo()
    }
  }, [props.selectedAreaId, props.enterType, props.activeKey]) // 从tables页面进入

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.querySelector(`[data-id="${selectedId}"]`) as HTMLElement;
      setTarget(element);

    }, 100);
    return () => clearTimeout(timer);
  }, [selectedId]);

  const queryShopInfo = () => {
    ApiServiceArea({
      params: { req: { status: ['1'] } }
    }).then(res => {
      const options = res?.data.map(item => ({
        value: item.id,
        label: item.areaName
      }));
      setServiceAreas(options)
      if (props.enterType === 0 && options[0]) { // tab点击进入则默认第一项，重新请求桌台列表数据
        setSelectedAreaId(options[0].value);
        selectedAreaId && queryQueryTableList()
      } else {
        setSelectedAreaId(props.selectedAreaId ? props.selectedAreaId : options[0].value);
      }
    })
  }

  const queryQueryTableList = () => {
    ApiQueryTableList({
      params: { queryServiceAreaReq: { areaId: selectedAreaId } }
    }).then(res => {
      setContents(reverseTransformData(res.data))
      setOriginContents(reverseTransformData(res.data))
    })
  }

  const cancelTableInfo = () => {
    setIsCancelModalOpen(true)
  }


  const saveTableInfo = () => {
    if (!canEdit) {
      messageApi.open({
        type: 'warning',
        content: i18n.t('instructions_remind'),
      });
      return
    }
    setIsLoading(true)
    const tableInfos = transformData(content, selectedAreaId)
    ApiAddTableInfo({
      params: { tableInfos }
    }).then(res => {
      if (res.data) {
        setOriginContents([...content])
        messageApi.open({
          type: 'success',
          content: i18n.t('successfully_saved'),
        });
        setHasChange(false)
      }
      setIsLoading(false)
    }).catch(() => {
      setIsLoading(false)
    })
  }

  const handleAreaChange = (value, option) => {
    if (hasChange) {
      setIsAreaModalOpen(true)
      setTempSelectedAreaId(option.value)
      return
    }
    setSelectedAreaId(option.value);
    setHasChange(false)
    setSelectedId(null)
  }

  const leavePage = () => {
    if (hasChange) {
      setIsLeaveModalOpen(true)
      return
    }
    // window.history.back();
    props.goBack();
  }

  const sureLeave = () => {
    // window.history.back();
    window.location.replace('/frontOfHouse/tables');
    setHasChange(false)
  }


  const switchArea = () => {
    setSelectedAreaId(tempSelectedAreaId);
    setHasChange(false)
    setIsAreaModalOpen(false)
    setSelectedId(null)
    setCanEdit(true)
  }

  const insertItem = (items, newItem) => {
    if (newItem.type === 'wall') {
      // 找到最后一个 "wall" 元素的索引
      let lastWallIndex = items.length - 1;
      while (lastWallIndex >= 0 && items[lastWallIndex].type !== 'wall') {
        lastWallIndex -= 1;
      }
      // 如果数组中没有 "wall"，或者 "wall" 已经在末尾，则将新元素添加到数组末尾
      if (lastWallIndex === items.length - 1) {
        items.push(newItem);
      } else {
        // 否则，将新元素插入到最后一个 "wall" 元素之后
        items.splice(lastWallIndex + 1, 0, newItem);
      }
    } else {
      // 如果新元素的类型不是 "wall"，则将其插入到数组末尾
      items.push(newItem);
    }
    return items;
  }

  const addInContent = (tempNewItem) =>{
    setHasChange(true)
    setContents((prevContents) =>{
      const existingIndex = prevContents.findIndex((i) => i.id === tempNewItem.id);
      if (existingIndex > -1) {
        // 如果存在，更新该对象
        return prevContents.map((i, index) =>{
          return index === existingIndex ? { ...i, ...tempNewItem } : i
        });
      } else {
        // 如果不存在，添加新对象
        return insertItem(prevContents, { ...tempNewItem, isNew: true }); // 生成新的id
      }
		 });
    setTimeout(() => {
      setSelectedId(tempNewItem.id)
    }, 0);
  }

  const getContainerInfo = ()=>{
    if ( !baseLeft || !baseTop) {
      const element = document.querySelector('.grid-container');
      const rect = element && element.getBoundingClientRect();
      const { left, top } = rect
      setBaseLeft(left)
      setBaseTop(top)
    }
  }

  const handleDrag = ({ target, beforeTranslate }) => {
    if (!canEdit) return
    const dataId = target.dataset.id;
    setContents((prevContents) =>{
      return prevContents.map((i) =>
        i.id === dataId ? { ...i, transformY:transformY(customRound(beforeTranslate[1], 16)), transformX:transformX(customRound(beforeTranslate[0], 16))  } : i
      );
		 });
		 setHasChange(true)
  };

  const transformX = (x)=>{
    const { left, width } = tempNewItem
    if (x + left < 0) {
      return -left
    } else if (x + left + width > 60) {
      return 60 - left - width
    } else {
      return x
    }
  }

  const transformY = (y)=>{
    const { top, height } = tempNewItem
    if (y + top < 0) {
      return -top
    } else if (y + top + height > 28) {
      return 28 - top - height
    } else {
      return y
    }
  }

  const addItem = (type, event) => {
    if (!canEdit) return
    const element = document.querySelector(`#${type}`);
    const rect = element.getBoundingClientRect();
    const { left, top } = rect
    setOffSet({ x:event.clientX - left, y:event.clientY - top })
    getContainerInfo()
    tempNewItem = { type: type, left: 0, top: 0, width: shapeDimensions[type].width, height: shapeDimensions[type].height, name: '', id: uuid(), seat: 0, transformX:0, transformY:0 }
  }
  const editItem = (info) => {
    getContainerInfo()
    const existingIndex = content.findIndex((i) => i.id === info.id);
    tempNewItem = content[existingIndex]
  }

  const handleDrop = (event) => {
    if (!canEdit) return
    event.preventDefault();
    // setSelectedId(tempNewItem.id)
    const left = Math.min(60 - shapeDimensions[tempNewItem.type].width, Math.max(0, customRound(event.clientX - baseLeft - offSet.x, 16)));
    const top = Math.min(28 - shapeDimensions[tempNewItem.type].height, Math.max(0, customRound(event.clientY - baseTop - offSet.y, 16)));
    tempNewItem = { ...tempNewItem, left, top }
    if (shapeContent.includes(tempNewItem?.type) && content.findIndex((i) => i.id === tempNewItem.id) < 0) {
      showModal()
    } else {
      addInContent(tempNewItem)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  }

  const sureCancel = () => {
    setIsCancelModalOpen(false);
    setContents([...originContent])
    setSelectedId(null)
    setCanEdit(true)
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        tempNewItem = { ...tempNewItem, name: values.name, seat: values.seat }
        addInContent(tempNewItem)
        setIsModalOpen(false);
        // 清空表单
        form.resetFields();
      })
      .catch(errorInfo => {
        // 校验失败，显示错误信息
        console.log('Validation Failed:', errorInfo);
      });
  };

  const handleDragEnd = ({ lastEvent }) => {
    if (!canEdit) return
    if (lastEvent) {
      const { beforeTranslate } = lastEvent;
      const x = beforeTranslate[0];
      const y = beforeTranslate[1];
      console.log('Drag operation finished at position:', x, y);
      // 在这里你可以根据需要处理元素的最终位置
    }
  };

  const setSelectedInfo = (key, value)=>{
    setContents((prevContents) =>{
      return prevContents.map((i) =>
        i.id === selectedId ? { ...i, [key]: value } : i
      );
		 });
		 setHasChange(true)
		 setTimeout(() => {
      if (moveableRef.current) {
        moveableRef.current.updateRect();
      }
		 }, 0);
  }

  const deleteItem = ()=>{
    setContents((prevContents) => prevContents.filter(item => item.id !== tempNewItem.id));
    setSelectedId(null)
    setIsDeleteModalOpen(false)
    setHasChange(true)
    setCanEdit(true)
  }

  const selectItem = (item) =>{
    setSelectedId(item.id)
    tempNewItem = item
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false);
  };
  const selectedItem = content.find(item => item.id === selectedId);
  formEdit.setFieldsValue(selectedItem);
  return (
    <div>
      {contextHolder}
      <div className='top-area'>
        <div className='left-part'>
          <LeftOutlined style={{ 'marginRight': '20px' }} onClick={leavePage}/>
          <Select
            value={selectedAreaId}
            style={{ width: 180 }}
            onChange={handleAreaChange}
            options={serviceAreas}
          />
        </div>
        <div className='right-part'>
          <Button style={{ 'marginRight': '20px' }} onClick={cancelTableInfo}>{i18n.t('cancel')}</Button>
          <Button type="primary" onClick={saveTableInfo}>{i18n.t('save')}</Button>
        </div>
      </div>
      <div className='content'>
        <div className='content-left'>
          <div className='shape-container'>
            <p>{i18n.t('tables')}</p>
            <div className='shape-content'>
              <CircleType onDragStart={(e)=>{addItem('circle', e)}} />
              <RectangleType onDragStart={(e)=>{addItem('square', e)}} />
            </div>
          </div>
          <div className='line'></div>
          <div className='shape-container'>
            <p className='object'>{i18n.t('object')}</p>
            <div className='shape-content'>
              <WallType onDragStart={(e)=>{addItem('wall', e)}}/>
            </div>
          </div>

        </div>
        <div className='content-right'>
          <div className='grid-container'
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className='grid-canvas'>
              {content.map((item) => (
                <Shape key={item.id} info={item} isActive={item.id === selectedId}  onDragStart={()=>{
                  if (!canEdit) return
                  editItem(item)
                }}
                onShapeClick={()=>{
                  if (!canEdit) return
                  selectItem(item)
                }}/>
              ))}
            </div>
            {content && content.length === 0 && <div className='grid-remind'>
              <p>{i18n.t('drag_remind')}</p>
            </div>}
            <div className='grid-bg'>
              {Array.from({ length: 60 * 28 }, () => null).map((_, index) => (
                <div className='cell' key={index} />
              ))}
            </div>
          </div>
          {selectedId ?
            <Moveable
              target={target}
              ref={moveableRef}
              draggable={true}
              resizable={false} // 启用可缩放功能
              // onResize={handleResize} // 处理缩放事件
              throttleResize={0} // 设置为0表示不限制调整大小的速率
              keepRatio={false} // 禁用等比例缩放
              edge={false}
              onDrag={handleDrag}
              onDragEnd={handleDragEnd}
            />
            : null}
          <div className='content-info'>
            {selectedId ?
              <React.Fragment>
                <div className='top'>
                  <div className='title'>{i18n.t('edit')} {shapeContent.includes(selectedItem?.type) ? i18n.t('table') : i18n.t('object')}</div>
                  <div className='delete' onClick={()=>{setIsDeleteModalOpen(true)}}>
                    <CloseCircleOutlined /><span className='delete-characher'>{i18n.t('frontOfHouse_pc_delete')}</span>
                  </div>
                </div>
                <Form form={formEdit} layout="vertical" autoComplete="off" initialValues={selectedItem}
								 onValuesChange={(changedValues) => {
                    // 根据字段名更新状态或执行其他逻辑
                    const fieldName = Object.keys(changedValues)[0];
                    const fieldValue = changedValues[fieldName];
                    setSelectedInfo(fieldName, fieldValue);
                  }}
                >
                  <div className='edit-area'>
                    {selectedItem && shapeContent.includes(selectedItem.type) &&
										<div className='item'>
										  <div className='name'>{i18n.t('menuDatabaseList_pc_name')}</div>
										  <Form.Item
										    name="name"
										    rules={[() => ({
										      validator (_, value) {
										        if (value) {
										          setCanEdit(true)
										          return Promise.resolve();
										        }
										        setCanEdit(false)
										        return Promise.reject(new Error(i18n.t('table_required')));
										      },
										    }),]}
										  >
										    <Input max={200}/>
										  </Form.Item>
										</div>}
                    {selectedItem && shapeContent.includes(selectedItem.type) &&
										<div className='item'>
										  <div className='name'>{i18n.t('seats')}</div>
										  <Form.Item
										    name="seat"
										    rules={[
										      () => ({
										        validator (_, value) {
										          if (value) {
										            setCanEdit(true)
										            return Promise.resolve();
										          }
										          setCanEdit(false)
										          return Promise.reject(new Error(i18n.t('seat_required')));
										        },
										      })
										    ]}
										  >
										    <InputNumber precision={0} min={0} max={99} style={{ width: 210, height: 50 }}/>
										  </Form.Item>
										</div>}
                    <div className='item'>
                      <div className='name'>{i18n.t('width')}</div>
                      <Form.Item
                        name="width"
                        rules={[
                          {
                            validator (_, value) {
                              if (!value || Number(value) > 0) {
                                setCanEdit(true)
                                return Promise.resolve();
                              }
                              setCanEdit(false)
                              return Promise.reject(new Error(i18n.t('number_required')));
                            },
                          },
                          () => ({
                            validator (_, value) {
                              if (value) {
                                setCanEdit(true)
                                return Promise.resolve();
                              }
                              setCanEdit(false)
                              return Promise.reject(new Error(i18n.t('number_required')));
                            },
                          }),
                        ]}
                      >
                        <InputNumber precision={0} min={ shapeContent.includes(selectedItem.type) ? shapeDimensions[selectedItem?.type].width : 1}  style={{ width: 210, height: 50 }}/>
                      </Form.Item>
                    </div>
                    <div className='item'>
                      <div className='name'>{i18n.t('height')}</div>
                      <Form.Item
                        name="height"
                        rules={[
                          {
                            validator (_, value) {
                              // 确保输入的值转换为数字后大于0
                              if (!value || Number(value) > 0) {
                                setCanEdit(true)
                                return Promise.resolve();
                              }
                              setCanEdit(false)
                              return Promise.reject(new Error(i18n.t('number_required')));
                            },
                          },
                          () => ({
                            validator (_, value) {
                              if (value) {
                                setCanEdit(true)
                                return Promise.resolve();
                              }
                              setCanEdit(false)
                              return Promise.reject(new Error(i18n.t('number_required')));
                            },
                          }),
                        ]}
                      >
                        <InputNumber precision={0} min={ shapeContent.includes(selectedItem.type) ? shapeDimensions[selectedItem?.type].height : 1} style={{ width: 210, height: 50 }}/>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </React.Fragment> :
              <div className='character-remind'>{i18n.t('click_to_edit')}</div>
            }


          </div>

          <Modal title="Add Table" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText={i18n.t('cancel')} okText={i18n.t('save')} width="438px" zIndex={10000}>
            <Form form={form} layout="vertical" autoComplete="off">
              <Form.Item
                name="name"
                label={i18n.t('frontOfHouse_pc_table_name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={i18n.t('seats')}
                name="seat"
                rules={[
                  () => ({
                    validator (_, value) {
                      if (value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(i18n.t('seat_required')));
                    },
                  })
                ]}
              >
                <InputNumber precision={0} min={0} max={99} style={{ width: 390, height: 32 }}/>
              </Form.Item>
              {/* <Form.Item
								name="seat"
								label={i18n.t('seats')}
								rules={[
									{ required: true, message: i18n.t('seat_required') },
									() => ({
										validator(_, value) {
											const number = Number(value); // 将值转换为数字
											if (!value || (number >= 1 && number <= 99)) {
												return Promise.resolve();
											}
											return Promise.reject(new Error(i18n.t('number_limit')));
										},
									}),
								]}
							>
								<Input type="number"/>
							</Form.Item> */}
            </Form>
          </Modal>
          <Modal zIndex={10000} open={isDeleteModalOpen} onOk={deleteItem} onCancel={()=>{setIsDeleteModalOpen(false)}} cancelText={i18n.t('cancel')} okText={i18n.t('confirm')} width="400px">
            <div style={{ padding: '20px' }}>{i18n.t('sure_delete')}</div>
          </Modal>
          <Modal zIndex={10000} open={isAreaModalOpen} closable={false} onCancel={()=>{setIsAreaModalOpen(false)}} onOk={switchArea} cancelText={i18n.t('cancel')} okText={i18n.t('discard')} width="400px">
            <div style={{ padding: '20px' }}>{i18n.t('unsaved_changes')}</div>
          </Modal>
          <Modal zIndex={10000} open={isCancelModalOpen} closable={false} onCancel={()=>{sureCancel()}} onOk={()=>{setIsCancelModalOpen(false)}} cancelText={i18n.t('cancel')} okText={i18n.t('discard')} width="400px">
            <div style={{ padding: '20px' }}>{i18n.t('unsaved_changes')}</div>
          </Modal>
          <Modal zIndex={10000} open={isLeaveModalOpen} closable={false} onOk={()=>{sureLeave()}} onCancel={()=>{setIsLeaveModalOpen(false)}} cancelText={i18n.t('cancel')} okText={i18n.t('discard')} width="400px">
            <div style={{ padding: '20px' }}>{i18n.t('unsaved_changes')}</div>
          </Modal>
          <Modal
            width={193}
            open={isLoading}
            closable={false}
            footer={null}
            wrapClassName="custom-modal"
            zIndex={10000}
            centered
          >
            <div className='loading-box'>
              <div className='loading-character'>{i18n.t('saving_data')}</div>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />}/>
            </div>
          </Modal>
        </div>
      </div>

    </div>
  );
};
export default App;


