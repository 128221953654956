import React from 'react';
import './shape.scss'


export const Shape = ({ info, onDragStart, onShapeClick, isActive }) => {
  const containerStyle: React.CSSProperties = {
    width: `${info.width * 16}px`,
    height: `${info.height * 16}px`,
    position: 'absolute',
    left:`${info.left * 16}px`,
    top:`${info.top * 16}px`,
    transform: `translate(${info.transformX * 16}px, ${info.transformY * 16}px)`,
    color: isActive ? '#FF5400' : '',
    borderColor: isActive ? '#FF5400' : '',
  };
  const characterStyle: React.CSSProperties = {
    width: `${(info.width + 2) * 16}px`,
    height: `${info.height * 16}px`,
    lineHeight: `${info.height * 16}px`,
    transform: 'translate(-18px, 0)',
    textAlign: 'center'
  };
  return (
    <div
      data-id={info.id}
      className={info.type}
      style={containerStyle}
      draggable={true}
      onDragStart={onDragStart}
      onClick={onShapeClick}
    >
      <div className='shape-name' style={characterStyle}>{info.name}</div>
    </div>
  );
};


export const RectangleType = ({ onDragStart }) => {
  return (
    <div
      id="square"
      className='square'
      onDragStart={onDragStart}
      draggable="true"
    />
  );
};

export const CircleType = ({ onDragStart }) => {
  return (
    <div
      id="circle"
      className='circle'
      onDragStart={onDragStart}
      draggable="true"
    />
  );
};

export const WallType = ({ onDragStart }) => {
  return (
    <div
      className='wall'
      onDragStart={onDragStart}
      id="wall"
      draggable="true"
    />
  );
};

