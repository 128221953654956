import React from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
const TipSuggestAreaSection = () => {
  return <div className='tip-area'>
    <TextLineNew text='Suggested Tip' />
    <div className='tip-area-item'>
      <TextLineNew text='15% (Tip $0.00, Total $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <TextLineNew text='18% (Tip $0.00, Total $0.00)'/>
    </div>
    <div className='tip-area-item'>
      <TextLineNew text='20% (Tip $0.00, Total $0.00)'/>
    </div>
  </div>
}
export default TipSuggestAreaSection;