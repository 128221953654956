import { makeAutoObservable } from 'mobx';
const DEFAULT_ACTIVE = {
  activeMenu: {
    uuid: '',
    name: ''
  },
  activeSubMenu: {
    uuid: '',
    name: ''
  }
};
// const menuStore = useLocalObservable(() => {
// })
class MenuBuilderStore {
  activeStore = {
    activeMenu: {
      uuid: null,
      name: null
    },
    activeSubMenu: {
      uuid: null,
      name: null
    }
  }

  constructor () {
    makeAutoObservable(this);
  }

  setActiveMenu (item) {
    this.activeStore.activeMenu = { ...item };
  }
  setActiveSubMenu (item) {
    this.activeStore.activeSubMenu = { ...item };
  }

  clearActive (item) {
    if (item) {
      this.activeStore[item] = {
        uuid: null,
        name: null
      };
    } else {
      this.activeStore.activeMenu = { ...DEFAULT_ACTIVE.activeMenu };
      this.activeStore.activeSubMenu = { ...DEFAULT_ACTIVE.activeSubMenu };
    }
  }

  get getActiveMenu () {
    return this.activeStore.activeMenu
  }
  get getActiveSubMenu () {
    return this.activeStore.activeSubMenu
  }
}

const menuStore = new MenuBuilderStore();
export default menuStore;
