import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { globalState } from '@/stores';
export const AccountWarning = () => {
  const { i18n } = globalState;
  Modal.destroyAll()
  Modal.warning({
    content: i18n.t('pc_account_change'),
    onOk: () => {
      window.location.assign(window.location.href.split('/')[0]);
    }
  })
};