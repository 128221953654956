import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Radio, Space, Spin } from 'antd';
import { ApiQueryPaymentMethodByUuids } from '@/request/api';
import './index.scss'
import NP from '@/utils/NP';

interface IProps {
  editItem: any;
  close?: () => void;
}

export default function PaymentMethodsEdit (props: IProps) {
  const { editItem, close } = props
  const { i18n } = globalState;
  const [form] = Form.useForm()
  const [isInit] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const watchedDisplayDualPricing = Form.useWatch('displayDualPricing', form);

  const initialValues = {
    displayDualPricing: undefined,
    cashDualPricingPercent: 0,
  }

  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    const resp = await ApiQueryPaymentMethodByUuids({ uuids: [editItem.uuid] })
    if (resp.code === 0) {
      const item = resp?.data?.[0] || {};
      form.setFieldsValue({
        displayDualPricing: item.displayDualPricing,
        cashDualPricingPercent: NP.times(item.cashDualPricingPercent || 0, 100),
      })
    }
    setLoading(false);
  }

  const handleCancel = () => {
    close();
  }

  return (
    isInit && <Spin spinning={ loading }>
      <div className="payment-methods-cash-edit">
        <div className="header-wrap">
          <div className="title">{ i18n.t('pc_payment_methods_cash_payment') }</div>
          <div className="actions">
            <Button onClick={ handleCancel }>{ i18n?.t('cancel') }</Button>
            <Button type="primary" onClick={ handleCancel }>{ i18n?.t('save') }</Button>
          </div>
        </div>
        <div className="form-wrap">
          <Form form={ form } layout="vertical" initialValues={ initialValues } requiredMark={ false }>
            <Form.Item name="displayDualPricing" label={ <span className="label-text">{ i18n.t('pc_sales_summary_cash_discount') }</span> } className="form-display-dual-pricing">
              <Radio.Group disabled={ true }>
                <Space direction="vertical" align="start">
                  <Radio value={ true }>
                    <>
                      <div>{ i18n.t('pc_payment_methods_cash_discount_apply') }</div>
                      {
                        watchedDisplayDualPricing &&
                        <Form.Item name={ 'cashDualPricingPercent' } label={ <div className="label-disabled-text">{ i18n.t('pc_payment_methods_cash_percentage') }<span className="required">*</span></div> }>
                          <InputNumber step={ '1.00' } min={ 0 } precision={ 2 } max={ 100 } addonAfter="%" stringMode width={ 400 } disabled={ true }/>
                        </Form.Item>
                      }
                    </>
                  </Radio>
                  <Radio value={ false }>
                    <div>{ i18n.t('pc_payment_methods_cash_discount_noapply') }</div>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  )
}