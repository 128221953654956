import React, { useContext, useMemo } from 'react'
import { Button, Table, TableProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

interface DragTableProps extends TableProps {
  onDragEnd?: (event: DragEndEvent) => void;
  onDragEndAction?: ({ key, sort }: { key: any, sort: number }) => void;
  rowKey: any;
  sortKey?: string;
  setDataSource?:  React.Dispatch<React.SetStateAction<any>>
}

const RowContext = React.createContext<RowContextProps>({});

export const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button type="text" size="small" icon={ <MenuOutlined/> } style={ { cursor: 'move' } } ref={ setActivatorNodeRef } { ...listeners } />
  );
};
const Row: React.FC<any> = (props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative' } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={ contextValue }>
      <tr { ...props } ref={ setNodeRef } style={ style } { ...attributes } />
    </RowContext.Provider>
  );
};
const DragTable: React.FC<DragTableProps> = (props) => {
  const { onDragEnd, onDragEndAction, setDataSource, sortKey = 'displayOrder', dataSource, rowKey, ...tableProps } = props

  const getOnDragEnd = () => {
    if (onDragEnd) return onDragEnd;
    if (onDragEndAction) {
      return ({ active, over }: DragEndEvent) => {
        if (active.id !== over.id) {
          const key = dataSource.find(f => f[rowKey] === active.id)[rowKey];
          const sort = dataSource.find(f => f[rowKey] === over.id)[sortKey];
          setDataSource((prevState) => {
            const activeIndex = prevState.findIndex((record) => record[rowKey] === active.id);
            const overIndex = prevState.findIndex((record) => record[rowKey] === over.id);
            return arrayMove(prevState, activeIndex, overIndex);
          });
          onDragEndAction({ key, sort });
        }
      }
    }
  }

  return (
    <div>
      <DndContext modifiers={ [restrictToVerticalAxis, restrictToParentElement] } onDragEnd={ getOnDragEnd() }>
        <SortableContext items={ dataSource?.map((i) => i[rowKey as any]) } strategy={ verticalListSortingStrategy }>
          <Table
            components={ { body: { row: Row } } }
            locale={ { emptyText: TableEmptyContent } }
            pagination={ false }
            dataSource={ dataSource }
            rowKey={ rowKey }
            rowClassName={ () => 'no-border-bottom' }
            { ...tableProps }
          />
        </SortableContext>
      </DndContext>
    </div>
  )
}
export default DragTable