import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
const EmployeeTotalComponent = ({ employeeId }) => (
  <>
    <div className='employee-totals-item'>
      <div className='employee-totals-item-line'>
        <TextLineNew text={`Employee ${employeeId}` } />
      </div>
      <div className='employee-totals-item-line' style={{ marginLeft: 16 }}>
        <TextLineNew text='Net sales' />
        <TextLineNew text={`${employeeId === 3 ? '$0.00' : '$10.00'}`} />
      </div>
      <div className='employee-totals-item-line' style={{ marginLeft: 16 }}>
        <TextLineNew text='Total tips' />
        <TextLineNew text={`${employeeId === 3 ? '$0.00' : '$10.00'}`} />
      </div>
      {
        employeeId !== 3 && <>
          <div
            className='employee-totals-item-line'
            style={{ marginLeft: 32 }}
          >
            <TextLineNew text='Card tips' />
            <TextLineNew text='$5.00' />
          </div>
          <div
            className='employee-totals-item-line'
            style={{ marginLeft: 32 }}
          >
            <TextLineNew text='Declared cash tips' />
            <TextLineNew text='$5.00' />
          </div>
        </>
      }

    </div>
  </>
)
const EmployeeTotalsSection = () => {
  const employees = [1, 2, 3];
  return <div className='employee-totals'>
    <div>
      <TextLineNew
        text='EMPLOYEE TOTALS'
        style={{
          fontWeight: 'bold',
        }} />
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }} />
    {employees.map((employee, index) => (
      <EmployeeTotalComponent employeeId={employee} key={index} />
    ))}
  </div>
}
export default EmployeeTotalsSection;