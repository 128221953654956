import { Dayjs } from 'dayjs';

export interface ISalesCompareReportRes {
  exceptionType: string;
  success: boolean;
  message: string;
  code: number;
  data: ISalesCompareReportData;
}

export interface ISalesCompareReportData {
  netSalesByDayList: Array<INetSalesByDay>;
  salesList: Array<ISalesData>;
}

export interface ISalesData {
  incrementData?: ISalesData;
  dataType: DATA_TYPE;
  discounts: string;
  gratuities: string;
  grossSales: string;
  netSales: string;
  refunds: string;
  tax: string;
  tips: string;
  voidSales: string;
}

export interface INetSalesByDay {
  dataType: number;
  date: Dayjs;
  netSales: string
}

export enum DATA_TYPE {
  // 当前数据
  CURRENT = 1,
  // 对比数据
  COMPARE = 2,
  // 增量数据
  INCREMENT = 3
}

export interface IToolTipsDataItem {
  currentDate: string,
  currentNetSales: string,
  comparedDate: string,
  compareNetSales: string,
}