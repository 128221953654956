import React, { useEffect, useState, useMemo, useContext } from 'react';
import { globalState } from '@/stores';
import { Flex, Button, message, Modal } from 'antd';
import DragList from '@/Components/DragList';
import { queryMenuGroup, reSortUniversal } from '@/request/Menus';
import { archiveMenu } from '@/request/Menus/MenuDatabase'
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import menuStore from '../store';

const SubMenus = ({ activeMenuId, activeMenuName, onload, ...props }) => {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [subMenuList, setSubMenuList] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const [modal, contextHolder] = Modal.useModal();

  const sortNotes = async (active, over) => {
    const params = {
      sourceMenuNode: {
        sourceId: activeMenuId,
        sourceType: 'Menu'
      },
      targetMenuNode: {
        sourceType: 'MenuGroup',
        sourceId: active.uuid,
        displayOrder: over.displayOrder
      }
    };
    onload(true)
    const res = await reSortUniversal({ params });
    onload(false)
    if (res.code === 0) {
      message.success(i18n.t('menuTabList_pc_success'));
      getMenuGroup()
    }
  }
  const addSubMenu = () => {
    navigate(`/menus/createSubMenu?menuId=${encodeURIComponent(activeMenuId)}&type=MenuGroup&menuName=${encodeURIComponent(activeMenuName)}&pageType=subMenu`)
  }
  const editSubMenu = (val) => {
    navigate(`/menus/createSubMenu?menuId=${encodeURIComponent(activeMenuId)}&subMenuId=${val.uuid}&menuName=${encodeURIComponent(activeMenuName)}&subMenuName=${encodeURIComponent(val.name)}&type=MenuGroup&pageType=subMenu`)
  }
  const deleteSubMenu = async (val) => {
    const confirmed = await modal.confirm({
      content: `${i18n.t('menuTabList_pc_archive_tips', { menu_name: val.name })}`,
      okText: i18n.t('confirm'),
      cancelText: i18n.t('cancel')
    });
    if (confirmed) {
      const params = {
        archiveList: [{
          archiveUUID: val.uuid,
          archiveType: 'MenuGroup'
        }]
      }
      const res = await archiveMenu({ params })
      if (res.code === 0) {
        message.success(i18n.t('menuTabList_pc_success'))
        getMenuGroup()
      }
    }
  }
  const handleClick = (item) => {
    setActiveItem(item.uuid)
    menuStore.setActiveSubMenu({ uuid: item.uuid, name: item.name })
  }
  useEffect(() => {
    getMenuGroup()
  }, [menuStore.getActiveMenu.uuid])
  const getMenuGroup = async () => {
    if (!menuStore.getActiveMenu.uuid) return
    const params = {
      node: {
        sourceId: menuStore.getActiveMenu.uuid,
        sourceType: {
          displayName: 'Menu',
          value: 'Menu'
        }
      }
    }
    onload(true)
    const res = await queryMenuGroup({ params })
    const { data = [] } = res
    onload(false)
    if (res.code === 0) {
      setSubMenuList(data)
      const activeSubMenuUuid = menuStore.getActiveSubMenu.uuid || null
      const matchingItem = data.find(item => item.uuid === activeSubMenuUuid)
      if (matchingItem) {
        setActiveItem(menuStore.getActiveSubMenu.uuid)
      } else {
        menuStore.setActiveSubMenu({ uuid: data[0]?.uuid || null, name: data[0]?.name || null })
        setActiveItem(data[0]?.uuid || null)
      }
    }
  }
  return <div className="sub-menus" style={{ width: '100%' }}>
    {subMenuList.length > 0 &&
      <>
        <Flex className="top" justify="space-between" align="center" style={{ paddingBottom: 10 }}>
          <div className="title" style={{ fontSize: '18px', fontWeight: '600' }}>{i18n.t('menu_groups')}</div>
          <a className='a-btn' onClick={addSubMenu}>{i18n.t('pc_service_charge_add_new')}</a>
        </Flex>
        <div className="list-box">
          <DragList
            list={subMenuList}
            name="name"
            edit
            archive
            handleEdit={editSubMenu}
            handleRemove={deleteSubMenu}
            onDragEnd={sortNotes}
            handleClick={handleClick}
            activeItem={activeItem}
            valueKey='uuid'
          />
        </div>
        {contextHolder}
      </>}
  </div>
}
export default observer(SubMenus)