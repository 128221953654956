import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import theme from '@/styles/antdTheme';

const createModal = (Component, props = {}) => {
  const container = document.createElement('div');
  const open = () => {
    document.body.appendChild(container);
    ReactDOM.render(
      <ConfigProvider theme={ theme }>
        <Component { ...props } close={ close }/>
      </ConfigProvider>,
      container
    );
  }

  const close = () => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };
  return { open, close }
};

export default createModal;