import React from 'react';
import { Button } from 'antd';
import './index.scss'
import { useNavigate } from 'react-router-dom';
import { globalState } from '@/stores';
export default function SuccessFul () {
  const { i18n } = globalState;
  const navigate = useNavigate();

  const jumpToLogin = () => {
    window.location.replace('/login')
  }
  return <div className="successFul-page">
    <div className="successFul-page-message">
      <div style={{ 'width': '60p', 'height': '60px', 'marginBottom': '10px' }}>
        <img src="https://overseasbucket.s3.us-west-1.amazonaws.com/image/PC/login_success.png"/>
      </div>
      <div className="successFul-page-message-tips">{ i18n.t('login_pc_successful') }</div>
    </div>
    <Button type="primary" style={{ 'borderRadius': '4px', 'marginTop': '12px', 'width': '350px', 'height': '48px' }} onClick={ jumpToLogin }>
      {i18n?.t('login_pc_go_to_login')}
    </Button>
  </div>
}