import React, { useEffect, useState } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal, Image, Upload, message, Spin } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { ApiGetToken, getOriginalImage } from '@/request/api'
import { toRes } from '@/utils';
import { globalState } from '@/stores';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './index.scss';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
interface UploadImgProps {
    width?: number; // 图片的宽度
    height?: number; // 图片的高度
    name?: string; // 可自定义组件name
    sizeLimit?: number; // 图片的大小限制，单位MB
    listType?: 'picture-card' | 'picture-circle'; // 图片风格
    format?: string; // 图片的格式
    maxCount?: number; // 上传图片的数量限制
    cropWidth?: number; // 裁剪后图片的宽度
    cropHeight?: number; // 裁剪后图片的高度
    enableCrop?: boolean; // 新增一个控制裁剪操作的参数
    disabled?: boolean; // 是否禁用
    onImageUpload?: (fileList: object[]) => void; // 新增一个回调函数属性
    fileImg: any
    onRemove?: (file) => void;
}
const UploadImage: React.FC<UploadImgProps & UploadProps> = ({
  name = 'avatar',
  sizeLimit = 5,
  listType = 'picture-card',
  format = '.png, .jpg, jpeg',
  maxCount = 2,
  cropWidth = 200,
  cropHeight = 200,
  enableCrop = false, // 默认不开启裁剪
  fileImg,
  onImageUpload,
  disabled,
  onRemove
}) => {
  const { i18n } = globalState;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [cropper, setCropper] = useState<any>();
  // 保存裁剪后的图片
  const handleCrop = () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas({
        width: cropWidth,
        height: cropHeight,
      }).toBlob( async (blob: Blob) => {
        // 这里可以进行进一步的处理，例如上传到服务器或者本地状态中
        // 例如上传到服务器：
        const formData = new FormData();
        formData.append('file', blob);
        const [noError, imgInfo] = await uploadImg(blob)
        if (noError) {
          handleChange({
            name: imgInfo,
            status: 'done',
            url: imgInfo,
            uid: imgInfo
          })
          // 关闭预览窗口
          setPreviewVisible(false);
        }
      });
    } else {
      console.error('cropper instance is not ready.')
    }
  };
  useEffect(() => {
    if (typeof fileImg == 'string') {
      if (fileImg != '') {
        setFileList([{
          name: fileImg,
          status: 'done',
          url: fileImg,
          uid: fileImg
        }])
      } else {
        setFileList([])
      }
    } else if (Array.isArray(fileImg)) {
      const arr:UploadFile[] = fileImg.length > 0 ? fileImg.map(item => {
        return ({
          name: item,
          status: 'done',
          url: item,
          uid: item
        })
      }) : []
      setFileList(arr)
    }
  }, [fileImg])
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const beforeUpload = async (file: FileType, format: string, sizeLimit: number) => {
    const isFormatValid = format.includes(file.type.split('/')[1]);
    if (!isFormatValid) {
      message.error(i18n.t('upload_image_type_tips'));
    }
    const isSizeValid = file.size / 1024 / 1024 < sizeLimit;
    if (!isSizeValid) {
      message.error(i18n.t('upload_image_size_tips', { size:sizeLimit }));
    }
    if (isFormatValid && isSizeValid) {
      const [noError, imgInfo] = await uploadImg(file)
      if (noError) {
        if (enableCrop) {
          setPreviewVisible(true);
          setPreviewImage(imgInfo)
        } else {
          handleChange({
            name: imgInfo,
            status: 'done',
            url: imgInfo,
            uid: imgInfo
          })
        }
      }
    }
    return false;
  };
  const uploadImg = async (file) => {
    setLoading(true);

    const [error, data] = await toRes(ApiGetToken({ params: {
      objectStoreUri: 'service.com.sankuai.apaas.overseas.Restaurant.objectStorage.overseasImageBucket' }
    }))
    let originalImageInfo = null
    if (!error) {
      originalImageInfo = await getOriginalImage({ ...data, formData: file })
      if (originalImageInfo?.status !== 200) {
        message.error('upload image error!')
      }
    }
    setLoading(false);
    return [error || originalImageInfo?.status !== 200 ? false : true, data.fileUrl]
  }
  const handleChange = (imgInfo) => {
    setFileList((prevItems) => {
      // 复制当前数组
      const updatedItems = [...prevItems, imgInfo];
      // 如果数组长度超过maxCount，删除第一个元素
      if (updatedItems.length > maxCount) {
        updatedItems.shift();
      }
      // 返回更新后的数组
      onImageUpload(updatedItems);
      return updatedItems;
    });
    setLoading(false);
  };
  const uploadButton = (
    <button style={{ border: 0, background: 'none', cursor: 'pointer' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </button>
  );
  return (
    <>
      <Upload
        name={name}
        listType={listType}
        defaultFileList={fileImg}
        beforeUpload={(file) => beforeUpload(file, format, sizeLimit)}
        onChange={undefined}
        maxCount={maxCount}
        disabled={disabled}
        onPreview={handlePreview}
        onRemove={onRemove}
        accept={format}
        style={{ border: '1px soild #000' }}
        fileList={fileList}
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
      {enableCrop && (<Modal
        open={previewVisible}
        title="Image cropping"
        onOk={handleCrop}
        confirmLoading={loading}
        maskClosable={false}
        onCancel={() => setPreviewVisible(false)}
      >
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          <Cropper
            src={previewImage}
            style={{ height: 400, width: '100%' }}
            aspectRatio={cropWidth / cropHeight}
            guides={false}
            viewMode={1}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            dragMode="move"
          />
        </Spin>
      </Modal>)}
    </>
  );
};

export default UploadImage;