import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, message, Popover, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ArchivedChange from '@/Pages/Menus/component/ArchivedChange';
import { ApiBatchArchivedPayments, ApiQueryPaymentMethodList, ApiSortPaymentMethod } from '@/request/api';
import { PAY_TYPE } from './enum';
import Drawer from '@/utils/GlobalDrawer';
import PaymentMethodsEdit from './PaymentMethodsEdit';
import PaymentMethodsCashEdit from './PaymentMethodsCashEdit';
import './index.scss'
import { TableEmptyContent } from '@/Components/EmptyContent';
import DragTable, { DragHandle } from '@/Components/DragTable';
import { formatBackI18n } from '@/utils';

export default function PaymentMethods () {
  const { i18n } = globalState;

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({ archived: [true, false] });

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    { key: 'sort', align: 'center', width: 50, render: () => <DragHandle/> },
    { dataIndex: 'name', key: 'name', title: i18n.t('amount_pc_name') },
    { dataIndex: 'payType',
      key: 'payType',
      title: i18n.t('amount_pc_type'),
      width: 200,
      render: (val) => <div> {formatBackI18n(val.displayName, i18n)} </div>
    },
    {
      dataIndex: 'display', key: 'display', title: i18n.t('amount_pc_display_on'), width: 200, align: 'center',
      render: (val) => val ? <div className="iconfont icon-tick1"/> : ''
    },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('amount_pc_status'), width: 100,
      filters: [{ text: i18n.t('active'), value: false }, { text: i18n.t('archived'), value: true }],
      filteredValue: filteredInfo.archived || null,
      defaultFilteredValue: [true, false],
      filterResetToDefaultFilteredValue: true,
      render: (data, row) => <ArchivedChange data={ data } onChange={ (val) => handleEditStatus(val, row) }/>
    },
    {
      key: 'actions', title: i18n.t('amount_pc_actions'), align: 'center', width: 100,
      render: (_, row) => {
        return (
          <Popover content={
            <div className='table-actions-wrap'>
              <Button type="link" onClick={ () => handleEdit(row) } disabled={ row.systemBuilt && row.payType.value !== PAY_TYPE.CASH_PAYMENT }>{ i18n.t('edit') }</Button>
            </div>
          }>
            <div className="iconfont icon-ellipsis actions-btn"/>
          </Popover>
        )
      }
    }
  ];

  const handleEdit = async (row?) => {
    if (row?.payType?.value === PAY_TYPE.CASH_PAYMENT) {
      Drawer.open({ component: <PaymentMethodsCashEdit editItem={ row }/>, config: { width: 600 } });
      return;
    }
    Drawer.open({ component: <PaymentMethodsEdit editItem={ row } fetchTableData={ fetchTableData }/>, config: { width: 600 } });
  }

  const handleEditStatus = async (val, row) => {
    if (val === row.archived) return;
    const params = {
      uuids: [row.uuid],
      archives: val
    }
    setLoading(true)
    const resp: any = await ApiBatchArchivedPayments(params)
    if (resp.code === 0) {
      await fetchTableData();
      message.success(i18n.t('message_success'))
    }
    setLoading(false)
  }

  const handleTableChange = (_pagination, _filters) => {
    setFilteredInfo(_filters)
  }

  const fetchTableData = async () => {
    setLoading(true)
    const resp: any = await ApiQueryPaymentMethodList();
    setLoading(false)
    if (resp.code === 0) {
      setTableData(resp.data)
    }
  }

  const onDragEndAction = async ({ key, sort }) => {
    setLoading(true);
    const res = await ApiSortPaymentMethod({ uuid: key, targetSort: sort })
    if (res.code === 0) {
      message.success(res.message);
    } else {
      message.error(res.message);
    }
    fetchTableData();
  }

  return (
    <div className="payment-methods">
      <div className="m-title">
        <div className="title">
          { i18n.t('payment_methods') }
        </div>
        <div className="actions">
          <Button type="primary" onClick={ () => handleEdit() }>{ i18n.t('add_payment_methods') }</Button>
        </div>
      </div>
      <div className="table-wrap">
        <DragTable
          loading={ loading }
          onDragEndAction={ onDragEndAction }
          setDataSource={ setTableData }
          rowKey={ 'uuid' }
          sortKey={ 'displayOrder' }
          columns={ tableColumns as ColumnsType }
          dataSource={ tableData.filter(x => (filteredInfo.archived || []).includes(x.archived)) }
          onChange={ handleTableChange }
          pagination={ false }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}