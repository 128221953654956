import React from 'react'
import { Form, FormListFieldData, TimePicker, ConfigProvider } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import { globalState } from '@/stores';
import { secondsToTimeString, timeStringToMilliseconds } from '@/utils'
import './pickupHours.scss'

const { RangePicker } = TimePicker
const { Item } = Form

interface IProps {
  timeFields: FormListFieldData[]
  currentDay: number // 0-6
  add: () => void
  remove: (index: number) => void
}
const format = 'hh:mm A' // 12小时制
const MAX_INTERVAL_TIMES = 5 // 最多5个时间段
const disabledStyle = { // 置灰样式
  cursor: 'not-allowed',
  color: 'rgba(0, 0, 0, 0.25)'
}
const IntervalTimeGroup:React.FC<IProps> = (props) => {
  const { timeFields, currentDay, add, remove } = props
  const { i18n } = globalState
  const { componentDisabled } = ConfigProvider.useConfig()
  const form = Form.useFormInstance()
  const openSwitch = Form.useWatch(['pickup_hours', currentDay, 'openSwitch'], form);
  const normalize = (intervalTime: [Dayjs, Dayjs]) => {
    const [startTime, endTime] = intervalTime.map((time) => (
      timeStringToMilliseconds(time.format(format), true)
    ))
    return { startTime, endTime }
  }
  const getValueProps = (intervalTime: {startTime:number, endTime:number} | undefined) => {
    if (!intervalTime) return intervalTime // 为空代表没有选择
    const value = [dayjs(secondsToTimeString(intervalTime.startTime, true), format), dayjs(secondsToTimeString(intervalTime.endTime, true), format)]
    return { value }
  }
  // 判断是否禁用(表单禁用 || 开关关闭)
  const isDisabled = () => {
    return !openSwitch || componentDisabled
  }
  const handleAddField = () => {
    if (timeFields.length >= MAX_INTERVAL_TIMES || isDisabled()) {
      return
    }
    add()
  }
  return (
    <>
      { timeFields.map((field, index) => (
        <div key={field.key} className='interval-times-item'>
          <Item
            style={{ marginBottom: '0' }}
            rules={[
              {
                validator: async (_, name) => {
                  const intervalTimes = form.getFieldValue(['pickup_hours', currentDay, 'intervalTimes'])
                  const isOverlap = (hoursIndex: number) => {
                    const currentInterval = intervalTimes[hoursIndex];
                    return intervalTimes.some((interval, index) => {
                      if (index === hoursIndex) return false;
                      if (!interval) return false
                      return !(currentInterval.endTime < interval.startTime || currentInterval.startTime > interval.endTime);
                    });
                  };
                  if (!name) return Promise.reject(new Error(i18n.t('required')))
                  if (isOverlap(index)) {
                    return Promise.reject(new Error(i18n.t('pc_oo_overlaps')))
                  }
                  return Promise.resolve()
                },
              },
            ]}
            name={[field.name]}
            normalize={normalize}
            getValueProps={getValueProps}
            validateTrigger={['onChange']}
            dependencies={timeFields.map((_, idx) => ['pickup_hours', currentDay, 'intervalTimes', idx])}
          >
            <RangePicker
              disabled={isDisabled()}
              format={format}
              allowClear={false}
              needConfirm={false}
              use12Hours={true}
            />
          </Item>
          <div className="operation-icon-container">
            {index === 0 && <PlusOutlined style={ timeFields.length >= MAX_INTERVAL_TIMES || isDisabled() ? disabledStyle : {} } onClick={handleAddField}/>}
            {index > 0 && <MinusOutlined style={ isDisabled() ? disabledStyle : {} } onClick={()=>{
              if (isDisabled()) {
                return
              }
              remove(index)
            }}/>}
          </div>

        </div>
      ))}
    </>


  )
}

export default IntervalTimeGroup