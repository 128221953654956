export enum DISCOUNT_TYPE {
  PERCENT = '1',
  FIXED = '2',
  OPEN_DISCOUNT_PERCENTAGE = '3',
  OPEN_DISCOUNT_FIXED_AMOUNT = '4'

}

export const DISCOUNT_TYPE_I18N = {
  [DISCOUNT_TYPE.FIXED]: 'discountManage_pc_dollar_amount_off',
  [DISCOUNT_TYPE.PERCENT]: 'discountManage_pc_percentage_off'
}