import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons'
import { TableEmptyContent } from '@/Components/EmptyContent';
import { ApiQueryPrintConfigList } from '@/request/PrintOutDecorations';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { TabType } from '../Printer/enum';
interface IProps{
  activeKey: TabType
}
const PrintOutDecoration = (props: IProps) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableColumns: any = [
    {
      dataIndex: 'configTypeDisplayName', key: 'configTypeDisplayName', title: <>Printout Type</>, width: 100, align: 'left'
    },
    {
      dataIndex: 'modifiedTime', key: 'modifiedTime', title: <>Updated</>, width: 100, align: 'left',
      render: (val, row) => !!row.modifiedTime ? moment(row.modifiedTime).format('MM/DD/YYYY') : '-'
    },
    {
      key: 'actions', title: <>Action</>, width: 100, align: 'left',
      render: (_, row) => {
        return (
          <Button type="link" icon={<EditOutlined />} onClick={() => {
            navigate(`/decorationPrintOut?type=${row.configType}`);
          }} />
        )
      }
    }
  ]

  const fetchData = async () => {
    console.log('fetchData', fetchData);
    setLoading(true);
    try {
      const { data, code } = await ApiQueryPrintConfigList();
      if (code === 0) {
        setTableData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.activeKey === TabType.PRINTOUTS) {
      fetchData();
    }
  }, [props.activeKey])

  return <div className='print-out-decoration'>
    <Table
      loading={loading}
      rowKey={'configType'}
      columns={tableColumns}
      dataSource={tableData}
      locale={{ emptyText: TableEmptyContent }}
      pagination={false}
    />
  </div>
}
export default PrintOutDecoration;