export enum PageModeEnum {
  POPUP,
  PAGE,
}

export enum SettingEnum{
  OPTIONAL = 'Optional',
  REQUIRED = 'Required'
}

export enum SearchEnum{
  Name = 1,
  ModifyGroup = 2
}