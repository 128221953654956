import { service } from '@/request/index'

// 新增
export const addPrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/addPrepStation', { params: { req: params } });

// 修改
export const savePrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/savePrepStation', { params: { req: params } });

// archive
export const archivePrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/archivePrepStation', { params: { req: params } });

// unarchivePrepStation
export const unarchivePrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/unarchivePrepStation', { params: { req: params } });

// 新接口（获取配置的详情）
export const queryPrepStationDetail: any = (params = {}) => service.post('/rms/overseas/MerchantManagementConsole/Kitchen/queryPrepStationDetail', params);

// 新接口（新增配置）
export const createPrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/createPrepStation', params);

// 新接口（修改配置）
export const updatePrepStation: any = (params = {}) => service.post('rms/overseas/MerchantManagementConsole/Kitchen/updatePrepStation', params);