import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Radio, Spin } from 'antd';
import { ApiBatchQueryDetail, ApiBatchSaveTax } from '@/request/api';
import { removeNullUndefinedFields, to } from '@/utils';
import './index.scss'
import { RATE_TYPE, RATE_TYPE_MAP, ROUNDING_TYPE, ROUNDING_TYPE_MAP } from '@/Pages/TaxRates/enum';
import NP from '@/utils/NP';

export default function TaxRatesEdit (props) {
  const { editItem, close, fetchTableData } = props
  const { i18n } = globalState;
  const [form] = Form.useForm()
  const [isInit] = useState(true)
  const [loading, setLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)

  const watchedType = Form.useWatch('type', form);

  const initialValues = {
    name: '',
    choose: true,
    type: RATE_TYPE.PERCENT,
    ratePercent: undefined,
    rateFixed: undefined,
    roundingType: ROUNDING_TYPE.ROUND_UP
  }

  useEffect(() => {
    init()
  }, []);

  const init = async () => {
    const _isEdit = !!editItem;
    setIsEdit(_isEdit);
    if (_isEdit) {
      await fetchDetail();
    }
    setLoading(false);
  }

  const roundingTypeOptions = [
    { value: ROUNDING_TYPE.ROUND_UP, title: i18n.t(ROUNDING_TYPE_MAP[ROUNDING_TYPE.ROUND_UP]), N1: '$0.112', N2: '$0.12', N3: '$0.115', N4: '$0.12', N5: '$0.125', N6: '$0.13' },
    { value: ROUNDING_TYPE.ROUND_DOWN, title: i18n.t(ROUNDING_TYPE_MAP[ROUNDING_TYPE.ROUND_DOWN]), N1: '$0.112', N2: '$0.11', N3: '$0.115', N4: '$0.11', N5: '$0.125', N6: '$0.12' },
    { value: ROUNDING_TYPE.HALF_EVEN, title: i18n.t(ROUNDING_TYPE_MAP[ROUNDING_TYPE.HALF_EVEN]), N1: '$0.112', N2: '$0.11', N3: '$0.115', N4: '$0.12', N5: '$0.125', N6: '$0.12' },
    { value: ROUNDING_TYPE.HALF_UP, title: i18n.t(ROUNDING_TYPE_MAP[ROUNDING_TYPE.HALF_UP]), N1: '$0.112', N2: '$0.11', N3: '$0.115', N4: '$0.12', N5: '$0.125', N6: '$0.13' },
  ]

  const formRules = {
    name: [
      { required: true, message: i18n.t('please_enter_x', { msg: i18n.t('pc_service_charge_name') }) },
      () => ({
        validator (_, value) {
          const chineseRegex = /[\u4e00-\u9fa5]/g;
          const chineseCount = (value.match(chineseRegex) || []).length;
          const totalLength = value.length + chineseCount;
          return totalLength > 200 ? Promise.reject(new Error(i18n.t('frontOfHouse_pc_maximum', { length: 200 }))) : Promise.resolve();
        },
      }),
    ],
    ratePercent: [{ required: true, message: i18n.t('please_enter_x', { msg: i18n.t('amount_pc_rate') }) }],
    rateFixed: [{ required: true, message: i18n.t('please_enter_x', { msg: i18n.t('amount_pc_rate_fixed') }) }],
    roundingType: [{ required: true, message: i18n.t('please_enter_x', { msg: i18n.t('amount_pc_rounding_options') }) }],
  }

  const fetchDetail = async () => {
    const resp = await ApiBatchQueryDetail({ uuids: [editItem.uuid] })
    if (resp.code === 0) {
      const item = resp?.data?.[0] ?? initialValues;
      form.setFieldsValue({
        name: item.name,
        choose: item.choose,
        type: item?.type?.value ?? item.type,
        ratePercent: item?.ratePercent && NP.times(item.ratePercent, 100),
        rateFixed: item.rateFixed,
        roundingType: item?.roundingType?.value ?? item.roundingType
      })
    }
    setLoading(false);
  }

  const handleCancel = () => {
    close();
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const resultObj = {
      choose: formParams.choose,
      name: formParams.name,
      roundingType: formParams.roundingType && { value: formParams.roundingType, displayName: i18n.t(ROUNDING_TYPE_MAP[formParams.roundingType]) },
      type: { value: formParams.type, displayName: i18n.t(RATE_TYPE_MAP[formParams.type]) },
      ratePercent: formParams.type === RATE_TYPE.PERCENT ? NP.divide(formParams.ratePercent, 100) : undefined,
      rateFixed: formParams.type === RATE_TYPE.FIXED ? formParams.rateFixed : undefined,
      uuid: editItem?.uuid
    }
    setLoading(true);
    const resp: any = await ApiBatchSaveTax({ taxes: [removeNullUndefinedFields(resultObj)] });
    if (resp.code === 0) {
      message.success(i18n.t('message_success'));
      fetchTableData();
      close();
    }
    setLoading(false)
  }

  return (
    isInit && <Spin spinning={ loading }>
      <div className="tax-rates-edit">
        <div className="header-wrap">
          <div className="title">{ isEdit ? i18n.t('edit_tax') : i18n.t('add_tax') }</div>
          <div className="actions">
            <Button onClick={ handleCancel }>{ i18n?.t('cancel') }</Button>
            <Button type="primary" onClick={ handleSave }>{ i18n?.t('save') }</Button>
          </div>
        </div>
        <div className="form-wrap">
          <Form form={ form } layout="vertical" requiredMark={ false } initialValues={ initialValues }>
            <Form.Item name={ 'name' } label={ <div>{ i18n.t('name') }<span className="required">*</span></div> } rules={ formRules.name }>
              <Input
                width={ 400 } placeholder={ i18n.t('enter_x', { msg: i18n.t('name') }) }
                onBlur={ (e) => form.setFieldValue('name', e?.target?.value?.trim?.() ?? '') }
              />
            </Form.Item>
            <Form.Item name="type" label={ <div>{ i18n.t('amount_pc_type') }<span className="required">*</span></div> }>
              <Radio.Group options={ [RATE_TYPE.PERCENT, RATE_TYPE.FIXED, RATE_TYPE.DISABLED].map(x => ({ value: x, label: i18n.t(RATE_TYPE_MAP[x]) })) }/>
            </Form.Item>
            {
              watchedType === RATE_TYPE.PERCENT &&
              <Form.Item name="ratePercent" label={ <div>{ i18n.t('amount_pc_rate') }<span className="required">*</span></div> } rules={ formRules.ratePercent }>
                <InputNumber step={ '1.00' } min={ 0 } precision={ 2 } max={ 100 } addonAfter="%" stringMode/>
              </Form.Item>
            }
            {
              watchedType === RATE_TYPE.FIXED &&
              <Form.Item name="rateFixed" label={ <div>{ i18n.t('amount_pc_rate_fixed') }<span className="required">*</span></div> } rules={ formRules.rateFixed }>
                <InputNumber step={ '1.00' } min={ 0 } precision={ 2 } addonBefore={ '$' } stringMode/>
              </Form.Item>
            }
            {
              watchedType === RATE_TYPE.PERCENT &&
              <Form.Item name="roundingType" label={ <div>{ i18n.t('amount_pc_rounding_options') }<span className="required">*</span></div> } rules={ formRules.roundingType }>
                <Radio.Group rootClassName="rounding-type-radio-group">
                  {
                    roundingTypeOptions.map(x => (
                      <Radio.Button key={ x.value } value={ x.value }>
                        <div className="rounding-type-radio-item">
                          <div>{ x.title }</div>
                          <div>{ x.N1 }<span> → </span>{ x.N2 }</div>
                          <div>{ x.N3 }<span> → </span>{ x.N4 }</div>
                          <div>{ x.N5 }<span> → </span>{ x.N6 }</div>
                        </div>
                      </Radio.Button>
                    ))
                  }
                </Radio.Group>
              </Form.Item>
            }
            <Form.Item name="choose" label={ <div>{ i18n.t('amount_pc_default?') }<span className="required">*</span></div> }>
              <Radio.Group options={ [{ value: true, label: i18n.t('yes') }, { value: false, label: i18n.t('no') }] }/>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  )
}