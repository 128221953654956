import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, message, Popover, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ArchivedChange from '@/Pages/Menus/component/ArchivedChange';
import { ApiBatchArchivedPayments, ApiBatchArchivedTax, ApiBatchSaveTax, ApiQueryPaymentMethodList, ApiQueryTaxesList } from '@/request/api';
import { PAY_TYPE } from '@/Pages/PaymentMethods/enum';
import './index.scss'
import Drawer from '@/utils/GlobalDrawer';
import { TableEmptyContent } from '@/Components/EmptyContent';
import TaxRatesEdit from '@/Pages/TaxRates/TaxRatesEdit';
import { formatPreCent, formatPrice } from '@/utils';

export default function TaxRates () {
  const { i18n } = globalState;

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({ archived: [true, false] });

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    { dataIndex: 'name', key: 'name', title: i18n.t('amount_pc_name') },
    {
      dataIndex: 'rateFixed', key: 'rateFixed', title: i18n.t('amount_pc_rate'),
      render: (_, row) => row?.rateFixed ? '$' + formatPrice(row?.rateFixed) : row?.ratePercent ? formatPreCent(row?.ratePercent) : ''
    },
    {
      dataIndex: 'choose', key: 'choose', title: i18n.t('amount_pc_default'),
      render: (val, row) => {
        return <Checkbox checked={ val } onChange={ (event) => handleRowEdit({ row, field: 'choose', value: event.target.checked }) }/>
      }
    },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('amount_pc_status'), width: 100,
      filters: [{ text: i18n.t('active'), value: false }, { text: i18n.t('archived'), value: true }],
      filteredValue: filteredInfo.archived || null,
      defaultFilteredValue: [true, false],
      filterResetToDefaultFilteredValue: true,
      render: (data, row) => <ArchivedChange data={ data } onChange={ (val) => handleEditStatus(val, row) }/>
    },
    {
      key: 'actions', title: i18n.t('amount_pc_actions'), align: 'center', width: 100,
      render: (_, row) => {
        return (
          <Popover content={
            <div className="table-actions-wrap">
              <Button type="link" onClick={ () => handleEdit(row) }>{ i18n.t('edit') }</Button>
            </div>
          }>
            <div className="iconfont icon-ellipsis actions-btn"/>
          </Popover>
        )
      }
    }
  ];

  const handleEdit = async (row?) => {
    Drawer.open({ component: <TaxRatesEdit editItem={ row } fetchTableData={ fetchTableData }/>, config: { width: 600 } });
  }

  const handleEditStatus = async (val, row) => {
    if (val === row.archived) return;
    setLoading(true)
    const resp = await ApiBatchArchivedTax({ uuids: [row.uuid], archived: val })
    if (resp.code === 0) {
      await fetchTableData();
      message.success(i18n.t('message_success'))
    }
    setLoading(false)
  }

  const handleTableChange = (_pagination, _filters) => {
    setFilteredInfo(_filters)
  }

  const handleRowEdit = async ({ row, field, value }) => {
    setLoading(true);
    const resp = await ApiBatchSaveTax({ taxes: [{ ...row, [field]: value }] })
    if (resp.code === 0) {
      row[field] = value;
      message.success(i18n.t('message_success'))
    }
    setLoading(false);
  }

  const fetchTableData = async () => {
    setLoading(true)
    const resp = await ApiQueryTaxesList();
    setLoading(false)
    if (resp.code === 0) {
      setTableData(resp.data)
    }
  }

  return (
    <div className="tax-rates">
      <div className="m-title">
        <div className="title">{ i18n.t('tax_rates') }</div>
        <div className="actions">
          <Button type="primary" onClick={ () => handleEdit() }>{ i18n.t('add_tax') }</Button>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          loading={ loading }
          rowKey={ 'uuid' }
          columns={ tableColumns as ColumnsType }
          dataSource={ tableData.filter(x => (filteredInfo.archived || []).includes(x.archived)) }
          onChange={ handleTableChange }
          pagination={ false }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}