import React, { useEffect, useState } from 'react';
import { Table, Flex, Input, Popover, Button, Modal, message } from 'antd'
import { globalState } from '@/stores';
import useGetState from '@/hooks/useGetState';
import { SearchOutlined, MoreOutlined, PieChartOutlined, EditOutlined } from '@ant-design/icons';
import Columns from '@/Components/Columns/Columns'
import { queryMenuOptionList, archiveMenu, restoreMenuArchive, viewArchiveUsage } from '@/request/Menus/MenuDatabase'
import Edit from '../component/Edit'
import ArchivedChange from '../component/ArchivedChange'
import UsagePop from '../component/UsagePop'
import EditModifier from '../component/EditModifier';
import { formatTime } from '@/utils/index'
import { IPage, IFilter, ISorter, IGetList, IComponentPage } from './types';
import { debounce } from '@/utils/index'
export default function ModifiersOptions ({ isActive, archiveType, sticky }) {
  const { i18n } = globalState;
  const [activeTitle, setActiveTitle] = useState([])
  const [tableData, setTableData] = useState([])
  const [popTableData, setPopTableData] = useState<any>([])
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchArchived, setSearchArchived] = useState<boolean[]>([true, false])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [archived, setArchived] = useState<boolean>()
  const [popLoading, setPopLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modifiedList, setModifiedList] = useState([])

  const tableColumns = [{
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('menuDatabaseList_pc_name'),
    width: 300,
  }, {
    dataIndex: 'modifierPrice',
    key: 'modifierPrice',
    title: i18n.t('menuDatabaseList_pc_modifierPrice'),
    width: 100,
    render: (data) => {
      return data !== null && data >= 0 ? `$${data}` : '-'
    }
  }, {
    dataIndex: 'uuid',
    key: 'uuid',
    title: i18n.t('menuDatabaseList_pc_id'),
    width: 100,
    hide: true,
  }, {
    dataIndex: 'standardCreatedTime',
    key: 'standardCreatedTime',
    title: i18n.t('pc_menudatabase_updated'),
    width: 100,
    hide: true,
    render: (data) => {
      return formatTime(data, 'MM/dd/yyyy')
    }
  // }, {
  //   dataIndex: 'taxes',
  //   key: 'taxes',
  //   title: i18n?.t('taxes'),
  //   width: 100,
  //   hide: true,
  // }, {
  //   dataIndex: 'preStations',
  //   key: 'preStations',
  //   title: i18n?.t('print_gearOpening_bill_pc_prepStation'),
  //   width: 100,
  //   hide: true,
  }, {
    dataIndex: 'archived',
    key: 'archived',
    title: i18n.t('menuDatabaseList_pc_status'),
    width: 100,
    filters: [
      { text: i18n.t('active'), value: false },
      { text: i18n.t('archived'), value: true },
    ],
    render: (data, row) => <ArchivedChange data={data} onChange={(val) => editStatus(val, row)}/>
  },
  {
    dataIndex: 'reason',
    key: 'reason',
    title: i18n.t('menuDatabaseList_pc_actions'),
    width: 100,
    render: (data, row) => {
      return (
        <>
          <Popover
            content={
              <>
                <Button type='link' icon={<EditOutlined/>} onClick={() => {
                  setModifiedList([row]);
                  setOpenModal(true);
                }}>{i18n.t('edit')}</Button>
                <Button disabled={row.archived} icon={<PieChartOutlined />} onClick={() => getUsage(row)} type="link">{i18n.t('usage')}</Button>
              </>
            }
          >
            <Button type='link' icon={<i className="iconfont icon-ellipsis actions-btn" />}></Button>
          </Popover>
        </>
      )
    }
  }
  ]
  const [open, setOpen] = useState(false);
  const [openUsagePop, setOpenUsagePop] = useState(false);
  const [itemName, setItemName] = useState('');
  const [usageData, setUsageData] = useState([]);

  const showModal = (val:boolean) => {
    if (!val) {
      return
    }
    setOpen(true);
  };

  const hideModal = () => {
    setPopTableData([])
    setPopLoading(false)
    setOpen(false);
  };
  const okModal = async () => {
    const list = popTableData.map(item => {
      return {
        archiveUUID: item.uuid,
        archiveType: archiveType
      }
    })
    setPopLoading(true)
    let res:any
    if (archived) {
      res = await archiveMenu({ params:{ archiveList: list } })
    } else {
      res = await restoreMenuArchive({ params:{ restoreArchive: list } })
    }
    if (res.code == 0) {
      getMenuList({})
    }
    setPopLoading(false)
    hideModal()
  };
  const [pager, setPager, getPager] = useGetState<IComponentPage>({
    current: 1, pageNo: 1, pageSize: 20, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const editStatus = (val, row) => {
    if (val === row.archived) {
      return
    }
    setPopTableData([row])
    setArchived(val)
    showModal(true)
  }
  const getUsage = async (row) => {
    setItemName(row.name)
    await getViewArchiveUsage(row)
    setOpenUsagePop(true)
  }
  const getViewArchiveUsage = async (row:any) => {
    const archive = {
      archiveType: archiveType,
      archiveUUID: row.uuid
    }
    const res = await viewArchiveUsage({ params:{ archive } })
    if (res.code === 0) {
      setUsageData(res.data)
    }
  }
  const onChangeColumns = (list) => {
    setActiveTitle(list)
  }
  const handleTableChange = (pagination: IPage, filters: IFilter, sorter: ISorter) => {
    const page = {
      ...pager,
      current: pagination.current,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    }
    let { archived = [] } = filters;
    if (!archived || archived.length === 0) {
      archived = [true, false]
    }
    setSearchArchived(archived)
    getMenuList({ page, filters: { archived }, sorter })
  }
  const changeSearch = (e: any) => {
    debounceInput(e.target.value)
  }
  const onPressEnter = (value) => {
    const page = {
      ...pager,
      current: 1,
      pageNo: 1,
      pageSize: 20,
    }
    setSearchValue(value)
    getMenuList({ value, page })
  }
  const debounceInput = debounce(onPressEnter, 500)
  const changeEdit = (val) => {
    const arr = selectedRows.filter((item:any) => {
      return item.archived !== val
    })
    setArchived(val)
    setPopTableData(arr)
    showModal(arr.length > 0)
  }
  const getMenuList = async ({ value = null, page = pager, filters = {}, sorter = {} }: IGetList) => {
    const { archived = searchArchived } = filters;
    const params = {
      page: {
        current: page.current,
        pageNo: page.current,
        pageSize: page.pageSize,
      },
      condition: {
        type: 'GroupCondition',
        op: 'and',
        conditions: [
          {
            type: 'LikeCondition',
            name: 'name',
            notLike: false,
            matchType: 'both',
            value: value !== null ? value : searchValue
          }, {
            type: 'InCondition',
            name: 'archived',
            op: '=',
            value: archived
          }
        ]
      }
    }
    setLoading(true)
    const data = await queryMenuOptionList(params)
    setLoading(false)
    if (data.code === 0) {
      setTableData(data.data)
      setSelectedRows([])
      setSelectedRowKeys([])
      setPager({
        ...pager,
        ...data.option
      })
    }
  }
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    if (isActive) {
      setActiveTitle(tableColumns.map(item => {
        if (item.hide !== true) return item.dataIndex
      }))
      getMenuList({})
    }
  }, [isActive])
  return (
    <div>
      <Flex className="top">
        <Flex align="center" flex="1" gap="large" justify="space-between" className="search-box">
          <Input
            prefix={< SearchOutlined style={{ color: '#BEBEBE' }} />}
            className="search"
            placeholder={`${i18n.t('search')} ${i18n.t('menuDatabaseList_pc_modifiers')}`}
            onChange={changeSearch}
            maxLength={100}
            allowClear
          />
          <Flex gap="middle">
            <Edit onChange={changeEdit} disable={selectedRows.length == 0}></Edit>
            <Columns value={activeTitle} options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex, hide: item.hide ? false : item.hide }))} onChange={onChangeColumns} />
          </Flex>
        </Flex>
      </Flex>
      <Table
        columns={ tableColumns.filter(x => {
          return activeTitle.includes(x.dataIndex)
        }) }
        rowSelection={rowSelection}
        dataSource={ tableData }
        pagination={ pager }
        onChange={ handleTableChange }
        loading={loading}
        rowKey="uuid"
        scroll={{ y: sticky }}
      />
      <Modal
        className="ArchivePop"
        title={archived ? i18n.t('archive') + ' item?' : i18n.t('active') + ' item?'}
        open={open}
        onOk={okModal}
        onCancel={hideModal}
        okText={i18n.t('confirm')}
        confirmLoading={popLoading}
        cancelText={i18n.t('cancel')}
      >
        <p className="text">{archived ? i18n.t('pc_archivepop') : i18n.t('pc_Activepop')}</p>
        <Table
          columns={[{
            dataIndex: 'name',
            key: 'name',
            title: i18n.t('menuDatabaseList_pc_name'),
          }]}
          dataSource={popTableData}
          scroll={{ y: sticky }}
          pagination={false}
        />
      </Modal>
      <UsagePop
        itemName={itemName}
        data={usageData}
        open={openUsagePop}
        modifierGroups
        hidePop={(rel:boolean) => setOpenUsagePop(rel)}
      />
      <EditModifier openModal={openModal} setOpenModal={setOpenModal} modifiedList={modifiedList} handleOk={() => {
        getMenuList({});
      }} />
    </div>
  )
}