import './index.scss';
import { globalState } from '@/stores';
import { Button } from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '@/utils';
import {
  getCashDrawerList,
} from '@/request/PrintersAndCashDrawers';
import Table, { ColumnsType } from 'antd/es/table';
import {
  ActivePointIcon,
  ArchivedPointIcon,
} from '../DiningOptions/component/IconComponent';
import CashConfigDrawer from './components';
import { TabType } from '../Printer/enum';
import { IProps, RowData } from './interface';

export default function CashDrawer (props: IProps) {
  const i18n = globalState.i18n;
  const [tableData, setTableData] = useState<ColumnsType<RowData> | undefined[]>([]);
  const [params, setParams] = useState({
    req: {},
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const DEFAULT_FILTER_TYPE = {
    STATUS: [0],
  };
  const STATUS_FILTER = [
    { text: i18n.t('active'), value: 0 },
    { text: i18n.t('archived'), value: 1 },
  ];

  const [filteredValue, setFilteredValue] = useState([]);
  const [rowData, setRowData] = useState<RowData>();
  const [open, setOpen] = useState<boolean>(false);

  // const [isEdit, setIsEdit] = useState<OperationType>(OperationType.EDIT); // 0代表编辑 1代表新增

  const columns: any = [
    {
      title: i18n?.t('name'),
      align: 'center',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: i18n?.t('dashboard_devices_printer_cashdrawer_assignee'),
    //   align: 'center',
    //   dataIndex: 'assignee',
    //   key: 'assignee',
    // },
    {
      title: i18n?.t('dashboard_devices_printer_cashdrawer_start_balance'),
      align: 'center',
      dataIndex: 'startBalanceConfigAmount',
      key: 'startBalanceConfigAmount',
      render: (_, row) => {
        return (
          <div>
            {
              row.startBalanceConfigType === 2 ?
                `$${row.startBalanceConfigAmount}` : '-'}

          </div>
        );
      },
    },
    {
      title: i18n?.t('status'),
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      defaultFilteredValue: DEFAULT_FILTER_TYPE.STATUS,
      filteredValue: filteredValue,
      filters: STATUS_FILTER,
      render: (_, row) => {
        return (
          <div>
            {row?.archived === 0 ? (
              <span>
                <ActivePointIcon /> {i18n?.t('active')}
              </span>
            ) : (
              <span>
                <ArchivedPointIcon /> {i18n?.t('archived')}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: i18n?.t('dashboard_devices_printer_cashdrawer_printer'),
      align: 'center',
      dataIndex: 'printName',
      key: 'printName',
    },
    {
      title: i18n?.t('table_actions'),
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        return (
          <div className="btn-group">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setOpen((prevState) => !prevState);
                // setIsEdit(OperationType.EDIT);
                setRowData(row)
              }}
            >
              {i18n?.t('edit_1')}
            </Button>
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    setTableLoading(true);
    const { data } = await getCashDrawerList(params);

    if (filteredValue.length) {
      const list = data.filter((fil) =>
        filteredValue?.includes(fil?.archived)
      );
      setTableData(list);
    } else {
      setTableData(data);
    }
    setTableLoading(false);
  };

  const handleTableChange = (_, filters, sorter) => {
    setFilteredValue(filters.status);
    const status = filters.status;
    const filterData = async () => {
      setTableLoading(true);
      const { data } = await getCashDrawerList(params);
      const list = data.filter((fil) => status?.includes(fil?.archived));
      setTableData(isEmpty(status) ? data : list);
      setTableLoading(false);
    };
    filterData();
  };

  useEffect(() => {
    if (props.activeKey === TabType.CASHDRAWER) {
      fetchData();
    }
  }, [props.activeKey]);
  return (
    <div className="cash-drawer">
      <div className="cash-drawer-head">
        <div className="cash-drawer-head-title">
          {i18n?.t('dashboard_devices_printer_cashdrawer')}
        </div>
      </div>
      <div className="cash-drawer-body">
        <Table
          columns={columns}
          dataSource={tableData}
          loading={tableLoading}
          rowKey={'id'}
          pagination={{
            showQuickJumper: true,
            showTotal: () =>
              i18n?.t('table_total_items', { num: tableData.length }),
          }}
          onChange={handleTableChange}
        />
      </div>


      <CashConfigDrawer
        open={open}
        setOpen={setOpen}
        reloadTableData={() => {
          setOpen((prevState) => !prevState);
          fetchData();
        }}
        // type={isEdit}
        rowData={rowData}
      />
    </div>
  );
}
