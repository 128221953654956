import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const SummarySection = (props: IProps) => {
  return <div className='summary-section'>
    <div>
      <TextLineNew
        text='SUMMARY'
        style={{
          fontWeight: 'bold',
        }}/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='summary-section-item'>
      <div className='summary-section-item-title'>
        <TextLineNew text='From'/>
      </div>
      <div className='summary-section-item-value'>
        <TextLineNew text='Tue 06/18/2024 04:00:00 AM'/>
      </div>
    </div>

    <div className='summary-section-item'>
      <div className='summary-section-item-title'>
        <TextLineNew text='To'/>
      </div>
      <div className='summary-section-item-value'>
        <TextLineNew text='Wed 06/19/2024 03:59:59 AM'/>
      </div>
    </div>

    <div className='summary-section-item'>
      <div className='summary-section-item-title'>
        <TextLineNew text='Net sales'/>
      </div>
      <div className='summary-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='summary-section-item'>
      <div className='summary-section-item-title'>
        <TextLineNew text='Tax amount'/>
      </div>
      <div className='summary-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='summary-section-item'>
      <div className='summary-section-item-title'>
        <TextLineNew text='Tips'/>
      </div>
      <div className='summary-section-item-value'>
        <TextLineNew text='$0.00'/>
      </div>
    </div>

    <div className='summary-section-item'>
      <div className='summary-section-item-title'>
        <TextLineNew text='Total' style={{
          fontWeight: 'bold',
        }}/>
      </div>
      <div className='summary-section-item-value'>
        <TextLineNew text='$0.00' style={{
          fontWeight: 'bold',
        }}/>
      </div>
    </div>
  </div>
}
export default SummarySection;