import React from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
import DashedLine from '../component/DashedLine';
interface IProps{
  text: string
}
const TotalVoidsSection = (props: IProps) => {
  return <div className='total-voids'>
    <div className='total-voids-item'>
      <TextLineNew
        text='VOID REASON'
        style={{
          fontWeight: 'bold',
        }}/>
      <TextLineNew text='Qty'/>
      <TextLineNew text='Amount'/>
    </div>
    <DashedLine gap={{ 'margin': '8px 0px' }}/>
    <div className='total-voids-item'>
      <TextLineNew text='Reason 1'/>
      <TextLineNew text='1'/>
      <TextLineNew text='$0.00'/>
    </div>
    <div className='total-voids-item'>
      <TextLineNew text='Reason 2'/>
      <TextLineNew text='1'/>
      <TextLineNew text='$0.00'/>
    </div>
    <div className='total-voids-item'>
      <TextLineNew
        text='Total voids'
        style={{
          fontWeight: 'bold',
        }} />
      <TextLineNew text='2' />
      <TextLineNew
        text='$0.00'
        style={{
          fontWeight: 'bold',
        }} />
    </div>
  </div>
}
export default TotalVoidsSection;