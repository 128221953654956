import React from 'react';
import logoUrl from '@/assets/svg/Logo.svg';
import logoCollapseUrl from '@/assets/svg/logoCollapse.svg';
const Logo = ({
  collapsed,
  onClick,
}: {
  collapsed: boolean;
  onClick: () => void;
}) => {
  const logoSrc = collapsed ? logoCollapseUrl : logoUrl;
  return (
    <div
      className="logo"
      onClick={onClick}
      style={{
        cursor: 'pointer',
        justifyContent: collapsed ? 'center' : 'left',
      }}
    >
      <img src={logoSrc} />
    </div>
  );
};
export default Logo;
