import React, { useEffect, useState } from 'react';
import './index.scss'
import TextLineNew from '../component/TextLineNew';
import printOutDecorationStore from '../../store';
import { toJS } from 'mobx';
import { IPreviewSectionChildren } from '../../interface';
import { IItemInfoType } from '../itemInfoSection/interface';
import { fontSizeMapValue } from '../itemInfoSection/enum';
interface IProps{
  itemInfo: IItemInfoType
}
const CheckNotesSection = (props: IProps) => {
  const { itemInfo } = props
  const [text, setText] = useState('')
  const [type, setType] = useState<string>('');
  const [checkNoteFontSize, setCheckNoteFontSize] = useState<string>('22')

  useEffect(() => {
    setType(toJS(printOutDecorationStore.getUrlParamsType));
    if (itemInfo && itemInfo.checkNotes.value) {
      setText(itemInfo.checkNotes.value || '')
    }

    const fontSize = itemInfo?.checkNotes?.modifier?.fontSize || '5'
    setCheckNoteFontSize(fontSize)

  }, [props])
  return <div className='preview-ui-check-notes'>
    <TextLineNew text={text} style={{
      fontSize: type === 'Kitchen' ? fontSizeMapValue[checkNoteFontSize] : 'sm',
      fontWeight: type === 'Kitchen' ? 'bold' : 'default'
    }}/>
  </div>
}
export default CheckNotesSection;