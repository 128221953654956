import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Modal, Typography, Table, Input, InputNumber, Spin, message } from 'antd'
import { globalState } from '@/stores';
import { viewArchiveUsage } from '@/request/Menus/MenuDatabase'
import { ApiModifierUpdate } from '@/request/Menus/EditModifier'
import UsageCard from './UsageCard';
import './EditModifier.scss';

const { Paragraph } = Typography;

interface IProps {
  openModal: boolean,
  setOpenModal: Dispatch<SetStateAction<boolean>>
  modifiedList: any[],
  handleOk?: (arr: any[]) => void
  handleCancel?: () => void
}

const EditModifier: FC<IProps> = (props) => {
  const { i18n } = globalState;
  const [modifierGroupsList, setModifierGroupsList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [arr, setArr] = useState<any[]>();
  const [chooseModifiers, setChooseModifiers] = useState<Array<any>>([]);
  const handleOk = async () => {
    if (!!arr.length) {
      if (arr.length && arr.filter(fil => !fil.name).length > 0) { // 如果modifier list有值并且有一项name为空
        message.error(i18n?.t('please_enter_x', { msg: i18n?.t('menu_modifier_name') }));
        return;
      }
      const req = arr.map(ele => ({
        uuid: ele.uuid,
        name: ele.name,
        foreignName: ele.foreignName,
        price: ele.modifierPrice
      }))[0];
      const { code } = await ApiModifierUpdate(req);
      if (code === 0) {
        message.success(i18n.t('message_success'));
        setArr([]);
        setTimeout(() => {
          props.setOpenModal(false);
          props.handleOk && props.handleOk(arr);
        })
      }
    }
  }
  const handleCancel = () => {
    setArr([]);
    setTimeout(() => {
      props.setOpenModal(false);
      props.handleCancel && props.handleCancel();
    })
  }

  const handleInputChange = (value, uuid, column) => {
    const newData = [...arr];
    const index = newData.findIndex(item => uuid === item.uuid);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [column]: value });
      setArr(newData);
    }
  };

  const handleInputBlur = (record) => {
    const { uuid } = record;
    const index = chooseModifiers.findIndex(find => find.uuid === uuid);
    if (index > -1) {
      const newArr = [...chooseModifiers];
      const newData = chooseModifiers[index];
      newData.name = record.name;
      newArr.splice(index, 1, newData);
      setChooseModifiers(newArr);
    } else {
      setChooseModifiers([
        ...chooseModifiers,
        record
      ]);
    }
  }

  const columns = [
    {
      title: <>{ i18n?.t('createModifierGroup_pc_item_group') } <span style={{ 'color': 'red' }}>*</span></>,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <div>
        <Input value={text} maxLength={100} onChange={e => handleInputChange(e.target.value, record.uuid, 'name')} onBlur={() => { handleInputBlur(record); }}/>
        {chooseModifiers.map(ele => ele.uuid).includes(record.uuid) && !record.name && <div style={{ 'color': '#ff4d4f' }}> {i18n?.t('please_enter_x', { msg: i18n?.t('menu_modifier_name') })} </div>}
      </div>,
    },
    {
      title: i18n?.t('foreign_name'),
      dataIndex: 'foreignName',
      key: 'foreignName',
      render: (text, record) => <Input value={text} maxLength={100} onChange={e => handleInputChange(e.target.value, record.uuid, 'foreignName')} />,
    },
    {
      title: i18n?.t('orderDetail_price'),
      dataIndex: 'modifierPrice',
      key: 'modifierPrice',
      render: (text, record) => <InputNumber defaultValue={text} min={0} max={9999999} precision={2} onChange={e => handleInputChange(e, record.uuid, 'modifierPrice')} />,
    }
  ]

  const initData = async () => {
    console.log('props.data', props.modifiedList);
    setArr([...props.modifiedList]);
    const { code, data } = await viewArchiveUsage({
      params: {
        archive: {
          archiveType: 'MenuOption',
          archiveUUID: props.modifiedList[0].uuid
        }
      }
    });
    if (code === 0) {
      const list = data.filter(x => x.archiveType.value === 'MenuOptionGroup');
      console.log('list', list);
      setModifierGroupsList(list)
    }
  }

  useEffect(() => {
    if (props.openModal) {
      setLoading(true);
      initData();
      setLoading(false);
    }
  }, [props.openModal])
  return (
    <div className='menu-edit-modifier'>
      <Spin spinning={loading}>
        <Modal
          title={i18n?.t('menus_edit_modifier')}
          open={props.openModal}
          onOk={handleOk}
          onCancel={handleCancel} width={800}
          cancelText={i18n?.t('cancel')}
          okText={i18n.t('confirm')}
          wrapClassName='modal-body-wrap'>
          <div className='menu-edit-modifier-card'>
            <Paragraph strong>{i18n?.t('modifier')}</Paragraph>
            <Table columns={columns} dataSource={arr} rowKey={'uuid'} pagination={false} />
          </div>
          <UsageCard list={modifierGroupsList} tip={i18n.t('used_in_modifierGroup', { x: modifierGroupsList.length })}/>
        </Modal>
      </Spin>
    </div>
  )
}

export default EditModifier;