export enum WEEK_DAY {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const WEEK_DAY_I18N = {
  [WEEK_DAY.MONDAY]: 'pc_online_orders_monday',
  [WEEK_DAY.TUESDAY]: 'pc_online_orders_tuesday',
  [WEEK_DAY.WEDNESDAY]: 'pc_online_orders_wednesday',
  [WEEK_DAY.THURSDAY]: 'pc_online_orders_thursday',
  [WEEK_DAY.FRIDAY]: 'pc_online_orders_friday',
  [WEEK_DAY.SATURDAY]: 'pc_online_orders_saturday',
  [WEEK_DAY.SUNDAY]: 'pc_online_orders_sunday',
};
export type AvailabilityType = {
  day: number;
  openSwitch: boolean;
  intervalTimes: Array<{
    startTime: number;
    endTime: number;
  }>;
};
export type IFormData = {
  mode: 1 | 2; // 1:ON; 2:OFF
  liftAmount: string;
  availableTime: AvailabilityType[];
};