import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Card, Form, Checkbox, Popover, Switch } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { globalState } from '@/stores';
import { getQuerys } from '@/utils'
import './PrepStations.scss'
import { IOptions, IDefaultAndInheritsRes } from '../interface';
import { useNavigate } from 'react-router-dom';

interface IProps {
  editInfo: {
    inheritPrepStation: boolean
    prepStations: Array<any>
  }
  isShowInheritRadio?: boolean
  defaultAndInherits: IDefaultAndInheritsRes
  pageType: 'menu' | 'subMenu' | 'item'
  inheritName: string
}

const PrepStations = forwardRef((props: IProps, ref) => {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const { subMenuId, itemId } = getQuerys(location.href);
  const {
    editInfo: { inheritPrepStation, prepStations },
    isShowInheritRadio = true,
    defaultAndInherits,
    pageType,
    inheritName = i18n.t('restaurant')
  } = props;

  // ** prepStation **
  // [prepStationsOptions]: 全量
  // [defaultPrepStationsOptions]: 自定义选择的默认
  // [inheritPrepStationsOptions]: 继承的默认
  const [prepStationsOptions, setPrepStationsOptions] = useState<Array<IOptions>>([]);
  const [defaultPrepStationsOptions, setDefaultPrepStationsOptions] = useState<Array<string>>([]);
  const [inheritPrepStationsOptions, setInheritPrepStationsOptions] = useState<Array<string>>([]);

  const [form] = Form.useForm();
  const [isInheritPrepStation, setIsInheritPrepStation] = useState<boolean>(true);

  useImperativeHandle(ref, () => {
    return {
      form
    };
  }, []);

  const changeCheckBox = () => {
    setIsInheritPrepStation(false)
    form.setFieldsValue({ 'inheritPrepStation': false })
  }

  const changSwitch = (value) => {
    setIsInheritPrepStation(value);
    if (value) {
      form.setFieldsValue({
        'prepStations': inheritPrepStationsOptions,
        'inheritPrepStation': true
      });
    }
    form.setFieldsValue({
      'inheritPrepStation': value
    });
  }
  const gotoPage = (link: string) => {
    navigate(link)
  }
  useEffect(() => {
    form.setFieldsValue({ 'prepStations': defaultPrepStationsOptions });
  }, [defaultPrepStationsOptions])

  useEffect(() => {
    form.setFieldsValue({ 'inheritPrepStation': inheritPrepStation ?? true });
    setIsInheritPrepStation(inheritPrepStation ?? true)
  }, [inheritPrepStation])

  useEffect(() => {
    if (prepStations) {
      const defaultPrepOptions = prepStations.map(item => item.uuid);
      setDefaultPrepStationsOptions(defaultPrepOptions);
    }
  }, [prepStations])


  useEffect(() => {
    const { prepStations, defaultPrepStations } = defaultAndInherits

    let inheritPrepOptions = []
    let defaultPrepOptions = []
    const taxOptions = prepStations.map(item => ({ label: item.name, value: item.uuid }));
    setPrepStationsOptions(taxOptions);

    switch (pageType) {
    case 'menu':
      defaultPrepOptions = defaultPrepStations.map(item => item.uuid);
      inheritPrepOptions = (prepStations.filter(item => item.choose === true) || []).map(item => item.uuid);
      break;

    case 'subMenu':
    case 'item':
      defaultPrepOptions = defaultPrepStations.map(item => item.uuid);
      inheritPrepOptions = [...defaultPrepOptions]
      if ((pageType === 'subMenu' && !subMenuId) || (pageType === 'item' && !itemId)) {
        setDefaultPrepStationsOptions(defaultPrepOptions);
      }
      setInheritPrepStationsOptions(inheritPrepOptions);
      break;

    default:
      break
    }
  }, [defaultAndInherits])

  return (
    <div className='menus-prep-stations'>
      <Card title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{i18n.t('prep_stations')}</span>
          <Popover
            placement='top'
            arrow={false}
            content={<div
              className='popover-text'
              onClick={() => gotoPage('/device/printer')}
              style={{ fontSize: 14 }}
            >{i18n.t('pc_prepStations_tips')}</div>}
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      } >
        <Form form={form} layout="vertical">
          { isShowInheritRadio ? <Form.Item name="inheritPrepStation">
            <div>
              <Switch
                checked={isInheritPrepStation}
                onChange={changSwitch}
                style={{ marginRight: 5 }}
              />
              <span>{`${i18n.t('pc_inherit')} ${inheritName}`}</span>
            </div>
          </Form.Item> : <div style={{ marginBottom: '10px' }}></div>
          }
          {prepStationsOptions.length > 0 ? <Form.Item name="prepStations">
            <Checkbox.Group
              options={prepStationsOptions}
              onChange={isShowInheritRadio ? changeCheckBox : undefined}
            />
          </Form.Item> :
            <span className='tips-text' onClick={() => gotoPage('/device/printer')}>{i18n.t('pc_prepStations_tips')}</span>
          }
        </Form>
      </Card>
    </div>
  )
})

export default PrepStations