import React, { useState, useEffect } from 'react';
import { Button, Modal, Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom';
import { globalState } from '@/stores';
import './ArchivedChange.scss'
interface archivePath{
  usedArchiveName: string;
  usedArchiveUUID: string;
  usedArchiveTyped: {
    displayName: string;
    value: string;
  }
  usedArchiveStatus: boolean;
}
interface UsedArchive {
  archiveType: {
    displayName: string;
    value: string;
  }
  archiveUUID: string;
  archiveStatus: boolean;
  archivePath: archivePath[];
  gotoDetail?: boolean
}
export default function UsagePop ({ data = [], itemName, open, hidePop, menu = false, subMenu = false, modifierGroups = false, gotoDetail = false }) {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [usedArchive, setUsedArchive] = useState<UsedArchive>();
  const [menuItemList, setMenuItemList] = useState([]);
  const [menuGroupList, setMenuGroupList] = useState([]);
  const [modifierGroupsList, setModifierGroupsList] = useState([]);
  const hide = () => {
    setMenuItemList([])
    setMenuGroupList([])
    setModifierGroupsList([])
    hidePop(false)
  };
  const usedItem = (list) => {
    return list.map((item, index) => {
      return <span className="pointer" key={index} onClick={() => gotoEdit(item)}>
        <i className={`point ${item.usedArchiveStatus ? 'archived' : 'active'}`}></i>
        {item.usedArchiveName}
        {list.length == index + 1 ? '' : ' / '}
      </span>
    })
  }
  const gotoEdit = (item:any) => {
    console.log(item, usedArchive)
    if (!gotoDetail) {
      return
    }
    const usedArchiveUUID = item.usedArchiveUUID
    const usedArchiveName = item.usedArchiveName
    switch (item.usedArchiveTyped.value) {
    case 'Menu':
      navigate(`/menus/createMenu?menuId=${usedArchiveUUID}&menuName=${encodeURIComponent(usedArchiveName)}`)
      break;
    case 'MenuGroup':
      navigate(`/menus/createSubMenu?menuId=${usedArchive.archivePath[0].usedArchiveUUID}&menuName=${encodeURIComponent(usedArchive.archivePath[0].usedArchiveName)}&subMenuId=${usedArchiveUUID}&subMenuName=${encodeURIComponent(usedArchiveName)}`)
      break;

    default:
      break;
    }
  }
  useEffect(()=> {
    setMenuItemList(data.filter(x => x.archiveType.value === 'MenuItem'))
    setMenuGroupList(data.filter(x => x.archiveType.value === 'MenuGroup'))
    setModifierGroupsList(data.filter(x => x.archiveType.value === 'MenuOptionGroup'))
  }, [data])
  return (
    <Modal
      className="UsagePop"
      title={i18n.t('view_usage')}
      open={open}
      onOk={hide}
      onCancel={hide}
      key={itemName}
      footer={[
        <Button key={itemName} onClick={hide}>{i18n.t('menuDatabaseList_pc_ok')}</Button>
      ]}
    >
      <Row>
        <Col span={9} className="name">{i18n.t('item_name')}</Col>
        <Col className="info">{itemName}</Col>
      </Row>
      {menu ? <Row>
        <Col span={9} className="name">
          {i18n.t('used_in_menuItem', { x: menuItemList.length })}:
        </Col>
        <Col className="info">
          {
            menuItemList.map((item, index) => {
              return <span className="item" key={index + 'menuItemList'} onMouseMove={() => setUsedArchive(item)}>
                {usedItem(item.archivePath)}
                {menuItemList.length == index + 1 ? '' : '、'}
              </span>
            })
          }
        </Col>
      </Row> : null}
      {subMenu ? <Row>
        <Col span={9} className="name">
          {i18n.t('used_in_menuGroup', { x: menuGroupList.length })}:
        </Col>
        <Col className="info">
          {
            menuGroupList.map((item, index) => {
              return <span className="item" key={index + 'menuGroupList'} onMouseMove={() => setUsedArchive(item)}>
                {usedItem(item.archivePath)}
                {menuGroupList.length == index + 1 ? '' : '、'}
              </span>
            })
          }
        </Col>
      </Row> : null}
      {modifierGroups ? <Row>
        <Col span={9} className="name">
          {i18n.t('used_in_modifierGroup', { x: modifierGroupsList.length })}:
        </Col>
        <Col className="info">
          {
            modifierGroupsList.map((item, index) => {
              return <span className="item" key={index + 'modifierGroupsList'}>
                {usedItem(item.archivePath)}
                {modifierGroupsList.length == index + 1 ? '' : '、'}
              </span>
            })
          }
        </Col>
      </Row> : null}
    </Modal>
  )
}