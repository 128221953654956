import React, { useState, useEffect } from 'react';
import { Flex, Button } from 'antd';
import { MenuOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './index.scss';

interface DragListProps {
  list: any;
  name: string;
  valueKey?: string;
  edit?: boolean;
  archive?: boolean;
  activeItem?: string | number;
  remove?: boolean;
  handleEdit?: (event) => void;
  handleRemove?: (event) => void;
  onDragEnd: (active, over) => void;
  handleClick?: (event) => void;
}

function SortableItem (props: any) {
  const { id, item, edit = false, archive = false, remove = false, editItem, removeItem, handleClick = () => { console.log('//'); }, activeItem = '', valueKey = 'id' } = props;
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative' } : {}),
  };

  return (
    <div ref={ setNodeRef } style={ style } { ...attributes }>
      <Flex
        className={`drag-item ${activeItem === item[valueKey] ? 'active' : ''}`}
        justify="space-between"
        align="center"
        onClick={() => handleClick(item)}>
        <Flex className="left-info" align='center'>
          {item.icon && <span className={`iconfont img-box icon-${item.url ? '' : item.icon}`}>{item.url && <img src={item.url} />}</span>}
          <Flex className="text-box" vertical>
            {item.name && <div className='text s1'>{item.name}</div>}
            {item.info && <div className='text s2'>{item.info}</div>}
          </Flex>
        </Flex>
        <Flex className="btn-box" align='center'>
          {item.rightText && <Flex className="right-text">{item.rightText}</Flex>}
          <Flex onClick={(e) => e.stopPropagation()} align='center'>
            {edit && <Button type="link" icon={<EditOutlined />} onClick={() => editItem(item)} ></Button>}
            {archive && <Button type="link" icon={<i className="iconfont icon-archive1"></i>} onClick={() => removeItem(item)} ></Button>}
            {remove && <Button type="link" icon={<CloseCircleOutlined />} onClick={() => removeItem(item)} ></Button>}
            <Button
              className="drag-btn"
              type="link"
              icon={<MenuOutlined />}
              ref={setActivatorNodeRef}
              style={{ touchAction: 'none', cursor: 'move' }}
              {...listeners}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}

const DragList: React.FC<DragListProps> = (props) => {
  const { list, name, edit, archive, remove, activeItem, valueKey, onDragEnd, handleEdit, handleRemove, handleClick } = props;
  const [listData, setListData] = useState<any>([]);
  const onChange = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = listData.findIndex((i) => i.id === active.id);
      const overIndex = listData.findIndex((i) => i.id === over?.id);
      console.log(activeIndex, overIndex);
      setListData((previous) => {
        const newList = arrayMove(previous, activeIndex, overIndex);
        console.log(newList);
        onDragEnd(listData[activeIndex], listData[overIndex]);
        return newList;
      });
    }
  };

  useEffect(() => {
    setListData(list);
  }, [list]);

  return (
    <div className="drag-list">
      <DndContext modifiers={[restrictToVerticalAxis, restrictToParentElement]} onDragEnd={onChange}>
        <SortableContext items={listData.map(i => i.id)} strategy={verticalListSortingStrategy}>
          <div className='drag-box'>
            {listData && listData.map(item => (
              <SortableItem
                key={item.id}
                id={item.id}
                valueKey={valueKey}
                item={item}
                name={name}
                edit={edit}
                archive={archive}
                editItem={handleEdit}
                remove={remove}
                handleClick={handleClick}
                activeItem={activeItem}
                removeItem={handleRemove}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default DragList;
