import React, { useEffect, useState } from 'react';
import { globalState } from '@/stores';
import DragTable, { DragHandle } from '@/Components/DragTable';
import './index.scss'
import { Button, Popover, Modal, Form, Input, message } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
} from '@dnd-kit/sortable';
import { MoreOutlined } from '@ant-design/icons';
import ArchivedChange from '@/Pages/Menus/component/ArchivedChange';
import { queryAllSalesCategory, sortSalesCategory, batchArchiveSalesCategory, batchSaveSalesCategory, querySalesCategoryDetail } from '@/request/SalesCategories'
import { isStrictlyEmptyString } from '@/utils';

export default function SalesCategories () {
  const { i18n } = globalState;
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [filteredInfo, setFilteredInfo] = useState({ archived: [false] });
  const [salesCategoriesForm] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
  };
  const tableColumns: any = [
    { key: 'id', align: 'left', width: 10, render: () => <DragHandle /> },
    {
      key: 'name', title: i18n?.t('createMenuGroup_pc_menu_group'), align: 'left',
      render: (_, row) => {
        return (
          <div>
            {row?.name}
          </div>
        )
      }
    },
    {
      key: 'description', title: i18n?.t('createMenuItem_pc_item_description'), align: 'left',
      render: (_, row) => {
        return (
          <div>
            {row?.description}
          </div>
        )
      }
    },
    {
      dataIndex: 'archived', key: 'archived', title: i18n.t('amount_pc_status'),
      filters: [{ text: i18n.t('active'), value: false }, { text: i18n.t('archived'), value: true }],
      filteredValue: filteredInfo.archived || null,
      defaultFilteredValue: [false],
      filterResetToDefaultFilteredValue: true,
      width: 120,
      render: (data, row) => <ArchivedChange data={data} onChange={(val) => handleEditStatus(val, row)} />
    },
    {
      key: 'action', title: i18n?.t('action'), align: 'center', width: 50,
      render: (_, row) => {
        return (
          <Popover content={
            <Button type="link" onClick={ () => {
              handleEdit(row);
            } }>
              { i18n?.t('edit_1') }
            </Button>
          }>
            <Button type="link" icon={ <MoreOutlined/> }/>
          </Popover>
        )
      }
    },
  ];
  const [tableData, setTableData] = useState([]);

  const handleTableChange = (_pagination, _filters) => {
    setFilteredInfo(_filters)
  }

  const handleEditStatus = async (val, row) => {
    if (val === !!row.archived) return;
    const { data, message: msg } = await batchArchiveSalesCategory({
      idList: [row.id],
      archived: +val
    })
    if (data) {
      message.success(msg);
      fetchData();
    }
  }

  const fetchData = async () => {
    setLoading(true);
    const { data, success } = await queryAllSalesCategory({});
    if (success) {
      setTableData(data);
    }
    setLoading(false);
  }

  const handleClick = () => {
    setIsModalOpen(true);
    setIsEdit(false);
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const id = tableData.find(f => f.id === active.id).id;
      const targetSort = tableData.find(f => f.id === over.id).displayOrder;
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      const { code, message: msg } = await sortSalesCategory({
        id,
        targetSort
      })
      if (code === 0) {
        message.success(msg);
        fetchData();
      }
    }
  };

  const handleEdit = async (row) => {
    const { id } = row;
    setIsEdit(true);
    setIsModalOpen(true);
    const { data: [item] } = await querySalesCategoryDetail({ idList: [id] });
    setEditData(item);
    salesCategoriesForm.setFieldsValue({
      name: item.name,
      description: item.description
    })
  }

  const handleOk = async () => {
    const res = await salesCategoriesForm.validateFields();
    const req = {
      salesCategoryList: [{
        ...( isEdit ? { ['id']: editData.id } : {} ),
        ...{
          name: res.name,
          description: res.description,
          displayOrder: 1,
          archived: 0
        }
      }]
    }
    const { code, message: msg } = await batchSaveSalesCategory(req) || {};
    if (code === 0) {
      salesCategoriesForm.resetFields();
      setIsModalOpen(false);
      message.success(msg);
      fetchData();
    }
  }

  const handleCancel = () => {
    salesCategoriesForm.resetFields();
    setIsModalOpen(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='sales-categories-wrap'>
      <div className='sales-categories-head'>
        <div className='sales-categories-head-title'>
          {i18n.t('sales_categories_section')}
        </div>
        <div>
          <Button type='primary' onClick={() => {
            handleClick()
          }}>
            {i18n.t('dashboard_add_category')}
          </Button>
        </div>
      </div>
      <div className='sales-categories-body'>
        <DragTable
          loading={loading}
          onDragEnd={onDragEnd}
          rowKey={'id'}
          onChange={handleTableChange}
          columns={tableColumns}
          dataSource={tableData && tableData.filter(x => (filteredInfo.archived || []).includes(!!x.archived))}>
        </DragTable>
      </div>
      <Modal title={i18n?.t(isEdit ? 'sales_categories_edit_x' : 'sales_categories_add_x', { msg: i18n?.t('permission_category') })}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={i18n?.t('pc_modal_confirm')}
        cancelText={i18n?.t('cancel')}>
        <Form
          {...layout}
          form={salesCategoriesForm}
          validateTrigger='onSubmit'
          layout='vertical'
          labelAlign='left'
        >
          <Form.Item
            label={
              <div>
                {i18n?.t('createMenuGroup_pc_menu_group')}<span style={{ 'color': 'red' }}>*</span>
              </div>
            }
            name='name'
            style={{ 'marginBottom': '0px' }}
            rules={[
              () => ({
                validator: (_, value) => {
                  if (isStrictlyEmptyString(value)) {
                    return Promise.reject(i18n?.t('required_x', { msg: i18n?.t('createMenuGroup_pc_menu_group') }));
                  }
                  return Promise.resolve()
                }
              }),
            ]}
          >
            <Input maxLength={255} required onBlur={() => { salesCategoriesForm.validateFields(['name']) }} onChange={(e) => {
              salesCategoriesForm.setFieldValue('name', e.target.value.trim());
            }} />
          </Form.Item>
          <Form.Item
            label={
              <div>
                {i18n?.t('createMenuItem_pc_item_description')}
              </div>
            }
            name='description'
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}