// 定义 JSON Schema
export const previewSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: 'string' },
      hide: { type: 'boolean' },
      sort: {
        type: 'array',
        items: {
          type: 'array',
          items: { type: 'string' }
        }
      },
      children: {
        type: 'object',
        additionalProperties: {
          type: 'object',
          properties: {
            value: { type: 'string' },
            hide: { type: 'boolean' },
            modifier: {
              type: 'object',
              properties: {
                fontSize: { type: 'string' }
              },
            }
          },
          required: ['value', 'hide']
        }
      },
      modifier: {
        type: 'object',
        properties: {
          spacing: { type: 'string' },
          showOnCustomerCopyOnly: { type: 'boolean' },
          showOnMerchantCopyOnly: { type: 'boolean' },
        },
      }
    },
    required: ['id', 'hide', 'sort', 'children'],
    additionalProperties: false
  }
};
