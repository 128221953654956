import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, InputNumber, InputNumberProps, Row, Slider } from 'antd';
import { IRenderConfigProps } from '../../interface';
import { toJS } from 'mobx';
import printOutDecorationStore from '../../store'
import { globalState } from '@/stores';


export default observer(function RenderSliders (props: IRenderConfigProps<'slider'>) {
  const { i18n } = globalState

  const { content, sectionId } = props;
  const { defaultValue, modifier } = content;
  const { id, value, label, range } = defaultValue[0]

  const [inputValue, setInputValue] = useState<string>('1');


  const onChange: InputNumberProps['onChange'] = (newValue: string) => {
    setInputValue(newValue);

    // ***** update ConfigUI *****
    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)
    const sliderMapKey = modifier?.sliderMapKey || 'spacing'

    // ***** modifier previewData *****
    previewData = previewData.map(item => {

      if (item.id === sectionId) {
        // -> 代表是父级
        if (item.modifier && item.id === id) {
          item.modifier = {
            [sliderMapKey]: String(newValue)
          }
        } else {
          // -> 代表是父级中，里面的children
          if (item.children[id]) {
            item.children[id].modifier = {
              [sliderMapKey]: String(newValue)
            }
          }
        }
      }
      return item;
    })

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  };


  useEffect(() => {
    // ***** update ConfigUI *****
    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)

    previewData = previewData.map(item => {
      if (item.id === sectionId) {
        const sliderMapKey = modifier?.sliderMapKey || 'spacing'

        // -> 代表是父级
        if (item.modifier && item.id === id) {
          const spacingValue = item?.modifier?.[sliderMapKey] !== undefined ? String(item.modifier[sliderMapKey]) : value;
          setInputValue(spacingValue)
        } else {
          // -> 代表是父级中，里面的children
          if (item.children[id]) {
            const childrenModifier = item.children[id]?.modifier
            const spacingValue = childrenModifier?.[sliderMapKey] !== undefined ? String(childrenModifier[sliderMapKey]) : value;
            setInputValue(spacingValue)
          }
        }

      }
      return item;
    })

  }, [printOutDecorationStore.getPreviewData, sectionId])

  return (
    <div style={{ paddingLeft: 20, paddingBottom: 10 }}>
      <Row>{i18n?.t(label)}</Row>
      <Row>
        <Col span={18}>
          <Slider
            min={range[0] || 0}
            max={range[1] || 20}
            onChange={onChange}
            value={Number(inputValue) || 0}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={range[0] || 0}
            max={range[1] || 20}
            style={{ margin: '0 16px' }}
            value={inputValue}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  )})