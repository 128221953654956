import React, { useEffect, useState } from 'react';
import { Table, Flex, Input, Modal, message, Button } from 'antd'
import { globalState } from '@/stores';
import useGetState from '@/hooks/useGetState';
import { SearchOutlined } from '@ant-design/icons';
import Columns from '@/Components/Columns/Columns'
import { queryMenuList, archiveMenu, restoreMenuArchive } from '@/request/Menus/MenuDatabase'
import Edit from '../component/Edit'
import ArchivedChange from '../component/ArchivedChange'
import { formatTime } from '@/utils/index'
import { IPage, IFilter, ISorter, IGetList, IModifiersGroupsListParams, IComponentPage, IOrderBy, IMenusListParams } from './types';
import { useNavigate } from 'react-router-dom';
import { debounce } from '@/utils/index'

export default function Item ({ isActive, archiveType, sticky }) {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [activeTitle, setActiveTitle] = useState([])
  const [tableData, setTableData] = useState([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchArchived, setSearchArchived] = useState<boolean[]>([true, false])
  const [popTableData, setPopTableData] = useState<any>([])
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [archived, setArchived] = useState<boolean>()
  const [popLoading, setPopLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const tableColumns = [{
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('menuDatabaseList_pc_name'),
    width: 200,
    render:(name:string, row: any) => {
      return <a style={{ color: '#252525' }} onClick={() => gotoEdit(row)}>{name}</a>
    }
  }, {
    dataIndex: 'uuid',
    key: 'uuid',
    title: i18n.t('menuDatabaseList_pc_id'),
    width: 100,
    hide: true,
  }, {
    dataIndex: 'modifiedTime',
    key: 'modifiedTime',
    title: i18n.t('pc_menudatabase_updated'),
    width: 100,
    render: (data) => {
      return formatTime(data, 'MM/dd/yyyy')
    }
  }, {
    dataIndex: 'taxes',
    key: 'taxes',
    title: i18n?.t('taxes'),
    width: 100,
    render: (data) => {
      return (data ?? []).join(', ') || '-'
    }
  },
  {
    dataIndex: 'preStations',
    key: 'preStations',
    title: i18n?.t('print_gearOpening_bill_pc_prepStation'),
    width: 100,
    render: (data) => {
      return (data ?? []).join(', ') || '-'
    }
  }, {
    dataIndex: 'archived',
    key: 'archived',
    title: i18n.t('menuDatabaseList_pc_status'),
    width: 100,
    filters: [
      { text: i18n.t('active'), value: false },
      { text: i18n.t('archived'), value: true },
    ],
    render: (data, row) => <ArchivedChange data={data} onChange={(val) => editStatus(val, row)}/>
  },
  ]
  const [open, setOpen] = useState(false);
  const gotoEdit = (row) => {
    navigate(`/menus/createMenu?menuId=${encodeURIComponent(row.uuid)}&menuName=${encodeURIComponent(row.name)}&form=menuDatabase`)
  };
  const showModal = (val:boolean) => {
    if (!val) {
      return
    }

    setOpen(true);
  };

  const hideModal = () => {
    setPopTableData([])
    setPopLoading(false)
    setOpen(false);
  };
  const okModal = async () => {
    const list = popTableData.map(item => {
      return {
        archiveUUID: item.uuid,
        archiveType: archiveType
      }
    })
    setPopLoading(true)
    let res:any
    if (archived) {
      res = await archiveMenu({ params:{ archiveList: list } })
    } else {
      res = await restoreMenuArchive({ params:{ restoreArchive: list } })
    }
    if (res.code == 0) {
      getMenuList({})
    }
    setPopLoading(false)
    hideModal()
  };
  const [pager, setPager, getPager] = useGetState<IComponentPage>({
    current: 1, pageNo: 1, pageSize: 20, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const editStatus = (val, row) => {
    if (val === row.archived) {
      return
    }
    setPopTableData([row])
    setArchived(val)
    showModal(true)
  }
  const onChangeColumns = (list) => {
    setActiveTitle(list)
  }
  const handleTableChange = (pagination:any, filters:any, sorter:any) => {
    const page = {
      ...pager,
      current: pagination.current,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    }
    let { archived = [] } = filters;
    if (!archived || archived.length === 0) {
      archived = [true, false]
    }
    setSearchArchived(archived)
    getMenuList({ page, filters: { archived }, sorter })
  }
  const changeSearch = (e: any) => {
    debounceInput(e.target.value)
  }

  const onPressEnter = (value) => {
    const page = {
      ...pager,
      current: 1,
      pageNo: 1,
      pageSize: 20,
    }
    setSearchValue(value)
    getMenuList({ value, page })
  }
  const debounceInput = debounce(onPressEnter, 500)

  const changeEdit = (val) => {
    const arr = selectedRows.filter((item:any) => {
      return item.archived !== val
    })
    setArchived(val)
    setPopTableData(arr)
    showModal(arr.length > 0)
  }
  const getMenuList = async ({ value = null, page = pager, filters = {}, sorter = {} }: IGetList) => {
    const { archived = searchArchived } = filters;
    const params: IMenusListParams = {
      current: page.current,
      pageSize: page.pageSize,
      request: {
        keyWord: value !== null ? value : searchValue,
        archived: archived === null || archived.length > 1 ? null : archived[0],
      },
    };
    setLoading(true)
    const res = await queryMenuList(params)
    setLoading(false)
    if (res.code === 0) {
      setTableData(res.data)
      setSelectedRows([])
      setSelectedRowKeys([])
      setPager({
        ...pager,
        pageSize: page.pageSize,
        current: res.current,
        total: res.total
      })
    }
  }
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    if (isActive) {
      setActiveTitle(tableColumns.map(item => {
        if (item.hide !== true) return item.dataIndex
      }))
      getMenuList({})
    }

    return () => {
      setSearchValue('')
    }
  }, [isActive])
  return (
    <div>
      <Flex className="top">
        <Flex align="center" flex="1" gap="large" justify="space-between" className="search-box">
          <Input
            prefix={< SearchOutlined style={{ color: '#BEBEBE' }} />}
            className="search"
            placeholder={`${i18n.t('search')} ${i18n.t('menuDatabaseList_pc_menu')}`}
            onChange={changeSearch}
            maxLength={100}
            allowClear
          />
          <Flex gap="middle">
            <Edit onChange={changeEdit} disable={selectedRows.length == 0}></Edit>
            <Columns value={activeTitle} options={tableColumns.map(item => ({ label: item.title, value: item.dataIndex, hide: item.hide ? false : item.hide }))} onChange={onChangeColumns} />
          </Flex>
        </Flex>
      </Flex>
      <Table
        columns={ tableColumns.filter(x => {
          return activeTitle.includes(x.dataIndex)
        }) }
        rowSelection={rowSelection}
        dataSource={ tableData }
        pagination={ pager }
        onChange={ handleTableChange }
        loading={loading}
        rowKey="uuid"
        scroll={{ y: sticky }}
      />
      <Modal
        className="ArchivePop"
        title={archived ? i18n.t('archive') + ' item?' : i18n.t('active') + ' item?'}
        open={open}
        onOk={okModal}
        onCancel={hideModal}
        okText={i18n.t('confirm')}
        confirmLoading={popLoading}
        cancelText={i18n.t('cancel')}
      >
        <p className="text">{archived ? i18n.t('pc_archivepop') : i18n.t('pc_Activepop')}</p>
        <Table
          columns={[{
            dataIndex: 'name',
            key: 'name',
            title: i18n.t('menuDatabaseList_pc_name'),
          }]}
          scroll={{ y: sticky }}
          dataSource={popTableData}
          pagination={false}
        />
      </Modal>
    </div>
  )
}