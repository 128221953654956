import React from 'react'
import TextLineNew from '../component/TextLineNew'
import './index.scss'
import DashedLine from '../component/DashedLine'
const ServiceDetailSection = () => {
  return <div className='service-detail-section'>
    <TextLineNew text='Dine In' style={{
      fontSize: 'lg',
      fontWeight: 'bold'
    }}/>

    <DashedLine gap={{ 'margin': '16px 0px' }}/>
  </div>
}
export default ServiceDetailSection;