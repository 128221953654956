import { service } from '@/request/index'

export const listServiceAreas: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/listServiceAreas', { params });

export const unarchiveServiceArea: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/unarchiveServiceArea', { params });

export const archiveServiceArea: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/archiveServiceArea', { params });

export const queryServiceAreaDetail: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/queryServiceAreaDetail', { params });

export const addServiceArea: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/addServiceArea', { params });

export const saveServiceArea: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/saveServiceArea', { params });

export const sortServiceArea: any = params => service.post('rms/overseas/MerchantManagementConsole/FrontOfHouse/sortServiceArea', { params });