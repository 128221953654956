import React, { FC, useState } from 'react';
import { Button, Input, Form, FormInstance } from 'antd';
import { getVerificationCode } from '@/request/Account';
import ResetPassword from '../ResetPassword'
import { checkEmail } from '@/utils/index'
import './index.scss'
import { useI18n } from '@/hooks/useI18n'
export interface ForgotPasswordComponentProps {
    email: string
}
export const ForgotPassword: FC<ForgotPasswordComponentProps> = () => {
  let [form] = Form.useForm();
  const [isSend, setIsSend] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const { i18n } = useI18n();
  const layout = {
    labelCol: {
      span: 3
    },
    wrapperCol: {
      span: 14
    }
  }
  const jumpToLogin = () => {
    window.location.replace('/login');
  }
  const handleValidateEmail = async () => {
    const params = await form.validateFields(['email'])
    setEmail(params.email);
    const res = await getVerificationCode({
      email: params.email,
      type: 1 })
    if (res) {
      setIsSend(true);
    }
  }
  return !isSend ? <div className='forgot-password'>
    <div style={{ 'textAlign': 'center' }}>
      <span className='forgot-password-title'>{i18n?.t('login_pc_forget_password')}</span>
    </div>
    <span className='forgot-password-tips'>
      {i18n?.t('login_pc_please_provide_email')}
    </span>
    <div className="mterial-form">
      <Form
        {...layout}
        form={form}
        ref={(ref: FormInstance) => {
          form = ref;
        }}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="email"
          required
          rules={[
            () => ({
              validator: (_, value) => checkEmail(value, i18n),
            }),
          ]}
        >
          <Input required placeholder={i18n?.t('email')} onBlur={ () => form.validateFields(['email']) }/>
        </Form.Item>
        <Form.Item>
          <Button onClick={jumpToLogin}>{i18n?.t('login_pc_back_to')}</Button>
          <Button type='primary' onClick={handleValidateEmail}>{i18n?.t('validate_email')}</Button>
        </Form.Item>
      </Form>
    </div>
  </div> : <ResetPassword email={email} validateEmail={(email: string) => {
    getVerificationCode({
      email,
      type: 1
    })
  }}/>
}