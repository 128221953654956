import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { globalState } from '@/stores';
import { Flex, Button, message, Spin, Popover, Modal, Tag } from 'antd';
import DragList from '@/Components/DragList';
import { reSortUniversal, deleteRelations, searchMenuItem, addRelations } from '@/request/Menus';
import { queryMenuItemList } from '@/request/Menus/MenuDatabase';
import ItemNullBgi from '@/assets/item-null-bgi.png'
import { observer } from 'mobx-react';
import menuStore from '../store';
import AddModifierGroup from '../component/AddModifierGroup';
import { debounce } from '@/utils/index'
import { useNavigate } from 'react-router-dom';
import './index.scss';

const MenuItem = ({ ...props }) => {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const [menuItemList, setMenuItemList] = useState([])
  const [searchItemList, setSearchItemList] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const [inheritTableData, setInheritTableData] = useState<boolean>(true);


  const sortNotes = useCallback(async (active, over) => {
    const params = {
      sourceMenuNode: {
        sourceId: menuStore.getActiveSubMenu.uuid,
        sourceType: 'MenuGroup',
      },
      targetMenuNode: {
        sourceType: 'MenuItem',
        sourceId: active.uuid,
        displayOrder: over.displayOrder,
      },
    };
    setLoading(true);
    const res = await reSortUniversal({ params });
    setLoading(false);
    if (res.code === 0) {
      getMenuList();
      message.success('success');
    }
  }, [menuStore.getActiveSubMenu.uuid]);
  const createItem = () => {
    navigate(`/menus/createItem?subMenuId=${menuStore.getActiveSubMenu.uuid}&subMenuName=${encodeURIComponent(menuStore.getActiveSubMenu.name)}&menuName=${encodeURIComponent(menuStore.getActiveMenu.name)}&menuId=${menuStore.getActiveMenu.uuid}&pageType=item`)
  }
  const editItem = (item) => {
    navigate(`/menus/createItem?itemId=${item.uuid}&itemName=${encodeURIComponent(item.name)}&subMenuId=${menuStore.getActiveSubMenu.uuid}&subMenuName=${encodeURIComponent(menuStore.getActiveSubMenu.name)}&type=MenuItem&menuName=${encodeURIComponent(menuStore.getActiveMenu.name)}&menuId=${menuStore.getActiveMenu.uuid}&pageType=item`)
  }
  const addExistingItem = () => {
    setSelectedItems([...menuItemList])
    setOpen(true)
  }
  const hide = () => {
    setSelectedItems([])
    setOpen(false)
  }
  // const addSet = () => {
  //   console.log('//');
  // }
  const deleteItem = async (item) => {
    const params = {
      targets: [
        {
          sourceType: {
            displayName: 'MenuItem',
            value: 'MenuItem'
          },
          sourceId: item.uuid
        }
      ],
      source: {
        sourceType: {
          displayName: 'MenuGroup',
          value: 'MenuGroup'
        },
        sourceId: menuStore.getActiveSubMenu.uuid
      }
    }
    setLoading(true)
    const res = await deleteRelations({ params })
    setLoading(false)
    if (res.code === 0) {
      getMenuList()
    }
  }
  const searchItem = async (value: string) => {
    const params = {
      req: {
        combo: false,
        side: false,
        name: value
      }
    }
    const res = await searchMenuItem({ params })
    if (res.code === 0 && res.data != null) {
      setSearchItemList(res.data)
    }
  }
  const onSelectedInfo = (row) => {
    // 首先，分离出继承的数据和非继承的数据
    const inheritData = menuItemList?.filter(item => item.isInherit);

    // 过滤掉row中继承的数据
    const filteredRow = row?.filter(r => !inheritData.some(i => i.uuid === r.uuid));

    // 更新tableData，保留继承的数据，非继承的数据更新为过滤后的row
    setSelectedItems([...filteredRow, ...inheritData]);

    // setSelectedItems(row)
  }
  const saveNewItem = async () => {
    const uuidSet = new Set(menuItemList.map(item => item.uuid));
    // 已选和原有items数据取差集
    const difference = selectedItems.filter(item => !uuidSet.has(item.uuid));
    if (difference.length == 0) {
      hide()
      return
    }
    const itemsList = difference.map(item => {
      return ({
        sourceType: {
          displayName: 'MenuItem',
          value: 'MenuItem'
        },
        sourceId: item.uuid
      })
    })
    const params = {
      targets: itemsList,
      source: {
        sourceType: {
          displayName: 'MenuGroup',
          value: 'MenuGroup'
        },
        sourceId: menuStore.getActiveSubMenu.uuid
      }
    }
    setLoading(true)
    const res = await addRelations({ params })
    setLoading(false)
    if (res.code === 0) {
      hide()
      getMenuList()
    }
  }
  const removeItems = (index) => {
    selectedItems.splice(index, 1)
    setSelectedItems([...selectedItems])
  }
  const debounceInput = useMemo(() => debounce(searchItem, 500), []);
  const inputItem = useCallback((e) => {
    debounceInput(e.searchQuery);
  }, [debounceInput]);
  useEffect(()=> {
    if (!!menuStore.getActiveSubMenu.uuid) {
      getMenuList()
    }
    return () => {
      setMenuItemList([])
    }
  }, [menuStore.getActiveSubMenu.uuid])
  const getMenuList = async () => {
    const params = {
      current: 1,
      pageSize: 9999,
      orderBy: null,
      request: {
        archived: false,
        keyWord: null,
        uuid: menuStore.getActiveSubMenu.uuid,
      }
    }
    setLoading(true)
    const res = await queryMenuItemList(params)
    setLoading(false)
    const { data = [] } = res
    if (res.code === 0 && data.length > 0) {
      const list = data.map((item, index) => {
        item.id = item.uuid + index
        item.icon = 'dinner'
        item.url = item.image || null
        const info = item.modifierGroupDTOS ? item.modifierGroupDTOS.map(i => `${i.name}(${i.modifierCount})`) : []
        item.info = info.join(', ')
        item.rightText = item.basePrice == null ? '' : typeof item.basePrice === 'number' ? `$${item.basePrice.toFixed(2)}` : `$${item.basePrice}`
        item.isInherit = true
        return item
      })
      setMenuItemList(list)

      setInheritTableData(list)
    } else {
      setMenuItemList([])
    }
  }
  return <Spin size="large" spinning={loading}>
    <Flex className="top" justify="space-between" align="center" style={{ paddingBottom: 10 }}>
      <div className="title" style={{ fontSize: '18px', fontWeight: '600', flex: '1 1' }}>{menuStore.getActiveSubMenu.name}</div>
      <Flex className="btn-box" gap="large">
        <Popover
          arrow={false}
          placement="bottomLeft"
          content={
            <Flex justify='flex-start' vertical>
              <a className='a-btn' onClick={createItem}>{i18n.t('add_new_item')}</a>
              <a className='a-btn' onClick={addExistingItem}>{i18n.t('add_existing_item')}</a>
            </Flex>
          }
        >
          <a className='a-btn'>{i18n.t('pc_add_item')} <i className='iconfont icon-DownOutlined'></i></a>
        </Popover>
      </Flex>
    </Flex>
    {menuItemList.length > 0 ?
      <div className="menu-item-list">
        <div className="list-box">
          <DragList
            list={menuItemList}
            name="name"
            edit
            remove
            handleEdit={editItem}
            handleRemove={deleteItem}
            onDragEnd={sortNotes}
          />
        </div>
      </div> : (<div className="null-box">
        <div className="null-item">
          <div className="bgi s2"><img src={ItemNullBgi} alt="" /></div>
          <div className="text s2">{i18n.t('pc_createItem_tips')}</div>
          <Button onClick={createItem}>{i18n.t('create_item')}</Button>
        </div>
      </div>)
    }
    <Modal
      className="existing-pop"
      title={i18n.t('menuTabList_pc_selected_items')}
      open={open}
      onOk={hide}
      onCancel={hide}
      footer={[
        <Button key='cancel' onClick={hide}>{i18n.t('cancel')}</Button>,
        <Button loading={loading} key='ok' type='primary' onClick={saveNewItem}>{i18n.t('confirm')}</Button>
      ]}
    >
      <AddModifierGroup
        type='itemGroup'
        dataList={searchItemList}
        defaultSelectionRow={selectedItems}
        onGetSearchInfo={inputItem}
        onSelectedInfo={onSelectedInfo}
        isShowSearchType={false}
        rowKey='uuid'
      />
      <div className="selected-name">
        {i18n.t('menuTabList_pc_selected_items')}({selectedItems.length})
      </div>
      <Flex gap="small" wrap>
        {selectedItems.map((item, index) => {
          return <Tag key={item.uuid} bordered={false} onClose={() => removeItems(index)} closable={item.isInherit !== true}>{item.name}</Tag>
        })}
      </Flex>
    </Modal>
  </Spin>
}
export default observer(MenuItem)