import React, {  useState } from 'react';
import { Popover } from 'antd'
import { globalState } from '@/stores';
import './ArchivedChange.scss'
import { DownOutlined } from '@ant-design/icons';

export default function ArchivedChange ({ onChange, data, canEdit = true }) {
  const { i18n } = globalState;
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const option = [
    { value: true, label: <div><span className="point archived"></span>{i18n.t('archived')}</div> },
    { value: false, label: <div><span className="point active"></span>{i18n.t('active')}</div> },
  ]
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const content = (
    <div onClick={hide}>
      <div className="pointer status-item" onClick={() => onChange(true)}>
        <span className="point archived"></span>{i18n.t('archived')}
      </div>
      <div className="pointer status-item" onClick={() => onChange(false)}>
        <span className="point active"></span>{i18n.t('active')}
      </div>
    </div>
  )
  return (
    canEdit ? <Popover
      content={ content }
      trigger="click"
      title=""
      open={open}
      placement="bottomRight"
      onOpenChange={handleOpenChange}
      arrow={false}
    >
      <div className="popover-btn pointer">
        {!!data === option[0].value ? option[0].label : option[1].label}
        <DownOutlined />
      </div>
    </Popover> : (
      <div className="popover-btn">
        { !!data === option[0].value ? option[0].label : option[1].label }
      </div>
    )
  )
}