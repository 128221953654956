import React, { useState } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import Settings from './Settings/Settings';
import './index.scss'

export default function Notes () {
  const { i18n } = globalState;
  const [tabValue, setTabValue] = useState({
    key: '1',
    label: i18n.t('order_remarks'),
  })
  const tabsItems = [
    {
      key: '1',
      label: i18n.t('order_remarks'),
    },
    {
      key: '2',
      label: i18n.t('refund_reason'),
    },
    {
      key: '3',
      label: i18n.t('void_reason'),
    }
  ]
  const changeTab = (value) => {
    tabsItems.forEach(items => {
      if (items.key === value) {
        setTabValue(items);
      }
    })
  }
  const tabBarExtraContent = {
    left: <div className="main-tab-title">{ i18n.t('overseas_peppr_entrance_notes') }</div>,
  }
  return (
    <div className="front-of-house-notes-wrap">
      <Tabs tabBarExtraContent={ tabBarExtraContent } defaultActiveKey='1' onChange={changeTab} items={tabsItems}></Tabs>
      <Settings tabValue={tabValue} />
    </div>
  )
}