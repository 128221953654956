import React from 'react';
import './index.scss'
import { globalState } from '@/stores';
import { formatFullPrice } from '@/utils';
import { SummaryInfo } from '../index';

interface IProps {
  item: Partial<SummaryInfo>
}

export default function SummaryContent (props: IProps) {
  const { i18n } = globalState;
  const { item = {} } = props;
  const { totalAmount, totalTransactionCounts, totalFees } = item;

  const dataList = [
    { title: i18n.t('pc_sales_summary_total_amount'), value: formatFullPrice(totalAmount) },
    { title: i18n.t('fee_total_transaction'), value: totalTransactionCounts ?? 0 },
    { title: i18n.t('total_fees'), value: formatFullPrice(totalFees) },
  ]
  const amountItemRender = (item, key) => {
    return (
      <div className="item-wrap" key={ key }>
        <div className="top">
          <div className="title">{ item.title }</div>
        </div>
        <div className={ 'amount' }>{ item.value }</div>
      </div>
    )
  }

  return (
    <div className="fee-report-summary-content">
      { dataList.map((x, i) => amountItemRender(x, i)) }
    </div>
  )
}