import React, { useState, useEffect } from 'react';
import { Input, Tabs } from 'antd'
import printOutDecorationStore from '../../store'
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { IRenderConfigProps } from '../../interface';


export default observer(function RenderInput (props: IRenderConfigProps<'input'>) {
  const { TextArea } = Input;
  const { sectionId, content } = props

  const [inputValue, setInputValue] = useState<string>('')
  const [disabledInput, setDisabledInput] = useState<boolean>(false)
  const [placeholder, setPlaceholder] = useState<string>('')

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    const moduleId = content.defaultValue[0]?.id || ''

    // ***** get previewData *****
    let previewData = toJS(printOutDecorationStore.getPreviewData)

    // ***** modifier previewData *****
    previewData = previewData.map(item => {
      if (item.id === sectionId && moduleId) {
        item.children[moduleId].value = e.target.value

        return { ...item };
      }
      return item;
    });

    // ***** update previewData *****
    printOutDecorationStore.savePreviewData(previewData)
  };

  useEffect(() => {
    const moduleId = content.defaultValue[0]?.id || ''
    // 当前值
    const previewData = toJS(printOutDecorationStore.getPreviewData);
    previewData.forEach(item => {
      if (item.id === sectionId) {
        setInputValue(item.children[moduleId]?.value || '')
        setDisabledInput(item.hide)
      }
    })

    // setPlaceholder
    setPlaceholder(content.defaultValue[0].value || '')

  }, [content, printOutDecorationStore.getPreviewData, sectionId]);

  return (
    <div style={{ paddingLeft: 20, paddingBottom: 10 }}>
      <TextArea
        size="large"
        showCount
        disabled={disabledInput}
        maxLength={300}
        value={inputValue}
        placeholder={inputValue ? '' : placeholder}
        onChange={onChange}
      />
    </div>
  );
})