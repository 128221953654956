import React from 'react';
export function ArchivedPointIcon () { return <svg xmlns="http://www.w3.org/2000/svg" fill="#2863e5" version="1.1" width="6" height="5.835261344909668" viewBox="0 0 6 5.835261344909668"><g><ellipse cx="3" cy="2.917630672454834" rx="3" ry="2.917630672454834" fill="#999999" fillOpacity="1"/></g></svg> }
export function ActivePointIcon () { return <svg xmlns="http://www.w3.org/2000/svg" fill="#2863e5" version="1.1" width="6" height="5.835261344909668" viewBox="0 0 6 5.835261344909668"><g><ellipse cx="3" cy="2.917630672454834" rx="3" ry="2.917630672454834" fill="#14AF00" fillOpacity="1"/></g></svg> }
export function ArchivedIcon () {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" viewBox="0 0 16 16">
    <g>
      <g><path d="M8.29289,2.292893C8.105360000000001,2.105357,7.851,2,7.58579,2L3,2C2.447715,2,2,2.447715,2,3L2,10L3,10L3,3L7.58579,3L8.58579,4L13,4L13,13L9,13L9,14L13,14C13.5523,14,14,13.5523,14,13L14,4C14,3.44772,13.5523,3,13,3L9,3L8.29289,2.292893ZM4,5L12,5L12,6L4,6L4,5Z" fillRule="evenodd" fill="#2563eb" fillOpacity="1"/></g>
      <g><path d="M5.632815,11.810226484375L3.972732,10.150146484375L3.265625,10.857253484375L4.9121749999999995,12.503696484375L3.265625,14.150146484375L3.972732,14.857256484375L5.632815,13.197176484375L7.292895,14.857256484375L8.000005,14.150146484375L6.353445,12.503696484375L8.000005,10.857253484375L7.292895,10.150146484375L5.632815,11.810226484375Z" fillRule="evenodd" fillOpacity="1" fill="#2563eb"/></g>
    </g>
  </svg> }
export function ActiveIcon () {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" viewBox="0 0 16 16">
    <g>
      <g><path d="M8.29289,2.292893C8.105360000000001,2.105357,7.851,2,7.58579,2L3,2C2.447715,2,2,2.447715,2,3L2,10L3,10L3,3L7.58579,3L8.58579,4L13,4L13,13L9,13L9,14L13,14C13.5523,14,14,13.5523,14,13L14,4C14,3.44772,13.5523,3,13,3L9,3L8.29289,2.292893ZM4,5L12,5L12,6L4,6L4,5Z" fillRule="evenodd" fill="#2563eb" fillOpacity="1"/></g>
      <g><path d="M7.28288,12.079365912475586L5.3535900000000005,10.150075912475586L4.64648,10.857182912475587L5.79297,12.003595912475586L2,12.003595912475586L2,13.003595912475586L5.7930399999999995,13.003595912475586L4.64648,14.150075912475586L5.3535900000000005,14.857185912475586L7.28288,12.927895912475586L7.35231,12.844795912475586C7.5143,12.610955912475585,7.49116,12.287645912475586,7.28288,12.079365912475586Z" fillRule="evenodd" fillOpacity="1" fill="#2563eb"/></g>
    </g>
  </svg> }
export function CheckIcon () {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="18" height="18" viewBox="0 0 18 18">
    <defs>
      <clipPath id="master_svg0_8817_53267">
        <rect x="0" y="0" width="16" height="16" rx="0"/>
      </clipPath>
    </defs>
    <g clipPath="url(#master_svg0_8817_53267)">
      <g>
        <path d="M6.9,14.175L1.5,8.85L2.55,7.8L6.9,12.15L15.225,3.75L16.275,4.8L6.9,14.175Z" fill="#2563eb" fillOpacity="1"/>
      </g>
    </g>
  </svg> }