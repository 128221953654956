import React, { useImperativeHandle, useEffect, useState, forwardRef } from 'react';
import './PreModifier.scss';
import { globalState } from '@/stores';
import { Form, Radio, Space, Typography } from 'antd';
import { IPreModifierGroupItem } from '@/Pages/PreModifier/interface';
import { ApiGetPreModifierGroupList } from '@/request/PreModifier';

export const PreModifier = forwardRef((props: any, ref) => {
  const { i18n } = globalState;
  const { Paragraph } = Typography;
  const [form] = Form.useForm();
  const { editInfo } = props;
  const [activePreModifierGroup, setActivePreModifierGroup] = useState<IPreModifierGroupItem[]>([]);

  useImperativeHandle(ref, () => {
    return {
      form,
      resetForm
    };
  }, []);

  const resetForm = () => {
    form.resetFields();
    form.setFieldsValue({ relatedPreModGroupId : null });
  }

  useEffect(() => {
    const fetchPreModifierGroups = async () => {
      try {
        const res = await ApiGetPreModifierGroupList({ archived: false });
        if (res.code === 0) {
          setActivePreModifierGroup(res.data || []);
        }
      } catch (error) {
        console.error('Failed to fetch pre-modifier groups:', error);
      }
    };

    fetchPreModifierGroups();
  }, []);


  useEffect(() => {
    if (editInfo) {
      const { relatedPreModGroupId } = editInfo;
      form.setFieldsValue({ relatedPreModGroupId : relatedPreModGroupId || null });
    }
  }, [editInfo])

  return (
    <div className='create-modifier-pre-modifier'>
      <Paragraph strong>{i18n.t('dashboard_menu_pre_modifier')}</Paragraph>
      <Form
        form={form}
        name="general_info_form"
        layout="vertical"
      >
        <Form.Item name="relatedPreModGroupId">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={null}>{i18n.t('dashboard_menu_pre_modifier_default_option')}</Radio>
              {
                activePreModifierGroup.map(item => (
                  <Radio value={item.uuid} key={item.uuid}>
                    {item.name}: {item.preModifiers.map(preModifier => preModifier.name).join(', ')}
                  </Radio>
                ))
              }
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  )
})