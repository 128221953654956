export const enum ArgumentsEnum {
  'ITEM-INFO-SECTION-DISHLINESPACING' = 'item-info-section-dishLineSpacing',
  'ITEM-INFO-SECTION-ITEMNOTES' = 'item-info-section-itemNotes',
  'ITEM-INFO-SECTION-CHECKNOTES' = 'item-info-section-checkNotes',
  'ITEM-INFO-SECTION-GROUPBYITEM' = 'item-info-section-groupByItem',
  'ITEM-INFO-SECTION-ITEMDISH' = 'item-info-section-itemDish',
  'ITEM-INFO-SECTION-ITEMDISHMODIFIER' = 'item-info-section-itemDishModifier',
}


export const fontSizeMapValue = {
  1: '14',
  2: '16',
  3: '18',
  4: '20',
  5: '22',
  6: '26',
  7: '30',
  8: '34',
  9: '38',
  10:'42',
};