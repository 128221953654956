import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, Modal, Table } from 'antd';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { globalState } from '@/stores';
import Loading from '@/utils/loading';
import { toRes } from '@/utils';
import { ApiGetRoleRelateEmployeeList, ApiUnbindRoleRelateEmployee } from '@/request/api';
import { ReactComponent as DelIcon } from '@/assets/svg/del.svg'
import './index.scss'

export default function ModalRoleRelateEmployeeList (props: {
  roleId: number;
  close: () => void;
  fetchData: () => void;
  item: any
}) {
  const { i18n } = globalState;

  const [title, setTitle] = useState<string>('Employees')
  const [visible, setVisible] = useState<boolean>(true);

  const [form] = Form.useForm()
  const paramsRef = useRef({ keyword: '' })

  const [tableData, setTableData] = useState([])
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchTableData();
  }, []);

  const tableColumns = [
    { dataIndex: 'firstName', key: 'firstName', title: i18n.t('employeeAdd_pc_first_name'), width: 100, render: (_, row) => row.employee.firstName },
    { dataIndex: 'lastName', key: 'lastName', title: i18n.t('myAccount_pc_last_name'), width: 100, render: (_, row) => row.employee.lastName },
    { dataIndex: 'email', key: 'email', title: 'Email', width: 100, render: (_, row) => row.employee.email },
    {
      key: 'actions', title: i18n.t('table_actions'), width: 50,
      render: (_, row) => {
        return (<div className='colActions'>
          <Button disabled={props?.item?.roleTag === 1} type="link" className='btnDisconnect' onClick={ () => handleDisconnect(row) } icon={<DelIcon/>}>
            { i18n.t('employeeList_pc_disconnect') }
          </Button>
        </div>)
      }
    },
  ]

  const fetchTableData = async ({ current, pageSize }: { current?: number, pageSize?: number } = {}) => {
    const params = {
      request:{
        keyword: paramsRef.current.keyword,
        roleId: props.roleId,
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    setLoading(true)
    const [_, data] = await toRes(ApiGetRoleRelateEmployeeList(params));
    setLoading(false)
    setTableData(data?.list ?? [])
    setPager((prev) => ({
      ...prev,
      pageSize: pageSize ?? prev.pageSize,
      total: data?.total ?? 0,
      current: current ?? data?.current
    }))
    setTitle(i18n.t('employeeList_pc_employees') + ((data?.total ?? 0) > 0 ? ` ( ${ data?.total } )` : ''))
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  }

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  }

  const handleDisconnect = async (row) => {
    Loading.show();
    const [err] = await toRes(ApiUnbindRoleRelateEmployee({ employeeId: row.employee.employeeId, roleId: props.roleId }))
    if (!err) {
      message.success(i18n.t('message_success'));
      fetchTableData();
    }
    Loading.hide();
  }

  const handleOk = () => {
    props.fetchData();
    setVisible(false);
    props.close();
  }

  return (
    visible && <Modal
      width={ 800 }
      title={ title }
      visible={ visible }
      onCancel={ handleOk }
      onOk={ handleOk }
    >
      <div className='modalRoleRelateEmployeeList'>
        <div className='filterWrap'>
          <Form form={ form } layout="inline">
            <Form.Item name={ 'keyword' }>
              <Input.Search placeholder={ i18n.t('enter_x', { msg: '' }) } allowClear onSearch={ handleSearch } style={ { width: 200 } }/>
            </Form.Item>
          </Form>
        </div>
        <div className='tableWrap'>
          <Table
            loading={ loading }
            columns={ tableColumns } dataSource={ tableData }
            pagination={ pager }
            scroll={ { x: 'max-content' } }
            onChange={ handleTableChange }
            locale={ { emptyText: TableEmptyContent } }
          />
        </div>
      </div>
    </Modal>
  )
}