import React, { useEffect, useState } from 'react';
import TextLineNew from '../component/TextLineNew';
import './index.scss'
interface IChildren {
  [key: string]: {
    value: string,
    hide: boolean
  }
}
interface IProps {
  id: string,
  hide: boolean,
  sort: Array<string[]>,
  children: IChildren,
}
const PrintInfoSection = (props: IProps) => {
  const [children, setChildren] = useState<IChildren>(null);
  useEffect(() => {
    const { children } = props;
    setChildren(children);
  }, [props])

  return <div className='print-info'>
    <div className='print-info-item'>
      <div className='print-info-item-title'>
        <TextLineNew text='Employee:' />
      </div>
      <div className='print-info-item-value'>
        {children && <TextLineNew text={children['print-info-section-name']?.value} />}
      </div>
    </div>
    <div className='print-info-item'>
      <div className='print-info-item-title'>
        <TextLineNew text='Print time:' />
      </div>
      <div className='print-info-item-value'>
        {children && <TextLineNew text={children['print-info-section-time']?.value} />}
      </div>
    </div>
  </div>
}
export default PrintInfoSection;