import { service } from '@/request/index'

// 保存 pre modifier
export const ApiSavePreModifierGroup: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/PreModifierGroup/save', params);
export const ApiUpdatePreModifierGroup: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/PreModifierGroup/update', params);

// 获取 list
export const ApiGetPreModifierGroupList: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/PreModifierGroup/list', params);

// active and archive
export const ApiArchivePreModifierGroup: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/PreModifierGroup/archive', params);
export const ApiActivePreModifierGroup: any = (params) => service.post('rms/overseas/MerchantManagementConsole/Menu/PreModifierGroup/active', params);


