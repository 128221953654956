import { ConfigTypeValue, IBaseDefaultMapData, ITag } from '../interface';
import { defaultZReportMapData } from '../defaultJsonData/defaultZReport';
import { defaultShiftReportMapData } from '../defaultJsonData/defaultShiftReport';
import { defaultRefundMapData } from '../defaultJsonData/defaultRefund';
import { defaultCheckMapData } from '../defaultJsonData/defaultCheck';
import { defaultReceiptMapData } from '../defaultJsonData/defaultReceipt'
import { defaultKitchenMapData } from '../defaultJsonData/defaultKitchen';

type ConfigDataMapOptions = Record<ConfigTypeValue, Array<IBaseDefaultMapData<ITag>>>;

// 根据url中的type去映射不同的数据
export const configDataOptions: ConfigDataMapOptions = {
  'Check': defaultCheckMapData,
  'Receipt': defaultReceiptMapData,
  'Kitchen': defaultKitchenMapData,
  'ZReport': defaultZReportMapData,
  'ShiftReport': defaultShiftReportMapData,
  'Refund': defaultRefundMapData,
}
