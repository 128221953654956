import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Button, message } from 'antd';
import { globalState } from '@/stores';
import './index.scss';
import BreadCrumb from '@/Pages/Menus/component/BreadCrumb'
import GeneralInfo from '../component/GeneralInfo'
import UsageCard from '../component/UsageCard';
import Setting from './Setting';
import ModifierTable from './ModifierTable';
import { useNavigate } from 'react-router-dom';
import { ApiQueryMenuOptionGroups, ApiQuickSaveModifierGroup, ApiViewArchiveUsage } from '@/request/Menus/CreateModifier';
import { SettingEnum } from './enum';
import { PreModifier } from './PreModifier';
interface IProps {
  pageMode?: 'popup' | 'page',
  sourceType?: string,
  subMenuId?: string,
  modifierGroupId?: string,
  handleCancel?: () => void,
  handleSave?: () => void
}

const CreateModifier = forwardRef((props: IProps, ref) => {
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [editMenuDetail, setEditMenuDetail] = useState<any>({});
  const [options, setOptions] = useState<any[]>([]);
  const [modifierTable, setModifierTable] = useState<any[]>([]);
  const [detail, setDetail] = useState<any>({});
  const [usageList, setUsageList] = useState<any[]>([]);

  // ** ref 定义 **
  const refGeneralInfo = useRef(null);
  const refSetting = useRef(null);
  const refPreModifier = useRef(null);

  const { i18n } = globalState;
  const navigate = useNavigate();

  useImperativeHandle(ref, () => {
    return {
      save,
      resetData
    };
  }, [options]);

  const getRequestParams = (generalInfo, settingInfo, list, preModifierInfo) => {
    const { requiredModeType, multiSelect, minSelections, maxSelections, duplicateModifiersEnabled } = settingInfo;
    const defaultSetting = {
      combo: false,
      duplicateModifiersEnabled: false,
      existMaxSelections: false,
    }
    let _minSelections = 0;
    let _duplicateModifiersEnabled = false
    if (requiredModeType && !multiSelect) { // 如果是选中了required并且multiple selection为no
      _minSelections = 1;
    } else {
      _minSelections = !minSelections ? 1 : minSelections;
    }
    if (multiSelect) { // Multiple selections选中了true
      _duplicateModifiersEnabled = duplicateModifiersEnabled;
    }
    minSelections === undefined ? 1 : minSelections;
    const newDetail = {
      ...defaultSetting,
      ...detail,
      ...generalInfo,
      ...preModifierInfo,
      multiSelect,
      minSelections: _minSelections,
      maxSelections,
      duplicateModifiersEnabled: _duplicateModifiersEnabled,
      existMaxSelections: !maxSelections === undefined,
      pricingMode: {
        displayName: 'Separate',
        value: 'INDIVIDUAL'
      },
      requiredModeType: requiredModeType ? {
        displayName: SettingEnum.REQUIRED,
        value: 'REQUIRED',
      } : {
        displayName: SettingEnum.OPTIONAL,
        value: 'OPTIONAL'
      },
      options: list
    }
    return newDetail;
  }

  const save = async () => {
    const list = options.length ? options.map(m => {
      const { name, foreignName, menuItemGuid, uuid, modifierPrice, type } = m;
      return {
        ...(uuid && type !== 'defaultModifier' ? { ['uuid']: uuid } : {}),
        ...(menuItemGuid ? { ['menuItemGuid']: menuItemGuid } : {}),
        name,
        foreignName,
        modifierPrice
      }
    }) : [];

    const generalInfo = await refGeneralInfo.current.form.validateFields();
    const settingInfo = refSetting.current.form.getFieldsValue();
    const { requiredModeType, minSelections } = settingInfo;
    const preModifierInfo = refPreModifier.current.form.getFieldsValue();


    if (list.length && list.filter(fil => !fil.name).length > 0) { // 如果modifier group有值并且有一项name为空
      message.error(i18n?.t('please_enter_x', { msg: i18n?.t('menu_modifier_name') }));
      return;
    }
    if (requiredModeType && list.length === 0) { // 如果选择了required并且modifier group的长度为0
      message.error(i18n?.t('createModifierGroup_pc_modifier_required'))
      return null;
    }
    if (requiredModeType && list.length < minSelections) {
      message.error(i18n?.t('createModifierGroup_pc_minSelections'))
      return null;
    }
    const request = getRequestParams(generalInfo, settingInfo, list, preModifierInfo);
    const { code, data, message: msg } = await ApiQuickSaveModifierGroup({
      params: {
        group: request
      }
    })
    if (code === 0) {
      props.handleSave && props.handleSave();
      props.pageMode !== 'popup' && navigate(-1);
      props.pageMode === 'popup' && resetData();
      message.success(msg)
    }
    setSaveButtonLoading(false);
    return data;
  }
  const initUsage = async () => {
    const { code, data } = await ApiViewArchiveUsage({
      params: {
        archive: {
          archiveType: 'MenuOptionGroup',
          archiveUUID: props.modifierGroupId
        }
      }
    })
    if (code === 0) {
      setUsageList(data);
    }
  }
  const initData = async () => {
    const { code, data } = await ApiQueryMenuOptionGroups({
      params: {
        node: {
          sourceId: props.modifierGroupId,
          sourceType: {
            displayName: 'MenuOptionGroup',
            value: 'MenuOptionGroup'
          }
        }
      }
    })
    if (code === 0 && !!data.length) {
      const item = data[0];
      const { name, foreignName, shortName, relatedPreModGroupId } = item;
      setModifierTable([...item.options.map(m => ({ ...m, type: 'existingModifier' }))]);
      setDetail(item);
      refGeneralInfo.current.form.setFieldsValue({
        name,
        foreignName,
        shortName
      })
      refSetting.current.initSetting(item, item.options.length);
      refPreModifier.current.form.setFieldsValue({
        relatedPreModGroupId
      });
    }
  }
  const resetData = () => {
    refGeneralInfo.current.form.resetFields();
    refSetting.current.resetForm();
    refPreModifier.current.resetForm();
    setDetail({});
    setModifierTable([]);
    setUsageList([]);
  }
  useEffect(() => {
    resetData();
    if (props.modifierGroupId) {
      initData();
      initUsage();
    }
  }, [props.modifierGroupId])
  return (
    <div className='create-modifier'>
      {
        props.pageMode !== 'popup' && <BreadCrumb
          slotAction={
            <div>
              <Button style={{ width: 88, marginRight: 15 }} onClick={() => {
                navigate(-1);
              }}>{i18n.t('cancel')}</Button>
              <Button style={{ width: 88 }} type='primary' onClick={save} loading={saveButtonLoading}>{i18n.t('save')}</Button>
            </div>
          }
          needConfirm
          customTitle={[{
            title: i18n.t('overseas_peppr_entrance_menu_builder'),
            router: '/menus/menuBuilder'
          }, {
            title: i18n.t('createModifierGroup_pc_create_modifier'),
          }]}
        />
      }
      <GeneralInfo
        title={i18n?.t('edit_modifier_modifier_group_info')}
        ref={refGeneralInfo}
        editInfo={editMenuDetail}
      />
      <ModifierTable resetOption={setOptions} options={modifierTable} />
      <Setting ref={refSetting} maxLength={options.length || 0} />
      <PreModifier ref={refPreModifier} editInfo={editMenuDetail} />
      {
        props.modifierGroupId && <UsageCard list={usageList} tip={i18n.t('used_in_menuItem', { x: usageList.length })}/>
      }
    </div>
  )
})

export default CreateModifier;
