import axios from 'axios';
import * as Sentry from '@sentry/react';
import builtInDataKey from '../multiLanguage/languageKey.json';
import builtInData_en from '../multiLanguage/en-US.json';
import builtInData_zh from '../multiLanguage/zh-CN.json';
import builtInData_es from '../multiLanguage/es-ES.json';

type StringMap = {
  [key: string]: string;
};

type LanguageType = {
  code: string;
  locale: string;
};

const accessToken = 'bb303a44ea254fc515ced59930569a0a8d39d306bd472d04e3560754ba1cdc1c35154d036023029a';
const projectId = '695005';
enum LanguageEnum {
  ENGLISH = 'en_US',
  CHINESE = 'zh_CN',
  Spanish = 'es_ES',
}
const languageKeyMap = {
  'en-US': 'en_US',
  'zh-CN': 'zh_CN',
  'es-ES': 'es_ES'
}
const reverseLanguageKeyMap = {
  'en_US': 'en-US',
  'zh_CN': 'zh-CN',
  'es_ES': 'es-ES'
}

export const getCurrentLang = () => {
  return localStorage.getItem('i18n/currentLang') || LanguageEnum.ENGLISH;
};

export const setCurrentLang = (value) => {
  localStorage.setItem('i18n/currentLang', value);
};

const currentLang = getCurrentLang()

const getBuildInData = () => {
  switch (currentLang) {
  case LanguageEnum.ENGLISH:
    return builtInData_en;
  case LanguageEnum.CHINESE:
    return builtInData_zh;
  case LanguageEnum.Spanish:
    return builtInData_es;
  default:
    return builtInData_en;
  }
}

let supportLangs: LanguageType[] = [];
let finalData: StringMap = getBuildInData();

export const fetchLatestData = async () => {
  try {
    console.log('Fetching data for language:', currentLang);
    finalData = getBuildInData()
    const filesData = await axios.get(`https://api.crowdin.com/api/v2/projects/${projectId}/files`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const files = filesData.data.data
    const dashBoardFile = files.find(element => element.data.name.includes('dashboard.'));

    if (!dashBoardFile) {
      throw new Error('Dashboard file not found');
    }
    let latestUrl = ''
    let builtInUrl = ''
    let builtInData: StringMap | null = null
    if (currentLang === LanguageEnum.ENGLISH) {
      const en = await axios.get(`https://api.crowdin.com/api/v2/projects/${projectId}/files/${dashBoardFile.data.id}/download`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        responseType: 'json'
      })
      latestUrl = en.data.data.url
      builtInUrl = builtInDataKey.enUrl;
    } else {
      const zh = await axios.post(`https://api.crowdin.com/api/v2/projects/${projectId}/translations/builds/files/${dashBoardFile.data.id}`, {
        targetLanguageId: reverseLanguageKeyMap[currentLang]
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      })
      latestUrl = zh.data.data.url
      builtInUrl = builtInDataKey.zhUrl;

    }
    builtInData = getBuildInData()

    if (builtInUrl == latestUrl) {
      console.log('已经是最新版文案，无需变更');
      finalData = builtInData
    } else {
      console.log('文案已更新', latestUrl);
      const response = await axios.get(latestUrl);
      finalData = response.data;

    }
  } catch (error) {
    finalData = getBuildInData()
    Sentry.captureEvent({
      message: '[multi language] update language failed',
      level: 'info',
      extra: {
        additionalInfo: error
      }
    });
  }
};


export const getSupportLangs = async () => {
  if (supportLangs.length) {
    return supportLangs
  } else {
    try {
      const projectData = await fetchProjectLanguages();
      const sourceLanguage = projectData.data.sourceLanguage;
      const targetLanguage = projectData.data.targetLanguages.map((item) => {
        return {
          code: item.twoLettersCode, //zh
          locale: languageKeyMap[item.locale]//"zh-CN"
        }
      });
      supportLangs = [...targetLanguage, {
        code: sourceLanguage.twoLettersCode,
        locale: languageKeyMap[sourceLanguage.locale]
      }]
      return supportLangs
    } catch (error) {
      Sentry.captureEvent({
        message: '[multi language] get language list failed',
        level: 'info',
        extra: {
          additionalInfo: error
        }
      });
      return [];
    }
    // return await fetchSupportedLanguages()
  }

}


// 获取项目的语言设置
async function fetchProjectLanguages () {
  try {
    const response = await axios.get(`https://api.crowdin.com/api/v2/projects/${projectId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    return response.data;
  } catch (error) {
    Sentry.captureEvent({
      message: '[multi language] get project info failed',
      level: 'info',
      extra: {
        additionalInfo: error
      }
    });
    throw error;
  }
}

export const _i18n = {
  t: (key, params = {}) => {
    const builtInData = getBuildInData()
    const template = finalData ? finalData[key] : builtInData[key];
    if (!template) {
      Sentry.captureEvent({
        message: '[multi language] single key miss',
        level: 'info',
        extra: {
          additionalInfo: `[multi language] ${key} in ${currentLang} is missed`
        }
      });
      return '';
    }
    // 使用正则表达式替换占位符
    return template.replace(/{(\w+)}/g, (match, p1) => {
      return (p1 in params) ? params[p1] : match;
    });
  }
}
