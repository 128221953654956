import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { TimePicker } from 'antd'
import { timeStringToMilliseconds, secondsToTimeString } from '@/utils'
const { RangePicker } = TimePicker;

type TimeRangePickerProps = {
  value: [number, number],
  onChange: (value: [number, number]) => void,
  disabled?: boolean,
  format?: 'hh:mm A' | 'HH:mm',
  use12Hours?: boolean,
  endTimeSet?: boolean,
}

const TimeRangePicker = (props: TimeRangePickerProps) => {
  const { value, onChange, disabled = false, format = 'hh:mm A', use12Hours = true, endTimeSet = false } = props;

  const handleChangeHours = (timeString) => {
    // setTime(timeString);
    const timeData = timeString.map((item, index) => {
      if (index === 1 && endTimeSet) {
        return timeStringToMilliseconds(item, use12Hours) + 59;
      }
      return timeStringToMilliseconds(item, use12Hours);
    })
    onChange(timeData);
  }
  return (
    <RangePicker
      value={[dayjs(secondsToTimeString(value[0], use12Hours), format), dayjs(secondsToTimeString(value[1], use12Hours), format)]}
      disabled={disabled}
      format={format}
      onChange={ (value, timeString) => handleChangeHours(timeString) }
      allowClear={ false }
      needConfirm={ false }
      use12Hours={use12Hours}
    />
  );
}
export default TimeRangePicker;